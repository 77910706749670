import { memo, useEffect, useLayoutEffect, useState } from "react"
import { lazy, Suspense } from "react"
import { useConsole } from "contexts/Console"
import { useWebSocket } from "contexts/WebSocket"
import { WatchVariationsContextProvider, useWatchVariations, mvSubscribe } from "contexts/Configurator"
import { Main, Wrapper } from "./"
import { Share } from "./styles"
import { Route } from "./Route"
import { Collection } from "./Collection"
import { VarNav } from "./VarNav"
import { StepList } from "./StepList"
import { WatchRoller } from "./WatchRoller"
import { BottomNav } from "./BottomNav"
import { Header } from "./Header"
import { WishlistButton } from "./WishlistButton"
import { GlassDoor } from "./GlassDoor"
import { logmine } from "./utils"
import { useLocation } from "react-router-dom"
import { BroadCast } from "components/components"
import { useNavigation } from "contexts/Navigation"
import useSSR from "utils/useSSR"
import { useSync, useProspect, readyStates } from "contexts/Prospect"
import { BusyLoader } from "./BusyLoader"
import styled from "@emotion/styled"

const PATH = {
  bg: "/bg/configurator-variation-thumbnail-background.jpg",
  model: "/dam/2022/upright-cc/",
  dial: "/dam/2022/harmonised/dial-raw-with-colored-shadow/",
  bezel: "/dam/2022/harmonised/bezel-with-colored-shadow/",
  bracelet: "/dam/2022/harmonised/bracelet-ud/",
  policy: "v6-model-feature",
}

const PARAMS = {
  build: (imagesPath, facet, code, v) => {
    return facet === "material"
      ? `${imagesPath}/material/${v}.jpg`
      : `${imagesPath}${PATH.bg}?impolicy=${PATH.policy}&c1path=${PATH[facet]}${v}.png&${PARAMS[code][facet]}`
  },
  "oyster-perpetual-28": {
    model: "sc1=2.07&c1x=-107&c1y=-126",
    dial: "sc1=2.74&c1x=-174&c1y=-198",
    bezel: "sc1=2.07&c1x=-107&c1y=-129",
    bracelet: "sc1=2.74&c1x=-174&c1y=-30",
  },
  "lady-datejust": {
    model: "sc1=2.07&c1x=-107&c1y=-121",
    dial: "sc1=2.74&c1x=-173&c1y=-191",
    bezel: "sc1=2.07&c1x=-107&c1y=-124",
    bracelet: "sc1=2.74&c1x=-173&c1y=-41",
  },
  "oyster-perpetual-31": {
    model: "sc1=2.07&c1x=-107&c1y=-121",
    dial: "sc1=2.74&c1x=-173&c1y=-190",
    bezel: "sc1=2.07&c1x=-107&c1y=-125",
    bracelet: "sc1=2.74&c1x=-174&c1y=-14",
  },
  "datejust-31": {
    model: "sc1=2.07&c1x=-107&c1y=-120",
    dial: "sc1=2.74&c1x=-174&c1y=-191",
    bezel: "sc1=2.07&c1x=-107&c1y=-123",
    bracelet: "sc1=2.74&c1x=-174&c1y=-41",
  },
  "oyster-perpetual-34": {
    model: "sc1=2.07&c1x=-107&c1y=-114",
    dial: "sc1=2.74&c1x=-175&c1y=-188",
    bezel: "sc1=2.07&c1x=-107&c1y=-122",
    bracelet: "sc1=2.74&c1x=-174&c1y=-19",
  },
  "pearlmaster-34": {
    model: "sc1=2.07&c1x=-107&c1y=-114",
    dial: "sc1=2.74&c1x=-174&c1y=-186",
    bezel: "sc1=2.07&c1x=-107&c1y=-118",
    bracelet: "sc1=2.74&c1x=-175&c1y=-46",
  },
  "oyster-perpetual-36": {
    model: "sc1=2.07&c1x=-107&c1y=-112",
    dial: "sc1=2.74&c1x=-174&c1y=-181",
    bezel: "sc1=2.07&c1x=-107&c1y=-118",
    bracelet: "sc1=2.74&c1x=-174&c1y=-22",
  },
  "datejust-36": {
    model: "sc1=2.07&c1x=-107&c1y=-111",
    dial: "sc1=2.74&c1x=-174&c1y=-182",
    bezel: "sc1=2.07&c1x=-107&c1y=-117",
    bracelet: "sc1=2.74&c1x=-176&c1y=-38",
  },
  "day-date-36": {
    model: "sc1=2.07&c1x=-107&c1y=-112",
    dial: "sc1=2.74&c1x=-173&c1y=-182",
    bezel: "sc1=2.07&c1x=-107&c1y=-118",
    bracelet: "sc1=2.74&c1x=-174&c1y=-35",
  },
  explorer: {
    model: "sc1=2.07&c1x=-107&c1y=-114",
    dial: "sc1=2.74&c1x=-173&c1y=-180",
    bezel: "sc1=2.07&c1x=-107&c1y=-119",
    bracelet: "sc1=2.74&c1x=-173&c1y=-39",
  },
  "yacht-master-37": {
    model: "sc1=2.07&c1x=-107&c1y=-113",
    dial: "sc1=2.74&c1x=-175&c1y=-191",
    bezel: "sc1=2.07&c1x=-107&c1y=-115",
    bracelet: "sc1=2.74&c1x=-173&c1y=-25",
  },
  "cellini-time": {
    model: "sc1=2.07&c1x=-106&c1y=-110",
    dial: "sc1=2.74&c1x=-173&c1y=-172",
    bezel: "sc1=2.07&c1x=-106&c1y=-112",
    bracelet: "sc1=2.74&c1x=-176&c1y=-35",
  },
  "cellini-date": {
    model: "sc1=2.07&c1x=-106&c1y=-110",
    dial: "sc1=2.74&c1x=-173&c1y=-172",
    bezel: "sc1=2.07&c1x=-106&c1y=-112",
    bracelet: "sc1=2.74&c1x=-176&c1y=-35",
  },
  "cellini-moonphase": {
    model: "sc1=2.07&c1x=-106&c1y=-110",
    dial: "sc1=2.74&c1x=-173&c1y=-172",
    bezel: "sc1=2.07&c1x=-106&c1y=-112",
    bracelet: "sc1=2.74&c1x=-176&c1y=-35",
  },
  "pearlmaster-39": {
    model: "sc1=2.07&c1x=-106&c1y=-112",
    dial: "sc1=2.74&c1x=-172&c1y=-182",
    bezel: "sc1=2.07&c1x=-106&c1y=-114",
    bracelet: "sc1=2.74&c1x=-174&c1y=-22",
  },
  "cosmograph-daytona": {
    model: "sc1=2.07&c1x=-107&c1y=-107",
    dial: "sc1=2.74&c1x=-175&c1y=-183",
    bezel: "sc1=2.07&c1x=-107&c1y=-111",
    bracelet: "sc1=2.74&c1x=-174&c1y=-20",
  },
  "day-date-40": {
    model: "sc1=2.07&c1x=-107&c1y=-101",
    dial: "sc1=2.74&c1x=-174&c1y=-179",
    bezel: "sc1=2.07&c1x=-107&c1y=-111",
    bracelet: "sc1=2.74&c1x=-174&c1y=-35",
  },
  "gmt-master-ii": {
    model: "sc1=2.07&c1x=-107&c1y=-109",
    dial: "sc1=2.74&c1x=-174&c1y=-177",
    bezel: "sc1=2.07&c1x=-107&c1y=-111",
    bracelet: "sc1=2.74&c1x=-174&c1y=-26",
  },
  milgauss: {
    model: "sc1=2.07&c1x=-107&c1y=-105",
    dial: "sc1=2.74&c1x=-175&c1y=-187",
    bezel: "sc1=2.07&c1x=-107&c1y=-111",
    bracelet: "sc1=2.74&c1x=-174&c1y=-30",
  },
  "yacht-master-40": {
    model: "sc1=2.07&c1x=-107&c1y=-110",
    dial: "sc1=2.74&c1x=-174&c1y=-177",
    bezel: "sc1=2.07&c1x=-107&c1y=-111",
    bracelet: "sc1=2.74&c1x=-173&c1y=-25",
  },
  "air-king": {
    model: "sc1=2.07&c1x=-107&c1y=-105",
    dial: "sc1=2.74&c1x=-174&c1y=-177",
    bezel: "sc1=2.07&c1x=-107&c1y=-111",
    bracelet: "sc1=2.74&c1x=-174&c1y=-25",
  },
  "oyster-perpetual-41": {
    model: "sc1=2.07&c1x=-107&c1y=-106",
    dial: "sc1=2.74&c1x=-174&c1y=-174",
    bezel: "sc1=2.07&c1x=-107&c1y=-112",
    bracelet: "sc1=2.74&c1x=-174&c1y=-11",
  },
  "datejust-41": {
    model: "sc1=2.07&c1x=-107&c1y=-104",
    dial: "sc1=2.74&c1x=-173&c1y=-175",
    bezel: "sc1=2.07&c1x=-107&c1y=-110",
    bracelet: "sc1=2.74&c1x=-173&c1y=-31",
  },
  submariner: {
    model: "sc1=2.07&c1x=-107&c1y=-108",
    dial: "sc1=2.74&c1x=-173&c1y=-188",
    bezel: "sc1=2.07&c1x=-107&c1y=-113",
    bracelet: "sc1=2.74&c1x=-174&c1y=-18",
  },
  "submariner-date": {
    model: "sc1=2.07&c1x=-107&c1y=-108",
    dial: "sc1=2.74&c1x=-173&c1y=-188",
    bezel: "sc1=2.07&c1x=-107&c1y=-113",
    bracelet: "sc1=2.74&c1x=-174&c1y=-18",
  },
  "explorer-ii": {
    model: "sc1=2.07&c1x=-108&c1y=-105",
    dial: "sc1=2.74&c1x=-173&c1y=-172",
    bezel: "sc1=2.07&c1x=-108&c1y=-111",
    bracelet: "sc1=2.74&c1x=-174&c1y=-32",
  },
  "sky-dweller": {
    model: "sc1=2.07&c1x=-107&c1y=-101",
    dial: "sc1=2.74&c1x=-174&c1y=-181",
    bezel: "sc1=2.07&c1x=-107&c1y=-107",
    bracelet: "sc1=2.74&c1x=-174&c1y=-27",
  },
  "yacht-master-42": {
    model: "sc1=2.07&c1x=-107&c1y=-106",
    dial: "sc1=2.74&c1x=-173&c1y=-181",
    bezel: "sc1=2.07&c1x=-107&c1y=-109",
    bracelet: "sc1=2.74&c1x=-174&c1y=-16",
  },
  "sea-dweller": {
    model: "sc1=2.07&c1x=-107&c1y=-107",
    dial: "sc1=2.74&c1x=-173&c1y=-188",
    bezel: "sc1=2.07&c1x=-107&c1y=-108",
    bracelet: "sc1=2.74&c1x=-174&c1y=-30",
  },
  "rolex-deepsea": {
    model: "sc1=2.07&c1x=-106&c1y=-99",
    dial: "sc1=2.74&c1x=-174&c1y=-184",
    bezel: "sc1=2.07&c1x=-106&c1y=-105",
    bracelet: "sc1=2.74&c1x=-174&c1y=-32",
  },
  "yacht-master-ii": {
    model: "sc1=2.07&c1x=-107&c1y=-105",
    dial: "sc1=2.74&c1x=-174&c1y=-179",
    bezel: "sc1=2.07&c1x=-107&c1y=-106",
    bracelet: "sc1=2.74&c1x=-174&c1y=-35",
  },
}

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  & span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

function MainLoader() {
  const { busy, route } = useWatchVariations()
  const [once, setOnce] = useState(1)

  function busyUpdate(v) {
    if (!route.get()) setOnce(1)
    else if (!v) setOnce(0)
  }

  function routeUpdate(v) {
    if (!v) setOnce(1)
  }

  useEffect(() => mvSubscribe("MainLoader", busy, busyUpdate, false), [])
  useEffect(() => mvSubscribe("MainLoader", route, routeUpdate), [])

  return once ? (
    <LoaderWrapper>
      <BusyLoader size={100} />
    </LoaderWrapper>
  ) : null
}

function ConfiguratorMode({ children, ...props }) {
  const console = useConsole()
  const ws = useWebSocket()

  console.verbose("Configurator(%o)", {})
  return (
    <Suspense fallback={null}>
      {ws.connector !== "puppet" && <Configurator {...props}>{children}</Configurator>}
      {ws.connector === "puppet" && <PuppetConfigurator {...props}>{children}</PuppetConfigurator>}
    </Suspense>
  )
}

const Configurator = memo(({ children, heading, image }) => {
  //logmine("Main", "Configurator")
  const console = useConsole()
  const navigation = useNavigation()
  const ssr = useSSR()

  const prospect = useProspect()

  useLayoutEffect(function autoBroadcast() {
    prospect.navigate(navigation.slug)
  }, [])

  return (
    !ssr && (
      <Main>
        <Wrapper>
          <Route />
          <Header heading={heading} />
          <Collection>{children}</Collection>
          <StepList />
          <WatchRoller />
          <BottomNav />
          <VarNav />
          <WishlistButton />
        </Wrapper>
        <GlassDoor />
      </Main>
    )
  )
})

const PuppetConfigurator = memo(({ children, heading, image }) => {
  //logmine("Main", "Configurator")
  const console = useConsole()

  return (
    <Main>
      <Wrapper>
        <Route />
        <Header heading={heading} />
        <Collection>{children}</Collection>
        <StepList />
        <WatchRoller />
        <BottomNav />
        <WishlistButton />
      </Wrapper>
    </Main>
  )
})

export const ConfiguratorProvider = ({ children }) => {
  //    const hash = process.browser?window.location.hash.split("").slice(1).join(""):''
  const ws = useWebSocket()

  return (
    <WatchVariationsContextProvider
      //        route={!!hash.length?hash:null}
      tint={"light"}
      variations={"model,size,material,bezel,bracelet,dial"}
      varparams={PARAMS}
      mode='family'
      puppet={ws.connector === "puppet"}
      std={ws.connector === "standalone"}
    >
      {children}
    </WatchVariationsContextProvider>
  )
}

export const ConfiguratorActive = ({ children }) => {
  const location = useLocation()
  const { clear } = useWatchVariations()

  useEffect(() => {
    if (!process.browser || !location) return
    const pathname = location.pathname?.split("/")
    const active = !!~pathname.indexOf("configure")
    if (!active) clear()
  }, [location])

  return children
}

export default ConfiguratorMode
