import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { watchShadow } from "./../../../css/shadows"
import { wviPhoneP, wviPadP, wviPadL } from "./../../../css/breakpoints"

export const Main = styled.section`
  --shadow-ratio: 1380 / 1180;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: var(--wv-watch-top);
  z-index: 1;
  pointer-events: none;
  height: calc(var(--wv-watch-height));

  .wv_puppet & {
    ${wviPhoneP(css`
      top: 55%;
      transform: translateY(-50%);
    `)}
  }
`

export const List = styled.div`
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  z-index: 1;
  opacity: 0;
  height: 100%;
  transform-origin: var(--wv-cover-scale-origin);
`

export const ListItem = styled.div`
  --ratio: 1380/1180;
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
  transform-style: preserve-3d;

  opacity: 0;
  &:nth-of-type(1) {
    opacity: 1;
    height: calc(var(--wv-watch-height) * var(--ratio));
  }
  ._wvdb & {
    //    background: rgba(0, 127, 127, 0.5);
  }

  @keyframes wvwrfadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes wvwrfadeout {
    0% {
      opacity: initial;
    }
    100% {
      opacity: 0;
    }
  }

  img {
    -webkit-user-drag: none;
    pointer-events: none;
    position: relative;
    object-fit: cover;
    width: auto;
    height: 100%;
    * & {
      left: 50%;
      transform: translate(-50%, 0);
    }
    [dir="rtl"] & {
      right: 50%;
      transform: translate(50%, 0);
    }
  }
`

export const WLoader = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
