import styled from "@emotion/styled"

export const Main = styled.span`
  position: relative;
  top: 4px;
  //  visibility: hidden;
  //  opacity: 0;

  .contrast-active & {
    rect {
      fill: hsl(0, 0%, 13%);
    }
  }
`
