import React, { useState, useCallback, useEffect, useRef, memo, useLayoutEffect } from "react"
import { Main, Button, ButtonStatic, Image, Label } from "./styles"
import { FADE_DURATION, FADE_EASE } from "./../constants"
import { useMediaLoader } from "./../hooks"
import { useWatchVariations, mvSubscribe, fromCollection } from "contexts/Configurator"
import { logmine, cssApply } from "./../utils"
import { useTransform } from "framer-motion"
import { useEnv } from "contexts/Env"

const VARIANTS = {
  main: {
    hidden: [
      ["transition", `opacity ${FADE_DURATION}ms cubic-bezier(${FADE_EASE})`],
      ["opacity", 0.25],
    ],
    visible: [
      ["transition", `opacity ${FADE_DURATION}ms cubic-bezier(${FADE_EASE})`],
      ["opacity", 1],
    ],
  },
}

function getFeature(step, item) {
  return step === "model" || step === "size" ? item.uid : item.assets[step]
}

function setSource(imagesPath, params, step, item) {
  //logmine("Main", "VarNavRoller", "setSource", params, step, item)
  return !!params && params.build(imagesPath, step, item.nameCode, getFeature(step, item))
}

function useItems(uid, steps, collection, varparams) {
  //logmine("CustomHook", "VarNavRoller", "useItems", uid)
  const [items, setItems] = useState(null)
  const sourcesRef = useRef(null)
  const env = useEnv()

  const update = useCallback(() => {
    //logmine("Callback", "VarNavRoller", "useItems", uid, "update")
    const _steps = steps.get()
    if (!_steps) return setItems(null)
    let [, _stepindex, ..._items] = _steps.split(",")
    if (+_stepindex < 0) return setItems(null)
    const sources = []
    const _collection = collection.get()
    if (!_collection) return setItems(null)
    _items = _items.map(fromCollection(_collection))
    const unready = !!_items.filter(v => !v).length
    if (unready) return setItems(null)
    const imagesPath = `${env.content.origin}${env.content.pathname}` //"https://content.rolex.com/dam/2022"
    sourcesRef.current = _items.reduce((a, v) => {
      let src = setSource(imagesPath, varparams, clean(uid), v)
      let index = sources.indexOf(src)
      if (!~index) {
        sources.push(src)
        index = sources.indexOf(src)
      }
      a.set(v.uid, index)
      return a
    }, new Map())

    sourcesRef.current.set("_", sources)
    _items = sources.map(src => ({
      key: `$WVVR_${uid}_${src}`,
      src: src,
    }))
    //logmine("Callback", "VarNavRoller", "useItems", "update", _items)
    setItems(_items)
  }, [uid, varparams, steps, collection])

  useEffect(() => mvSubscribe("VarNavRoller useItems", steps, update), [steps, update])
  useEffect(() => mvSubscribe("VarNavRoller useItems", collection, update), [collection, update])

  return [items, sourcesRef]
}

const Thumbnail = memo(({ src }) => {
  //logmine("Main", "VarNavRoller", "Thumbnail")
  return <Image src={src} loading='eager' width='100' height='40' alt='' />
})

function buildZIndexes(arr) {
  let k = 1
  return arr.reduce((a, item) => {
    a[item] = k++
    return a
  }, Object.create(null))
}

function firstPos(arr, item) {
  const index = arr.indexOf(item)
  if (index < 0) return
  arr.splice(index, 1)
  arr.unshift(item)
}

function clean(uid) {
  return uid //.replace("size", "model")
}

function buildFade(state, zIndex, straight) {
  return state
    ? [
        ["animation", `wvvnrfadein ${straight ? 0 : FADE_DURATION}ms cubic-bezier(${FADE_EASE})`],
        ["animation-fill-mode", "both"],
        ["zIndex", zIndex],
      ]
    : [
        ["animation", `wvvnrfadeout 0ms linear ${straight ? 0 : FADE_DURATION}ms`],
        ["animation-fill-mode", "both"],
        ["zIndex", zIndex],
      ]
}

function fade(index, selected, zIndexes, straight) {
  const state = +(index === selected)
  return buildFade(state, zIndexes[index], straight)
}

function animate(list, arr, selected, zIndexes, straight) {
  list.forEach(el => {
    cssApply(el, fade(arr.indexOf(el.src), selected, zIndexes, straight))
  })
}

function useReady(uid, items, ref, load, handleReady) {
  //logmine("CustomHook", "VarNavRoller", uid, "useReady")
  const ready = useMediaLoader(`$WVVR_${uid}`, ref, items)

  const update = useCallback(
    v => {
      //logmine("Callback", "VarNavRoller", uid, "useReady update", v)
      if (v && ready.get()) handleReady(uid)
    },
    [uid, ready, handleReady]
  )

  const readyUpdate = useCallback(
    v => {
      //logmine("Callback", "VarNavRoller", uid, "useReady readyUpdate", v)
      if (v) handleReady(uid)
    },
    [uid, handleReady]
  )

  useEffect(() => mvSubscribe("VarNavRoller", load, update), [load, update])
  useEffect(() => mvSubscribe("VarNavRoller", ready, readyUpdate), [ready, readyUpdate])
}

function useDisplay(pos, ref, wactive) {
  //logmine("CustomHook", "VarNavRoller", "useDisplay")

  const update = useCallback(
    _wactive => {
      //logmine("Callback", "VarNavRoller", "useDisplay", "update")
      if (!_wactive) return
      if (!ref.current) return setTimeout(() => update(_wactive), 100)
      const disabled = !+_wactive.smap[pos]
      ref.current.setAttribute("data-disabled", disabled)
      cssApply(ref.current, VARIANTS.main[disabled ? "hidden" : "visible"])
    },
    [pos, ref]
  )

  useEffect(() => {
    //logmine("useEffect", "VarNavRoller", "useDisplay")
    //    update(wactive.get())
    cssApply(ref.current, VARIANTS.main.visible, true)
  }, [ref])

  useTransform(wactive, update)
}

function useHighlight(items, ref, sourcesRef, active) {
  //logmine("CustomHook", "VarNavRoller", "useHighlight")
  const listRef = useRef(null)
  const colRef = useRef(null)

  const update = useCallback(
    straight => {
      const _active = active.get()
      if (!_active || !sourcesRef.current || !listRef.current) return
      const current = listRef.current
      const selected = sourcesRef.current.get(_active)
      firstPos(current, selected)
      const zIndexes = buildZIndexes(current.slice().reverse())
      animate(colRef.current, sourcesRef.current.get("_"), selected, zIndexes, straight)
    },
    [active, sourcesRef, colRef]
  )

  useEffect(() => {
    //logmine("useEffect", "VarNavRoller", "useHighlight", "items", items)
    if (!items) return
    listRef.current = items.map((v, i) => i)
    colRef.current = [...ref.current.querySelectorAll("img")]
    update(true)
  }, [items, update, colRef, ref])

  useTransform(active, v => update(false))
  //    useEffect(() => mvSubscribe("VarNavRoller useHighlight", active, v => update(false), false), [active, update])
}

export const VarNavRoller = memo(({ ctx, uid, pos, label, load, handleReady }) => {
  //logmine("Main", "VarNavRoller", uid)
  const { steps, newstepindex, active, wactive, collection, route, varparams, fromui, tint } = useWatchVariations(ctx)
  const ref = useRef()
  const [theme] = useState(tint.get())
  const [items, sourcesRef] = useItems(uid, steps, collection, varparams)

  const update = useCallback(
    v => {
      //logmine("Callback", "VarNavRoller", "update", v)
      if (!ref.current) return
      ref.current.setAttribute("aria-selected", pos === v)
    },
    [pos]
  )

  const redirect = useCallback(
    e => {
      //logmine("Func", "VarNavRoller", uid, "redirect")
      if (ref.current.getAttribute("aria-selected") === "true" || ref.current.getAttribute("data-disabled") === "true") return
      const _wactive = wactive.get()
      if (!_wactive) return
      fromui.set(true)
      route.set(`/${_wactive.familyCode}/${_wactive.uid}/${clean(uid)}`)
    },
    [uid, route, fromui, wactive]
  )

  useTransform(newstepindex, update)
  //    useEffect(() => mvSubscribe("VarNavRoller", newstepindex, update), [newstepindex, update])

  useDisplay(pos, ref, wactive)
  useReady(uid, items, ref, load, handleReady)
  useHighlight(items, ref, sourcesRef, active)

  return (
    <Main ref={ref}>
      <Button onClick={redirect} theme={theme}>
        {!!items && items.map(item => <Thumbnail {...item} />)}
        <Label>{label}</Label>
      </Button>
    </Main>
  )
})

export const VarNavStatic = memo(({ ctx, uid, pos, label, load, handleReady }) => {
  //logmine("Main", "VarNavRoller", uid)
  const { steps, newstepindex, active, wactive, collection, route, varparams, fromui, tint } = useWatchVariations(ctx)
  const ref = useRef()
  const [theme] = useState(tint.get())

  const update = useCallback(
    v => {
      //      console.log(">>> update", pos, v, ref.current)
      //logmine("Callback", "VarNavRoller", "update", v)
      if (!ref.current) return
      ref.current.setAttribute("aria-selected", pos === v)
    },
    [pos]
  )

  const redirect = useCallback(
    e => {
      //logmine("Func", "VarNavRoller", uid, "redirect")
      if (ref.current.getAttribute("aria-selected") === "true" || ref.current.getAttribute("data-disabled") === "true") return
      const _wactive = wactive.get()
      if (!_wactive) return
      fromui.set(true)
      route.set(`/${_wactive.familyCode}/${_wactive.uid}/${clean(uid)}`)
    },
    [uid, route, fromui, wactive]
  )

  useTransform(newstepindex, update)

  useDisplay(pos, ref, wactive)

  useLayoutEffect(() => {
    update(newstepindex.get())
  }, [])

  return (
    <Main ref={ref}>
      <ButtonStatic onClick={redirect} theme={theme}>
        <Label>{label}</Label>
      </ButtonStatic>
    </Main>
  )
})
