import { useLayoutEffect } from "react"
import { useConsole } from "contexts/Console"
import useConstant from "utils/useConstant"
import EventTarget from "@ungap/event-target"
import { Provider, readyStates } from "contexts/WebSocket"

let unloading = false // track if page is unloading, prevent attempting to reopen WS connection
global?.addEventListener?.("beforeunload", () => (unloading = true))

const protocol = global?.document?.location?.protocol === "https:" ? "wss:" : "ws:"
const sockets = new Map()

const storage = (function () {
  if (!process.browser) return {}

  try {
    localStorage.setItem("__wstest", "__wstest")
    localStorage.removeItem("__wstest")
    return localStorage
  } catch {
    return sessionStorage
  }
})()

const MAX_CONNEXION_ATTEMPTS = Infinity

export default function WebSocket({ children, id, host = global?.document?.location?.hostname, connector, timeout = 5000, token: ssrToken }) {
  const console = useConsole()
  const path = `/broker/${connector}`
  const et = useConstant(() => new EventTarget())
  const state = useConstant(() => ({
    queue: [],
    token: null,
    get readyState() {
      const wss = sockets.get(id)
      if (this.token) return readyStates.TOKENIZED
      else return wss?.readyState ?? readyStates.CLOSED
    },
  }))

  useLayoutEffect(() => {
    const addListeners = () => {
      const wss = sockets.get(id)
      wss?.addEventListener?.("close", onclose)
      wss?.addEventListener?.("error", onerror)
      wss?.addEventListener?.("message", onframe)
      wss?.addEventListener?.("readystatechange", onreadystatechange)
    }

    const removeListeners = () => {
      const wss = sockets.get(id)
      wss?.removeEventListener?.("close", onclose)
      wss?.removeEventListener?.("error", onerror)
      wss?.removeEventListener?.("message", onframe)
      wss?.removeEventListener?.("readystatechange", onreadystatechange)
    }

    const onclose = e => {
      state.token = null
      console.verbose(`WebSocket::on${e.type}(%o)`, { unloading })
      onreadystatechange()
      if (!unloading) open(1)
    }
    const onerror = e => {
      console.verbose("WebSocket::onerror(%s)", e.message)
      onclose(e)
    }

    const pong = () => {
      console.verbose("WebSocket::pong()")
      const wss = sockets.get(id)
      wss?.send?.("pong")
    }

    const onframe = async e => {
      // console.verbose("WebSocket::onframe()")
      if (e.data === "ping") return pong()

      const wss = sockets.get(id)
      try {
        const message = JSON.parse(e.data)

        switch (true) {
          case message?.type === "error": {
            console.error("WebSocket::onmessage::error(%s)", message.error ?? "") //TODO : manage error
            break
          }
          case message?.type === "identify": {
            const token = storage.getItem("ws.token") ?? null
            console.verbose("WebSocket::identify() => %o", { token })
            wss.send(JSON.stringify({ type: "identify", token }))
            break
          }
          default: {
            const { type, timeStamp, ...details } = message
            console.verbose("WebSocket::onmessage(%s, %o)", type, details)
            const event = new Event(type)
            Object.assign(event, details)
            et.dispatchEvent(event)
            break
          }
        }
      } catch (err) {
        console.error(err) //TODO
      }
    }

    const ontoken = ({ token }) => {
      console.verbose("WebSocket::ontoken(%o)", { token })
      storage.setItem("ws.token", (state.token = token))

      onreadystatechange()
      while (state.queue.length) {
        const fn = state.queue.shift()
        fn?.()
      }
    }

    const open = async attempt => {
      const url = `${protocol}//${host}${path}`
      console.verbose(`WebSocket::open(%s, %o)`, url, { attempt })

      let wss = sockets.get(id)
      switch (wss?.readyState ?? global.WebSocket.CLOSED) {
        case global.WebSocket.CLOSING: {
          console.verbose("WebSocket::open::CLOSING")
          return
        }
        case global.WebSocket.CLOSED: {
          console.verbose("WebSocket::open::CLOSED", url)
          sockets.set(id, new global.WebSocket(url))
          removeListeners()
          wss = sockets.get(id)
          return open(attempt)
        }
        case global.WebSocket.CONNECTING: {
          console.verbose("WebSocket::open::CONNECTING()")

          await new Promise((resolve, reject) => {
            let rejected = false
            let timer = setTimeout(() => {
              console.warn("WebSocket::open::timeout()")

              rejected = true
              reject(new Error("WebSocket::open::CONNECTING::timeout()"))
            }, timeout)

            wss.addEventListener("open", () => {
              console.verbose("WebSocket::open::onopen()")
              addListeners()
              clearTimeout(timer)
              if ( !rejected ) resolve()
            }, { once: true }) // prettier-ignore
          }).catch(err => {
            if (attempt >= MAX_CONNEXION_ATTEMPTS) throw err
            console.warn(err.message)
            return open(attempt + 1)
          })
          break
        }
        case global.WebSocket.OPEN: {
          console.verbose("WebSocket::open::OPEN()")
          addListeners()
          break
        }
        default:
          break
      }
    }

    const onreadystatechange = () => {
      console.verbose("WebSocket:onreadystatechange(%o)", { readyState: state.readyState })
      const event = new Event("readystatechange")
      Object.assign(event, { readyState: state.readyState })
      et.dispatchEvent(event)
    }

    if (!connector || connector !== "none")
      open(1).catch(err => {
        console.error(err)
        // TODO
      })

    et?.addEventListener?.("token", ontoken)
    return () => {
      et?.removeEventListener?.("token", ontoken)
      removeListeners()
    }
  })

  const addEventListener = (...args) => et.addEventListener(...args)
  const removeEventListener = (...args) => et.removeEventListener(...args)
  const send = (...args) => {
    if (!process.browser) return 1

    if (state.readyState !== readyStates.TOKENIZED) {
      console.verbose("WebSocket::send=>queue(%o)", args)
      state.queue.push(() => send(...args))
      return -1
    }
    try {
      console.verbose("WebSocket::send(%o)", args)
      const wss = sockets.get(id)
      const [type, details = {}] = args
      wss?.send?.(JSON.stringify({ type, ...details }))
      return 1
    } catch (e) {
      return 0
    }
  }

  const ctx = {
    addEventListener,
    removeEventListener,
    send,
    connector,
    get readyState() {
      return state.readyState
    },
    get token() {
      return process.browser ? state.token : ssrToken
    },
  }

  console.verbose("WebSocket(%o)", { connector, host, id, path, timeout, token: ctx.token })
  return <Provider value={ctx}>{children}</Provider>
}
