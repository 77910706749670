import { useLayoutEffect } from "react"
import { Global, css } from "@emotion/react"
import emotionNormalize from "emotion-normalize"
import { Helmet } from "react-helmet-async"
import EventTarget from "@ungap/event-target"
import useConstant from "utils/useConstant"
import { useConsole } from "contexts/Console"
import cssVarFormat from "utils/cssVarFormat"
import { Provider } from "contexts/Theme"

const globals = css`
  ${emotionNormalize}

  /* Helvetica Now Light */
  @font-face {
    font-family: "Helvetica Now Text";
    src: url("https://static.rolex.com/Fonts/Rolex/HelveticaNow/8d8e92a7-bfd4-4de7-bc65-45be2306bf81.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
  }

  /* Helvetica Now Light Italic */
  @font-face {
    font-family: "Helvetica Now Text";
    src: url("https://static.rolex.com/Fonts/Rolex/HelveticaNow/74164382-f210-4a60-95bc-999091a2ed5c.woff2") format("woff2");
    font-weight: 300;
    font-style: italic;
    font-display: fallback;
  }

  /* Helvetica Now Regular */
  @font-face {
    font-family: "Helvetica Now Text";
    src: url("https://static.rolex.com/Fonts/Rolex/HelveticaNow/aa38329d-5165-4fb4-82c8-fa97778b7cbd.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
  }

  /* Helvetica Now Regular Italic */
  @font-face {
    font-family: "Helvetica Now Text";
    src: url("https://static.rolex.com/Fonts/Rolex/HelveticaNow/bd4b5949-893c-4f87-843f-a84867b2b03a.woff2") format("woff2");
    font-weight: 400;
    font-style: italic;
    font-display: block;
  }

  /* Helvetica Now Bold */
  @font-face {
    font-family: "Helvetica Now Text";
    src: url("https://static.rolex.com/Fonts/Rolex/HelveticaNow/10d97c98-c7f0-4958-b294-92319d027783.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
  }

  /* RolexFont small version (refers to number of characters in font) */
  /* Languages supported: Bosnian, Czech, Estonian, Indonesian, Irish, Lithuanian, Slovak, Slovenian, Swahili, Zulu */

  /* RolexFont S Light */
  @font-face {
    font-family: "RolexFont-S";
    src: url("https://static.rolex.com/Fonts/Rolex/RolexFont/2.1/RolexFont-Light-WebS.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: block;
  }

  /* RolexFont S Light Italic */
  @font-face {
    font-family: "RolexFont-S";
    src: url("https://static.rolex.com/Fonts/Rolex/RolexFont/2.1/RolexFont-LightItalic-WebS.woff2") format("woff2");
    font-weight: 300;
    font-style: italic;
    font-display: fallback;
  }

  /* RolexFont S Regular */
  @font-face {
    font-family: "RolexFont-S";
    src: url("https://static.rolex.com/Fonts/Rolex/RolexFont/2.1/RolexFont-Regular-WebS.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: block;
  }

  /* RolexFont S Regular Italic */
  @font-face {
    font-family: "RolexFont-S";
    src: url("https://static.rolex.com/Fonts/Rolex/RolexFont/2.1/RolexFont-Italic-WebS.woff2") format("woff2");
    font-weight: 400;
    font-style: italic;
    font-display: fallback;
  }

  /* RolexFont S Bold */
  @font-face {
    font-family: "RolexFont-S";
    src: url("https://static.rolex.com/Fonts/Rolex/RolexFont/2.1/RolexFont-Bold-WebS.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
  }

  /* RolexFont S Bold Italic */
  @font-face {
    font-family: "RolexFont-S";
    src: url("https://static.rolex.com/Fonts/Rolex/RolexFont/2.1/RolexFont-BoldItalic-WebS.woff2") format("woff2");
    font-weight: 700;
    font-style: italic;
    font-display: fallback;
  }

  /* RolexFont extra large version (refers to number of characters in font) */
  /* Languages supported: Afrikaans, Albanian, Arabic, Azerbaijani, Basque, Belarusian, Bosnian, Bulgarian, Catalan, Croatian, Czech, Danish, Dutch, English, Estonian, Faroese, Filipino, Finnish, French, Galician, German, Hungarian, Icelandic, Indonesian, Irish, Italian, Kazakh, Latvian, Lithuanian, Macedonian, Malay, Mongolian, Norwegian Bokmål, Persian, Polish, Portuguese, Romanian, Russian, Serbian, Slovak, Slovenian, Spanish, Swahili, Swedish, Turkish, Urdu, Welsh, Zulu */

  /* RolexFont XL Light */
  @font-face {
    font-family: "RolexFont-XL";
    src: url("https://static.rolex.com/Fonts/Rolex/RolexFont/2.1/RolexFont-Light-WebXL.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
  }

  /* RolexFont XL Light Italic */
  @font-face {
    font-family: "RolexFont-XL";
    src: url("https://static.rolex.com/Fonts/Rolex/RolexFont/2.1/RolexFont-LightItalic-WebXL.woff2") format("woff2");
    font-weight: 300;
    font-style: italic;
    font-display: fallback;
  }

  /* RolexFont XL Regular */
  @font-face {
    font-family: "RolexFont-XL";
    src: url("https://static.rolex.com/Fonts/Rolex/RolexFont/2.1/RolexFont-Regular-WebXL.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
  }

  /* RolexFont XL Regular Italic */
  @font-face {
    font-family: "RolexFont-XL";
    src: url("https://static.rolex.com/Fonts/Rolex/RolexFont/2.1/RolexFont-Italic-WebXL.woff2") format("woff2");
    font-weight: 400;
    font-style: italic;
    font-display: fallback;
  }

  /* RolexFont XL Bold */
  @font-face {
    font-family: "RolexFont-XL";
    src: url("https://static.rolex.com/Fonts/Rolex/RolexFont/2.1/RolexFont-Bold-WebXL.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
  }

  /* RolexFont XL Bold Italic */
  @font-face {
    font-family: "RolexFont-XL";
    src: url("https://static.rolex.com/Fonts/Rolex/RolexFont/2.1/RolexFont-BoldItalic-WebXL.woff2") format("woff2");
    font-weight: 700;
    font-style: italic;
    font-display: fallback;
  }

  html {
    background-color: rgb(var(--pure-white));
    background-repeat: no-repeat;
    color: rgb(var(--on-background) / 1);
    font-family: "Helvetica Now Text", Helvetica, Arial, sans-serif;
    /* font-size: var(--root-fs); */
    font-weight: 300;
    line-height: 1;
    min-height: calc(100% + env(safe-area-inset-top));
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html,
  body {
    overscroll-behavior: contain;
    scrollbar-width: thin;
  }

  body {
    width: 100vw;
  }
  html {
    overflow-x: hidden;
  }
  html.lock {
    overflow: hidden;
  }

  * {
    touch-action: pan-x pan-y;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }

  // Important rule for Safari
  *:not(input):not(textarea) {
    user-select: none;
  }

  :is(h1, h2, h3, p, ul, ol, dd, figure) {
    margin: 0;
    padding: 0;
    font-size: inherit;
    line-height: inherit;
  }

  :is(ul, ol) {
    list-style-type: none;
  }

  img {
    display: inline-block;
  }

  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

  button:focus-visible,
  a:focus-visible {
    outline: 2px solid rgb(var(--focus) / 1) !important;
    outline-offset: 3px !important;
  }

  :root {
    --vh100: 100vh;
    --z-modal: 1000;
    --z-over: 100;
    --z-ui: 10;
    --z-content: unset;
    --sat: env(safe-area-inset-top);
  }
`

export const events = {
  rootfschange: "rootfschange",
}

function VH100() {
  useLayoutEffect(() => {
    const onresize = () => {
      document.documentElement.style.setProperty("--vh100", `${document.documentElement.clientHeight}px`)
    }

    if (process.browser && !CSS.supports("height: 100dvh")) {
      global.addEventListener("resize", onresize)
      onresize()
    } else document.documentElement.style.setProperty("--vh100", `100dvh`)
    return () => {
      global.removeEventListener("resize", onresize)
    }
  })

  return null
}

export default function Theme({ children, rootFontSize = [], themes, outlines = false }) {
  const console = useConsole()

  const state = useConstant(() => ({}))
  const et = useConstant(() => new EventTarget())

  const { palette, schemes, supports } = useConstant(() => {
    const palette = {}
    const schemes = {}
    const supports = new Set()

    themes.forEach(({ colorScheme, palette: { colors = [] } = {}, swatches = [] }) => {
      const scheme = (schemes[colorScheme] ??= {})
      supports.add(colorScheme)

      Object.values(colors).forEach(({ key, value }) => {
        palette[key] ??= value
      })

      Object.values(swatches).forEach(({ name, color }) => {
        palette[color.key] ??= color.value
        scheme[name] = { color: color.key }
      })
    })

    return { palette, schemes, supports: [...supports].join(" ") }
  })

  const cssRootFS = useConstant(() => {
    const calc = rootFontSize.reduce((acc, { unit, value }, i) => {
      unit = unit === "percent" ? "vw" : unit
      return `${acc.length ? acc + " + " : ""}${value}${unit}`
    }, "")
    return `calc(${calc})`
  })

  const [cssVars, cssPrefers] = useConstant(() => [
    Object.entries(palette)
      .map(([key, value]) => `${cssVarFormat(key)}: ${value}`)
      .join(";"),
    Object.entries(schemes).reduce((acc, [name, swatches], additional) => {
      const body = Object.entries(swatches)
        .map(([key, { color }]) => `${cssVarFormat(key)}: var(${cssVarFormat(color)})`)
        .join(";")

      if (!additional) return (acc += `:root { ${body}  }`), acc //eslint-disable-line no-sequences
      return (
        (acc += `
      @media (prefers-color-scheme: ${name}){ :root { ${body} } }`),
        acc
      ) //eslint-disable-line no-sequences
    }, ""),
  ])

  useLayoutEffect(() => {
    const onresize = () => {
      state.rootFS = rootFontSize.reduce((acc, { unit, value }) => {
        switch (unit) {
          case "px":
            return acc + value
          case "vw":
          case "percent":
            return acc + global.innerWidth * 0.01 * value
          case "vh":
            return acc + global.innerHeight * 0.01 * value
          default:
            return acc
        }
      }, 0)
      const event = new Event(events.rootfschange)
      Object.assign(event, { rootFS: et.rootFS })
      et.dispatchEvent(event)
    }

    onresize()
    global.addEventListener("resize", onresize)
    return () => global.removeEventListener("resize", onresize)
  })

  const addEventListener = (...args) => et.addEventListener(...args)
  const removeEventListener = (...args) => et.removeEventListener(...args)

  console.verbose("Theme(%o => %o)", { rootFontSize, themes }, { cssRootFS, cssVars, cssPrefers, palette, schemes, outlines })
  return (
    <>
      <Global styles={globals} />
      <Helmet>
        <meta name='color-scheme' content={supports} />
        <style>{`
          :root{ 
            --root-fs: ${cssRootFS};
            ${cssVars}
          }
          ${cssPrefers}
          `}</style>
        <link rel='preload' href='https://fast.fonts.net/t/1.css?apiType=css&projectid=3fadd8a3-dbcb-4cce-bb01-f72c00230171' as='style' />
        {/* preload for fast.fonts.net css to make following <link /> non render-blocking */}
        <link rel='stylesheet' href='https://fast.fonts.net/t/1.css?apiType=css&projectid=3fadd8a3-dbcb-4cce-bb01-f72c00230171' />
        <link rel='preconnect' href='https://static.rolex.com/' crossorigin />
        <link rel='dns-prefetch' href='https://static.rolex.com/' />
        <link
          rel='preload'
          href='https://static.rolex.com/Fonts/Rolex/HelveticaNow/8d8e92a7-bfd4-4de7-bc65-45be2306bf81.woff2'
          as='font'
          type='font/woff2'
          crossOrigin
        />
        <link
          rel='preload'
          href='https://static.rolex.com/Fonts/Rolex/HelveticaNow/aa38329d-5165-4fb4-82c8-fa97778b7cbd.woff2'
          as='font'
          type='font/woff2'
          crossOrigin
        />
        <link
          rel='preload'
          href='https://static.rolex.com/Fonts/Rolex/HelveticaNow/10d97c98-c7f0-4958-b294-92319d027783.woff2'
          as='font'
          type='font/woff2'
          crossOrigin
        />
        {/* <link rel='preload' href='https://static.rolex.com/Fonts/Rolex/RolexFont/2.1/RolexFont-Light-WebS.woff2' as='font' type="font/woff2" crossOrigin /> */}
        {/* <link rel='preload' href='https://static.rolex.com/Fonts/Rolex/RolexFont/2.1/RolexFont-Regular-WebS.woff2' as='font' type="font/woff2" crossOrigin /> */}
        {/* <link rel='preload' href='https://static.rolex.com/Fonts/Rolex/RolexFont/2.1/RolexFont-Bold-WebS.woff2' as='font' type="font/woff2" crossOrigin /> */}
      </Helmet>
      <VH100 />
      <Provider value={{ palette, schemes, addEventListener, removeEventListener, outlines }}>{children}</Provider>
    </>
  )
}
