export const CfgLightTheme = {
  bgcolor: "transparent",
  arrowbtn: "omicron",
  uifgcolor: "rgb(255,255,255)",
  uibgcolor: "rgba(255,255,255, .2)",
}

export const CfgDarkTheme = {
  bgcolor: "transparent",
  arrowbtn: "omicron",
  uifgcolor: "green",
  uibgcolor: "blue",
}
