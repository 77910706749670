import { useConsole } from "contexts/Console"
import { Provider } from "contexts/Suspender"
import { Helmet } from "react-helmet-async"

export default function Suspender({ children, store = new Map() }) {
  const console = useConsole()

  const ctx = (params, resolver, initialStateForcedOrSSR) => {
    const id = (Array.isArray(params) ? params : [params]).filter(param => typeof param === "string").join(":")
    const ssr = initialStateForcedOrSSR === true

    if (!store.has(id)) {
      const initialState = initialStateForcedOrSSR instanceof Object && initialStateForcedOrSSR
      const result = ssr && process.browser ? global.__suspenders?.[id] ?? initialState : initialState

      console.verbose("suspender:set(%s) <= %o", id, { result, ssr })
      store.set(id, {
        done: !!result,
        result: result,
        promise:
          !result &&
          Promise.resolve(resolver?.())
            .catch(err => (console.error(err), null))
            .then(result => {
              const suspender = store.get(id)
              suspender.done = true
              suspender.result = result
              console.verbose("suspender(%s) => %o", id, { result: suspender.result })
            }),
      })
    }

    const suspender = store.get(id)
    console.verbose("suspender:get(%s) <= %o", id, suspender)
    if (!suspender.done) throw suspender.promise

    try {
      return [
        suspender.result,
        ssr ? (
          <Helmet>
            <script type='application/javascript'>
              {!process.browser && `window.__suspenders = window.__suspenders || {}; window.__suspenders["${id}"] = ${JSON.stringify(suspender.result)};`}
            </script>
          </Helmet>
        ) : null,
      ]
    } catch (err) {
      console.error(err)
      return [suspender.result, null]
    }
  }

  console.verbose("Suspender(%o)", { store })
  return <Provider value={ctx}>{children}</Provider>
}
