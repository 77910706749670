import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { motion } from "framer-motion"
import { getMediaQuery } from "./../../css/breakpoints"

export const marginsleft = {
  small: "5vw",
  medium: "10vw",
}

const colStyles = css`
  width: min(1140px, 80%);
  ${
    "" /* @media ${getMediaQuery("m", "max")} {
    width: 90%;
  } */
  }
`

export const View = styled(motion.section)`
  --min-col-width: min(1140px, 80vw);
  --min-col-pad: calc((100vw - var(--min-col-width)) / 2);

  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  background-color: ${({ theme }) => theme.bgcolor};
  padding: ${({ imageBackground }) => imageBackground && "10vh 0"};
  pointer-events: all;

  background-position: center;
  background-size: cover;

  @media (max-aspect-ratio: 1/1) {
    height: 100%;
  }

  html:not(.contrast-active) & {
    background-image: ${({ imageBackground }) => (!!imageBackground?.imageMap[1113] ? `url(${imageBackground.imageMap[1113].standard})` : "none")};

    @media ${getMediaQuery("xs")} {
      background-image: ${({ imageBackground }) => (!!imageBackground?.imageMap[0] ? `url(${imageBackground.imageMap[0].standard})` : "none")};
    }
    @media ${getMediaQuery("s")} {
      background-image: ${({ imageBackground }) => (!!imageBackground?.imageMap[421] ? `url(${imageBackground.imageMap[421].standard})` : "none")};
    }
    @media ${getMediaQuery("m")} {
      background-image: ${({ imageBackground }) => (!!imageBackground?.imageMap[768] ? `url(${imageBackground.imageMap[768].standard})` : "none")};
    }
  }
`

export const Frame = styled(motion.div)`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-aspect-ratio: 1/1) {
    height: 100%;
  }
`

export const ConfiguFrame = styled(Frame)``

export const Strip = styled(motion.ul)`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 100%;
  column-gap: 4px;
  margin: 0 auto;
  padding: 0;
  height: auto;
  ${colStyles};

  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  ${({ type }) =>
    type !== "configurator" &&
    css`
      padding-bottom: 150px;
      margin-bottom: -150px;
    `}
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }

  @media (max-aspect-ratio: 1/1) {
    height: 100%;
    //    grid-auto-columns: 80%;
  }
`

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 638px;
`

export const InteractionZone = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  cursor: move;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`

export const Item = styled.li`
  position: relative;
  list-style: none;
  height: 100%;
  width: 100%;
  @media ${getMediaQuery("m", "min")} {
    & > a > figure > picture > img {
      transform: scale(0.95);
      transform-origin: center center;
    }

    & > a:hover {
      & > figure > picture > img {
        transform: scale(1);
        transform-origin: center center;
        transition: transform 0.3s cubic-bezier(1, 0.7, 0.5, 1);
      }
    }
  }
`
