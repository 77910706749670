import { Helmet } from "react-helmet-async"
import useConstant from "utils/useConstant"
import { useConsole } from "contexts/Console"
import { useLocale, useDir } from "contexts/Locale"
import { useWebSocket } from "contexts/WebSocket"
import { useNavigation } from "contexts/Navigation"

export default function HelmetConfig({ app, children, metas = [] }) {
  const console = useConsole()
  const navigation = useNavigation()
  const ws = useWebSocket()
  const dir = useDir()
  const { locale: currLocale } = useLocale()
  const state = useConstant(() => ({ [currLocale]: metas.find(({ locale }) => locale === currLocale) ?? {} }))
  const defaultTitle = state[currLocale].title
  const titleTemplate = `${defaultTitle}| %s`
  const tags = state[currLocale]?.tags ?? []

  console.verbose("Helmet(%o)", { lang: currLocale, dir, defaultTitle, titleTemplate, tags })
  return (
    <>
      <Helmet defaultTitle={defaultTitle} defer={false} titleTemplate={titleTemplate} encodeSpecialCharacters={false}>
        <html lang={currLocale} dir={dir} className={navigation.app} />
        {tags.map(({ name, content }, i) => (
          <meta key={i} name={name} content={content} />
        ))}
        <link rel='manifest' href={`/${app}/manifest.json`} />
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta name='apple-mobile-web-app-status-bar-style' content='black-translucent' />
        <meta name='viewport' content='initial-scale=1, user-scalable=no, viewport-fit=cover' />
      </Helmet>
      {children}
    </>
  )
}
