import styled from "@emotion/styled"
import { Suspense, useRef } from "react"
import useConstant from "utils/useConstant"
import { useConsole } from "contexts/Console"
import PopOver from "components/v2/majesty/PopOver"
import RollerKeyFeatures from "components/v2/majesty/RollerKeyFeatures"
import { useTranslations } from "contexts/I18N"
import { useWA } from "contexts/WA"
import { useNavigation } from "contexts/Navigation"
import { useWebSocket } from "contexts/WebSocket"

const Root = styled.button`
  align-items: center;
  background: transparent !important;

  /* > svg {
    margin-inline-end: 0.4rem;
  } */
`

function ButtonKF({ rpopover, rmc, family }) {
  const console = useConsole()
  const navigation = useNavigation()
  const translations = useTranslations()
  const ws = useWebSocket()
  const wa = useWA()
  const [fam, prodRmc] = process.browser && navigation.slug.split("/").slice(1).filter(Boolean)

  return (
    <Root
      onClick={() => {
        rpopover.current?.show?.()

        if (process.browser && document.querySelector(".embed")) {
          wa.dispatch({
            detail: {
              triggerName: "featuresClicked",
              productFamily: family || fam,
              productRMC: rmc || prodRmc,
              pageScreenMode: "embed screen",
            },
          })
        } else {
          wa.dispatch({
            detail: {
              triggerName: "featuresClicked",
              productFamily: family || fam,
              productRMC: rmc || prodRmc,
            },
          })
        }
      }}
    >
      {/* <svg width='15px' height='4px' viewBox='0 0 15 4'>
        <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
          <g transform='translate(-18.000000, -668.500000)' fill='#212121' fill-rule='nonzero'>
            <g transform='translate(0.000000, 663.000000)'>
              <g transform='translate(18.000000, 5.500000)'>
                <path d='M4,2 C4,3.1 3.1,4 2,4 C0.9,4 0,3.1 0,2 C0,0.9 0.9,0 2,0 C3.1,0 4,0.9 4,2 Z M7.5,0 C6.4,0 5.5,0.9 5.5,2 C5.5,3.1 6.4,4 7.5,4 C8.6,4 9.5,3.1 9.5,2 C9.5,0.9 8.6,0 7.5,0 Z M13,0 C11.9,0 11,0.9 11,2 C11,3.1 11.9,4 13,4 C14.1,4 15,3.1 15,2 C15,0.9 14.1,0 13,0 Z'></path>
              </g>
            </g>
          </g>
        </g>
      </svg> */}
      {translations["label-key-features"]}
    </Root>
  )
}

export default function KeyFeatures({ id, mapping, videos = [], beautyShots = [], socialRenditions = [], rmc, family }) {
  const console = useConsole()
  const rroller = useRef()
  const rpopover = useRef()

  const features = useConstant(() => {
    const features = []
    for (let i = 1; i < 11; i += 1) {
      const feature = mapping?.["feature_" + i] ?? {}
      if (Object.keys(feature).length > 1) features.push(feature)
    }

    if (beautyShots.length) features.unshift({ beautyShots: beautyShots })
    if (videos.length) features.unshift(videos[0])
    if (socialRenditions.length) features.push({ socialRenditions: socialRenditions })

    return features
  })

  console.verbose("KeyFeatures:Catalog(%o)", { features, beautyShots, socialRenditions, rmc })
  return (
    <>
      {features.length && <ButtonKF rroller={rroller} rpopover={rpopover} rmc={rmc} family={family} />}
      <Suspense fallback={null}>
        <PopOver id={"popover_" + id} ref={rpopover}>
          <RollerKeyFeatures id={"rollkey" + id + rmc} ref={rroller} features={features} rmc={rmc} family={family} />
        </PopOver>
      </Suspense>
    </>
  )
}
