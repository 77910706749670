import { useLayoutEffect } from "react"

export default function useLockTouchAction() {
  const lock = () => {
    const node = document.scrollingElement
    node.style.setProperty("touch-action", "none")
    node.style.setProperty("overflow-y", "hidden")
  }

  const unlock = () => {
    const node = document.scrollingElement
    node.style.removeProperty("touch-action")
    node.style.removeProperty("overflow-y")
  }

  useLayoutEffect(() => {
    const onresize = () => {
      const sat = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sat") ?? "0px")
      if (document.scrollingElement.scrollHeight - sat > document.scrollingElement.clientHeight) unlock()
      else lock()
    }

    onresize()
    global.addEventListener("resize", onresize)
    return () => {
      unlock()
      global.removeEventListener("resize", onresize)
    }
  })
}
