import { createContext, useContext } from "react"

const Context = createContext()
export const Provider = Context.Provider
export function useTranslations() {
  return useContext(Context).translations
}
export function useTransform() {
  return useContext(Context).transform
}
