import { css } from "@emotion/react"
import { linearToRight } from "css/gradients"

export function gradient(darker, lighter) {
  return css`
    background: ${linearToRight(darker, lighter)};
    background-clip: text;
    color: transparent;
  `
}

/**
 * https://codesandbox.io/s/clamp-linear-intepolation-based-on-viewport-width-builder-xgkft
 */

export const big = css`
  /* font-size: 2.5rem; */
  font-size: clamp(1.875rem, 1.4063rem + 2.0833vw, 2.5rem);
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 1.1;
`

export const large = css`
  /* font-size: 1.875rem; */
  font-size: clamp(0.75rem, -0.0938rem + 3.75vw, 1.875rem);
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 1.1;
`

export const medium = css`
  /* font-size: 0.75rem; */
  font-size: clamp(0.625rem, 0.5313rem + 0.4167vw, 0.75rem);
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 1;
`

export const small = css`
  /* font-size: 0.625rem; */
  font-size: clamp(0.5rem, 0.4063rem + 0.4167vw, 0.625rem);
  font-weight: 300; // or 700
  letter-spacing: 0.1em;
  line-height: 1.5;
`

export const xsmall = css`
  /* font-size: 0.5rem; */
  font-size: clamp(0.4rem, 0.4063rem + 0.4167vw, 0.5rem);
  font-weight: 300; // or 700
  letter-spacing: 0.1em;
  line-height: 1.5;
`

export const label = css`
  /* font-size: 0.875rem; */
  font-size: clamp(0.625rem, 0.4375rem + 0.8333vw, 0.875rem);
  font-weight: 300;
  letter-spacing: 0.05em;
  line-height: 1.3;
`

export const smallLabel = css`
  font-size: 0.5rem;
  font-weight: 300; // or 700
  letter-spacing: 0.1em;
  line-height: 1.5;
`

export default css`
  font-family: RolexFont-S, sans-serif;
  letter-spacing: 0.1em;
`
