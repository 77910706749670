import { useConsole } from "contexts/Console"
import { readyStates, Provider } from "contexts/Pairing"
import { useWebSocket, readyStates as wsStates } from "contexts/WebSocket"
import { startTransition, useLayoutEffect, useState } from "react"
import useConstant from "utils/useConstant"

export default function Pairing({ children, ...props }) {
  const console = useConsole()
  const ws = useWebSocket()
  const [readyState, setReadyState] = useState(readyStates.IDLE)
  const activity = useConstant(() => ({ timeStamp: 0 }))
  const [active, setActive] = useState(false)
  const et = useConstant(() => new EventTarget())

  const ctx = {
    addEventListener(...args) {
      return et.addEventListener(...args)
    },
    removeEventListener(...args) {
      return et.removeEventListener(...args)
    },

    get paired() {
      return readyState === readyStates.PAIRED
    },
    get readyState() {
      return readyState
    },

    /** lifecycle */
    create(userId, fn) {
      ws.send("pair:create", { userId })

      if (typeof fn === "function") {
        ws.addEventListener("pair:create", fn)
        return () => {
          ws.removeEventListener("pair:create", fn)
        }
      }

      return () => {}
    },
    close() {
      ws.send("pair:close")
    },

    activity: {
      get active() {
        return active
      },
      get timeStamp() {
        return activity.timeStamp
      },
    },
  }

  useLayoutEffect(function wsStateChange() {
    const onreadystatechange = ({ readyState: wsState }) => {
      startTransition(() =>
        setReadyState(readyState => {
          if (wsState !== wsStates.TOKENIZED) return readyStates.IDLE
          if (readyState === readyStates.IDLE) return readyStates.CHECKING
          return readyState
        })
      )
    }
    ws.addEventListener("readystatechange", onreadystatechange)
    onreadystatechange({ readyState: ws.readyState })
    return () => {
      ws.removeEventListener("readystatechange", onreadystatechange)
    }
  })

  useLayoutEffect(
    function readystatechange() {
      let interval
      const onpaircheck = ({ paired }) => startTransition(() => setReadyState(readyStates[paired ? "PAIRED" : "UNPAIRED"]))
      const onpaircreate = () => startTransition(() => setReadyState(readyStates["PAIRED"]))
      const onpairclose = () => startTransition(() => setReadyState(readyStates["UNPAIRED"]))
      const onpairerror = ({ error }) => {
        console.error(new Error(error))
      }
      const onactivitychange = ({ active }) => {
        const event = new Event("activitychange")
        Object.assign(event, { active })
        et.dispatchEvent(event)
      }
      const ontouch = () => {
        const event = new Event("touch")
        et.dispatchEvent(event)
      }

      const ping = () => ws.send("pair:ping")

      const pong = () => ws.send("pair:pong")

      if (readyState === readyStates.CHECKING) {
        ws.addEventListener("pair:check", onpaircheck)
        ws.send("pair:check")
      }

      if (readyState === readyStates.UNPAIRED) {
        ws.addEventListener("pair:create", onpaircreate)
      }

      if (readyState === readyStates.PAIRED) {
        ws.addEventListener("pair:close", onpairclose)
        ws.addEventListener("pair:activitychange", onactivitychange)
        ws.addEventListener("pair:touch", ontouch)

        if (ws.connector === "catalog") {
          interval = setInterval(ping, 10000)
        } else {
          ws.addEventListener("pair:ping", pong)
        }
      }

      ws.addEventListener("pair:error", onpairerror)
      return () => {
        clearInterval(interval)
        ws.removeEventListener("pair:check", onpaircheck)
        ws.removeEventListener("pair:create", onpaircreate)
        ws.removeEventListener("pair:close", onpairclose)
        ws.removeEventListener("pair:error", onpairerror)
        ws.removeEventListener("pair:activitychange", onactivitychange)
        ws.removeEventListener("pair:touch", ontouch)
        ws.removeEventListener("pair:ping", pong)
      }
    },
    [readyState]
  )

  useLayoutEffect(() => {
    const onactivitychange = ({ active }) =>
      startTransition(() => {
        if (active) activity.timeStamp = Date.now()
        setActive(active)
      })
    const ontouch = () =>
      startTransition(() => {
        activity.timeStamp = Date.now()
        setActive(true)
      })

    et.addEventListener("activitychange", onactivitychange)
    et.addEventListener("touch", ontouch)

    if (active && readyState !== readyStates.PAIRED) {
      setActive(false)
    } else if (!active && readyState === readyStates.PAIRED) {
      activity.timeStamp = Date.now()
      setActive(true)
    }
    return () => {
      et.removeEventListener("activitychange", onactivitychange)
      et.removeEventListener("touch", ontouch)
    }
  }, [readyState])

  console.verbose("Pairing(%o)", { readyState, active })
  return (
    <>
      <WAPairing paired={ctx.paired} />
      <Provider /*key={readyState}*/ value={ctx}>{children}</Provider>
    </>
  )
}

function WAPairing({ paired }) {
  if (process.browser && global.digitalDataLayer.userInfo) {
    global.digitalDataLayer.userInfo.pairingStatus = paired ? "paired" : "not paired"
  }

  return null
}
