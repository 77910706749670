import styled from "@emotion/styled"
import Wrapper from "../Wrapper"

export const Roller = styled(Wrapper)`
  ${"" /* --wv-collection-roller-img-padding: calc(min(var(--wv-collection-roller-img-width), var(--wv-collection-roller-img-max-width))); */}
  display: grid;
  width: 100%;
  padding-left: 5vw;
  ${"" /* padding-left: calc(50% - var(--wv-collection-roller-img-padding) / 2); */}
  column-gap: 10px;
  max-width: unset;
  margin-bottom: 0;
  padding-bottom: 0;

  height: auto;
  min-height: auto;

  [dir="rtl"] & {
    padding-left: initial;
    padding-right: 5vw;
    ${"" /* padding-right: calc(50vw - var(--wv-collection-roller-img-padding) / 2); */}
  }

  & > li:last-child::before {
    content: "";
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    padding-left: initial;
    padding-right: 5vw;
    ${"" /* padding-right: calc(50vw - var(--wv-collection-roller-img-padding) / 2); */}
  }

  [dir="rtl"] & > li:last-child::before {
    left: initial;
    right: 0;
    padding-right: initial;
    padding-right: 5vw;
    ${"" /* padding-left: calc(50vw - var(--wv-collection-roller-img-padding) / 2); */}
  }
`
