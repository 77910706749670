import { useCallback, useEffect, useContext, createContext } from "react"
import { useMotionValue } from "framer-motion"

const Ctx = createContext(null)

function updateLoadingQ(loadingQ, index, state) {
  let q = loadingQ.split("")
  q[index] = state
  //  console.log('>>>',q.join("") )
  return q.join("")
}

export const findById = (id, list, key) => {
  return !!list && !!key ? list.find(v => v[key] === id) : null
}

function buildWorldsRules(list) {
  if (!list) return null
  const rules = new Map()
  list.map((item, i) => {
    if (!!item.rules) {
      item.rules.map(rule => {
        let [key, values] = rule.split(":")
        if (!!values) {
          values.split(",").map(value => {
            rules.set([key, `${value}`].join("."), i)
          })
        }
      })
    }
  })
  return rules
}

export const WatchVariationsContextProvider = ({ children, ...props }) => {
  const firstload = useMotionValue(true)
  const root = useMotionValue(null)
  const ctxprops = props.ctxprops || null
  //  const _updateDataLayer = props.updateDataLayer || null
  const mode = props.mode || null
  const dict = useMotionValue(null)
  const layout = props.layout || {}
  const datapatch = props.datapatch || null
  const puppet = props.puppet
  const std = props.std
  const tint = useMotionValue(props.tint || null)
  const popin = useMotionValue(props.popin || null)
  const entry = useMotionValue(props.entry || null)
  const reset = useMotionValue(props.reset || null)
  const route = useMotionValue(props.route || "/")
  const family = useMotionValue(props.family || null)
  const familyrange = props.families || []
  const category = useMotionValue(null)
  const flagship = useMotionValue(props.flagship || null)
  const variations = (props.variations || "").split(";")
  const varparams = props.varparams
  const varexpanded = useMotionValue(props.varexpanded || false)
  const stepindex = useMotionValue(props.stepindex || -1)
  const steps = useMotionValue(props.steps || null)
  const active = useMotionValue(props.active || null)
  const wactive = useMotionValue(null)
  const newactive = useMotionValue(props.active || null)
  const wnewactive = useMotionValue(null)
  const loadingQ = useMotionValue("00000")
  const busy = useMotionValue(false)
  const newstepindex = useMotionValue(props.newstepindex || -1)
  const collection = useMotionValue(null)
  //  const labels = useMotionValue(props.labels||null)
  const config = useMotionValue(null)
  const rules = buildWorldsRules(props.worlds)
  const world = useMotionValue(-1)
  //  const wupdate = useMotionValue(false)
  const coverReady = useMotionValue(false)
  const fromui = useMotionValue(false)
  const hashing = useMotionValue(false)
  const moving = useMotionValue(false)
  const cta = useMotionValue(null)
  const rtl = process.browser && document.querySelector("html").getAttribute("dir") === "rtl"
  const winWidth = useMotionValue(process.browser ? document.documentElement.clientWidth : 0)
  const winHeight = useMotionValue(process.browser ? document.documentElement.clientHeight : 0)
  const footeredh = useMotionValue(0)
  const sticky = useMotionValue(false)
  const ended = useMotionValue(false)
  const last = useMotionValue(null)
  const highlight = useMotionValue(false)
  const glow = useMotionValue(false)
  const reversed = useMotionValue(false)

  function updateDataLayer(data = {}) {
    const _wactive = wactive.get()

    return Object.assign(
      {
        triggerName: "configuratorStarted",
        configuratorStep: stepindex.get() + 1, //step in configurator funnel
        productCategory: _wactive.category, //category of products "classic" OR "professional"
        productFamily: _wactive.familyCode, //product family
        productModel: _wactive.nameCode, // product model
        //        productPrice: "",
        productRMC: _wactive.uid, //product RMC
      },
      data
    )
  }

  /*
  const updateDataLayer = (quit = false) =>
    !!_updateDataLayer
      ? _updateDataLayer(
          firstload,
          route.get(),
          fromui.get(),
          stepindex.get(),
          newstepindex.get(),
          category.get(),
          family.get(),
          entry.get(),
          wactive.get(),
          wnewactive.get(),
          quit,
          ended
        )
      : null
*/
  const initQ = useCallback(
    v => {
      loadingQ.set(updateLoadingQ(loadingQ.get(), v, 1))
    },
    [loadingQ]
  )

  const loadQ = useCallback(
    v => {
      loadingQ.set(updateLoadingQ(loadingQ.get(), v, 0))
    },
    [loadingQ]
  )

  const focus = useCallback(() => {
    const { current: el } = root.get()
    if (!el || stepindex.get() < 0) return
    const selector = ".wvContents .wvFocus"
    const target = el.querySelector(selector)
    if (!target) return
    target.click()
  }, [root, stepindex, last, mode])

  const labels = useCallback(
    v => {
      const _dict = dict.get()
      return (!!_dict && _dict[v]) || v
    },
    [dict]
  )

  //  const busy = useTransform(loadingQ, v => !!+v)

  useEffect(
    () =>
      mvSubscribe("ContextProvider", active, v => {
        if (!v) return wactive.set(null)
        const _wactive = fromCollection(collection.get())(v)
        wactive.set(_wactive)
      }),
    [active, wactive, collection]
  )
  useEffect(
    () =>
      mvSubscribe("ContextProvider", newactive, v => {
        if (!v) return wnewactive.set(null)
        const _wnewactive = fromCollection(collection.get())(v)
        wnewactive.set(_wnewactive)
      }),
    [newactive, wnewactive, collection]
  )
  useEffect(
    () =>
      mvSubscribe("ContextProvider", wactive, v => {
        wnewactive.set(v)
      }),
    [wactive, wnewactive]
  )
  //  useEffect(() => mvSubscribe("ContextProvider", newactive, v => wupdate.set(v!==active.get())), [active, newactive, wupdate])
  useEffect(() => mvSubscribe("ContextProvider", collection, v => wactive.set(fromCollection(v)(active.get()))), [collection, active, wactive])
  useEffect(() => mvSubscribe("ContextProvider", loadingQ, v => busy.set(!!+v)), [loadingQ, busy])
  useEffect(
    () =>
      mvSubscribe("ContextProvider", wactive, v => {
        if (!v || !rules) return
        const _rule = rules.get(`material.${v.assets.material}`)
        world.set(_rule === undefined ? 0 : _rule)
        last.set(!v.next[stepindex.get()])
      }),
    [world, rules, wactive, stepindex, last]
  )

  useEffect(
    () =>
      mvSubscribe("ContextProvider", stepindex, v => {
        const _wactive = wactive.get()
        if (!_wactive) return last.set(false)
        last.set(!_wactive.next[v])
      }),
    [stepindex, wactive, last]
  )

  const onResize = useCallback(
    e => {
      winWidth.set(document.documentElement.clientWidth)
      winHeight.set(document.documentElement.clientHeight)
    },
    [winWidth, winHeight]
  )

  useEffect(() => {
    if (!process.browser) return
    window.addEventListener("resize", onResize, false)
    window.addEventListener("orientationchange", onResize, false)

    return () => {
      window.removeEventListener("resize", onResize, false)
      window.removeEventListener("orientationchange", onResize, false)
    }
  }, [onResize])

  const clear = useCallback(() => {
    family.set(null)
    steps.set(null)
    stepindex.set(-1)
    newstepindex.set(-1)
    collection.set(null)
    active.set(null)
    newactive.set(null)
    entry.set(null)
    reset.set(null)
    route.set(null)
  }, [])

  return (
    <Ctx.Provider
      value={{
        firstload,
        root,
        ctxprops,
        mode,
        layout,
        datapatch,
        tint,
        popin,
        entry,
        reset,
        route,
        family,
        familyrange,
        category,
        flagship,
        variations,
        varparams,
        varexpanded,
        stepindex,
        steps,
        active,
        wactive,
        newactive,
        wnewactive,
        //        shadow,
        loadingQ,
        busy,
        newstepindex,
        collection,
        dict,
        labels,
        config,
        rules,
        world,
        //        wupdate,
        coverReady,
        fromui,
        hashing,
        rtl,
        moving,
        cta,
        winWidth,
        winHeight,
        footeredh,
        sticky,
        focus,
        initQ,
        loadQ,
        updateDataLayer,
        ended,
        last,
        highlight,
        glow,
        clear,
        reversed,
        puppet,
        std,
      }}
    >
      {children}
    </Ctx.Provider>
  )
}

export const useWatchVariations = () => useContext(Ctx)

export const mvSubscribe = (ctx, mv, changeFunc, run = true) => {
  const unsub = mv.onChange(v => {
    changeFunc(v)
  })
  if (run) {
    changeFunc(mv.get())
  }
  return unsub
}

export const fromCollection = list => uid => {
  if (!list || !uid) return null
  return list.find(v => v.uid === uid)
}
