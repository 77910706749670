export default function Crown() {
  return (
    <svg viewBox='0 0 426 458.86' width='426' height='458.86' alt=''>
      <path
        d='M468.25,363.93q7-16.46,14.06-32.9c8.23-19.19,16.33-38.44,24.77-57.54,6.56-14.83,11.9-30.21,19.71-44.49a7.89,7.89,0,0,0,.5-1.91c2.09-4.05,2.74-8.67,4.91-12.7,1.82-5.74,5.86-10.74,5.66-17.15-2.15-5.21-5.43-10-6.22-15.71a22.23,22.23,0,0,1,.64-13.47v-3a41,41,0,0,1,7.41-8.9c1.1-1,2.06-2.12,3.71-1a2,2,0,0,1-.52,1.81,4.64,4.64,0,0,0-.26.4,2.32,2.32,0,0,0-.13.47c5.51-1.16,9.12,3,13.37,5.07,1.83.91,3.56,2.26,5.54,3.08.1,3.32-2.95,4-4.8,5.62-5.48,4.73-11.11,9.29-13.54,16.5-.77,2.95-.45,6-.34,8.92.18,4.64-1.33,8.81-3.11,12.94C523.53,249.2,507,288.26,490.31,327.3c-4.43,10.39-8.36,21-13.88,30.95-3.49,6-5.89,12.77-12.38,16.5-1.77.49-3.49.66-4.9-.85-.35-.84-.36-1.84.54-2.11C463.85,370.55,465.67,366.83,468.25,363.93Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#faf4e1" }}
      />
      <path
        d='M162.45,174.17a16.48,16.48,0,0,1,3.87-11.08c4.78-2.62,8.33-7.46,14.31-7.89,1.49,2.77,4.16,2.42,6.74,2.88,8.62,1.54,16.22,5,20.28,13.41.85,1.75,1.32.59,2.07.35,1.19-.37,1.89.15,2.21,1.31-1,4.43-3.29,8.29-5.36,12.22a13.19,13.19,0,0,0,.15,13.28c3.14,5.84,6,11.84,8.79,17.83,3.91,12.09,10.16,23.24,14.44,35.18a3.72,3.72,0,0,1,.15,1.45c-1.67,2.47.21,5.69-1.68,8.13-2.8.17-4-1.93-4.31-4-.83-6.39-3.77-11.92-6.46-17.58C213,229.85,210.59,219,205,209.57c-.66-1.11-1-2.91-2.5-2.74-2,.23-1.63,2.25-1.74,3.62-.51,6.38,2.16,12,4.71,17.52,1.73,3.76,3.57,7.41,3.41,11.69-3.19,1.32-3.56-2.43-5.63-3.13a3.71,3.71,0,0,1-.62-1.26c0-2.25.34-4.61-2.25-5.86-3-4.32-4-9.44-5.8-14.25-.15-2.65-1.75-5-1.73-7.66.88-5.89-.38-7.71-5.55-9.76-8-3.18-15.66-7-20.11-15-.72-1.3-2.13-1.49-3-2.42C162.59,178.54,163.35,176.13,162.45,174.17Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f7edd7" }}
      />
      <path
        d='M400.24,67.15c.62,5.13-.6,10-1.94,14.9-.81,6.17-5.8,9.67-9.1,14.25-2.12.13-2.4,3.94-5.26,2.65-1.91-4.19.9-6.92,3.19-9.68,2-2.38,4.28-4.5,5-7.59-4-.49-4.26,6.35-9,4.4.69-5.3,4-8.28,8.78-10,5.89-1.29,6.08-1.69,4-8.32-2.53-5.3-8.11-5.78-12.62-8-10.79-3.59-21.59-2.18-32.4-.14a2.46,2.46,0,0,1-1.6-.25c-1.74-1.74.68-2.18,1-3.26,1.29-.33,2.09-1.76,3.6-1.6a10.93,10.93,0,0,0,10-6.26c2.83-5.54,7.38-5.81,12.56-4.77a11.85,11.85,0,0,1,1.94.37c1.22,2.64,2,5.16-2.51,4.19-1.55-.33-2,.76-2.89,1.29,6.19,1.37,12.6,1.85,18.3,4.93a26.35,26.35,0,0,1,7.87,7.9C400.31,63.65,398.47,65.8,400.24,67.15Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#8b5f46" }}
      />
      <path
        d='M502.47,91.1c.24,1,.47,2,.71,3.06-1,1.53.8,3.38-.56,4.87-2.63,1-3.77-1.58-5.68-2.33-4.44-4.4-9.94-6.86-15.23-8.47,2.41,1.86,7,1,8.9,5a1.81,1.81,0,0,1-1.38,1.54c-5,.14-8.59-3.78-13.28-4.49-2.41-.64-4.89-.85-7.33-1.27-4.05-1.35-8.33-.77-12.45-1.54-.19-.28-.44-.63-.34-.9,1.28-3.79,7.06-3.12,7.48-4.94,1-4.32,3.21-4.15,6.26-3.8a9.23,9.23,0,0,0,7.1,2c6.53.08,12.06,3,17.43,6.24,1.19.82,2.38,1.67,3.51,2.58-1.32-1.28-1.93-3.31-4.06-3.73a5.89,5.89,0,0,1-4.06-2.32,7.57,7.57,0,0,0-6.65-3.7c-1.86,0-3.24-.58-3.88-2.44a1.56,1.56,0,0,1,.31-1.3C489.73,76.5,497.78,81.36,502.47,91.1Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#825a42" }}
      />
      <path
        d='M255.29,79.06c3.67-3.53,8.63-4.08,13.12-5.73,5.55-.38,10.92.21,15.91,2.9-1,2.74-2.93,1.13-4.43.86-2.44-.45-4.83-1.16-8.19.83a29.11,29.11,0,0,1,22.58,8.17c2.34,3.67,3.16,7.86,4.09,12-.15,5.06-.33,10.13-2.07,15,0,1.63-.57,2.85-2.28,3.29-2.16-1.09-1.08-2.79-.72-4.26.49-2.06,1.43-4,1.39-6.19,1.06-4.4-.11-8.49-1.76-12.51a8.75,8.75,0,0,1-.24-1.34c-1.35-4.55-5.76-5.38-9.1-7.52-11.5-4.36-21.84-1.44-31.63,5a3.4,3.4,0,0,1-3.3,1c-2-3,1.08-4.36,2.18-6.24S254.36,81.29,255.29,79.06Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#764f3b" }}
      />
      <path
        d='M349.26,59.38l1.08-.25c8-1.17,16-2.39,24-.26,3.71,3.06,8.46,5,10.08,10.28.42,1.37,3.25.77,3.48,2.92-1.61,4.2-4,7.71-8.22,9.73-4.79.66-6.59-.84-6.09-5.43.34-3.14.93-6.21-.86-9.15-3-5-7.21-7-13-5.34a9.57,9.57,0,0,1-1.39.3c-2.9.41-6-.31-8.71,1.23a16.79,16.79,0,0,1-1.8.9,1.39,1.39,0,0,1-1.25-.18l-.38,0C346,61.75,347.48,60.46,349.26,59.38Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#e9d2b2" }}
      />
      <path
        d='M542.45,156.3l.95-1.17c7.55-4.79,15.62-6.38,24.31-3.78,1.7.51,3.63.78,4.63,2.6a1,1,0,0,1,0,1.27c-4.13-.16-7.68-2.7-12.41-2.74,6.82.64,12.23,3.63,17.85,6.09a4.07,4.07,0,0,1,1.57,1.2c1,1.37,3.8,2.33,2.61,4.16s-3.22-.51-4.94-.62c-3.82-1.13-6.76-4.07-9.87-5.13a2.31,2.31,0,0,1,1.49,1.48c.06,3.14-.1,6.11-4.51,5.76-3.29-4.12-6.72-8-12.82-7-2.59.22-5.05-1.38-7.79-.11C542.37,158.88,541.36,157.81,542.45,156.3Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#724d38" }}
      />
      <path
        d='M214.55,217q-4.71-9.27-9.44-18.54c-2.08-4.07-2.51-8.13-.33-12.32s4.41-8.7,6.61-13c.63-.75.1-1.71.46-2.52.85-1.67.62-4.16,3.35-4.39l1.23,4.84a9.55,9.55,0,0,1-2.62,6.88c-2.82,3-3.48,7.25-4.24,11.27-.23,1.6-.94,3.11-.78,4.77a28.45,28.45,0,0,0,2.56,8.72c1.72,4.3,4.21,8.32,4.74,13.06C215.86,216.5,215.51,217.12,214.55,217Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#28160e" }}
      />
      <path
        d='M180.32,156.05c-4.34,1-7.49,4.1-11.12,6.35-.87.54-1.56,2-2.88.69,8.16-15,25.81-18.56,40.25-8.16-.22,2.33-1.84,1.33-2.92,1.09-2.24-.51-4.33-1.71-6.74-1.52C191.28,154,185.82,155.25,180.32,156.05Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#7e5a46" }}
      />
      <path
        d='M399.13,62.17c-3.21-1.62-5.17-4.67-7.9-6.84-3.37-4.54-7.47-7-13.42-5.41-1.74.46-3.9.86-5.51-.84,1.52-.6.76-6.88,4.88-1.76.86,1.07.57-2.1,1.22-3.13s2.94,2.06,2.91-1C391.2,45.79,396.77,52.47,399.13,62.17Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#b68665" }}
      />
      <path
        d='M381.31,43.14c.4,4.14-1.73,1.29-2.91,1a10.41,10.41,0,0,1-1.48-.07c-6-1.08-10.09.87-13.06,6.47-2,3.83-5.79,5.89-10.54,4.39-.21-4.5,4-5.61,6.32-8.19.67-.76,2.28-.38,2.43-1.87C368.15,40.71,374.75,42.13,381.31,43.14Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#6d4735" }}
      />
      <path
        d='M294.28,86.09c-.75.12-1.48.84-2.25,0-5.82-6.31-13.52-7-21.49-7,1.65-3.79,3.39-4.51,7.92-3.77,2,.31,3.64,2.38,5.86.9A20.32,20.32,0,0,1,294.28,86.09Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#c99672" }}
      />
      <path
        d='M572.29,155.09c0-.38,0-.76.05-1.14,8.71,3.3,12.81,10.27,14.88,18.81.43,1.76.7,3.55,1,5.32l-.81,2.25c-2,.36-2.94-.74-3.46-2.53-.81-2.81-2.35-5.35-3.21-8.16.15-3.25-3.75-4.31-4-7.35,1.89-.68,3.06,1.36,5.11,1.66a13.41,13.41,0,0,0-4.48-4.9C575.71,157.73,573.3,157.32,572.29,155.09Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#614234" }}
      />
      <path
        d='M197.36,153.16c3.32-.74,5.79,3,9.21,1.77a11,11,0,0,1,5.87,6.09c-1,2.42,2.54,4.91-.1,7.32-4.33-3.66-8.48-7.52-13.09-10.86A4.51,4.51,0,0,1,197.36,153.16Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#c09d82" }}
      />
      <path
        d='M479.27,75.16a6.46,6.46,0,0,1,0,.88c-3.09,2-6.59,2-10.08,2-1.79,1.16-4.21.65-5.23,4-.74,2.44-5.66,2.23-7.7,4.91a2.38,2.38,0,0,1-2.13,2.32c-.87.14-1.09-.39-.92-1.16-.19-1.79,1.89-2.37,2.11-3.94C461.79,77.07,469.64,73.74,479.27,75.16Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#5e3e2d" }}
      />
      <path
        d='M162.45,174.17c1.47,1.68,1.12,4,2,5.87,2.45,5.84,7.67,9.54,11.21,14.54a1.44,1.44,0,0,1-.74,1c-1.72.81-3.18-.86-4.86-.47-3.47.08-4.15-2.42-4.66-5.07a49.9,49.9,0,0,1-3.16-14Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#a78a6a" }}
      />
      <path
        d='M208.39,189.08c.47-7,4.79-12.28,8-18a2,2,0,0,1,.69.15q.09,5.38.17,10.76c-.59,1,.35,2.62-1.17,3.33-1.87-.52-1.8-2.07-1.91-3.5.58-.29-.32.08,0-.47s.05-.67-.25-.21C212.21,183.82,212.47,188,208.39,189.08Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#7e5a46" }}
      />
      <path
        d='M362.07,44.92c.44,2.83-2.64,2.07-3.64,3.55-1.54,2.27-4.6,3.39-5.11,6.52-1.17-.06-1.31,2.66-3,1.13C352.87,51,356.6,47,362.07,44.92Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#341d15" }}
      />
      <path
        d='M212.31,168.11c.7-3.2-1.54-5.45-3-8.69l3.15,1.6c1.31,1.52,2.53,3.09,2.76,5.18-1.86,1.13-1,3.73-2.72,5A2.07,2.07,0,0,1,212.31,168.11Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#7e5a46" }}
      />
      <path
        d='M581.49,168.67c2.44,3.66,2.25,8.66,6,11.66-.08,1.24-.17,2.48-.26,3.71-2.78-.2-4-3.68-7-3.37l-2.73-.53c-3.59-.31-6.58-2.83-10.27-2.76a7.58,7.58,0,0,1-3.48-.71c-1.69-3.33,2.52-1.75,2.88-3.47-1.12-2.32-4.34-2.91-4.89-5.74a6.76,6.76,0,0,1,.24-1.33,2.49,2.49,0,0,1,2.37-1c3.35-.17,3-3.32,4.2-5.21C574.48,160.4,578.41,163.91,581.49,168.67Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#462e26" }}
      />
      <path
        d='M580.36,180c3.86-1.31,4.89,2.13,6.84,4,.54,2.13-.85,3.93-1,6l-2,3.1c-1.68.25-3.67-.79-3.28-1.94C582.3,187.23,579.88,183.72,580.36,180Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#614234" }}
      />
      <path
        d='M408.48,342.16c4.29,2.36,5.85-1,6.81-3.83,1.95-5.79,3.16-11.82,5-17.66,3.16-10.26,4.06-21.11,8.54-31,1.39-3.08,1.83-6.7,2.14-10.12.7-7.46,3.74-14.27,5.38-21.46,3.32-2.62,3.91-6.08,4-10.19a50.52,50.52,0,0,1,4-18.27c3-7,4.24-14.3,6.53-21.39a38.26,38.26,0,0,0,2.32-11.71,4.52,4.52,0,0,1,.71-2.37c.72-1.17,1.66-2.34,3.14-1.94,1.26.33,1.43,1.74,1.5,2.91.2,3.35-.6,6.64-1.17,9.88-1.8,10.14-2.46,20.44-4.49,30.54a10.62,10.62,0,0,0,.52,4.93c.55,9.06-2.13,17.75-3.11,26.64-.33,2.93-.29,6-1.93,8.68-6.9,3.25-6.64,10.05-7.9,16.08-.37,1.8.52,3.23,1.66,4.52a6.32,6.32,0,0,1,1.36,5.15c-.09.83-.37,2.24-.89,2.18-3.22-.36-4.38,1.05-4.75,4.13-.33,2.8-2.61,5.15-2.81,8-.65,9.13-4.85,17.26-7,25.94s-6.93,16.59-8.91,25.36c-1.63,7.23-3.81,13.92-9.22,19.43-2.43,2.47-1.25,4.53,2.23,5,2.62.39,5.35.22,7.73,1.73a1.52,1.52,0,0,1,.69,1.4,3.64,3.64,0,0,1-2.9,1.61c-10.54-.54-21-1.89-31.5-3a3.56,3.56,0,0,1-2.9-1.57.54.54,0,0,1,.33-.74c.08,0,.08-.25.13-.39-4.72,1.84-9.36-.42-14.07-.32-7.78.15-15.46,1-23.18,1.65a7.19,7.19,0,0,1-3.42-.21c-1.55-5.18.63-10,1.81-14.79a31.13,31.13,0,0,1,7.78-14.25c4.68-4.94,9.1-10.8,10.72-17.24,1.81-7.17,5.63-13.81,5.82-21.4a29.53,29.53,0,0,1,.81-4.92c.67-3.34,2.41-4.66,5.68-3,2.81,1.4,3.79.67,3.81-2.53s1.29-6.47,2.71-9.49c3.35.46,3.87,3.05,4.19,5.7.7,6,1,12.09,2.94,17.9a60.93,60.93,0,0,1,1.89,6.31c-.07,4,2.5,5.63,5.8,6.66C400.55,342.83,404.77,340.59,408.48,342.16Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#e8c99c" }}
      />
      <path
        d='M452.4,240.06c-2.69-4.06,0-8,.48-11.89,1.2-10.65,2.92-21.23,4.37-31.85a18.29,18.29,0,0,0-.16-3.44,2.61,2.61,0,0,0-2.65,2.69c.17,9.54-3.36,18.39-6.24,27.08-2.43,7.33-3.71,15-6.83,22.16-1.08,2.47,1.09,5.4.15,8.51s-1.94,4.8-5.21,4.73l0-7.94c1-7,3.46-13.72,4.77-20.68,2-10.43,4.74-20.68,7.36-31,1.91-7.45,3.2-15,5.12-22.45,2.92-11.22,4-22.77,6.87-34,.7-2.78.2-5.64.83-8.39.69-2.4,1.67-4.91.84-7.33a15.89,15.89,0,0,1,2.35-15.09c3.23-4.55,1.62-9.64,1.93-14.49.12-1.7-1.32-3-2.65-4s-3.14-1.95-3.7-3.89c1.36-2.65,3.73-.6,5.53-1.19.65.11,1.23.49,1.85.62,7.52,1.6,11,5.68,8.85,13.65-.53,1.93-.12,3.57,2.71,2.19.72-.36,1.71.75,1.71,2a6,6,0,0,1-1.64,3.58c-7.86,8.15-10.05,18.35-11.26,29.16-3.61,32-8.71,63.89-13.32,95.8C454.21,236.54,454,238.57,452.4,240.06Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#efdbb7" }}
      />
      <path
        d='M460.26,89.07c8.5,4.75,10.62,12.23,6.13,21-2.67,5.19-5.72,10.34-2.83,16.62,1,2.14,0,4.45-1.2,6.46l-.55-.36a17.44,17.44,0,0,1-3.2-5.62,13.4,13.4,0,0,0-1.39-3c-2.12-3.7-5.34-6.79-6.14-11.21-.7-3.84-2.86-7.44-1.86-11.55a20.06,20.06,0,0,1,3.09-11.25C454.94,89.63,457.44,88.18,460.26,89.07Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#faf1de" }}
      />
      <path
        d='M388.43,96.24c-5.76,20.69-.58,41.53-.53,62.28.06,19.91,1.71,39.82,2.72,59.73q2.52,49.76,5.14,99.52a5,5,0,0,0,.49,1.38l.29,9.79c.55,4.91.55,4.91-1.53,5.94a8.16,8.16,0,0,1-2-5.51c-.12-4.81-.76-9.62-.88-14.39-.42-15.94-1.55-31.83-2.45-47.73q-2.31-40.55-4-81.11c-1-24.25-2.8-48.47-2.91-72.76,0-2.17-.43-4.3-.62-6.45-.26-2.86-.26-5.69,2.15-7.84C386.49,99.35,385.6,95.08,388.43,96.24Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#755941" }}
      />
      <path
        d='M462.36,133.14c.16,6.18-1.55,12.1-2.4,18.14-1.38,9.81-3.91,19.36-5.95,29-1.24,5.86-2.27,11.87-3.79,17.74-3,11.47-6.25,22.89-8.25,34.67-1,5.82-2.15,12.15-5.63,17.43-1.26-10.45,2.59-20.13,4.53-30.1,2-10.35,5.12-20.46,6.7-30.92.37-2.43.51-5.41,3.35-6.86.42-.21,1.08-.86,1-1-3.35-6.05,5.55-10.81,1.76-17.14-.78-1.3.44-4.2,1.4-6,.82-1.53,1-2.67.19-4.08-.63-2,0-3.86.4-5.77,3.54-2.67,3.52-6.63,3.78-10.49.14-2-.2-4.38,2.83-4.67Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#faf1de" }}
      />
      <path
        d='M395.52,334.85c1.72-1.73.22-4,1-5.91.25.77.57,1.52.75,2.3,2.22,9.12,9.41,11.31,18,7.09,0,2.37-1.62,3.72-3.26,4.7-4.12.66-11.9,1.89-15.68-1.83a4.89,4.89,0,0,1-1.28-2.72C395.05,337.25,394.46,336,395.52,334.85Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#b9a08e" }}
      />
      <path
        d='M460.26,89.07c-2.65.34-5.12,2-7.95,1.08.08-.79,0-1.65.89-2.1,1.48.85,2-.92,3.05-1.15a23.64,23.64,0,0,1,12.22,1,1.59,1.59,0,0,1-2.19.31C464.26,88.39,462.09,87.52,460.26,89.07Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#b09381" }}
      />
      <path
        d='M449.1,109.28c.13-1.16.29-2.32-.76-3.2.23-1.57-.65-3.36.89-4.68q1.53,5.85,3.08,11.71c-.45.49-.62,1.33-1.54,1.24C449.67,112.84,448.74,111.27,449.1,109.28Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#b5a692" }}
      />
      <path
        d='M382.34,304c.79,4.43-2.51,8.22-1.55,13,.56,2.8-3.22,1.9-4.91.63-3.32-2.5-4.2-1.61-4.8,2.24-1,6.1-3.56,12.23-4.41,17.92-1.45,9.55-6.13,16.77-11.52,23.41-6.44,7.92-10.38,16.17-10.94,26.25-.07,1.18-1.19,2.3-1.82,3.45-3.77,3.5-8.69,3.78-13.23,3.59-3.26-.13-4.53-3.47-3.05-6.51,2.34-4.78,3.13-10.12,5.85-14.77,2.29-3.93,2-8.68,3.21-12.95,1.86-6.81,1.71-12.28-5.38-15.91-1.56-.8-2.15-2.9-3-4.49-2.63-4.73-1.89-5.8,3.87-5.73,2.19,1,3.54,3,5.27,4.57a14.55,14.55,0,0,1,2.38,2.26c1.82,1.64,4.06.7,6.09,1,2.63.34,1.37,1.65.46,2.45-1.82,1.57-2.34,3.53-.85,5.2s3.7.59,5.54-.2c4.62-2,4.89-6.79,6.79-10.53v-9.9a2.43,2.43,0,0,1-.45-1.61c1.45-9.63,4-19.06,4.26-28.91a23.18,23.18,0,0,1,4.3-13.57,4,4,0,0,0,1.08-2.21c.09-7.62,1.75-15.14,1.9-22.71a212.5,212.5,0,0,1,1.71-22.16c.72-5.77,1.4-11.52,1.79-17.31.07-1-.07-2,.08-3,.28-1.86-.24-4.64,2.46-4.64,2.32,0,2.09,2.62,2.27,4.35.21,2-.05,4.1,2.6,4.7a4.76,4.76,0,0,1,1.37,4.12c-.62,7.95.77,15.81,1.25,23.69.89,14.71.41,29.51,2.61,44.15C384,297.28,384.75,300.92,382.34,304Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#efd8b2" }}
      />
      <path
        d='M335.39,340l-4.2-5c-5.07-3.87-4.91-9.9-6.36-15.28.95-7.82-1.59-15.13-3.75-22.38-3.46-11.61-5.74-23.5-8.88-35.18-3.48-13-6-26.12-9.43-39.06-4.76-18.11-7.58-36.6-12-54.75-2.72-11.11-4.29-22.54-8.63-33.21-1.28-3.15.14-7.21-3.3-9.58-2.41-2.19-.86-4.5-.1-6.71a36.29,36.29,0,0,1,2.74-5.69c2.64-3.24,3.67-7.47,6.48-10.61a2.42,2.42,0,0,1,2.14-.66c2.25,2,1.63,4.89,2,7.45a4.23,4.23,0,0,1-.49,1.91c-6.46,9.59-5.45,19.71-2.34,30.06.85,2.83,1.59,5.77.93,8.81,3.82,16.81,7.52,33.65,11.48,50.43Q315.22,258,329,315.32c.53,2.22.35,4.61,1.6,6.66C333.31,327.68,335.82,333.44,335.39,340Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#130803" }}
      />
      <path
        d='M356.3,339c.31,5.66-1.76,9.85-7.17,12.2-2.66,1.16-5.82,1.68-7-.86-.88-1.81-1.73-5.72,2.54-6.72.65-.15.32-1.09-.27-1.54,2.44-1.24,3.76,0,7.84-1.38a27,27,0,0,0,4.1-1.7C357.92,337.85,354.69,340.07,356.3,339Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f1dcb8" }}
      />
      <path
        d='M290.61,143.18l.75,1.86c-1.08,1.54,2.76,4.2-1.15,5.1-2.21-7-3.43-14.32-4.8-21.55-1.42-7.52,2.89-13.25,6-19.34a2,2,0,0,1,1.59-1.63c3,.61,1.38,2.28.91,3.75-1.36,4.27-4.34,7.88-5.07,12.4A39.28,39.28,0,0,0,290.61,143.18Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f0e5d5" }}
      />
      <path
        d='M335.39,340c-1.72-6-4.08-11.78-4.84-18,.82.11.45-2.05,1.8-1a95.44,95.44,0,0,0,6,18.64c0,.45-.07.9-.11,1.35Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#e5dcd1" }}
      />
      <path
        d='M338.38,339.67c-4.23-5.5-5.19-12.05-6-18.64C334.71,327.13,337.3,333.16,338.38,339.67Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#b6aca6" }}
      />
      <path
        d='M347.2,76.23a3.91,3.91,0,0,0-.76-.14c-.16-4-.7-8,.14-12l.59,0c2.31,2.8,1.86,6.19,2,9.46A3.61,3.61,0,0,1,347.2,76.23Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#b5ac99" }}
      />
      <path
        d='M505.22,106.11c-.66,3-1.33,5.92-2,8.88a16.15,16.15,0,0,1-2.38,6.38,12.58,12.58,0,0,1-3.1,2.9c-1.37.91-2.44,2.18-3.73,3.18-1,.81-2.25,1.78-3.5.75-1.41-1.18-.35-2.35.52-3.35a9.64,9.64,0,0,0,1.25-2.09c2.88-4.56,7.81-7.72,9-13.39,1.51-3.27-.24-6.8.83-10.1.45-1.69-.55-3.65,1-5.11C505.4,97.88,503.29,102.34,505.22,106.11Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#5f4433" }}
      />
      <path
        d='M229.49,252.85a3.53,3.53,0,0,1,.07-.86c.21-.49.28-1.09,1-1.19,9.93,22.56,19.81,45.14,29.81,67.66,6.32,14.22,12.83,28.34,19.26,42.51,2.1,2.47,3.67,5.34,5.87,7.74.89,1,1.95,2.13-.31,2.84a5.27,5.27,0,0,1-4.71-3c-1.71-3.35-4.1-6.26-6-9.51-1.77-8.77-6.67-16.24-10.26-24.23Q247.71,298,231.91,260.87A24.75,24.75,0,0,1,229.49,252.85Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#604d36" }}
      />
      <path
        d='M245.3,101.88c-1.47-.93-.75-2.54-1.17-3.8.5-1.66-.55-3.67,1.12-5.07,2.14.21.57-3.58,3.13-2.91L251.29,89c3.07-2,5.83-5.12,10-2.55a1.87,1.87,0,0,1,.35,1.41,4.51,4.51,0,0,1-2.14,1.93c-5,2-9.47,4.57-12.31,9.35C246.66,100.12,246.34,101.26,245.3,101.88Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#b1998a" }}
      />
      <path
        d='M230.53,250.8l-1,1.19q-7.51-17.48-15-35l.93-1.15L217.4,219c3.38,10.31,8.66,19.84,12.52,29.95C230.15,249.55,230.33,250.18,230.53,250.8Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#3b2c21" }}
      />
      <path
        d='M248.38,90.1c-.67,1.28-1.1,2.8-2.08,3.78-1.28,1.3-.64-.6-1.05-.87a23.46,23.46,0,0,1,10-14c.85,2.81-2.11,3.15-3.15,4.74C250.8,85.85,248.85,87.52,248.38,90.1Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#452a22" }}
      />
      <path
        d='M285.34,371.17c-.73-4.06-5-6.15-5.74-10.2,2.39.41,2.55,3.42,4.73,4.06,1.17,3.37,3.11,5.84,7,6,.05.22.11.45.16.68-1.73,2.6-3.81,2.41-6.07.76A1.13,1.13,0,0,1,285.34,371.17Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#dcdad7" }}
      />
      <path
        d='M383.09,391.16c11.62,2,23.39,2.66,35.1,3.91,2.61.9,5.22-1.19,7.84,0,6.76,1.24,13.58.83,20.34.33,3.8-.29,6.79-2.87,9.28-5.5a93.74,93.74,0,0,0,19.78-31.8c12.24-28.54,24.61-57,36.69-85.63,8.33-19.72,17-39.28,24.56-59.34a20.51,20.51,0,0,1,1.69-3c2.46.29,1.87,2.2,2,3.74-1,7.27-4.38,13.75-7.1,20.45-14.89,37.05-29.7,74.13-44.7,111.13-6.82,16.23-13.85,32.37-22.07,48a104.83,104.83,0,0,0-5.4,12.85,144.88,144.88,0,0,0-8.91,25.66c-.54,2.06-.75,4.26-2.32,5.92-2.67.66-2.7-1.4-2.5-2.88,1.58-11.6-5-17.86-14.39-22.37a18,18,0,0,1-3.34-2.2c-8.65-5.19-18.56-5.94-28.09-8-12.78-2.7-25.76-2-38.69-2-14.84.2-29.51,1.84-43,8.41-14.36,7-17.87,15.61-15.72,27.62,2.17,10.28,6.78,19.05,15.68,25.15,12.28,6.53,25.45,9.48,39.26,10a219.62,219.62,0,0,0,51-3.9,47.78,47.78,0,0,0,4.76-.53c1.55-.18,3.32-.38,3.71,1.9a4.37,4.37,0,0,1-3,2.43c-9.44,2.73-18.84,5.37-28.77,6-9.47.63-18.93.88-28.41,1a153.49,153.49,0,0,1-41.53-4.95,6.87,6.87,0,0,0-3.38-.39c3.9,6,10.48,8.06,16.35,11.12,2.94,1.67,6.6,2.1,8.78,5.08-.46,1.58-1.79,1.69-3.1,1.86a161,161,0,0,1-44.94-15.93,18.4,18.4,0,0,1-5.8-4.69c-.86-1.09-2.36-2.14-.94-3.83,1.68-.55,2.83.47,3.89,1.46,3.41,3.2,7.55,5.08,11.83,6.72,3.79,1.54,7.84,2.3,11.35,3.85a85.18,85.18,0,0,1-6.67-6.84c-2.72-3.57-.91-7.76-1.5-11.63-1.25-8.19-3.34-16.22-3.91-24.56-.89-12.93,3.6-23.33,13.16-31.78,1.25-1.11,2.37-2.33,3.6-3.45a29.89,29.89,0,0,1,8.09-4.23C343.16,391,362.87,388.12,383.09,391.16Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#130805" }}
      />
      <path
        d='M455.23,371.23l4.12,1.52c1.25,2,3.18,1,4.86,1.1,2.29,1.44,1.18,2.81-.24,3.88a20.25,20.25,0,0,0-5.09,5.44c-3.65,5.86-9.22,8.23-15.73,9.27-5.07.8-10.26,1.1-15,3.26-2.66-.28-5.45.1-7.77-1.7h0c-.27-3.3,2.15-4.76,4.52-5.76,4.92-2.08,6.57-5.74,6-10.73a17.72,17.72,0,0,1,1.11-8.77c.91-2.24.57-4.51.54-6.83a16.12,16.12,0,0,1,4.87-12c2.43,3.91.94,8.38,1.76,12.56,2,10.09,3.92,11.39,13.13,7.69A3.17,3.17,0,0,1,455.23,371.23Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#c79d6d" }}
      />
      <path
        d='M562.31,166.13c.15,2.3,1.87,3.26,3.57,4.32,1.39.88,3,2.14,2.5,3.86-.4,1.47-2.32.28-3.47.74a15.86,15.86,0,0,0-1.66.92,2.69,2.69,0,0,1-.84,1.86c-3.26,2.51-6.41,5.13-8.63,8.69-2.14,2.27-3.8,5-6.25,7-3.9,2.79-5,2.4-5.61-2-.18-1.44-.5-3,.37-4.35C545.14,179,551.47,173.91,558,169c1.15-.87,2.85-1.14,3.29-2.84A.78.78,0,0,1,562.31,166.13Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#190a07" }}
      />
      <path
        d='M428.34,395c4.28-3.84,9.89-2.75,14.88-3.85,5.6-1.24,10.64-2.84,14.06-7.91,1.78-2.64,3.19-5.77,6.81-6.78,1.3-.37.43-1.68.12-2.58,5.27-4.17,7.73-10.35,11.22-15.79a66.5,66.5,0,0,1-23.64,36.72c-2.63,2.07-6,2.67-9.43,2.51-5.42-.24-10.89.26-16.24-1.1.6-1,1.62-.74,2.43-.62s.12-.28.12-.32C428.73,394.58,428.77,395.48,428.34,395Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#b88c5f" }}
      />
      <path
        d='M542.29,187.12c0,2.6,0,5.19,1.78,7.52l3.39-2.49c.77,4.81-2.59,8.57-3.29,13-1.79,2.93-.9,7.27-4.78,9.1-.39-1.38.47-3-1-4.15a37.06,37.06,0,0,0,2.73-17.33C541,190.89,540.8,188.77,542.29,187.12Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#3f2312" }}
      />
      <path
        d='M562.31,166.13l-1,0c-1.67.11-2.94.54-4.84-.86-4.71-3.46-9.25-8.52-16.31-5.2-.12-2.22.79-3.22,2.33-3.75,0,1.21.42,1.82,1.71,1.35,2.65-1,5.37-.59,8.08-.51,3.74,2.38,8.38,3.75,9.83,8.71Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#bc9b83" }}
      />
      <path
        d='M539.29,197.07l-2,0c-1.47-3.32-3-6.6-4.39-10-.67-1.63-1.47-3.44.33-5C533.41,187.84,537.39,192,539.29,197.07Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#b0a78c" }}
      />
      <path
        d='M208.38,240.06c-1.63-4.21-2.73-8.54-4.74-12.71-2.91-6.06-5.81-12.73-3.82-19.95.31-1.15.25-2.91,2-3.13a2.94,2.94,0,0,1,3.09,2.11c2.27,7.32,7.43,13.23,9.14,21a53.17,53.17,0,0,0,7.39,16.81,20,20,0,0,1,3,8.81c.33,3.17.75,6.31,4,8,2.52,4.89,5.77,9.46,6.36,15.15,1.83,7.61,5.11,14.71,7.75,22,6,16.51,12.84,32.69,19.7,48.8,3.81,8.94,7.26,19.38,15.23,25.34,6.57,4.91,7.59,11.12,9.42,17.64,1.07,3.84,1.51,7.83,2.32,11.73s-2.1,8.61-5.55,10.39c-4,2-6.63-.05-9.16-2.75a27.29,27.29,0,0,1-3.66-4.08c-2.28-6.31-5-12.45-6.9-18.92a24,24,0,0,0-4.22-8.27c-6.48-13.15-10.55-27.28-15.38-41-4.12-11.72-10.56-22.56-13.12-34.83a6.58,6.58,0,0,0-1.35-3.19c-3.45-3.69-4.62-8.36-5.67-13-2.27-10.17-7.74-19-11.56-28.56-1.73-4.31-2.69-8.87-4.42-13.19C207.63,243,207.19,241.41,208.38,240.06Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#fcf7e5" }}
      />
      <path
        d='M181.67,164.08c12,0,17.65,6.67,16.2,19.06-.07.63,0,1.29,0,1.93-.08,5.59-2.86,7.77-8.22,7.09-10.69-1.35-17.5-6.85-20.29-17.43-1.53-5.78,1.68-10.48,7.8-10.65C178.67,164.05,180.17,164.08,181.67,164.08Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#fdfcf9" }}
      />
      <path
        d='M233.38,276a8.28,8.28,0,0,0-1-4.64c-2-3.16-3.88-6.43-4-10.35.62-2.7-2.57-5.92,1.12-8.2,8.65,19.73,17.23,39.5,26,59.2,5.92,13.35,12.06,26.61,18.08,39.92,1,2.23,2.54,4.36,1.74,7-4.39,1.74-6.1-1.83-7.63-4.44-7.09-12-13.51-24.51-17.42-37.92-2.72-9.34-7.76-17.47-11.29-26.34C237.05,285.58,236.49,280.32,233.38,276Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f7e9cb" }}
      />
      <path
        d='M208.38,240.06c3.31,11.2,6.87,22.25,12.77,32.48,2.08,3.6,2.12,8.3,3.78,12.21q9.52,22.29,18.36,44.87c5.87,15,10.51,30.5,16.58,45.47a13.82,13.82,0,0,1,.51,2.92c.94,1.47,1.4,3,.46,4.66-4.17,1.11-4.15-2.66-4.73-4.7-2.26-7.85-4.63-15.77-8.08-23.06-6.26-13.26-8.73-27.92-15.83-40.79-1.13-2-2.09-4.2-4.35-5.3-3.77-8.13-6.21-16.76-9.12-25.2-5.3-15.36-10.65-30.71-15.76-46.15a1.5,1.5,0,0,1,.47-1.41C206.16,236.06,206.74,238.71,208.38,240.06Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f8ebc7" }}
      />
      <path
        d='M212.31,168.11c.06,1,.12,2,.17,3l-1.09,2c-2.88-2.62-1.71,4.49-4.28,1.85-1.44-1.47.16-4.06-1.68-5.87-7.33-7.19-15.53-11.72-26.26-9.44.4-1.25.77-2.43,1.15-3.6,5.26-3.44,11.11-3.38,17-2.89a21.56,21.56,0,0,0,8.07,7.48C208.59,162.33,210.42,165.25,212.31,168.11Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#e3c8b0" }}
      />
      <path
        d='M170.84,196c-.13-.37-.27-.74-.41-1.1,1.36-3.58,2.32,1.18,3.55.18a53.48,53.48,0,0,0,12.72,5.1c.64.18.06-1,.13-.33,0,.26-.14.38-.43.24-3.6-1.78-8.24-1.41-10.83-5.25-4.63-4.25-10.26-7.75-11.09-14.84,1.36.23,3-.45,3.81,1.45,4.08,9,12.3,12.53,20.83,15.49,4.64,1.6,6.89,6.79,4,10.15a1.72,1.72,0,0,1-1.42,0,18.65,18.65,0,0,0-7.36-3.68A27.42,27.42,0,0,1,170.84,196Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#efd7aa" }}
      />
      <path
        d='M203.44,236.06l-.12,1c-.31.47-.62.44-.92,0q-2.6-6.48-5.17-13a12.27,12.27,0,0,1-3.07-9,.85.85,0,0,1,1.31-.09l5.58,14.15c2.53,1.38,1.42,4.09,2.23,6.1A.89.89,0,0,0,203.44,236.06Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#99896e" }}
      />
      <path
        d='M195.47,215h-1c-2.8-1.44-3-4-2.9-6.71a2.51,2.51,0,0,1,.85-1.29,5.16,5.16,0,0,0,.78.08C194.29,209.67,195.91,212.06,195.47,215Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#907e5f" }}
      />
      <path
        d='M203.28,235.29c-.74-2-1.49-4.07-2.23-6.1C205.72,229.78,203.6,232.86,203.28,235.29Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f8ebc7" }}
      />
      <path
        d='M390.17,334.17l-2-6.15c-2.62-19.78-5.94-39.5-6.5-59.49-.23-8.28-.83-16.56-1.23-24.82-.65-13.22-.95-26.49-2.25-39.69-.82-8.27-.57-16.58-1.1-24.87-1-16.24-1.43-32.53-2.4-48.77-.82-13.63-1-27.24-.74-40.86.21-3.81,2.32-6.34,5.51-8.13l8-9.06a4.22,4.22,0,0,1,.81-1.88,2.68,2.68,0,0,1,2.59-.74c1.81,2.15,4.47,4.14,1.32,7.19-4.49,1.61-7.15,5-8.81,9.26a3.57,3.57,0,0,1-.1,2.92c-3.59,7.63-2.6,15.6-1.78,23.54.7,6.79.83,13.62,1.07,20.4.61,17.11,1.48,34.2,2.39,51.28,1.16,21.55,2.08,43.11,3.59,64.64.54,7.6.77,15.24,1.24,22.86,1,16.54,1.42,33.11,3.31,49.6.55,4.76.26,9.62,1.5,14.32.43,2.5-.47,2.95-2.52,1.63C390.93,336.6,391.25,335,390.17,334.17Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#170703" }}
      />
      <path
        d='M394.43,336.9c-3.11-9.59-3.24-19.63-3.88-29.51-2.41-37.51-4.46-75-6.5-112.59-1.68-30.89-3.06-61.8-4.78-92.69-.33-5.9.71-11.16,4.12-16,1-.61,2.39-1,2.81-1.87,1.61-3.39,4.45-4.26,8-4.44A21.32,21.32,0,0,1,388.71,89c-2.77,2.65-5.13,5.74-4.43,10-1.16,11.82.11,23.62.48,35.41.64,20.3,1.58,40.58,2.63,60.86q3.47,67.07,7.17,134.11a43.83,43.83,0,0,0,1,5.39c.25,1.22.5,2.44.76,3.67C395.18,338.54,394.76,337.77,394.43,336.9Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f6e1bd" }}
      />
      <path
        d='M390.4,70.18l-1.85,1.26c-3.54-4.78-7.27-9.36-13.29-11.2a68.14,68.14,0,0,0-22.57-.55,6.75,6.75,0,0,1-2.35-.56,3.65,3.65,0,0,1,2.89-1.74c10.44-.45,21-3.25,31.18,1.51-.62,1.15-1.72.58-2.14.65,3.64,1.36,8.23,1.51,11.33,5.14C391.57,66,393.73,69.68,390.4,70.18Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#a37e68" }}
      />
      <path
        d='M372.3,49.08c4.47.85,8.83-2.27,13.45-.07,2.92,1.39,5.17,2.88,5.48,6.32a63.5,63.5,0,0,0-22.46-4.66C369.66,48.57,371.24,49.55,372.3,49.08Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#e2b288" }}
      />
      <path
        d='M390.4,70.18c1.58-1.36.34-4.35,3-5.11,1.93-.41,2.59,1.51,4,2.13a12.63,12.63,0,0,0,.36,2.4c1.85,5.26.38,7.25-5.48,7.3C393.47,74.16,391.49,72.29,390.4,70.18Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#472c24" }}
      />
      <path
        d='M397.32,67.2l-4-2.13c-3.31-2.78-7.72-3.32-12.4-5.7l3.45-.47C389.2,60.91,394.72,61.79,397.32,67.2Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#e4c5ad" }}
      />
      <path
        d='M452.69,371c-8.41,5.16-12.82,3-14.63-6.77-.87-4.75-.29-9.5-.76-14.23-1.64-1.67-1.12-3.69-.87-5.64,1.51-11.65,2.52-23.42,4.87-34.9,1.8-8.78,1.81-17.72,3.66-26.43.52-2.45.94-4.88,2.33-7,1.78-12,4.24-23.87,5.11-36,1.38-8.89,2.87-17.77,4.12-26.68,2-13.83,3.76-27.67,5.58-41.52,1.87-14.17,3.8-28.33,5.5-42.52,1.19-9.86,7.31-16.89,13.19-24.1.59-3.76,4.38-5.31,5.83-8.47a15.67,15.67,0,0,1,2.82-2.64l1-.88c3.19-1.46,4.35,1.59,6.33,2.83,6.16,4.15,7.3,8.4,3.64,13.61a7.65,7.65,0,0,1-2,1.1,26.08,26.08,0,0,0-8.77,3c-5.2,2.16-8.26,6.56-11.4,10.88-4.78,6.45-4.6,14.23-4.94,21.53-.69,14.62-2.71,29.08-4.22,43.6-1.38,13.36-2.8,26.72-4.37,40.06-1,8.73-2.07,17.46-3.33,26.16-1.74,12-3.38,24-4.59,36-.43,4.3-1.28,8.55-1.39,12.88a150,150,0,0,0-2.47,20.08c-.63,9.05-1.94,18-2.44,27.07A20.94,20.94,0,0,0,452,368,4.6,4.6,0,0,1,452.69,371Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#130705" }}
      />
      <path
        d='M487.41,97.26c-1.52,3.23-4.14,5.55-6.62,8-.46-.16-1.05-.59-1.37-.44-5.13,2.45-5.27.42-4.34-4.09.88-4.28,2.07-9.44-5.2-10-1.25-.1-2.4-1.6-3.6-2.45l2.19-.31c2.66.11,5.32.23,7.79,1.4,3.37,2,7.13,3.57,7.07,8.44,1.48-.17,2.16-2.72,4.1-1.37a.5.5,0,0,1,.13.39A1.9,1.9,0,0,1,487.41,97.26Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#ddbc9c" }}
      />
      <path
        d='M469.18,78c3.08-2.12,6.6-1.91,10.08-2,1.17,1.08,2.05,2,4.17,1.82,3-.29,5.88.85,6.78,4.4-4.69.87-8.55-2.51-13.16-2.51-2.19,0,.08,1-.55,1.16C473.64,81,470.46,81.89,469.18,78Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#9d6e4d" }}
      />
      <path
        d='M487.41,97.26c-3.27-1.45-2.63,3.88-4.79,3-2.8-1.09,1.18-4.16-1.07-6-1.89-1.52-3.54-3.33-5.29-5,4.87.28,8.46,4.09,13.18,4.79A4.48,4.48,0,0,1,487.41,97.26Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#be997e" }}
      />
      <path
        d='M476.5,80.91l-3.27-1.79c6.57-1.08,11.71,1.44,17,3.14a7.2,7.2,0,0,0,4,2,2,2,0,0,1,.09,2.94C488.69,84.27,483.13,81.08,476.5,80.91Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#d4a17d" }}
      />
      <path
        d='M496.15,96.23l-5.74-3c-3.31-2.92-8.12-2.83-11.56-5.71,5.57-.55,13.56,3.12,18.75,8.55A1,1,0,0,1,496.15,96.23Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#e8c7b0" }}
      />
      <path
        d='M496.15,96.23l1.45-.14c2.11.22,3,2.22,4.56,3.17,1.19,3.65,2.07,7.29,0,10.93-.46.77-.95,1.47-2,1.16-.76-.53-1.3-1.15-.79-2.15a7.71,7.71,0,0,0-.47-10.87C498,97.56,497.06,96.92,496.15,96.23Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#bc9777" }}
      />
      <path
        d='M494.34,87.2l-.09-2.94c3.32,1.17,4.54,4,5.64,7.07C497.91,90.09,495.66,89.28,494.34,87.2Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#9d6e4d" }}
      />
      <path
        d='M261.49,87.21c-3.93-2.35-6.86.88-10.2,1.82,2.46-4.56,7.46-4.42,11.45-6,6.31-2.51,12.89-2.19,19.44-.78a3.11,3.11,0,0,1,2.18,1.87c2.72,2.84,7.46,3.28,9,7.53,0,.26,0,.53,0,.79-.48,1.39.72,3.67-2.05,3.83-2.17-4.8-5.54-8.09-11-8.72-2.66,0-5.31-.06-8,0C268.7,87,265,88.78,261.49,87.21Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#9d765f" }}
      />
      <path
        d='M290.61,143.18c-3.92-9.81-5.69-19.49,1.07-29,2.62-3.68,2.53-4.4.82-6-.29-2.68,1.61-2,3-2,1.28,3.47-3.05,6.21-1.28,9.74C288,124.38,289,133.74,290.61,143.18Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#a38d79" }}
      />
      <path
        d='M295.54,106.25c-.86.89-2.61.39-3,2l-1.06,1c-1.77-2.07-.29-5-1.85-7.12-1-1.07-.23-2.1,0-3.14.47-1,.76-2.14,1.84-2.74,1.65-.77-.27-3.28,1.86-3.82C295.75,96.77,297.56,101.21,295.54,106.25Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#2b160f" }}
      />
      <path
        d='M293.36,91.64c-2.32-3.33-7.15-3.64-9-7.53a17,17,0,0,1,9.8,6.78.48.48,0,0,1-.05.44A3.82,3.82,0,0,1,293.36,91.64Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#d5ad92" }}
      />
      <path
        d='M379.48,81.39c-.83,2.56-3.58,3.92-4.14,6.65-2,2.75-1.46,5.94-1.56,9-.13,3.75.28,7.53-.48,11.26a53.69,53.69,0,0,0-.93,21.16,19.81,19.81,0,0,1,.26,6.46c-2.09,13-1.4,26.14-1.92,39.17-.57,14.22-2.21,28.42-1.91,42.69.18,8.39-1.79,16.47-4.19,24.44a4.23,4.23,0,0,1-2.33,3.05c-2.56-.69-1.95-2.86-1.88-4.51.48-12.09,1.16-24.17,1.8-36.25.69-13.08,2.52-26.11,2.53-39.21,0-11.62,1.5-23.12,1.95-34.7.37-9.14-.3-18.25-.25-27.37.64-3,.6-5.88-1.48-8.5-2.48-3.14-2-6.46-1.39-10.32,1.2-8.06,2.6-16.57-4.24-23.33,9.67-4.91,18.3,6.67,15.72,14.17C373.43,79.94,375.65,81.05,379.48,81.39Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f7e8cd" }}
      />
      <path
        d='M359.32,61.09a5.15,5.15,0,0,1,5,4.15c1.23,6.55,2.72,13.27.44,19.77-1.35,3.82-.8,6.78,1.63,9.55,2.11,2.4,2.79,4.76,1,7.55-1.79-.27-2.36,3.3-4.65,1.49-1-2.5-.51-5.24-1.3-7.77-.31-2.14-.84-4.23-2.46-5.77C355,86.32,352,81.77,348.52,77.68a3.27,3.27,0,0,1-.31-3.45c-.35-3.35-.69-6.71-1-10.06L348.33,63c3.2-1.35,6.72-1,10-1.89Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#fbf8ec" }}
      />
      <path
        d='M375.26,60.24c6.2-.42,12.27,5.6,13.29,11.2l-1.07.89c-1.19-2.14-2.94-1.17-4.82-.55C384.93,64.64,376.88,64.62,375.26,60.24Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#c7a486" }}
      />
      <path
        d='M358.34,61.15a21.28,21.28,0,0,1-10,1.89C351.23,60.12,354.94,61.44,358.34,61.15Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f7e8cd" }}
      />
      <path
        d='M562.07,165.85l-9.83-8.72c6-.29,10,2.29,12.08,8A1.92,1.92,0,0,1,562.07,165.85Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#8c6552" }}
      />
      <path
        d='M581.49,168.67a28.66,28.66,0,0,0-13-8.77c-1.66-1.46-4.45-1-5.94-3.71,5.58.83,9.95,3.36,14.22,6.1C578.33,164.43,581.78,165.18,581.49,168.67Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#ceab97" }}
      />
      <path
        d='M572.29,155.09c1.89,1.09,4,1.92,5.13,4-4.53-.13-7.61-4-12.06-4.62-2-.27-4-1.41-6.08-1.75C564,151.41,568.19,153.09,572.29,155.09Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#9e7457" }}
      />
      <path
        d='M216.13,184.85c-2.43,5.92-5.9,11.51-5.74,18.24-2.07-2.68-2.28-6-2.86-9.09,3-4.06,3.65-9.54,7.63-13.06C215.48,182.25,214.87,183.78,216.13,184.85Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#ac947f" }}
      />
      <path
        d='M215.16,180.94c-2.48,4.39-3,9.91-7.63,13.06.29-1.64.57-3.28.86-4.92A49.65,49.65,0,0,0,214,177.91C216,178.29,215.27,179.73,215.16,180.94Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f2e1c8" }}
      />
      <path d='M175.57,194.88l12.27,5.56c-5.17-.43-10-1.7-13.86-5.32Z' transform='translate(-162.27 -42.07)' style={{ fill: "#89653e" }} />
      <path
        d='M338.31,489c-3.32-.6-6.13-2.35-9-3.95-.47-1.88.22-2.8,2.19-2.59,1.27.14,2.51.51,3.78.76,10.91,2.72,21.88,5.16,33,7a27.36,27.36,0,0,0,11.36-.26,20.5,20.5,0,0,1,7.53,0c7.62,1.31,15-.61,22.45-1.67A125,125,0,0,0,438,480.41c6.4-2.6,12.91-4.85,19-8.23,3.75-.72,5.88-3.05,7.17-6.57a52.93,52.93,0,0,0,3.37-13.67,6.45,6.45,0,0,1,.33-1.43c5.85-18.51,13.45-36.44,18.64-55.15,2.06-7.41,5.15-14.32,7.66-21.48,2.66-7.58,6.53-14.9,7.17-23.14a7.07,7.07,0,0,1,.49-1.45c5.8-12.1,10.19-24.71,14.52-37.4,3.73-10.94,6.81-22.14,11.65-32.71,2.34-5.12,3.2-10.79,5.26-16,2.31-5.82,4.34-11.71,6.42-17.6,1.21-3.41,2.56-6.8,3.65-10.27,3-8.39,7-16.42,9.44-25a20,20,0,0,1,4-5.91c3.19-3.44,8.08-3.65,11.75-6.19a35.08,35.08,0,0,0,5.25-3.26c3.56-2.41,5.13-5.59,4.4-10-.3-1.85-.71-3.85.05-5.76a2.15,2.15,0,0,1,2.13.81c1.2,3.31,4.15,6.21,1.45,10.3-1,1.55,2.07,1.41,2.45,2.79-1.07,3.35-4,5.35-6,8-1.62,3.26-4.77,4.82-7.87,5.6-8.11,2-11.9,7.75-14.18,15.09-1.12,3.62-2.26,7.25-4.64,10.32q-17.69,50.58-35.37,101.15-16.29,46.6-32.59,93.21a28.07,28.07,0,0,1-1.25,2.67c-3.42,1.76-3.06,5.49-3.87,8.32-3.16,11.1-7.29,21.88-10.74,32.88-.66,2.12-1.42,4.51-4.17,5.08-5.09.15-9,3.37-13.28,5.38-11,5.17-22.29,9.63-34.23,12.09-8.1,1.67-16.25,3.25-24.6,3.24-.67,0-1.63-.26-2.18.79,1.31-.73,2.41-.86,3.3.42a1.37,1.37,0,0,1-.85,1.33c-14.16,2.78-28.18,1-42.19-1.25-1.63-.26-3.36-.46-3.68-2.6.12-1.31,1.09-1.87,2.12-2.36,3.75-.43,7.2,1.52,10.4,1.22C352.07,492.16,344.71,492.64,338.31,489Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#775236" }}
      />
      <path
        d='M202.4,237.07c.3,0,.61,0,.92,0q11.43,32.73,22.87,65.48c.65,1.85,1.46,3.64,2.2,5.46,3.5,4.44,4.57,10,6.31,15.08,7.33,21.57,15.17,43,22.46,64.54,2.12,6.3,4,12.7,6.44,18.87,3.88,13.82,8.57,27.31,16.31,39.53,1.09,1.73,2,3.52.94,5.62-1.63,1.42-2.73-.06-4-.72-3.24-3.66-2.68-9-5.69-12.79-5.5-15.6-11.1-31.17-16.49-46.81q-14.58-42.29-29-84.62-11.4-33.39-22.76-66.78A20.82,20.82,0,0,1,202.4,237.07Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#9a7e62" }}
      />
      <path
        d='M346.13,494.13c2.64,3.39,6.7,1.89,10,2.72,11.92,3,24,2.35,36.14,1.07,3.38-2,7.24-1.57,10.9-2.08,1.32-.19,3.09,0,3.12,2.17-34.54,6.64-67.72,1.59-100-11.57-2-.81-4-1.52-6-2.27-8.62-4.34-18.59-7.25-20-19.12,0-1.91,1.05-1.18,2-.72a2.68,2.68,0,0,1,.81,1.32c-.15,4.07,2.63,6.3,5.54,8.18,7.83,5,16.6,8.05,25.3,11.18a201.29,201.29,0,0,0,29.67,7.75A3.36,3.36,0,0,1,346.13,494.13Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#341d0f" }}
      />
      <path
        d='M406.33,498c-2.71-2.44-5.48-.16-8.22-.09-1.93,0-3.87,0-5.8,0,0-.3,0-.6.05-.9,4.86-2.36,10.26-2.15,15.4-3a157.52,157.52,0,0,0,49-16.74,21.85,21.85,0,0,1,6.41-2.41c2.42-1.47,3.38-3.78,4.2-6.4,4-12.8,8.87-25.31,12.36-38.27.33-1.24,1.18-2.35,2.17-4.26.21,1.58.32,2.35.43,3.12L467.31,475c-3.27,3.73-7.74,5.53-12.07,7.58-14.41,6.81-29.23,12.4-45,15.17A39.08,39.08,0,0,1,406.33,498Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#5d402b" }}
      />
      <path
        d='M192.36,208.45c-2-3.4-5.72-3.75-8.84-4.75a44.81,44.81,0,0,1-12.19-6.41c-.16-.41-.33-.83-.49-1.25,6.23,2.64,11.92,6.5,18.75,7.79,1.1.21,1.88,2.1,2.8,3.22Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#5e4733" }}
      />
      <path
        d='M271.18,438.15c2.33-.52,1.73,1.62,2.24,2.61,1.55,3,1.49,6.61,3.87,9.24a6.66,6.66,0,0,1,1.48,4.78,2.14,2.14,0,0,1-1.56,1.45Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#74533a" }}
      />
      <path
        d='M277.21,456.23,278.3,455c3,2.63,5,5.68,4.08,9.94-.69-.14-1.42-1.08-2.06.09Q278.78,460.64,277.21,456.23Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#60422a" }}
      />
      <path
        d='M574.26,196.05l-4.92,3.09a3.69,3.69,0,0,1-2.57-3.36c-.06-4.82-3.13-9.14-2.26-14.14.36-2.08-1.71-3.1-2.48-4.7l1.22-1,4,.19C575,180.54,578,189.16,574.26,196.05Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#c59c71" }}
      />
      <path
        d='M574.26,196.05c1.41-7.94-1.06-14.53-7-19.89,4-.34,7.19,2.44,11,3C581.25,187.65,580.4,191.26,574.26,196.05Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#9f7658" }}
      />
      <path
        d='M447.29,276c-2.5,17.9-5,35.8-7.48,53.71-.92,6.75-1.68,13.51-2.52,20.27-.76,5.07-3.66,9.32-3.48,15.09.17,5.56-1.38,11.5-1.78,17.37A7,7,0,0,1,426.2,389c-2.88.49-4.31,2.82-5.86,4.95-3-.19-5.82-1-8.84-1.14-4.81-.17-6.08-3.35-3.1-7.31a35.14,35.14,0,0,1,4.73-5.1c4.34-3.84,3.13-9.56,4.66-14.28,2.7-8.31,6.82-16.07,8.82-24.6,2.07-8.79,6-17,7.17-26.18.69-5.23,3.45-10.52,4.86-15.91,2.73.54.76,3.28,3,4.14.61-2.45,1.63-4.34-1-6.77-4.24-4-.23-18.39,5.3-20.17A11.79,11.79,0,0,0,447.29,276Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#deb783" }}
      />
      <path
        d='M383.09,391.16c-15.58-.29-31.17-.81-46.5,2.76-4.49,1.05-8.85,2.6-13.28,3.91-8.41-.31-10.95-4.09-7.7-12,1.53-3.71,2.39-7.62,3.87-11.33a10.24,10.24,0,0,1,1.47-3.15c9.35-10.42,5.39-21.8,1.08-32.2-5-12.05-9.63-23.83-9.16-37.14.26-7.36-3.49-14.15-3.37-21.63.05-3.5-.1-5.94,4.29-5.55,2.6,1.67,2.26,4.72,2.95,7.11,3.31,11.44,6.5,22.92,9.07,34.55a13.1,13.1,0,0,1-.05,3.48c1.86,5,3,10.27,5.43,15-5.45.14-5.32.07-2.93,4.92a15.66,15.66,0,0,0,8.4,8c1.84.73,3,1.54,1.95,3.6-2.15,4.09-2,8.59-2.64,13-1.12,8.08-5.6,15-8.21,22.6-.63,1.82-2.22,3.45-.33,5.44s3.89,1.68,5.86.43c2.82-1.8,6.35-.09,9.1-2,15-2,29.93-3,44.87-.44C386.08,391.54,384.45,390.62,383.09,391.16Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f1dcb8" }}
      />
      <path
        d='M372.33,108.1c0-4.79,0-9.58-.07-14.37-.05-2.55.64-4.55,3.08-5.68,0,52.08,4.76,104,6.16,156,.67,25.27,3.41,50.41,6.37,75.51.33,2.79.23,5.63.32,8.45-2.54-5.43-2.58-11.4-3-17.14-.21-3-1.79-4.64-2.87-6.85-1-17.75-2.34-35.5-2.84-53.26-.25-8.85-2-17.62-1.26-26.51.06-.71-.57-1.48-.89-2.21-1.23-1.11-1.35-2.6-1.47-4.11-1-12.77-1-25.59-2.05-38.36-.91-10.93-2.2-21.88-1.7-32.84C372.72,133.85,369.88,121,372.33,108.1Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#e0ba91" }}
      />
      <path
        d='M394.43,336.9l1.85,1.62c0,.89.05,1.78.07,2.68-4.44-.25-6.55-2.55-6.18-7C392.13,334.23,391.56,338.25,394.43,336.9Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#7f5f41" }}
      />
      <path
        d='M428.34,395l1.84.19.09.5-4.15.53c-2.66-.29-5.42.17-7.93-1.12.8-.18,1.69-.2,2.15-1.07C423,394,425.56,395.55,428.34,395Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#deb783" }}
      />
      <path
        d='M458,176.74c2.39,3.8,0,7.15-.66,10.7C455.59,183.75,458.23,180.33,458,176.74Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#e8c99c" }}
      />
      <path
        d='M456.31,148.1c.6-7,3.48-13.75,3.13-20.94.86,2,3.11,3.37,2.86,5.93-3.61,3.29-.67,8-2.56,11.88A5.18,5.18,0,0,1,456.31,148.1Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f0efea" }}
      />
      <path
        d='M451.17,114.23l1.14-1.12c1.36,4,3.92,7.43,6.23,10.94l-1.22,1C453.62,122.41,450.78,119.23,451.17,114.23Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#8f7c63" }}
      />
      <path
        d='M451.17,114.23c1.15,4.13,3.85,7.39,6.15,10.87.24.72.47,1.44.71,2.16-5.67-4.72-10.16-10-8.93-18Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f0efea" }}
      />
      <path
        d='M372.33,108.1c-.08,13.76,1.43,27.49,1.51,41.19.1,18.37,2.94,36.54,2.76,54.91-.06,5.88.66,11.88.75,17.84-1.65,1.32-2.12,1.21-2.74-.63-.84-2.49.5-5.27-.92-7.71-1.88.34-1.31,1.78-1.32,2.77-.1,8.28-1.9,16.44-2.34,24.54-.34,6.36-3.19,12.84-.87,19.39a5.71,5.71,0,0,1-.14,3.91c-2.15,4.76-1.69,9.75-1.66,14.71a13.89,13.89,0,0,1-2.81,8.46,21.49,21.49,0,0,0-3.22,12.61c.38,8.5-2.67,16.53-3.53,24.86-.11,1.07-.94,2.06-1.43,3.08l1.92-23c-1.05-7.65.23-15.25.54-22.86.84-11-.17-22.1,2-33,.29-1.45.25-3,1.49-4.06,2.67-9.36,5.23-18.73,5-28.63a217.17,217.17,0,0,1,1.88-29.15c.66-5.7-1.39-11.39,0-17.18,2.7-11-.32-22.27,1.81-33.42,1.33-7-1.47-14.33-.46-21.65C371,112.6,371.17,110.28,372.33,108.1Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f5e2c0" }}
      />
      <path
        d='M362.33,245.07q-1.51,18.48-3,37c-.31.47-.62.47-.94,0-.71-17.93.81-35.83,1.21-53.74.43-19.87,1.28-39.74,1.93-59.62.67-20.53,1.95-41.05,1.8-61.61a2,2,0,0,1,.08-3c1.57-.19.17-6.29,4-2,1.48,9.84,1.24,19.75.75,29.61-.37,7.49-1.33,15-1.53,22.47-.57,21.36-2.35,42.6-4.09,63.87-.64,7.75.11,15.58-1.1,23.33C361.18,242.65,361.09,244.08,362.33,245.07Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#fcfaef" }}
      />
      <path d='M358.35,282h.94c.76,7.7-1.1,15.29-1,23A55.51,55.51,0,0,1,358.35,282Z' transform='translate(-162.27 -42.07)' style={{ fill: "#efeeed" }} />
      <path
        d='M352.4,86.09l-1.08.09a20.15,20.15,0,0,1-4.12-10c.34-.66.67-1.33,1-2,.35,1,.7,1.94,1,2.91C350.16,80.17,353.88,82.22,352.4,86.09Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#8d7c5f" }}
      />
      <path
        d='M259.3,89.06l2-1c5.57-1,9.26.48,11.92,5.38,2.52,4.65,4.65,9.52,4.48,15-.09,2.66-.52,5.86,4,3.6.7-.35,1,.49.79,1.25-1.49,3.83-4.68,7.18-3.29,11.82,2.44,3.64,2.1,7.94,2.8,12-1.21,1.71-4.56,2.4-2.65,5.6a11.32,11.32,0,0,1,.36,2.32c3.78,21,3.85,42.5,7.3,63.53,1.45,8.8,2.86,17.61,4,26.44,1.21,9.18.93,18.53,2.65,27.69a6.42,6.42,0,0,1-1.06,5.23c-3.69.45-3.35-2.51-3.66-4.6-1.45-10-1.19-20.26-3-30.26-4-22.12-4.63-44.71-8.58-66.78-2-11.41-1.32-23.26-5.5-34.29-.41-1.08-.26-2.45-.94-3.35-4.51-6-4.35-12.62-3.17-19.55a23.85,23.85,0,0,0-.35-8.45c-.62-3.23-1.73-6-5.07-7.48C260.61,92.45,259.25,91.22,259.3,89.06Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f8efdb" }}
      />
      <path
        d='M281.25,138.08c-.18-4.39-2.37-8.46-2-12.92,3-1.34,2.69,1.6,2.78,2.62.75,7.69,4.49,14.55,5.81,22.08,2,11.3,4.89,22.43,7.19,33.68,1.87,9.19,3.82,18.43,5.94,27.6,2.67,11.52,5.78,22.94,8.37,34.48,1.63,7.31,4.51,14.37,5.8,21.63,2.59,14.65,7.58,28.63,10.66,43.1.62,2.93,2.45,6.45,0,9.63-3.46-12.81-6.15-25.83-10.85-38.29-.84-2.24.33-4.93-1.35-7-2.13-1.53-2.21-4-2.79-6.21-10-38-18.14-76.33-25.77-114.82a7.81,7.81,0,0,0-.55-1.9C283.48,147.15,280.87,143,281.25,138.08Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#2f1c0b" }}
      />
      <path
        d='M282.5,113.34c-.4-.18-.85-.57-1.19-.49-1.57.39-1.62,3.58-3.66,2.57s-1.36-3.59-1.24-5.48c.42-6.45-1.84-12.15-5.23-17.29a10.07,10.07,0,0,0-9.89-4.59c.07-.28.14-.56.2-.85a31.88,31.88,0,0,1,10.87-1c5.31,2.57,7.71,7.47,9.85,12.5,1.19,2.8,2.15,5.48,6,3.38.05,0,.25.18.34.3s.14.54-.11.85C286.67,106.72,285.13,110.35,282.5,113.34Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#eedbbd" }}
      />
      <path
        d='M288.43,103.22c-2.64-1.68-2.23,4.29-4.57,2-1.41-1.37-1.86-3.78-2.59-5.77-1.9-5.16-5.3-9.28-8.91-13.28l8.88.11c2.52,3.13,6.62,5.14,7,9.78.13,1.51,3.89.44,2.17,3.16-.28,1-.56,1.93-.85,2.9Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#e3c6a5" }}
      />
      <path
        d='M497.55,123.28a16,16,0,0,1-8.74,8.34c-4.67,1.75-6.21,4.91-6.42,9.35-.05,1.16,0,2.35-1.06,3.18a3.42,3.42,0,0,1-1.15-2.09c-.16-7.36,1.79-13.72,8.55-17.76,1.4-.84,2.73-2,4.55-1.23.36,2.07-2,2.67-2.92,4.63C494,127.38,494.86,123.88,497.55,123.28Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#90735b" }}
      />
      <path
        d='M493.29,123.07c-8.23,3.61-12.22,9.91-12,18.88-2.34,0-2.1-1.87-2.07-3.16.12-4.94,1.1-9.76,5.27-12.94,5.17-3.95,10.08-8.11,13.91-13.42a2.71,2.71,0,0,1,2.1-1.18l1.7-1.06C500.69,115.51,497,119.3,493.29,123.07Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#edd3b0" }}
      />
      <path
        d='M245.3,101.88c0-1.09,0-2.2,1-3,2.44,2.6,1.85,6.32,3.4,9.24,2.8,5.25,6.41,9.52,12.18,11.92,5.59,2.33,6,4.29,2.89,8.27a3.17,3.17,0,0,1-2.18.34c-5.36-2.84-10.65-5.75-13.7-11.38-1.68-2.81-4.59-5.13-3.57-9A6.68,6.68,0,0,1,245.3,101.88Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f8efdb" }}
      />
      <path
        d='M245.29,108.3c.11,3.51,2.63,5.92,4,8.83a1,1,0,0,1-1.52,0l-3-5.06C244.66,110.77,243.72,109.37,245.29,108.3Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#8e8373" }}
      />
      <path
        d='M233.38,276c2.76,2,4.78,4.36,4.68,8.12a7.37,7.37,0,0,0,1.11,4.27c4.37,6.05,5.9,13.46,9.89,19.79,2.63,4.19,3.26,9.8,4.89,14.74a116.15,116.15,0,0,0,11.84,25.09c2.14,3.45,2.75,7.87,6.7,10.19a5.73,5.73,0,0,0,2.79.79,14.06,14.06,0,0,1,4.67,6.45,10.33,10.33,0,0,0,5.39,5.7,6.77,6.77,0,0,0,0,.9c2.65,5.77,8.85,6.79,13.63-.77.35-.38.72-.72,1.1-1.06,1.72-2.81,2-5.92,1.88-9.08-.4-12.57-2.68-24.88-4.93-37.21a209.79,209.79,0,0,1-3.12-25.71,54.1,54.1,0,0,0-2.08-11.75c-1-3.72-1.74-7.48.11-11.19,3.22-1,3.59,1.67,4.11,3.54a37.74,37.74,0,0,1,1.63,10.31c-.11,11.4,2,22.46,4.34,33.56a183.71,183.71,0,0,1,4.17,30.08c.33,7.72,1,16-2.93,23.48a31.66,31.66,0,0,1-6.83,9.14c-3,2.7-4.25,6-3.36,9.87,1.24,5.42-.76,10.23-2.78,14.95-1.84,4.3-7.13,6.15-11.94,4.69-4.32-1.3-7.41-5.68-7-9.92,1.48,1.32,3.05,2.55,4.43,4,2.05,2.1,4,4.2,7.26,2.16,3.51-2.21,5.7-5.38,5-9.51-1.3-7.3-3.13-14.5-4.67-21.75-.26-1.23-.66-2.42-1.86-2.72-4.88-1.21-7.43-5.2-9.29-9-4.09-8.41-10.07-15.75-12.94-24.86-2.16-6.89-5.58-13.38-8.29-20.1-4.86-12-9.85-24-13.88-36.4C235.91,283.18,233.47,280,233.38,276Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f8efdb" }}
      />
      <path
        d='M300.35,372h-1c-1.3.68-2.18,2.19-3.77,2.23-2,0-4.21,0-3.22-3.15,3.71-.94,4.81-3.71,5-7.12.75-14.66-1.44-29.09-3.35-43.54q-2.73-20.62-5.65-41.24c-2-14.07-4.09-28.12-6.15-42.18-1.94-13.23-4-26.45-5.79-39.71-1.82-13.77-3.77-27.52-6.62-41.11-.5-2.36.66-5.1-1.45-7.11a8.43,8.43,0,0,1-2.14-5.94c.17-5.15-2.3-9.94-1.81-15.1,2.66-3.6.79-5.42-2.58-6.75-10.16-4-14.59-12-15.54-22.38,2.63-5.54,7.32-8.38,13-9.86,1.4,1.3,2.49,2.65,4.67,3.29,5,1.46,4.78,6.42,5.24,10.34a33.65,33.65,0,0,1-.53,11.37c-.93,4-.05,7.76,2.43,11.7A33.29,33.29,0,0,1,276,143.6c-.21,9.64,3.05,18.55,3.75,27.92.8,10.67,2.25,21.3,3.63,31.92,1.09,8.32,1.58,16.7,3.39,25a130.39,130.39,0,0,1,2.44,23.12c.17,4.66,1.48,9.17,1.21,13.84a2.91,2.91,0,0,0,1.92,2.69,5.16,5.16,0,0,1,0,7A64.45,64.45,0,0,0,294,289.24c1.79,8.85,2.48,17.94,3.24,27,.22,2.66,1.09,5.18,1.1,7.83.88,9.87,3.06,19.59,3.71,29.48C302.47,359.7,303.55,366.09,300.35,372Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#fcfaef" }}
      />
      <path
        d='M292.37,371.1c1.71,5,4.66.59,7,.92-1.9,4.26-4.44,7-9.82,5.66-3.26-.83-4.86-2.18-4.17-5.6,2.15.53,4.36,1.37,6-1.05Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#fcf7e5" }}
      />
      <path
        d='M285.45,150.9c3.85,13.58,6.07,27.52,8.92,41.31,5.35,25.85,11.48,51.52,18.1,77.08.46,1.76.73,3.57,1.08,5.36-5.72,4.22-2.56,10.29-1.52,14.59a80.37,80.37,0,0,1,2.29,20.58c-.06,3.9,1.78,7.31,2.54,10.88,3,14.17,13.29,26.5,10.59,42.13-.5,2.9-.48,5.61-3.61,7-1.51.67-1.89,2.17-2.49,3.55-2,4.56-2.87,9.44-4.85,14-2.66,6.11-1.48,7.53,6.81,10.43l-6.82,3.22a7.74,7.74,0,0,0-2.78,2.06c-2,1.93-4.17,4.41-4.84-.91-8-6.36-4.47-14.29-3-22,1.87-10,1.79-20.19,2.87-30.27.23-2.21-.85-4.18-1.51-6.24-4-12.48-7.1-25.07-7.46-38.34-.24-8.76-2.05-17.46-3.18-26.18-1.76-13.61-4.5-27-5.52-40.81-.63-8.44-.62-17.26-3.76-25.44-1.7-4.45-.95-9.17-1.67-13.76-1.94-12.41-2.08-25.05-4.12-37.5C280.82,157.63,280.63,153.2,285.45,150.9Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f5e2c4" }}
      />
      <path
        d='M285.45,150.9c-7,9.35-.43,19.09-.58,28.61-.12,8.53,1.41,17.12,2.53,25.63,1.27,9.61,3.06,19.15,4.32,28.75.87,6.66,1.22,13.39,1.8,20.09a11.67,11.67,0,0,0,.13,2.45c4.63,14.93,5.84,30.55,7.19,45.87a236.4,236.4,0,0,0,5.46,34.46,28.42,28.42,0,0,0,2.68,7.48,10.26,10.26,0,0,1,1.36,6.23c-1,10-1.81,20.09-3.08,30.09-.59,4.57-2.44,8.93-2,13.71.22,2.28.24,4.11,3,4.94,2.61.78,4.68,2.4,1.5,5.34,3.42.35,3.12-4.84,6.66-3.5-5.59,5.41-11.13,10.73-14.16,18.24-3.9,9.67-2.17,19.25-.48,28.91,1.11,6.35,3.09,12.6,2,19.17-.29,1.85,1.07,3.06,1.58,4.6-.84,2.15-1.93.77-2.77.09a5.28,5.28,0,0,1-1.59-3.88c1.44-6.81-4.21-10.42-7.16-15.14-1.36-2.19-3.15-4.21-4.46-6.51a210,210,0,0,0-13.28-21c-5.64-7.63-8-17.09-11.89-25.73-1.47-3.33-2.91-6.71-4.1-10.17-.75-2.2-1.74-4.42.13-6.57l0-5c2.49,1,3.09,3.37,3.72,5.54,2.13,7.25,5.82,14,7.26,21.46,2.06,6.12,5.41,9,10.28,9,4.43,0,7.28-1.82,8.3-6.23s3.24-8.51,1.56-13.44c-1.25-3.68.42-7,3.54-9.62,6.83-5.83,9.23-13.29,9.53-22.37.54-16.18-2.76-31.71-5.69-47.38a145.26,145.26,0,0,1-2.7-25.1c-.06-5,.42-10.67-3.82-14.83,0-2.33,0-4.65,0-7-1.52-13.58-2.35-27.21-4-40.79-2-17.13-5.44-34.08-6.34-51.41-.48-9.22-.29-18.81-3.48-27.83-.42-1.19-.15-2.63-.2-4,1.25-.42,1.71.56,2.36,1.39,0-1.74-1.46-2.37-2.37-3.38a3.09,3.09,0,0,1-.49-4.28c1-1.41,2.26-.16,3.38.27C283.52,142.07,284.52,146.47,285.45,150.9Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#faf1d6" }}
      />
      <path
        d='M557.32,205.14c-1.25,1.68-2.51,3.35-3.77,5-6.53,11.74-11.53,24.18-17,36.42-4.38,9.83-7.32,20.22-12.83,29.66-3.52,6-5.69,13-7.55,19.9a11.59,11.59,0,0,1-1.43,3.71c-5.82,8.34-7.57,18.24-11,27.51-3.25,8.67-6.58,17.38-10.14,26-4.16,10.08-8.46,20.11-11.57,30.65-1.48,5-3.75,9.87-5.61,14.83-4,10.59-4.14,21.81-6.5,32.64-.27,1.24.49,2.24.77,3.36a3.23,3.23,0,0,1-.06,1.45c-4.48,5.14-3.52,12-5.47,17.9-.5,1.51-.54,3.27-1.23,4.73-1,2.25-2.34,2-3.41-.13.27-4.42-.39-8.94,1.81-13.12.8-1.52.47-3.26.14-4.91a16.57,16.57,0,0,1,1.06-10.25,9.66,9.66,0,0,0,1.05-3.84c.07-15.92,6.52-30.37,10.41-45.41a53.2,53.2,0,0,1,7.48-17.42c2.48-3.62,3.11-8.8,6-12.72,1.57-2.13-.94-4-1.08-6.08l44.93-111c2.73-3,3.65-6.92,4.91-10.61,2.92-8.63,9-14.41,16.61-18.86C555.54,203.55,555.61,203.67,557.32,205.14Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#c89f73" }}
      />
      <path
        d='M487.36,345c4.4,4.31,4.56,6.1-.05,10.37a6.24,6.24,0,0,0-2.2,5.41,8.49,8.49,0,0,1-2.94,6.39,14.18,14.18,0,0,0-4,7c-1.68,7.53-3.82,15-6.18,22.33-3.11,9.64-6,19.25-5.79,29.55,0,1.87-1,3.8-1.75,5.65-.54,1.4-1.46,2.79-1.05,4.28,2.13,7.95-2,15.31-2,23-.2,9.94-7.32,17.62-16,17.2-.6-1.13,0-1.93.82-2.57,11.33-9.41,13.32-21.95,11.68-35.54-.56-4.67.32-9.23.5-13.79.24-5.95-2.13-12.57,2.77-17.86-.35-.33-.95-.14-1.24-.61,5.77-15.63,14.52-29.89,20.82-45.28C482.83,355.36,485.13,350.21,487.36,345Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#d1a979" }}
      />
      <path
        d='M311.36,473.05c-.83-.72-3-1.42-.19-2.29.83-.26,1.93.23,2.87.49,22.25,6.33,44.93,6.79,67.8,5.18a126.53,126.53,0,0,0,36.45-7.53,48.94,48.94,0,0,1,14.56-6.76c1.49.14,3,0,4.47.31,1.92.36,4.25.3,4.65,2.89a5.62,5.62,0,0,1-2.69,5.55A40.58,40.58,0,0,1,428.5,476a71,71,0,0,0-8.67,3.65c-4.16,2-9,2.37-13.33,2.69-6.72.48-13.88,1.51-20.26-2.41-1-.6-2.27-.38-3.43-.21-15.34,2.32-30.8,1.48-46.21,1.64-2.43-.09-4.73-1-7.14-1.21C323,478.79,316.85,476.8,311.36,473.05Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f9f4d3" }}
      />
      <path
        d='M459.88,405.85c1.53.09,1.43-2.24,3.05-2.05.78,1.35-.45,2.08-.88,3.08-1.44,3.4-2.73,6.33-2.37,10.72.41,4.92,1.47,10.67-.93,15.74-.57,1.22.14,2.23.29,3.35,1.25,9.54,1.08,18.93-3.69,27.68-2.51,4.58-5.23,9-10.05,11.58-2,2.88-5.6,2.6-8.37,4-9.31,4.67-19.32,6.78-29.68,7.44a39.92,39.92,0,0,1-14.54.39c-1.71-.28-3.25-.39-3.69-2.42.71-2.27,2.6-2.34,4.45-2.2,16.78,1.18,31.78-5.3,46.85-11.15,7.7-3,11.28-9.49,10.63-16.81-.09-1,.05-2.24-.64-2.86-3.16-2.84-2.32-6.65-2.74-10.17a4.61,4.61,0,0,1,1.54-4.26C452.38,427.11,454.6,416,459.88,405.85Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#e6c48c" }}
      />
      <path
        d='M302.26,468.09c.55,3.49.81,3.79,3.21,3.87l7.4,8.49-13.45-4.56c-.56-1.9-2.22-2.77-3.64-3.86a49,49,0,0,1-15.38-20.09c-.13-6-4.6-10.05-7-15-4.65-9.71-7.89-19.81-10.95-30,3.1-1.59,3.86.75,4.4,2.85.93,3.56,1.61,7.17,5.08,9.34a1.82,1.82,0,0,1,.77,1.25c1.3,9.84,7.75,17.32,12.66,25.2S294.61,462.12,302.26,468.09Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#ddbd89" }}
      />
      <path
        d='M318.38,462c-8.75-6.06-13.36-14.63-15.1-24.93-1.66-12.46,3.86-23,15.13-27.85,9.93-4.31,20.41-6.5,31.18-7.77,10.27-1.22,20.52-.47,30.78-.81,6.31.8,12.81.39,18.81,3.08.81,1.75-.68,1.9-1.66,2.4-8.94.82-17.79-1.23-26.76-.81a168.57,168.57,0,0,0-44.35,7.65,28.38,28.38,0,0,0-11.73,7.2c-11.44,9-9.25,23.62-1.91,31.58,3.13,2,5,5.76,9.08,6.38,2.67,1.76,7.12.94,7.91,5.44-2.22,1.94-4.37.41-6.33-.4A10.32,10.32,0,0,0,318.38,462Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f8f4e2" }}
      />
      <path
        d='M557.32,205.14c-4.21-.17-6.69,2.87-9.6,5.15-6.82,5.32-9.78,12.94-12.51,20.73a11.36,11.36,0,0,1-2,3.95c-.77.83-.65-.53-.91-.93q3.54-9.89,7.1-19.78l3.8-9.41c3.63-2.12,7.22-4.29,11.64-4.51,2.71-.14,4-1.79,4.1-4.58s.27-5.6-.77-8.3c-.75-2-.72-3.81.8-5.29-1.87,1.28-2.42,4.86-6,3.82,2.15-3.87,5.5-6.54,9-9.05,3.46.36,4,2.73,4,5.61-.09,5.77,1.52,11.21,3.36,16.59C565.66,201.8,560.67,201.83,557.32,205.14Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#dfbb8c" }}
      />
      <path
        d='M318.38,462c.49-1.48,1.2-2.87,2.89-1.35,2.34,2.11,5.39,2.22,8.1,3.25,1.5-1.13,3.17-.87,4.78-.44,24.16,6.5,48.58,6.08,73.12,2.83,1.8-.24,3.61-.7,5.4.05,1.69,3.19-1.85,1.52-2.44,2.6-18.51,3.74-37.19,5.4-56,3.69C341.62,471.46,329.2,469.3,318.38,462Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#d5b28e" }}
      />
      <path
        d='M380.38,401.9c-11.26-.11-22.52-.4-33.72,1.28-9.49,1.43-18.84,3.4-27.8,6.95s-13.23,10.5-14.78,19.54c-.42,2.43-.54,4.92-.8,7.38-3-9.87.51-19.26,9.76-25.91,10.32-7.42,22.42-9.35,34.55-11,4.9-.68,9.84-1.7,14.85-1,6.55,1.2,13.2-.34,19.75.8C383.1,401.94,380.92,401.18,380.38,401.9Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#6f5233" }}
      />
      <path
        d='M410.23,468.92l3.27-1.82c.73-1.54,2.19-1.92,3.63-2.35a58,58,0,0,0,11.17-4,26.09,26.09,0,0,0,15.17-18.95c.92-2.33.91-2.35,2.89-3.53.16-.1.64.22.7.07.27-.61-.12.22-.31-.38-1.41-4.62-.33-9.92-3.8-14a26.7,26.7,0,0,0-11.16-10,6.3,6.3,0,0,1-1.13-.87,3.72,3.72,0,0,1-.93-1.93,2.56,2.56,0,0,1,.42-1.36,1,1,0,0,1,1.25.29c3.5,2.78,8,3.74,11.26,7.37,4.62,5.11,7.29,10.23,5.76,17.15a3.15,3.15,0,0,0,.69,3.23c-.26,1.37-.52,2.74-.79,4.1-1.49,9.93-6.9,16.87-16,21-4.6,2.14-8.88,5.06-14,5.9C415.59,466.53,412.92,470.1,410.23,468.92Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#372519" }}
      />
      <path
        d='M280.4,451.94c2.61,1.71,3.76,4.59,5.24,7.08a43.17,43.17,0,0,0,11.52,12.67c.93.7,4.94,1.07,2.26,4.2-6-1.41-10.75-4.9-15.12-9.05a3,3,0,0,1-1.76-1.88h-.16L278.3,455q-.51-2.5-1-5Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#9f7750" }}
      />
      <path
        d='M282.54,465l1.76,1.88c.71,4,4.1,5.68,7.08,7.33a140.6,140.6,0,0,0,32.11,12.7c3.94,1.05,7.63,3.07,11.83,3.13,4.34,1,9.25-.49,13,3-.46.9-1.36.93-2.19,1.11-12.95-1.46-25.2-5.62-37.38-9.95-7.18-2.56-14.28-5.38-20.81-9.4C284.31,472.54,282,469.54,282.54,465Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#7a6050" }}
      />
      <path
        d='M431.4,410.14l-1.1.05a3.16,3.16,0,0,1-2.88.2c-14.34-5-29.2-7.39-44.22-9-.3,0-.55-.32-.78-.57-4.48-1.86-9.18-.4-13.75-.91-2.08-.23-4.33.78-6.23-.83,20-1.47,39.63,1.07,59,6.11C425,406.15,428.74,407.14,431.4,410.14Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#896e5a" }}
      />
      <path
        d='M311.36,473.05l18,5.89c1.66,1.7,4.74,2,5,5.06-1.6.72-4-2.42-5,1.05C322.54,482.21,315.83,479.31,311.36,473.05Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#8d6c4d" }}
      />
      <path
        d='M348.32,493c-4.26-1.34-8.89-1-13-3,1.05-.16,2.29.22,3-1,7.85.69,15.19,4,23.12,4.23C357,494.91,352.63,494.35,348.32,493Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#412211" }}
      />
      <path
        d='M553,186c3.48-1.27,4.86-5.23,9.71-7.27-3.54,3.75-4.7,6.44-2.84,10.47,1.24,2.69.18,6.45.11,9.73a2.07,2.07,0,0,1-1.86,2c-5,1.06-10.4,1.3-14.4,5.24l-.54-1.32c.15-4.66,3-8.4,4.27-12.7C549.69,190.44,550.43,187.38,553,186Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#e6cb9c" }}
      />
      <path
        d='M302.26,468.09c-6.5.29-6.53-6.25-9.57-9.5a36.09,36.09,0,0,1-4.81-6.77c-5.55-10-12.46-19.11-16.51-30.11-1.7-4.61-5.06-9-6.62-14.12-.13-.42-1.48-.46-2.26-.67-7-20.15-13.88-40.31-20.94-60.42-4.5-12.79-9.35-25.45-13.16-38.48,5.1,1.83,5.93,7,7.61,10.93,3.57,8.41,5.27,17.76,9,25.86,4,8.59,6.36,17.66,10.3,26.17,1.83,3.94,1.24,8.9,5,12-.17,7.1,4.18,12.77,6.35,19.1,1.09,3.17,2.8,6.36,3.9,9.58,4.3,12.6,14.27,21.79,19.84,33.65A53.74,53.74,0,0,0,301.19,460C303.5,462.35,302,465.39,302.26,468.09Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "wheat" }}
      />
      <path
        d='M460.21,255.84c2.53-22.15,5.25-44.27,7.53-66.44,1.45-14.17,3-28.33,4-42.56.58-8.06-.71-17.25,6.29-23.88,2.6.08,1.91,2,1.38,3.11-2.09,4.55-1.83,9.32-1.68,14.1.15,4.08-1.14,8-1.07,12.12-2,16.26-4.12,32.5-6.09,48.77-2.24,18.56-4.5,37.13-7.12,55.65-.27,1.93-.64,4-2.13,5.47C458.32,260.55,460.28,258,460.21,255.84Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f7eed3" }}
      />
      <path
        d='M475.32,152.1q.56-5.92,1.12-11.85c.35-9.24,5.25-15.54,12.94-20,3.74-2.81,5.71-7.13,8.86-10.43l1.11-.58c.37.68.75,1.36,1.12,2a48.47,48.47,0,0,1-13.68,14.31c-5.63,4-7.67,10.45-5.53,16.39,0,.73.05,1.47.07,2.2.51,3.07-1.7,5.89-.83,9-2.74,18-5.55,35.92-8.21,53.9-2.77,18.65-5.4,37.32-8.09,56-1.13.09-1.48-.69-1.74-1.57,1.08-11.9,3-23.69,4.47-35.55,2.11-17.12,4.12-34.26,6.5-51.34.87-6.23,1.59-12.48,2.49-18.7A4.78,4.78,0,0,0,475.32,152.1Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#9e7755" }}
      />
      <path
        d='M463.31,262.05l.89,1q-2.71,20.94-5.43,41.89c-2.44,18.74-4.93,37.48-7.3,56.23a75,75,0,0,0-.22,7.94c-3.45-5.05-3.69-10.52-2.1-16.22a1.46,1.46,0,0,1,.52,1.68c.67-.18.1-.61.12-.88,1.42-15.27,2.53-30.57,4.54-45.79,1.8-13.86,2.92-27.81,5.67-41.53.32-.55,1.68.25,1.39-1.31C461.15,263.76,461.15,262.07,463.31,262.05Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#8f694b" }}
      />
      <path
        d='M490.16,121c-7.66,4.22-11.93,10.86-13.72,19.27-.11-5.39-1-10.9,2.48-15.75.43-.61-.25-1.19-.84-1.54,3.1-3.94,5.85-8.27,10.91-10.1a3.51,3.51,0,0,1,1.9-.06C489.69,115.44,492.91,118.48,490.16,121Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#e8caa3" }}
      />
      <path
        d='M461.22,266c-2.4,10.26-3.25,20.77-4.76,31.18-.52,3.58-.84,7.2-1.25,10.8-2.47-.38-1-2.06-1.08-3.24,1.54-16.34,3.23-32.67,6.08-48.85.75,2-.63,4.29,1,6.14A3.24,3.24,0,0,1,461.22,266Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#dfbb9c" }}
      />
      <path
        d='M454.13,304.69c.4,1.07-.12,2.44,1.08,3.24-1.76,16.63-3.53,33.25-5.41,51.05-.3-2.79-.47-4.44-.65-6.1C450.57,336.79,451.35,320.64,454.13,304.69Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#c39e83" }}
      />
      <path
        d='M490.16,121c-.78-1,2.47-3.25-1.44-3.6-2.07-.18-1.19-2.35.44-3,2.49-1,1-1.17-.18-1.54a19.5,19.5,0,0,1,9.26-3.08C496.84,114.44,493.83,118,490.16,121Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#bc9777" }}
      />
      <path
        d='M290.43,99.23c-1.35-1.13-8.83-11.67-9.19-12.94,5,1.67,10.06,3.32,10.22,10A4.11,4.11,0,0,1,290.43,99.23Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#c6a385" }}
      />
      <path
        d='M352.4,86.09c-.24-3.27-3.48-5.48-3.15-9,4.37,6,11.16,10.15,13.15,17.87a2.88,2.88,0,0,0-.65.13c-3.82-2.32-6.65-5.65-9.4-9.08Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f7e8cd" }}
      />
      <path
        d='M470.31,436c-3-2.82-.67-5.7-.61-8.71a83.66,83.66,0,0,1,2.72-18.38c2-8.09,3.55-16.33,7.91-23.76,1.87-3.2,2.54-7.26,3.69-10.89,2.93-9.2,6.82-18,10.54-26.8,2.84-6.73,5.28-13.7,8-20.44,2.09-5.22,4.67-11,5-17.13.15-2.8,2.46-4.53,3.89-6.67,3.95-5.92,5.62-12.84,7.65-19.4,2.32-7.53,7.38-13.52,9.79-20.88,1.87-5.73,3.65-11.65,6.64-16.82,3.67-6.35,4.09-13.91,8.76-19.94,2.91-3.74,3.72-9,6.3-13.28.85-1.41,1.49-2.35,3-2.72-.13,6.38-4.21,11.47-5.77,17.41-.69,2.61-2.31,5-3.51,7.47-2.73,6-5.35,12-6.81,18.56-.91,4.09-3.46,7.71-4.68,11.9a65.27,65.27,0,0,1-4.26,11.09C524.27,285,522,293.93,519,302.73c-3.11,9.27-5.8,18.78-10.86,27.38-2.06,3.51-2.15,8-3.27,12a61.53,61.53,0,0,1-2.6,7.95h0c-.24,5.59-5,9.11-6.38,14.28-1.16,4.48-1.57,9-5.17,13.08-2.22,2.5-2.65,7.5-3.91,11.37a260.6,260.6,0,0,0-7.55,26.57,75.53,75.53,0,0,1-7.11,19.51C471.78,435.58,471.34,436.27,470.31,436Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#b98d63" }}
      />
      <path
        d='M502.3,350a59.94,59.94,0,0,1,4.31-19c1.81-4.27,2.59-9.16,5.83-12.94a10.71,10.71,0,0,0,2.46-6.36c0-6.56,4.49-11.65,5.33-18,1.06-8,6.55-14.45,8.35-22.34.85-3.76,3.28-7.22,4.82-10.93,3.13-7.55,4.45-15.78,8.62-22.92a5.12,5.12,0,0,1,2.25-2.54c-2,8.62-5.31,16.76-8.47,25-1.64,4.24-2.85,8.66-4.37,13-3.38,9.58-6.77,19.16-10.27,28.7-3.45,9.39-5.48,19.31-9.85,28.35C508.12,336.61,507.67,344.43,502.3,350Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#a47753" }}
      />
      <path
        d='M470.31,436c5.55-11.56,8.42-23.9,11.48-36.28,1.1-4.47,2-9.66,4.91-13.88,1.48-2.14,3.37-4.66,1.41-7.68a1.37,1.37,0,0,1,.75-2c6.17-1.62,4.81-7.55,5.55-11.39a15.53,15.53,0,0,1,3.83-7.42c1.83-2.21,1.8-5.37,4.06-7.27,0,5.5-3,10.34-4,15.33-1.94,9.73-7.63,17.77-9.81,27.41-2.4,10.59-6.43,21-10.25,31.28-2.83,7.65-6.43,15.16-7.89,23.31-.28,1.57-.46,2.93-2,3.68a2.39,2.39,0,0,1-1.56-3.18c.95-4,1.59-8.13,3.51-11.86Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#a47753" }}
      />
      <path
        d='M470.31,436c.71,3.81-1,7.19-2.24,10.54a4.83,4.83,0,0,0,.29,4.5c0,.32,0,.64,0,1a100.36,100.36,0,0,1-5.75,15.58c-1.11,2.52-2.59,4.49-5.25,5.41A59.12,59.12,0,0,1,441,480.52c-5.2,1.46-9.8,4.82-15.34,5.58-5.14.71-9.83,3.73-15.25,3a142.74,142.74,0,0,1-23.17,2l-7.88,0a52.43,52.43,0,0,1-8.5-.1c-1.48-.08-3.56-.15-2-2.68,6.89-2.8,13.95-1.31,21-.94,5.84.3,11.79,1.29,17.44-1.29,9.44-.65,18.58-2.43,27.25-6.47,3.38-1.58,7.11-2.41,10.68-3.58,9.3-1.89,13.93-8.2,16-16.93,1.33-.36,1.63-2.85,2.58-5.14C466.34,448,463.72,440.32,470.31,436Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#b98d63" }}
      />
      <path
        d='M368.36,488.94c3.24,3,7.74-.67,11,2.07-3.6,1.88-7.58,1.41-11.08.56-6.63-1.6-13.47-1.82-20-3.62-4.66-1.28-9.3-2.63-13.94-3.95-1.6-1.76-4.41-2.33-5-5.06l6.95,1.08c.82.65,1.9.44,2.81.81,9.51.68,18.25,4.87,27.64,6.07A1.72,1.72,0,0,1,368.36,488.94Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#a97d50" }}
      />
      <path
        d='M463.21,474.89c-21.78,13.27-45.69,19.71-70.85,22.13l-3.91-.21c0-.25-.07-.5-.1-.76,6-1,12-2.25,18-2.92,15-1.67,28.9-6.79,42.45-13.12,4.3-2,8.65-3.94,12.94-6C462.86,473.49,463.2,473.79,463.21,474.89Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#9b7657" }}
      />
      <path
        d='M457.32,473a38.72,38.72,0,0,0,8.28-17.09,5.13,5.13,0,0,1,2.72-3.9C469.86,459.92,463.57,471.93,457.32,473Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#976c46" }}
      />
      <path
        d='M574.09,200.82c-5.59,4.84-14.59,3.85-17.9,11.67C557.67,204.91,564.15,200.73,574.09,200.82Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#c9a17c" }}
      />
      <path
        d='M387.27,491a7.31,7.31,0,0,1,4.21-1c6.36.56,12.63-.82,19-.91C402.83,491,395.18,492.56,387.27,491Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#a97d50" }}
      />
      <path d='M352.35,86.06l9.4,9.08C357.15,93.63,354.25,90.36,352.35,86.06Z' transform='translate(-162.27 -42.07)' style={{ fill: "#7d6f56" }} />
      <path
        d='M278.36,142.09c4.66.36,4.08,3.3,3.4,7.23-1.42-2.19-2.4-3.72-3.39-5.24C278.37,143.42,278.37,142.75,278.36,142.09Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f5e2c4" }}
      />
      <path
        d='M262.76,128.13c-5.05-.8-8.51-4.44-12.55-7.06l-2.4-3.95h1.52C252.78,122.05,258.47,124.23,262.76,128.13Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#4d372a" }}
      />
      <path
        d='M300.35,372c2.17-10.42.64-20.75-.44-31.14-.58-5.6-2.75-11.08-1.56-16.84,2.1,8,2.79,16.15,3.93,24.26,1,6.81,2.14,13.7.15,20.6C302.05,370.24,301.71,371.41,300.35,372Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#fcf7e5" }}
      />
      <path
        d='M250.35,103.91c0-3.09,4.5-9,7.37-9.71,3.34-.78,7.44,2.79,7.44,7.51a50.29,50.29,0,0,1-2,13.09c-1,3.73-3.51,4.17-6.17,1.37C253.73,112.72,250.42,109.16,250.35,103.91Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#fcfcf9" }}
      />
      <path
        d='M338.38,481l-2.11-.93c8.31,0,16.64.35,24.92-.16,7.16-.45,14.44.34,21.49-1.74,4.24-1.24,7.39,2.21,11.21,2.95,2.3.45,4.44,1.54,6.5-.48.22-.21.66-.47.88-.39,4.14,1.52,8-.56,11.84-.78a19.52,19.52,0,0,0,8.48-2.31c4.66-2.43,10-3.24,14.62-5.91,1.12-.64,2.51-.88,3.3-2s2-2.46,1.5-4-2.3-.78-3.43-1.26c-1.7-.72-3.68.43-5.26-.87a42,42,0,0,0,16-21c-.23,5.3,3.93,9.24,4.16,14.62a15.8,15.8,0,0,1-10,15.8c-12.53,5.38-25.39,10.41-39.24,11.7-4.65.43-9.38-.38-14,.91-5,3.72-10.69,2.16-15.8,1.26C361.84,484.24,350.11,482.62,338.38,481Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f6e3ac" }}
      />
      <path
        d='M338.38,481A134.78,134.78,0,0,1,372.7,485a35,35,0,0,0,16.62,0c4.65,3,9.73,1.84,14.71,1.52a31.84,31.84,0,0,0,3.34-.55c-1,2-2.86,1.94-4.71,1.95-7,0-14,1.12-21,.09-4.45-.65-8.88.44-13.31.91-1.15-1.1-2.58-1.23-4.08-1.39C355.33,486.62,347,483.37,338.38,481Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#dbb576" }}
      />
      <path
        d='M312.74,420.05c4.92-5.57,11.48-8.16,18.4-10.05,18.51-5.06,37.3-7.28,56.49-5.46,3.13.29,6.28.41,9.42.61,9.74-.59,19.05,1.49,28.2,4.58a17.6,17.6,0,0,1,6.07,3.34l-.06-.06c4.9,3,10.32,5.56,11.53,12.1-.66,1.61-1.8,1.13-2.77.49a26.63,26.63,0,0,0-5.63-2.86c-11.92-6.62-24.86-9.81-38.29-11.39-1.71-.2-3.77.29-4.71-1.88-.2-.45.73-.9.47-.89-8,.35-15.79-1.91-23.77-1.27-2,.16-4,0-5.9.67-16.7,1.51-33.31,3.37-47.95,12.7A2.47,2.47,0,0,1,312.74,420.05Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#20120d" }}
      />
      <path
        d='M312.74,420.05l1.6-.11c1,2.52-2.67,3.46-1.91,5.88a4.82,4.82,0,0,1,.28,1.95c-2.33,4.1-5.1,8.09-3.23,13.22-.42,3.68,2.1,6.61,2.56,10.06.16.66.31,1.32.47,2C302.86,445.55,303,429.55,312.74,420.05Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#a89678" }}
      />
      <path
        d='M382.42,400.84c16.33,1.24,32.58,2.87,47.88,9.35l-.12,1.23c-3.91.17-7.22-1.95-10.77-3-6.61-2-13.7-1.51-20.13-4.21l-18.9-2.27C380.76,401,381.65,401,382.42,400.84Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#361e17" }}
      />
      <path
        d='M399.28,404.17c10.61,1.1,21,3.06,30.9,7.25.21.67,1.64.49,1.14,1.65-11-4.48-22.71-5.88-34.28-7.93Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#e0d5ce" }}
      />
      <path
        d='M435.34,422c3-.66,4.14,3.47,7.15,2.72.29-.66,0-1.65,1.09-1.84,4.36,6.2,5.75,12.53.86,19.15-1.21-5.17-2.22-10.4-5.33-14.88C437.32,425.83,434.9,425,435.34,422Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#765c4b" }}
      />
      <path
        d='M443.58,422.89l-1.09,1.85c-2.23-5.36-7.19-8.11-11.23-11.73C437.07,414.18,440.25,418.62,443.58,422.89Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#d8cac1" }}
      />
      <path
        d='M461.22,266c0-1.33,0-2.65,0-4,5.14-34.74,9.06-69.64,13.28-104.49.21-1.81.57-3.6.86-5.39a3.12,3.12,0,0,1,1.83,3.68c-2.22,15.77-4.51,31.53-6.61,47.32-1.85,13.9-3.51,27.83-5.21,41.75-.7,5.73-2.24,11.36-2,17.2C461.35,262.67,463.54,265.51,461.22,266Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#835b37" }}
      />
      <path
        d='M362.19,406.83c3,1,6.29-.72,9.2,1.05-1.16,1.1-2.59.41-3.89.61-11,1-21.93,2.35-32.63,5.4-8.72,2.48-16.76,6-23.56,12.05-.89-3,2.37-3.82,3-6,10-8.4,22.43-9.73,34.56-11.84C353.27,407.33,357.75,407.23,362.19,406.83Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#f2e9cb" }}
      />
      <path
        d='M311.31,425.94c2.61-4.64,7.15-6.94,11.7-8.8a125.68,125.68,0,0,1,44.44-9c.25.19.33.38.23.56s-.18.26-.28.26c-11.07,1.76-22.33,2.46-33.09,6-8.08,2.69-16.07,5.4-22.05,11.91Z'
        transform='translate(-162.27 -42.07)'
        style={{ fill: "#7e6951" }}
      />
      <path d='M367.4,409a7.38,7.38,0,0,1,0-.82l3.95-.27h12.16V409Z' transform='translate(-162.27 -42.07)' style={{ fill: "#20120d" }} />
    </svg>
  )
}
