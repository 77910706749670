import { useLayoutEffect } from "react"
import * as pop from "popmotion"
import useConstant from "utils/useConstant"

export const directions = {
  DOWN: 0,
  UP: 1,
}

const noop = Function.prototype

export default function useT({ t: initialT = 0, min = 0, max = 1, easing: ease = pop.linear, duration = 300, repeat = 0, repeatType = "loop" } = {}) {
  const state = useConstant(() => ({
    duration,
    repeat,
    repeatType,
    animation: null,
    onMin: new Set(),
    onMax: new Set(),
    onUpdate: new Set(),
    lockers: new Map(),
    __target: pop.clamp(min, max, initialT),
    __t: pop.clamp(min, max, initialT),
    get target() {
      if (this.lockers.size) return [...this.lockers.values()][0]
      return this.__target
    },
    set target(v) {
      this.__target = pop.clamp(min, max, v)
    },
    get t() {
      return this.__t
    },
    set t(v) {
      this.__t = v
    },
  }))

  const set = () => {
    state.t = state.target
    void [...state.onUpdate].forEach(handler => handler(state.t))
    if (state.t === min) [...state.onMin].forEach(handler => handler(min))
    if (state.t === max) [...state.onMax].forEach(handler => handler(max))
  }
  const animate = async ({ delay = 0 } = {}) => {
    if (state.target === state.t) return

    const dir = (state.dir = state.target >= state.t ? directions.UP : directions.DOWN)
    const range = Math.abs(state.target - state.t) / (max - min)
    const duration = state.duration * range
    const elapsed = -delay + (dir === directions.UP ? (state.t / (max - min)) * duration : (1 - state.t / (max - min)) * duration)
    return new Promise(resolve => {
      state.animation = pop.animate({
        from: state.t,
        to: pop.clamp(min, max, state.target),
        duration,
        elapsed,
        ease,
        repeat,
        repeatType,
        onUpdate: t => {
          state.t = t
          void [...state.onUpdate].forEach(handler => handler(t))
          if (t === min) [...state.onMin].forEach(handler => handler(min))
          if (t === max) [...state.onMax].forEach(handler => handler(max))
        },
        onComplete: resolve,
      })
    })
  }

  useLayoutEffect(() => {
    animate()
    return () => {
      state.animation?.stop?.()
      state.animation = null
    }
  })

  return {
    get duration() {
      return state.duration
    },
    set duration(v) {
      state.duration = v
    },
    get repeat() {
      return state.repeat
    },
    set repeat(v) {
      state.repeat = v
    },
    get repeatType() {
      return state.repeatType
    },
    set repeatType(v) {
      state.repeatType = v
    },
    onMin(handler = noop) {
      state.onMin.add(handler)
      return () => state.onMin.delete(handler)
    },
    onMax(handler = noop) {
      state.onMax.add(handler)
      return () => state.onMax.delete(handler)
    },
    onChange(handler = noop) {
      state.onUpdate.add(handler)
      return () => state.onUpdate.delete(handler)
    },
    delayed(delay, t = max) {
      if (t === state.target && state.animation) return state.animation
      state.animation?.stop?.()
      state.animation = null
      state.target = t
      return animate({ delay })
    },
    set(t) {
      state.animation?.stop?.()
      state.animation = null
      state.target = t
      return set()
    },
    t(t, opt = {}) {
      if ([undefined, null].includes(t)) return state.t
      if (t === state.target && state.animation) return state.animation
      state.animation?.stop?.()
      state.animation = null
      state.target = t
      return animate({ opt })
    },
    toggle() {
      state.animation?.stop?.()
      state.animation = null
      state.target = state.dir === directions.UP ? min : max
      animate()
      return +!!state.dir
    },
    lock(t, id) {
      if (t === state.target && state.animation) return state.animation
      state.animation?.stop?.()
      state.animation = null
      state.lockers.set(id, pop.clamp(min, max, t))
      return animate()
    },
    unlock(id) {
      state.animation?.stop?.()
      state.animation = null
      state.lockers.delete(id)
      return animate()
    },
  }
}
