import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { getMediaQuery } from "./../../../css/breakpoints"
import { button, buttonLight, buttonRound, mask } from "css/button"
import { linearToRight } from "css/gradients"
import { mediumLighter } from "css/heading-new"
import { clamp } from "css/text"
import { wviPhoneP, wviPhonePS, wviPhoneP6s, wviPadP, wviPadL } from "./../../../css/breakpoints"
/*
import { colors } from "../../../Shared/Color"
import { toREM } from "../../../Shared/Font"
import { Link } from "./../../Link"
import { Button } from "./../../Button"
*/
const Wrapper = styled.div`
  position: relative;
  //  overflow: hidden;
  button {
    align-items: center;
  }
`
/*
export const Sticker = styled.div`
  visibility: hidden;
  position: absolute;
  z-index: 2;
  width: 100%;
  text-align: center;
  top: var(--wv-bottomnav-top);
  padding: var(--wv-bottomnav-sticker-pad) 0;

  .contrast-active &[data-sticky=true] button {
    background-color: ${colors.greenContrast};
    border-color: ${colors.greenContrast};
  }

  .tab-active &[data-sticky=true] {
    visibility: hidden !important;
  }
  .tab-active &[data-sticky=false] {
    visibility: visible !important;
  }
`
*/
export const Main = styled.nav`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  z-index: 2;
  text-align: center;
  box-sizing: border-box;
  top: ${({ embed }) =>
    embed ? `calc(var(--vh100) - 2rem - (var(--wv-bottom-HY) - 25px) - var(--wv-safe))` : `calc(var(--vh100) - 2rem - var(--wv-bottom-HY) - var(--wv-safe))`};
  display: flex;
  //  overflow: hidden;
  flex-direction: row-reverse;
  ._wv & {
    background: rgba(0, 255, 127, 0.9);
  }
  &::after {
    //    content: "";
    flex: 1;
    display: block;
    width: 30px;
  }
  & button {
    ${button}
    ${buttonLight}

    & span {
      font-size: 1em !important;
    }
  }
  ${wviPhoneP(css`
    &::after {
      display: none;
    }
  `)}
`

export const DiscoverItem = styled(Wrapper)`
  padding: 0;
  text-align: center;
  ${wviPhoneP(css`
    & > button {
      padding: calc(var(--height) / 5) calc(var(--height) / 4);
    }
  `)}
`
/*
export const SelectItem = styled(Wrapper)`
  padding: 0 1rem;
`

export const BackItem = styled(Wrapper)`
  flex: 1;
  text-align: start;
  visibility: hidden;
  opacity: 0;
  button {
    padding-left: calc(min(1vw,.5rem));
    padding-right: calc(min(1vw,.5rem));
  }
  span:nth-child(2) {
    font-size: 90%;
  }
  @media ${getMediaQuery("s", "max")} {
    span:nth-child(2) {
      display: none;
    }
  }
  outline: none;
`
*/
export const ResetItem = styled(Wrapper)`
  flex: 1;
  text-align: start;
  & > * {
    margin-right: 1rem;
  }

  & > a {
    ${buttonRound}
    background: ${linearToRight("rgb(var(--cultured))", "rgb(var(--pure-white))")};

    &::before {
      background: linear-gradient(to right, rgb(var(--raisin-black)), rgb(var(--dark-silver)));
      content: "";

      ${mask(
        '<path d="m16.3278842 29.9159202c6.1190193-.6741125 11.0229584-5.4848247 11.7208506-11.5028111.9159836-7.8687317-5.3027346-14.56695888-13.0979415-14.65275502v-3.52989834c0-.19610546-.2492472-.30028649-.417489-.17772057l-7.39018017 5.33774557c-.12462361.09192443-.12462361.26964501 0 .36156944l7.39018017 5.33774562c.1682418.1225659.417489.0122565.417489-.1777206v-3.52377006c5.4772077.08579614 9.8639588 4.66976136 9.490088 10.14845766-.3177902 4.7126595-4.2247404 8.536716-9.0165182 8.8370025-5.0784122.3186713-9.39662028-3.2479968-10.16928667-7.9667845-.14331715-.8763463-.92221472-1.513689-1.81950472-1.513689-1.11538131 0-1.98774659.974399-1.81327353 2.0591074 1.08422541 6.7227404 7.39641129 11.7663277 14.70558602 10.963521z" />'
      )}

      [dir="rtl"] & {
        transform: scaleX(-1);
      }
    }
  }
`

export const LoaderWrapper = styled.div`
  position: absolute;
  right: -30px;
  //  transform: translateX(100%);
  //  background: pink;
`
/*
export const LoaderItem = styled.div`
  flex: 1;
  position: relative;
`

export const NavButton = styled(Button)`
  position: relative;
  &:not(:lang(ar)):not(:lang(fa)) span::first-letter {
    text-transform: uppercase;
  }
  outline: none;

  .tab-active &:focus {
    outline: 2px solid ${colors.green};
    outline-offset: 3px;
  }
`

export const NavLink = styled(Link)`
  position: relative;
  &:not(:lang(ar)):not(:lang(fa)) span::first-letter {
    text-transform: uppercase;
  }
  outline: none;

  .tab-active &:focus {
    outline: 2px solid ${colors.green};
    outline-offset: 3px;
  }
`

export const BottomNavWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
`

export const BottomNavSwitch = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`
*/
