import React, { createContext, useContext, useReducer, useRef, useEffect } from "react"
import { useMotionValue, useTransform } from "framer-motion"
import { detectScrollType } from "normalize-scroll-left"

export const CarolContext = createContext()
export const CarolCtx = createContext()

const reducer = (state, data) => {
  return { focusSlide: data.focusSlide }
}

export const CarolStateProvider = ({ children }) => {
  const contextValue = useReducer(reducer, { focusSlide: 0 })
  return <CarolContext.Provider value={contextValue}>{children}</CarolContext.Provider>
}

export const CarolProvider = ({ children, remote }) => {
  const ref = useRef()
  const winWidth = useMotionValue(process.browser ? document.documentElement.clientWidth : 0)
  const winHeight = useMotionValue(process.browser ? document.documentElement.clientHeight : 0)
  const progress = useMotionValue(0)
  const scrolling = useMotionValue(false)
  const grabbing = useMotionValue(false)
  const slide = useMotionValue(0)
  const targetSlide = useMotionValue(0)
  const focused = useMotionValue(0)
  const scrollType = detectScrollType()
  const snapPoints = useMotionValue("")
  const ofsWidth = useMotionValue(0)
  const scrollWidth = useMotionValue(0)
  const visiblePercent = useMotionValue(0)
  const direction = useMotionValue(process.browser ? document.documentElement.getAttribute("dir") : "ltr")
  const wsl = useMotionValue(0)

  // const visiblePercent = useTransform([ofsWidth, scrollWidth], ([ow, sw]) => ow / sw)
  const winSize = useTransform(winWidth, w => (process.browser ? useCarol.bpNames[useCarol.BP.findIndex(r => w >= r[0] && w <= r[1])] : ""))

  const winSizeConf = useTransform(winWidth, w => (process.browser ? useCarol.bpNames[useCarol.BPConf.findIndex(r => w >= r[0] && w <= r[1])] : ""))

  const onResize = e => {
    winWidth.set(document.documentElement.clientWidth)
    winHeight.set(document.documentElement.clientHeight)
  }

  useEffect(() => {
    if (process.browser) {
      window.addEventListener("resize", onResize, true)
      window.addEventListener("orientationchange", onResize)
    }

    return () => {
      if (process.browser) {
        window.removeEventListener("resize", onResize, true)
        window.removeEventListener("orientationchange", onResize)
      }
    }
  }, [])

  return (
    <CarolCtx.Provider
      value={{
        ref,
        progress,
        scrolling,
        grabbing,
        snapPoints,
        slide,
        visiblePercent,
        focused,
        scrollType,
        scrollWidth,
        ofsWidth,
        targetSlide,
        winWidth,
        winHeight,
        winSize,
        winSizeConf,
        direction,
        wsl,
        remote,
      }}
    >
      {children}
    </CarolCtx.Provider>
  )
}

export const useCarol = () => useContext(CarolCtx)
export const useCarolStateValue = () => useContext(CarolContext)

export const isTouchDevice = !(process.browser && window.matchMedia("(hover: hover) and (pointer: fine)"))?.matches

useCarol.bpNames = ["xs", "s", "m", "l", "xl"]
useCarol.BP = [
  [0, 320],
  [321, 767],
  [768, 1068],
  [1069, 1919],
  [1920, Infinity],
]
useCarol.BPConf = [
  [0, 320],
  [321, 767],
  [768, 1440],
  [1441, 1919],
  [1920, Infinity],
]
