"use client"

import { Cloudinary } from "@cloudinary/url-gen"
import { limitFit } from "@cloudinary/url-gen/actions/resize"

export function videoLoader({ policy, src }, env) {
  const { cloudinary: { cloudName, secureDistribution } = {} } = env
  src = src && src.constructor === Object ? src : { public_id: src || "/", version: 1 }

  const cld = new Cloudinary({
    cloud: {
      cloudName,
    },
    url: Object.assign({ privateCdn: !!secureDistribution }, !!secureDistribution && { secureDistribution }),
  })

  return ({ width, quality = "auto", format = "auto:video" }) => {
    const video = cld.video(src.public_id)
    video.setVersion(src.version)

    if (policy) {
      video.namedTransformation(policy)
    }

    video.resize(limitFit().width(width))
    video.addTransformation(`f_${format}`)
    video.quality(quality)

    const vUrl = new URL(video.toURL())
    vUrl.searchParams.delete("_a") // necessary for hydration

    const poster = video.addTransformation("so_0")
    const pUrl = new URL(poster.toURL())
    pUrl.searchParams.delete("_a") // necessary for hydration

    return {
      poster: pUrl,
      src: vUrl,
    }
  }
}

export default function VideoCLD({ video = [], env }) {
  return (
    <div className='grid bg-slate-500 w-full h-full '>
      <video
        className='landscape w-full h-full object-cover col-start-1 col-end-1 row-start-1 row-end-1 hidden md:block'
        controlsList='nofullscreen nodownload'
        disablePictureInPicture
        disableRemotePlayback
        loop={true}
        muted={true}
        playsInline
        preload='metadata'
        tabIndex='-1'
        poster={videoLoader({ src: video.landscape[0] }, env)({ width: video.landscape[0].width, quality: 75 }).poster}
      >
        <source src={videoLoader({ src: video.landscape[0] }, env)({ width: video.landscape[0].width }).src} type='video/mp4' />
      </video>
      <video
        className='portrait w-full h-full object-cover col-start-1 col-end-1 row-start-1 row-end-1 block md:hidden'
        controlsList='nofullscreen nodownload'
        disablePictureInPicture
        disableRemotePlayback
        loop={true}
        muted={true}
        playsInline
        preload='metadata'
        tabIndex='-1'
        poster={videoLoader({ src: video.portrait[0] }, env)({ width: video.portrait[0].width, quality: 75 }).poster}
      >
        <source src={videoLoader({ src: video.portrait[0] }, env)({ width: video.portrait[0].width }).src} type='video/mp4' />
      </video>
    </div>
  )
}
