import styled from "@emotion/styled"

export const Main = styled.div`
  position: absolute;
  flex: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 500;
  visibility: hidden;
  .wv_puppet & {
    background: rgba(255, 0, 0, 0.3);
  }
`

export const WLoader = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  opacity: 0;
`
