import { createContext, useContext } from "react"

const Context = createContext()
export const Provider = Context.Provider
export function useWebSocket() {
  return useContext(Context)
}

// https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState
export const readyStates = {
  CONNECTING: global?.WebSocket?.CONNECTING ?? 0,
  OPEN: global?.WebSocket?.OPEN ?? 1,
  CLOSING: global?.WebSocket?.CLOSING ?? 2,
  CLOSED: global?.WebSocket?.CLOSED ?? 3,
  TOKENIZED: 4,
}
