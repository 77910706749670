import styled from "@emotion/styled"

import { Wrapper } from "../Wrapper"

export const Roller = styled(Wrapper)`
  width: 100%;
  height: auto;
  column-gap: initial;

  grid-auto-columns: calc(100% / 1.5);
  padding-left: 25%;

  & > li {
    width: 100%;
    overflow-x: hidden;
    user-select: none;
    transform: translateZ(0);
  }

  & > li:last-child {
    padding-right: 50%;
  }

  [dir="rtl"] & {
    padding-left: initial;
    padding-right: 25%;
  }

  [dir="rtl"] & > li:last-child {
    padding-right: initial;
    padding-left: 50%;
  }

  @media (min-width: 1024px) and (max-width: 1440px) {
    grid-auto-columns: calc(100% / 2.5);
    padding-left: calc(100% / (4 / 1.5));

    & > li:last-child {
      padding-right: 150%;
    }

    [dir="rtl"] & {
      padding-left: initial;
      padding-right: calc(100% / (4 / 1.5));
    }

    [dir="rtl"] & > li:last-child {
      padding-right: initial;
      padding-left: 150%;
    }
  }

  @media (min-width: 1441px) {
    grid-auto-columns: calc(100% / 3.5);
    padding-left: calc(100% / 2.4);

    & > li:last-child {
      padding-right: 250%;
    }

    [dir="rtl"] & {
      padding-left: initial;
      padding-right: calc(100% / 2.4);
    }

    [dir="rtl"] & > li:last-child {
      padding-right: initial;
      padding-left: 250%;
    }
  }
`
