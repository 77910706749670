import React, { useState, useRef, useEffect, useCallback, memo } from "react"
import { Wrapper, Main, SubHeading, Desc, PriceLabel, InfoIcon, ButtonInfo } from "./styles"
import { INOUT_DURATION, IN_EASE, OUT_EASE, FADE_DURATION, FADE_EASE, EMPTY_STRING } from "./../constants"
import Price from "./../Price"
import { useWatchVariations, mvSubscribe } from "contexts/Configurator"
import { logmine, cssApply, upperFirstLetter } from "./../utils"
import { useTransform } from "framer-motion"

const VARIANTS = {
  main: {
    hidden: [
      [
        "transition",
        `opacity ${INOUT_DURATION[1]}ms cubic-bezier(${OUT_EASE}),
        visibility 0ms ${INOUT_DURATION[1]}ms`,
      ],
      ["opacity", 0],
      ["visibility", "hidden"],
    ],
    visible: [
      [
        "transition",
        `opacity ${INOUT_DURATION[0]}ms cubic-bezier(${IN_EASE}),
        visibility 0ms 0ms`,
      ],
      ["visibility", "inherit"],
      ["opacity", 1],
    ],
  },
}

const Title = memo(({ heading }) => {
  //logmine("Main", "Header", "Title")
  const { stepindex, wactive } = useWatchVariations()
  const [label, setLabel] = useState(EMPTY_STRING)

  const update = useCallback(([_wactive, _stepindex]) => {
    //logmine("Callback useTransform", "Header", "Title", "update")
    return _stepindex === 0 ? heading.title : !_wactive ? EMPTY_STRING : _wactive.specs[2]
  }, [])

  const title = useTransform([wactive, stepindex], update)
  useEffect(() => mvSubscribe("Header Title", title, v => setLabel(v)), [title])

  return <h1 dangerouslySetInnerHTML={{ __html: label }}></h1>
})

const Detail = memo(() => {
  //logmine("Main", "Header", "Detail", null)
  const { stepindex, wactive } = useWatchVariations()
  const [label, setLabel] = useState(EMPTY_STRING)

  const update = useCallback(([_wactive, _stepindex]) => {
    //logmine("Callback useTransform", "Header", "Title", "update")
    if (!_wactive || _stepindex <= 0) return EMPTY_STRING
    return upperFirstLetter(_stepindex <= 1 ? EMPTY_STRING : _wactive.specs[_stepindex + 1])
  }, [])

  const detail = useTransform([wactive, stepindex], update)

  const detailUpdate = useCallback(v => {
    //logmine("Callback", "Header", "Title", "detailUpdate", v)
    setLabel(v)
  }, [])

  useEffect(() => mvSubscribe("Header Detail", detail, detailUpdate), [detail, detailUpdate])

  return <span>{label}</span>
})

const Full = memo(() => {
  //logmine("Main", "Header", "Full")
  const { mode, stepindex, wactive } = useWatchVariations()
  const [label, setLabel] = useState(EMPTY_STRING)

  const update = useCallback(
    ([_wactive, _stepindex]) => {
      //logmine("Callback useTransform", "Header", "Full", "update", _wactive, _stepindex)
      if (_stepindex === 0) return EMPTY_STRING
      if (!_wactive) return null
      let ret = upperFirstLetter(_wactive.specs[0])
      if (mode === "global") {
        if (_stepindex === 0) ret = EMPTY_STRING
        else ret = _wactive.specs[1]
      }
      return ret
    },
    [mode]
  )

  const full = useTransform([wactive, stepindex], update)

  const fullUpdate = useCallback(v => {
    //logmine("Callback", "Header", "Full", "fullUpdate", v)
    setLabel(v)
  }, [])

  useEffect(() => mvSubscribe("Header Full", full, fullUpdate), [full, fullUpdate])

  return <p>{label}</p>
})

function useDisplay(route, newstepindex, stepindex, busy, category, mode, ref) {
  //logmine("CustomHook", "Header", "useDisplay")
  /*  const update = useCallback(v => {
    //logmine("Callback", "Header", "update")
    if (!v)
      return cssApply(ref.current, VARIANTS.main.hidden, true)
  }, [ref])
*/
  const newstepindexUpdate = useCallback(
    v => {
      //logmine("Callback", "Header", "newstepindexUpdate")
      const _stepindex = stepindex.get()
      if (v > 0) {
        if (_stepindex > 0) return
        cssApply(ref.current, VARIANTS.main.hidden)
      } else {
        if (_stepindex < 0) return
        cssApply(ref.current, VARIANTS.main.hidden)
      }
    },
    [ref, stepindex]
  )

  const stepindexUpdate = useCallback(
    v => {
      //logmine("Callback", "Header", "stepindexUpdate")
      if (v < 0) return
      ref.current.setAttribute("data-subtitle", v === 0)
      cssApply(ref.current, VARIANTS.main.visible)
    },
    [ref]
  )

  useEffect(() => mvSubscribe("Header useDisplay", newstepindex, newstepindexUpdate), [newstepindex, newstepindexUpdate])
  useEffect(() => mvSubscribe("Header useDisplay", stepindex, stepindexUpdate), [stepindex, stepindexUpdate])
  //  useEffect(() => mvSubscribe("Header useDisplay", busy, stepindexUpdate), [stepindex, stepindexUpdate])
}

export const Header = ({ children, heading }) => {
  //logmine("Main", "Header")
  const { mode, newstepindex, stepindex, category, route, busy, tint, labels } = useWatchVariations()
  const [theme] = useState(tint.get())
  const ref = useRef()

  useDisplay(route, newstepindex, stepindex, busy, category, mode, ref)

  //  useEffect(() => mvSubscribe("Footer", tint, v => setTheme(v)), [tint])

  return (
    <Main ref={ref} role='region' theme={theme}>
      <header>
        <Title heading={heading} />
        <SubHeading aria-live='polite'>
          <Full />
          <Detail />
        </SubHeading>
      </header>
      <Price />
      {children}
    </Main>
  )
}
