import { useRouteMatch } from "react-router"
import { useConsole } from "contexts/Console"
import { Provider } from "contexts/Locale"

export default function Locale({ children, locales = [], schema = "/:locale" }) {
  const console = useConsole()

  const { params: { locale: urlLocale = null } = {} } = useRouteMatch(schema) ?? {}
  const { code: defaultLocale } = locales[0]
  const { code: locale, dir } = locales.find(({ code }) => code === urlLocale) ?? locales[0]
  const codes = locales.map(({ code }) => code)
  schema = schema.replace(":locale", `:locale(${codes.join("|")})?`)

  console.verbose("Locale(%o)", { locales, codes, defaultLocale, locale, dir, schema })
  return <Provider value={{ locales: codes, defaultLocale, locale, dir, schema }}>{children}</Provider>
}
