import { css } from "@emotion/react"

export const size = {
  xs: {
    max: "320px",
  },
  s: {
    min: "321px",
    max: "767px",
  },
  m: {
    min: "768px",
    max: "1068px",
  },
  l: {
    min: "1069px",
    max: "1919px",
  },
  xl: {
    min: "1920px",
  },
}

export const breakpoints = {
  xs: {
    min: 0,
    max: parseInt(size.xs.max, 10),
  },
  s: {
    min: parseInt(size.s.min, 10),
    max: parseInt(size.s.max, 10),
  },
  m: {
    min: parseInt(size.m.min, 10),
    max: parseInt(size.m.max, 10),
  },
  l: {
    min: parseInt(size.l.min, 10),
    max: parseInt(size.l.max, 10),
  },
  xl: {
    min: parseInt(size.xl.min, 10),
    max: Infinity,
  },
}

/**
 * getMediaQuery(mq) Is a method returning the media query syntax for the breakpoints, it used for css with Styled-Components
 * @params "mq"
 * Is the letter passing in parameter, represent the breakpoint
 * @params "custom"
 * Is a string represent the 'min' or 'max' request and create a custom mediaquery
 */
export const getMediaQuery = (mq, custom) => {
  if (!mq) throw new Error(" At least one parameter is required !")

  switch (mq) {
    case "xs":
      return `(max-width: ${size[mq].max})`
    case "xl":
      return `(min-width: ${size[mq].min})`
    case "s":
    case "m":
    case "l":
      return !custom
        ? `(min-width: ${size[mq].min}) and (max-width: ${size[mq].max})`
        : custom === "min"
        ? `(min-width: ${size[mq].min})`
        : custom === "max"
        ? `(max-width: ${size[mq].max})`
        : ""
    default:
      throw new Error(" The MediaQuery requested : " + mq + " is unknown. Please, make your choice between xs, s, m, l and xl")
  }
}

export const wviPadL = v => css`
  @media screen and (min-width: 1024px) and (max-height: 900px) {
    ${v}
  }
`

export const wviPadXP = v => css`
  @media screen and (min-width: 1024px) and (min-height: 1100px) {
    ${v}
  }
`

export const wviPadP = v => css`
  @media screen and (max-width: 900px) {
    ${v}
  }
`

export const wviPhoneP6s = v => css`
  @media screen and (max-width: 440px) and (max-height: 780px) {
    ${v}
  }
`

export const wviPhoneP = v => css`
  @media screen and (max-width: 440px) {
    ${v}
  }
`

export const wviPhonePS = v => css`
  @media screen and (max-width: 380px) {
    ${v}
  }
`

export const wviPhonePSE = v => css`
  @media screen and (max-width: 375px) and (max-height: 667px) {
    ${v}
  }
`
