import React, { useEffect, useState, useRef, useCallback, useLayoutEffect, memo } from "react"
import { mvSubscribe, useWatchVariations } from "contexts/Configurator"
import { logmine, cssApply } from "./utils"
import { WatchVariationsMain, WatchVariationsWrapper } from "./styles"
import { STEPS, KILL_ROUTE, KILL_DURATION, KILL_EASE, INOUT_DURATION, IN_EASE } from "./constants"
import { useHash } from "./Hash"
import { useWebSocket } from "contexts/WebSocket"

const VARIANTS = {
  main: {
    hidden: [
      [
        "transition",
        `opacity ${KILL_DURATION}ms cubic-bezier(${KILL_EASE}),
        visibility 0ms ${KILL_DURATION}ms`,
      ],
      ["opacity", 0],
      ["visibility", "hidden"],
    ],
    visible: [
      [
        "transition",
        `opacity ${INOUT_DURATION[0]}ms cubic-bezier(${IN_EASE}),
        visibility 0ms 0ms`,
      ],
      ["visibility", "inherit"],
      ["opacity", 1],
    ],
  },
  wrapper: {
    hidden: [
      [
        "transition",
        `opacity ${KILL_DURATION * 1.1}ms cubic-bezier(${KILL_EASE}),
        visibility 0ms ${KILL_DURATION * 1.1}ms`,
      ],
      ["opacity", 0],
      ["visibility", "hidden"],
    ],
    visible: [
      [
        "transition",
        `opacity ${INOUT_DURATION[0]}ms cubic-bezier(${IN_EASE}),
        visibility 0ms 0ms`,
      ],
      ["visibility", "inherit"],
      ["opacity", 1],
    ],
  },
}

function useDisplay(route, mode, ref) {
  //logmine("CustomHook", "Main", "useDisplay")
  const update = useCallback(
    v => {
      if (mode === "cover") return
      if (!v || v === KILL_ROUTE) cssApply(ref.current, VARIANTS.main.hidden)
      else cssApply(ref.current, VARIANTS.main.visible, true)
    },
    [mode, ref]
  )

  useEffect(() => mvSubscribe("Main", route, update, false), [route, update])
}

export const Main = memo(({ children }) => {
  //logmine("Main", "Main")
  const { layout, root, mode, route, footeredh, fromui, hashing, family, stepindex, puppet, std } = useWatchVariations()
  const ref = useRef()
  const ws = useWebSocket()

  useDisplay(route, mode, ref)

  useLayoutEffect(() => {
    root.set(ref)
    const { current: main } = ref
    if (!main) return
    if (ws.connector === "catalog") main.classList.add("wv_retailer")
    if (ws.connector === "puppet") main.classList.add("wv_puppet")
    if (ws.connector === "standalone") main.classList.add("wv_standalone")
  }, [root])

  useHash(route, fromui, hashing, family.get(), puppet, std)

  const stepindexUpdate = useCallback(
    v => {
      //logmine("Callback", "Header", "stepindexUpdate")
      STEPS.map((v, i) => {
        ref.current.classList.remove(`wvstep_${i}`)
      })
      if (v < 0) return
      ref.current.classList.add(`wvstep_${v}`)
    },
    [ref]
  )

  useEffect(() => mvSubscribe("Main", stepindex, stepindexUpdate, false), [stepindex, stepindexUpdate])

  useLayoutEffect(() => {
    //    alert(window.screen.availHeight)
  }, [])

  return (
    <WatchVariationsMain ref={ref} layout={layout.main} className='wvContents'>
      {children}
    </WatchVariationsMain>
  )
})

export const Wrapper = memo(({ children }) => {
  //logmine("Main", "MainWrapper")
  const { hashing, busy, route, mode } = useWatchVariations()
  const ref = useRef()

  const busyUpdate = useCallback(
    v => {
      if (!v) {
        hashing.set(false)
        cssApply(ref.current, VARIANTS.wrapper.visible)
      }
    },
    [hashing]
  )

  const hashingUpdate = useCallback(
    v => {
      if (v) {
        cssApply(ref.current, VARIANTS.wrapper.hidden)
      }
    },
    [ref]
  )

  const routeUpdate = useCallback(
    v => {
      if (mode !== "cover") return
      if (!v || v === KILL_ROUTE) {
        cssApply(ref.current, VARIANTS.wrapper.hidden)
      }
    },
    [mode, ref]
  )

  useEffect(() => mvSubscribe("Main", busy, busyUpdate, false), [busy, busyUpdate])
  useEffect(() => mvSubscribe("Main", hashing, hashingUpdate, false), [hashing, hashingUpdate])
  useEffect(() => mvSubscribe("Main", route, routeUpdate, false), [route, routeUpdate])

  return (
    <WatchVariationsWrapper ref={ref} className='wvWrapper'>
      {children}
    </WatchVariationsWrapper>
  )
})
