export function linearToRight(darker, lighter) {
  return `linear-gradient(to right, ${darker}, ${lighter})`
}

export function linearToBottom(lighter, darker) {
  return `linear-gradient(to bottom, ${lighter}, ${darker})`
}

export function linearToTop(lighter, darker) {
  return `linear-gradient(to top, ${lighter}, ${darker})`
}
