import styled from "@emotion/styled"
import { useConsole } from "contexts/Console"
import { useNavigation } from "contexts/Navigation"
import { useTranslations } from "contexts/I18N"
import { useLastLocation } from "react-router-last-location"
import { buttonRound, mask } from "css/button"
import { bold } from "css/text"

const Link = styled.a`
  --safe: env(safe-area-inset-bottom, 0px);
  left: 8vw;
  position: absolute;
  top: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: currentColor;
  user-select: none;
  ${bold}

  > span {
    pointer-events: none;
    ${buttonRound}
    background: rgb(var(--concrete));

    &::before {
      background: rgb(var(--raisin-black));
      content: "";

      ${mask(
        '<polygon points="21.2857143 29.7142857 5.71428571 14.8571429 21.2857143 1.29420284e-13 23.2857143 2 9.85714286 14.8571429 23.2857143 27.7142857"></polygon>'
      )}

      [dir="rtl"] & {
        transform: scaleX(-1);
      }
    }
  }
  > p {
    margin-inline-start: 0.5rem;

    @media screen and (max-width: 480px) {
      display: none;
    }
  }
`

export default function Back({ children }) {
  const console = useConsole()

  const navigation = useNavigation()
  const lastLocation = useLastLocation()
  const translations = useTranslations()

  const lastPath = lastLocation?.pathname

  console.verbose("Back(%o)", navigation, lastPath, {})
  return (
    <>
      {process.browser && navigation.slug !== "/" && navigation.app !== "embed" && navigation.canGoBack() ? (
        <Link
          href={lastPath}
          onClick={e => {
            e?.preventDefault?.()
            navigation.goBack()
          }}
          aria-label={translations["back"]}
          title={translations["back"]}
        >
          <span />
          {children && <p>{children}</p>}
        </Link>
      ) : (
        process.browser &&
        navigation.app === "embed" &&
        navigation.canGoBack() && (
          <Link
            href={lastPath}
            onClick={e => {
              e?.preventDefault?.()
              navigation.goBack()
            }}
            aria-label={translations["back"]}
            title={translations["back"]}
          >
            <span />
            {children && <p>{children}</p>}
          </Link>
        )
      )}
    </>
  )
}
