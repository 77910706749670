import { lazy } from "react"

export const AddToFavorites = lazy(() => import("components/v2/selection/AddToFavorites"))
export const AddToWishlist = lazy(() => import("components/v2/selection/AddToWishlist"))
export const AddToWishlistSTD = lazy(() => import("components/AddToWishlistSTD"))
export const Asset = lazy(() => import("components/library/asset/Asset"))
export const Account = lazy(() => import("components/v2/account/Account"))
export { default as Back } from "components/Back"
export const Ballerina = lazy(() => import("components/ballerina/Canvas"))
export const Banner = lazy(() => import("components/Banner"))
export const BannerStd = lazy(() => import("components/Banner"))
export const Billboard = lazy(() => import("components/billboard/Billboard"))
export const BillboardItem = lazy(() => import("components/billboard/BillboardItem"))
export const BottomNavigation = lazy(() => import("components/bottom-navigation/BottomNavigation"))
export const BroadCast = lazy(() => import("components/BroadCast"))
export const BroadCasting = lazy(() => import("components/BroadCasting"))
export const Card = lazy(() => import("components/card/Card"))
export const Configurator = lazy(() => import("components/configurator/Configurator"))
export const CollectionStd = lazy(() => import("components/collectionStd/CollectionStd"))
export const CollectionLinks = lazy(() => import("components/collectionStd/CollectionLinks"))
export const Cupboard = lazy(() => import("components/cupboard/Cupboard"))
export const CupboardPanel = lazy(() => import("components/cupboard/CupboardPanel"))
export const CupboardItem = lazy(() => import("components/cupboard/CupboardItem"))
export const Dealer = lazy(() => import("components/dealer/Dealer"))
export const Favorites = lazy(() => import("components/favorites/Favorites"))
export const Filler = lazy(() => import("components/Filler"))
export { default as Header } from "components/v2/header/Header"
export { default as Image } from "components/Image"
// export { default as ImageCLD } from "components/v2/ImageCLD"
export const Home = lazy(() => import("components/v2/standalone/home/Home"))
export const Navigation = lazy(() => import("components/v2/standalone/home/Navigation"))
export const NavigationItem = lazy(() => import("components/v2/standalone/home/NavigationItem"))
export const Library = lazy(() => import("components/library/Library"))
export const Link = lazy(() => import("components/Link"))
export const Majesty = lazy(() => import("components/majesty/Majesty"))
export const MajestyStd = lazy(() => import("components/majestyStd/MajestyStd"))
export const MajestyV2 = lazy(() => import("components/v2/majesty/Majesty"))
export const MajestyStdV2 = lazy(() => import("components/v2/standalone/Majesty"))
export const MajestyEmbed = lazy(() => import("components/v2/embed/Majesty"))
export const Modal = lazy(() => import("components/Modal"))

export const Pairing = lazy(() => import("components/v2/pairing/Pairing"))
export const PairingManager = lazy(() => import("components/v2/pairing/Manage"))
export const Pane = lazy(() => import("components/Pane"))
export const PaneV2 = lazy(() => import("components/v2/Pane"))
export { default as Picture } from "components/Picture"
export const PinchZoomPicture = lazy(() => import("components/pinch-zoom-picture/PinchZoomPicture"))
export const Plateau = lazy(() => import("components/wishlist/Plateau"))
export const Popover = lazy(() => import("components/Popover"))
export const ProspectManager = lazy(() => import("components/v2/prospect/Manage"))
export const Price = lazy(() => import("components/Price"))
export const Push = lazy(() => import("components/v2/Push"))
export const Remote = lazy(() => import("components/remote/Remote"))
export const RemoteItem = lazy(() => import("components/remote/RemoteItem"))
export const RemoteV2 = lazy(() => import("components/v2/remote/Remote"))
export const RemoteItemV2 = lazy(() => import("components/v2/remote/RemoteItem"))
export const RemoteRoller = lazy(() => import("components/v2/remote/RemoteRoller"))

export const RemoteStd = lazy(() => import("components/remoteStd/RemoteStd"))
export const RemoteItemStd = lazy(() => import("components/remoteStd/RemoteItemStd"))
export const QandA = lazy(() => import("components/v2/QandA"))
export const Roller = lazy(() => import("components/roller/Roller"))
export const Search = lazy(() => import("components/Search"))
export const SelectionSTD = lazy(() => import("components/SelectionSTD"))
export const Splash = lazy(() => import("components/Splash"))
export const Stats = lazy(() => import("components/Stats"))
// export const Takeaway = lazy(() => import("components/Takeaway"))
export const TakeawayV2 = lazy(() => import("components/v2/Takeaway"))
export { default as Video } from "components/Video"
export { default as VideoCLD } from "components/v2/VideoCLD"
export const Wait = lazy(() => import("components/wait/Wait"))
export const WatchCard = lazy(() => import("components/watch-card/WatchCard"))
export const WatchGrid = lazy(() => import("components/watch-grid/WatchGrid"))
export const Wishlist = lazy(() => import("components/wishlist/Wishlist"))
export const WishlistSTD = lazy(() => import("components/wishliststd/Wishlist"))
