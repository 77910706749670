import { CTACLASSNAME, ROUTE_REPLACE, ROUTE_IGNORE } from "./constants"

const logColors = {
  FgBlack: "\x1b[30m",
  FgRed: "\x1b[31m",
  FgGreen: "\x1b[32m",
  FgYellow: "\x1b[33m",
  FgBlue: "\x1b[34m",
  FgMagenta: "\x1b[35m",
  FgCyan: "\x1b[36m",
  FgWhite: "\x1b[37m",
}

const logColorsByHook = {
  Main: "red",
  Callback: "magenta",
  onChange: "grey",
  useEffect: "green",
  useLayoutEffect: "green",
  CustomHook: "blue",
  Listener: "gold",
  Func: "pink",
}

const filterChar = "WatchVariations"

export const logmine = (color, ...args) => {
  if (!process.browser) return null
  const colortype = logColorsByHook[color]
  const colorcode = colortype || color
  const display = ["Collection"]
  const hide = []
  //  console.log.apply(null, [colorcode, [filterChar, ...args]])
  let ready = display.reduce((a, item) => {
    return a || !!~args.indexOf(item) || color === item
  }, !display.length)
  ready = hide.reduce((a, item) => {
    return a && color !== item
  }, ready)
  if (ready) {
    console.log.apply(null, [["%c", filterChar, ...args].join(" "), `background: ${colorcode}; color: white;`])
    const last = args.pop()
    if (typeof last === "object") console.log(last)
  }
}

export const cssApply = (el, props, straight) => {
  if (!el || !props) return
  props.map(line => {
    const prop = line[0]
    el.style[prop] = straight && prop === "transition" ? "none" : line[1]
  })
}

export const upperFirstLetter = s => {
  return !!s && [s.charAt(0).toUpperCase(), s.slice(1)].join("")
}

export const stampCta = ref => {
  const { current: root } = ref
  if (!root) return
  const el = root.querySelector("button,a")
  if (!el) return
  el.classList.add(CTACLASSNAME)
}

export const focusCta = ref => {
  const { current: root } = ref
  if (!root) return
  const el = root.querySelector(`.${CTACLASSNAME}`)
  if (!el) return
  el.focus()
}

const objectify = (...args) => {
  const string = args.length > 0 && args[0] !== undefined ? args[0] : ""
  const object = {}
  const del = "="
  const sep = "&"
  void (string.search(sep) !== -1 ? string.split(sep) : string.length ? [string] : []).forEach(pair => {
    //pair = unescape( pair.replace(/\+/g, "%20") )
    const idx = pair.indexOf(del)
    const key = pair.split(del, 1)[0]
    const value = pair.slice(idx + 1)
    object[key.trim()] = idx !== -1 ? value : true
  })

  return object
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread()
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance")
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter)
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i]
    }
    return arr2
  }
}

const serialize = (...args) => {
  const object = args.length > 0 && args[0] !== undefined ? args[0] : {}
  const del = "="
  const sep = "&"
  return _toConsumableArray(
    Object.keys(object).map(key => {
      return "".concat(key).concat(del).concat(object[key])
    })
  )
    .join(sep)
    .replace(/%20/g, "+")
}

export const hashIt = (props, replace) => {
  if (!process.browser) return
  let s = ""
  const _hash = decodeURI(window.location.hash.split("").slice(1).join(""))
  if (!props) {
    if (!_hash.length) return
    window.history[!!replace ? "replaceState" : "pushState"]({}, "", window.location.href.split("#")[0])
    //    console.log(">>> wv hashIt 1", props, replace)
    return
  }
  if (typeof props === "string") s = props
  else {
    const hash = objectify(_hash) || {}

    Object.entries(props).forEach(([key, value]) => {
      if (value !== null) hash[key] = value
      else delete hash[key]
    })

    s = serialize(hash)
  }
  if (_hash !== s) {
    //    console.log(">>> wv hashIt 2", props, replace)
    window.history[!!replace ? "replaceState" : "pushState"]({}, "", ["#", s].join(""))
    const event = new Event("urlchange")
    Object.assign(event, { url: window.location.href.slice(window.location.origin.length) })
    window.dispatchEvent(event)
    //    console.log(">>> wv hashIt 3 history", ["#", s].join(""), replace)
  }
}

export const cleanRoute = v => {
  if (!v) return v
  let _route = v
  let dirty = _route.indexOf(ROUTE_REPLACE) === 0 || _route.indexOf(ROUTE_IGNORE) === 0
  if (dirty) _route = _route.split("").slice(1).join("")
  return _route
}
