import { useConsole } from "contexts/Console"
import { Provider } from "contexts/WA"
import { useLayoutEffect, useEffect } from "react"
import { useLocale } from "contexts/Locale"
import useSSR from "utils/useSSR"
import hash from "@emotion/hash"

const fireViewEndCustomEvent = !process.browser
  ? () => {}
  : () => {
      const event = new CustomEvent("event-view-end")
      global.dispatchEvent(event)
    }
const fireViewStartCustomEvent = !process.browser
  ? () => {}
  : data => {
      var event = new CustomEvent("event-react-view-start", data)
      global.dispatchEvent(event)
    }

async function loadScript(url) {
  if (!url) return
  const scriptId = "wa" + hash(url)

  const node = (function () {
    if (document.getElementById(scriptId)) return scriptId
    return document.head.appendChild(Object.assign(document.createElement("script"), { src: url, defer: true, async: true }))
  })()

  return (global[scriptId] ??= new Promise((resolve, reject) => {
    node.addEventListener("load", resolve, { once: true })
    node.addEventListener("error", reject, { once: true })
  }).catch(err => {}))
}

export default function WA({ children }) {
  const ssr = useSSR()

  const console = useConsole()
  const { locale: currLocale } = useLocale()

  const launchUrl = ssr
    ? null
    : process.env.NODE_ENV === "production" && global.location.origin.includes("rolex.com")
    ? "https://assets.adobedtm.com/7e3b3fa0902e/6944e657435b/launch-8ca85094a007.min.js"
    : "https://assets.adobedtm.com/7e3b3fa0902e/6944e657435b/launch-04e4ac8909a5-development.min.js"

  if (process.browser) {
    const origin = global.location.host

    global.digitalDataLayer = {
      environment: {
        environmentName:
          process.env.NODE_ENV === "development"
            ? "dev"
            : origin.endsWith(".rolex.com")
            ? "production"
            : origin.endsWith(".rlx-prod.com")
            ? "qa"
            : origin.endsWith(".rlx-uat.com")
            ? "uat"
            : "staging",
        environmentVersion: "", // version of the web app //TODO
      },
      location: { languageCode: currLocale },
      userInfo: {
        rswi: "rswi_2507", // ID of the retailer; dynamic value //TODO OKTA
        ruid: "000001", // ID of the session; dynamic value //TODO OKTA
      },
    }
  }

  const ctx = {
    dispatch: !process.browser
      ? () => {}
      : async (data = {}) =>
          loadScript(launchUrl).then(() => {
            const event = new CustomEvent("event-action-trigger", data)
            document.querySelector("body").dispatchEvent(event)
            console.verbose("wa - data event-action-trigger", data)
          }),
    fireViewStart: ({ detail = "" } = {}) => fireViewStartCustomEvent({ detail }),
    fireViewEnd: () => fireViewEndCustomEvent(),
  }

  // useLayoutEffect(() => {
  //   if (ssr) return
  //   const script = document.getElementById("launch")
  //   const onload = () => {
  //     // ctx.fireViewStart()
  //     // ctx.fireView()
  //     // alert("load")
  //   }
  //   script.addEventListener("load", onload)
  //   return () => {
  //     script.removeEventListener("load", onload)
  //   }
  // }, [ssr])

  if (process.browser) loadScript(launchUrl).then(() => ctx.fireViewStart())

  useEffect(() => {
    let late = false
    let timer
    loadScript(launchUrl).then(() => {
      if (!late) {
        timer = setTimeout(() => ctx.fireViewEnd(), 300)
      }
    })
    return () => {
      late = true
      clearTimeout(timer)
    }
  })

  console.verbose("WA(%o)", { ctx })
  return (
    <>
      {/* {!ssr && (
        <Helmet>
          <script id='launch' src={launchUrl} async />
        </Helmet>
      )} */}
      <Provider value={ctx}>{children}</Provider>
    </>
  )
}
