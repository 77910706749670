import { Provider } from "contexts/Akamai"
import { useConsole } from "contexts/Console"
import camelCase from "utils/camelCase"

export default function Akamai({ children, reqcontext = {}, location }) {
  const console = useConsole()
  const cc = new URL(location || global.location, "https://digitalcatalog.rolex.com").searchParams.get("cc")
  const ctx = Object.keys(reqcontext).reduce((ctx, key) => {
    const cckey = camelCase(key)
    try {
      ctx[cckey] = JSON.parse(reqcontext[key])
    } catch {
      ctx[cckey] = reqcontext[key]
    }
    return ctx
  }, {})

  if (cc) ctx.countryCode = cc

  console.verbose("Akamai(%o)", ctx)
  return <Provider value={ctx}>{children}</Provider>
}
