import { useState, startTransition, useLayoutEffect } from "react"

export default function useSSR() {
  const [SSR, setSSR] = useState(true)

  useLayoutEffect(() => {
    if (SSR) startTransition(() => setSSR(false))
  })

  return SSR
}
