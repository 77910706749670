export const STEPS = ["family", "model", "size", "material", "bezel", "bracelet", "dial"]
export const UNIVERSES = ["classic", "professional", "man", "woman", "gold"]

export const IN_DURATION = 600
export const OUT_DURATION = 200
export const INOUT_DURATION = [IN_DURATION, OUT_DURATION]
export const IN_EASE = "1,1,1,1" //".32,.94,.6,1"
export const OUT_EASE = "1,1,1,1" //".22,1,.36,1"
export const INOUT_EASE = [IN_EASE, OUT_EASE]
export const TRANSIT_DELAY = OUT_DURATION * 1.1
export const FADE_DURATION = 400
export const FADE_EASE = "1,1,1,1" //".32,.94,.6,1"
export const UPDATE_DELAY = 200
export const UPDATE_LAG = 140

export const TRACKING_DELAY = 200

export const LAUNCH_ROUTE = "/"
export const KILL_ROUTE = "_"
export const LAUNCH_DURATION = 600
export const KILL_DURATION = 300
export const KILL_LAG = 0
export const LAUNCH_EASE = ".22,1,.36,1" //".32,.94,.6,1"
export const KILL_EASE = "1,1,1,1" //".32,.94,.6,1"
export const FOCUS_DURATION = 1000
export const FOCUS_EASE = ".32,.94,.6,1"

export const MAIN_CONTROL_ID = "Configurator-main"

export const OFFSET_HEIGHT = 60
export const OFFSET_TIGHT = 10

export const ROUTE_REPLACE = "_"
export const ROUTE_IGNORE = "~"

export const CTACLASSNAME = "wv_cta"
export const EMPTYSTRING = "\u00A0"
export const EMPTY_STRING = "\u00A0"

// Firefox Mac IMG.complete bad value
export const ALWAYSCOMPLETE = process.browser && window.navigator.userAgent.toUpperCase().indexOf("MAC") >= 0 && !!window.mozInnerScreenX
