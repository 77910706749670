import { useState, useLayoutEffect, useRef, createContext, useContext, useImperativeHandle, forwardRef } from "react"
import { motion } from "framer-motion"
import styled from "@emotion/styled"
import { buttonRound, mask } from "css/button"
import { noScrollbar } from "css/helper"
import useT from "utils/useT"
import * as pop from "popmotion"
import { useWebSocket } from "contexts/WebSocket"

const Context = createContext()
export function useZoomIn() {
  return useContext(Context)
}

const Root = styled(motion.div)`
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  width: 100%;
  height: var(--vh100);

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--z-modal);
  background: rgb(var(--background));
  overflow: auto;

  & > ul {
    ${noScrollbar}

    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    scroll-snap-type: inline mandatory;
    @media screen and (max-width: 480px) {
      column-gap: 2vw;
    }

    & > li {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      list-style: none;
      scroll-snap-align: center;

      & > img {
        width: 100vw;
        height: 100%;
        object-fit: cover;

        @media screen and (max-width: 480px) {
          width: 100vw;
          height: fit-content;
          object-fit: cover;
        }
      }
    }
  }

  & > div {
    position: fixed;
    right: 8vw;
    top: 1.5rem;
    z-index: 10;
    padding-block-start: env(safe-area-inset-top, 0px);

    & > button {
      ${buttonRound}
      background-color: rgb(var(--background)/0.2);
      pointer-events: auto;
      ::before {
        background: rgb(var(--highlight));
        ${mask('<path d="m17 15 13 13-2 2-13-13-13 13-2-2 13-13-13-13 2-2 13 13 13-13 2 2z" />')}
      }
    }
  }
`

const container = {
  hidden: {
    opacity: 0,
    pointerEvents: "none",
    // x: "100%",
    // visibility: "hidden",
    transition: {
      type: "spring",
      ease: "easeInOut",
      bounce: 0,
      duration: 0.8,
    },
  },
  visible: {
    opacity: 1,
    pointerEvents: "auto",
    // x: 0,
    // visibility: "visible",
    transition: {
      type: "spring",
      ease: "easeInOut",
      bounce: 0,
      duration: 0.8,
    },
  },
}

const close = {
  hidden: {
    opacity: 0,
    scale: 0,
    transition: {
      type: "spring",
      ease: "easeInOut",
      duration: 0.8,
    },
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      type: "spring",
      ease: "easeInOut",
      duration: 0.8,
    },
  },
}

function ZoomIn({ children, className, beautyShots, active }, handle) {
  const [zoom, setZoom] = useState(false)
  const rref = useRef()
  const scroll = useT({ t: 0, duration: 0, ease: pop.easeInOut })
  const ws = useWebSocket()

  const ctx = {
    in: () => {
      setZoom(true)
    },
    out: () => {
      setZoom(false)
    },
    scrollTo(idx, immediate = false) {
      const track = rref.current
      const { clientWidth, scrollWidth } = track
      const length = scrollWidth - clientWidth
      const at = track.querySelector(`li:nth-child(${idx + 1})`).offsetLeft / length
      if (immediate) scroll.set(at)
      else scroll.t(at)
    },
    zoom,
  }

  useImperativeHandle(handle, () => ctx)

  useLayoutEffect(() => {
    if (!active) setZoom(false)
  }, [active])

  useLayoutEffect(() =>
    scroll.onChange(t => {
      const track = rref.current
      const { clientWidth, scrollWidth } = track
      const length = scrollWidth - clientWidth
      const scrollLeft = length * t
      track.scrollLeft = scrollLeft
    })
  )

  return (
    <Root className='zoomin' variants={container} initial='hidden' animate={zoom ? "visible" : "hidden"}>
      <motion.div variants={close} initial='hidden' animate={zoom ? "visible" : "hidden"}>
        <button onClick={() => ctx.out?.()} />
      </motion.div>
      <motion.ul ref={rref}>
        <Context.Provider value={ctx}>
          {beautyShots &&
            beautyShots?.map((url, idx) => {
              return (
                <motion.li index={idx} variants={children} initial='hidden' animate={zoom ? "visible" : "hidden"} key={"zoomin" + idx}>
                  <img src={url} alt='' />
                </motion.li>
              )
            })}
        </Context.Provider>
      </motion.ul>
    </Root>
  )
}

export default forwardRef(ZoomIn)
