import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { getMediaQuery } from "./../../../css/breakpoints"
import { rolex } from "css/text"
import { gradient, big, smallMedium, mediumLighter, largeLPnone } from "css/heading-new"
import { headline30, legend100, body50, bold, light, body70 } from "css/text"
import { noScrollbar } from "css/helper"
import { wviPhoneP, wviPhoneP6s, wviPhonePS, wviPadP, wviPadL } from "./../../../css/breakpoints"

export const Main = styled.div`
  position: absolute;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 0;
  top: calc(var(--wv-collection-top) + var(--wv-safe));
  left: 0;

  ${wviPadL(css`
    --wv-collection-top: 16vh;
    --wv-collection-roller-img-width: 22vw;
    --wv-collection-roller-img-max-width: -1px;
    --wv-collection-roller-img-ratio: 7/5;
  `)}
  ${wviPadP(css`
    --wv-collection-top: 16vh;
    --wv-collection-roller-img-width: 40vw;
    --wv-collection-roller-img-max-width: -1px;
    --wv-collection-roller-img-ratio: 7/5;
  `)}
  ${wviPhoneP(css`
    --wv-collection-top: 14vh;
    --wv-collection-roller-img-width: 56vw;
    --wv-collection-roller-img-max-width: -1px;
    --wv-collection-roller-img-ratio: 7/5;
  `)}
  ${wviPhoneP6s(css`
    --wv-collection-top: 17vh;
    --wv-collection-roller-img-width: 50vw;
    --wv-collection-roller-img-max-width: -1px;
    --wv-collection-roller-img-ratio: 7/5;
  `)}
  ${wviPhonePS(css`
    --wv-collection-top: 20vh;
    --wv-collection-roller-img-width: 47vw;
    --wv-collection-roller-img-max-width: -1px;
    --wv-collection-roller-img-ratio: 7/5;
  `)}

  ._wvdb & {
    background: rgba(255, 64, 127, 0.9);
  }
`

export const Footer = styled.figcaption`
  position: relative;
  ${gradient("rgb(var(--on-background) / 1)", "rgb(var(--on-background) / 0.6)")}
  font-weight: 700;
  padding: 6px 0 4px;
`

export const List = styled.section`
  & h2 {
    ${headline30}
    padding: 0 0 0 5vw;
  }
  & ul {
    ${noScrollbar}
    display: flex;
    //  height: fit-content;
    //    grid-gap: 0.4rem;

    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    //  scroll-padding: 0 25vw;
    scroll-behavior: smooth;
    //  scroll-padding: 0 calc(50vw / 2);
    //  padding-inline: calc(50vw - 16vw / 2);
    //  padding-block: 3vh;
    justify-items: center;

    .wv_puppet & {
      //      scroll-snap-type: x proximity;
      //      scroll-behavior: auto;
    }

    ${wviPadL(css`
      padding-block-start: 0.5rem;
    `)}
    ${wviPadP(css`
      padding-block-start: 1rem;
    `)}
  ${wviPhoneP(css`
      padding-block-start: 0.5rem;
    `)}
  ${wviPhonePS(css`
      padding-block-start: 0.5rem;
    `)}

    & li {
      scroll-snap-align: start;
      padding-left: 0.4rem;
      &:first-of-type {
        padding-left: 5vw;
      }
      &:last-of-type {
        padding-right: 5vw;
      }

      @media (hover: hover) and (pointer: fine) {
        cursor: pointer;
      }
    }
  }

  ${wviPadL(css`
    padding-block-start: 50px;
  `)}
  ${wviPadP(css`
    padding-block-start: 80px;
  `)}
  ${wviPhoneP(css`
    padding-block-start: 60px;
  `)}
  ${wviPhoneP6s(css`
    padding-block-start: 40px;
  `)}
  ${wviPhonePS(css`
    padding-block-start: 30px;
  `)}
`
