import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { wviPhoneP, wviPhoneP6s, wviPhonePS, wviPadP, wviPadL, wviPadXP } from "./../../css/breakpoints"

export const cfg_keen = css`
  perspective: 1px;
  transform-style: preserve-3d;
  backface-visibility: hidden;
`

export const cfg_center = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const cfg_hcenter = css`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
`

export const cfg_vcenter = css`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
`

export const cfg_column = css`
  width: 90%;
  max-width: 650px;
  left: 50%;
  transform: translateX(-50%);
  [dir="rtl"] & {
    left: auto;
    right: 50%;
    transform: translateX(50%);
  }
`
/*
    @media ${getMediaQuery("l", "min")} {
    width: 50%;
  }

  */
const basics = css`
  --wv-safe: env(safe-area-inset-top);
  //  --wv-safe: 50px;
  --wv-header-top: 10vh;
  --wv-header-height: 60px;
  --wv-watch-top: 5vh;
  --wv-watch-height: min(120vmin, 50vh);
  --wv-watch-bottom: 10px;
  --wv-watch-icon: 0px;
  --wv-vars-height: 90px;
  --wv-vars-bottom: 0px;
  --wv-nav-height: 140px;
  --wv-nav-bottom: 0px;
  --wv-share-height: 0px;
  --wv-bottom-HY: 40px;
  &.wv_retailer {
    --wv-bottom-HY: 100px;
  }
  ${wviPadL(css`
    --wv-nav-height: 140px;
    --wv-watch-height: 62vh;
    --wv-watch-top: 0vh;
    --wv-watch-bottom: 0vh;
    --wv-vars-height: 90px;
    --wv-bottom-HY: 40px;
    &.wv_retailer {
      --wv-watch-height: 56vh;
      --wv-bottom-HY: 100px;
    }
    &.wv_puppet {
      --wv-vars-height: 40px;
    }
  `)}

  ${wviPadP(css`
    --wv-nav-height: 200px;
    --wv-watch-height: 57vh;
    --wv-watch-top: 0vh;
    --wv-bottom-HY: 40px;
    &.wv_retailer {
      --wv-nav-height: 240px;
      --wv-bottom-HY: 100px;
    }
  `)}
  ${wviPadXP(css`
    --wv-watch-height: 44vh;
  `)}
  ${wviPhoneP(css`
    --wv-watch-top: 6vh;
    --wv-watch-height: 54vh;
    --wv-nav-height: 180px;
    --wv-bottom-HY: 40px;

    .embed & {
      --wv-watch-top: 0vh;
    }
    &.wv_retailer {
      --wv-watch-height: 50vh;
      --wv-watch-top: var(--wv-safe);
      --wv-watch-bottom: 0px;
      --wv-vars-height: 80px;
      --wv-nav-height: 200px;
      --wv-bottom-HY: 80px;
    }
  `)}
  ${wviPhoneP6s(css`
    --wv-bottom-HY: 40px;
    &.wv_retailer {
      --wv-bottom-HY: 70px;
      --wv-watch-top: calc(var(--wv-safe) + 10px);
    }
    &.wv_standalone {
      --wv-bottom-HY: 10px;
    }
  `)}
  ${wviPhonePS(css`
    --wv-bottom-HY: 40px;
    --wv-watch-top: 6vh;
    --wv-watch-height: 54vh;
    --wv-vars-height: 60px;
    --wv-nav-height: 140px;

    .embed & {
      --wv-vars-height: 80px;
      --wv-watch-top: 0vh;
    }
    &.wv_retailer {
      --wv-vars-height: 70px;
      --wv-watch-height: 48vh;
      --wv-bottom-HY: 60px;
    }
    &.wv_standalone {
      --wv-vars-height: 70px;
      --wv-bottom-HY: 10px;
    }
  `)}
`

const computed = css`
  --wv-vars-top: calc(var(--wv-watch-top) + var(--wv-watch-height) + var(--wv-watch-bottom));
  --wv-nav-top: calc(var(--wv-vars-top) + var(--wv-vars-height));
  --wv-share-top: calc(var(--wv-nav-top) + var(--wv-nav-height));
  --wv-wish-top: calc(var(--wv-watch-top) + var(--wv-watch-height) / 2);
  --wv-min-height: calc(var(--wv-share-top) + var(--wv-share-height));
`

export const basicsXglobal = css`
  ${basics}
  ${computed}
  --wv-height: 100dvh;
`

export const WatchVariationsMain = styled.section`
  ${basicsXglobal}
  &.wvstep_0 {
    ${wviPadL(css`
      --wv-header-top: max(9vh, 5.5rem);
    `)}
    ${wviPadP(css`
      --wv-header-top: max(9vh, 5.5rem);
    `)}
    ${wviPhoneP(css`
      --wv-header-top: max(11vh, 5.5rem);
    `)}
  }
  min-height: var(--wv-height);
  ${({ layout }) => layout}
  --wv-watch-ratio: calc(680/980);
  position: relative;
  height: var(--wv-height);
  margin: 0;
  ////  overflow: hidden;
  //  pointer-events: auto;

  -moz-user-select: none;
  -ms-touch-action: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-drag: none;
  grid-row: marginTop/span 3;
  * {
    -webkit-tap-highlight-color: transparent;
  }
  &._wvdb {
    background-color: rgba(255, 127, 0, 0.8);
  }
  &.wv_puppet {
    pointer-events: none;

    & * {
      pointer-events: none !important;
    }
  }
`

export const WatchVariationsWrapper = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  ////  top: 50%;
  ////  transform: translate3d(0, -50%, 0);
  //  height: var(--wv-height);
  height: var(--wv-min-height);

  ._wvdb & {
    ${wviPadXP(css`
      background-color: rgba(0, 0, 0, 0.8);
    `)}
    ${wviPadL(css`
      background-color: rgba(255, 255, 0, 0.8);
    `)}
    ${wviPadP(css`
      background-color: rgba(255, 0, 255, 0.8);
    `)}
    ${wviPhoneP(css`
      background-color: rgba(0, 255, 0, 1);
    `)}
  ${wviPhoneP6s(css`
      background-color: rgba(0, 0, 255, 1);
    `)}
  ${wviPhonePS(css`
      background-color: rgba(255, 0, 0, 1);
    `)}
  }
`

export const Share = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: var(--wv-min-height);
`
