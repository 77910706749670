import { useLayoutEffect, useState, useRef, startTransition } from "react"
import { useConsole } from "contexts/Console"
import { useWebSocket, readyStates as wsStates } from "contexts/WebSocket"
import { useHeader } from "contexts/Header"
import { Provider, useWishlist } from "contexts/SelectionSTD"
import useConstant from "utils/useConstant"
import { useNavigation } from "contexts/Navigation"
import { useTranslations } from "contexts/I18N"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { buttonRound, mask } from "css/button"
import { label } from "css/heading"
import { bold } from "css/text"

/* Todo: DRY */
const tool = css`
  &::before {
    opacity: 1 !important;
    z-index: 1;
    content: "";
  }

  :is(.active)::before {
    background: linear-gradient(to right, rgb(var(--green)), rgb(var(--sea-green)));
  }
`

const ToolWishlist = styled.a`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: currentColor;
  ${bold}

  ${({ isHome }) =>
    !isHome
      ? `
  > span {
    &::before {
      background:rgb(var(--highlight)) !important;
    }
  }
  > p {color:rgb(var(--highlight)) ;}
  `
      : `
  > span {
    &::before {
      background:rgb(var(--on-background)) !important;
    }
  }
  > p {color:rgb(var(--on-background));}
  `}

  > span {
    position: relative;
    ${buttonRound}
    ${tool}
    pointer-events: none;
    &::before {
      ${mask(
        '<path d="M29.9441238,11.3392971 C29.4728324,16.5668103 23.5888307,23.4889569 14.991333,28.7164701 C6.39383539,23.4889569 0.509833671,16.5668103 0.0385422716,11.3392971 C-0.432749128,6.11178382 3.48039765,1.43 8.36469033,1.43 C13.248983,1.43 14.991333,4.99160242 14.991333,4.99160242 C14.991333,4.99160242 16.7479646,1.43 21.6179758,1.43 C26.4879869,1.43 30.4154152,6.11178382 29.9441238,11.3392971 Z" />'
      )}
    }
  }
  > p {
    pointer-events: none;
    margin-inline-start: 0.5rem;
    @media screen and (max-width: 480px) {
      display: none;
    }
  }
`
const Counter = styled.div`
  display: flex;
  z-index: 1;
  width: 0.2rem;
  height: 0.2rem;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
  visibility: var(--v);
  position: absolute;
  top: -0.5vw;
  right: 5.5rem;
  padding: 0.5rem;
  font-size: 0.6em;
  opacity: var(--o);
  transition: opacity 400ms ease;
  will-change: opacity, visibility;
  color: rgb(var(--interface));
  background: rgb(var(--sea-green));
  font-weight: 600;
  border-radius: 9999px;
  pointer-events: none;

  @media screen and (max-width: 480px) {
    top: -1.2vw;
    right: -1.2vw;
    font-size: 0.5rem;
  }
`
const Clip = styled.div`
  z-index: 0;
  --safe: env(safe-area-inset-top, 0px);
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 6.1rem;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: space-around;
  width: clamp(2.5rem, calc(var(--t) * 22vh), 22vh);
  height: clamp(2.5rem, calc(var(--t) * 20vh), 20vh);
  transition: width 500ms, height 500ms;
  will-change: width, height;

  background: ${({ isHome }) => (!isHome ? `rgb(var(--background)/0.1););` : `rgb(var(--concrete));`)};

  @media screen and (max-width: 480px) {
    width: clamp(2.5rem, calc(var(--t) * 15vh), 15vh);
    height: clamp(2.5rem, calc(var(--t) * 15vh), 15vh);
    left: 0;
  }
  @media (max-aspect-ratio: 1/1) {
    width: clamp(2.5rem, calc(var(--t) * 18vh), 18vh);
    height: clamp(2.5rem, calc(var(--t) * 16vh), 16vh);
  }

  > div {
    display: flex;
    flex-direction: column;
    padding: 1.2rem;
    opacity: var(--t);
    transition: /*transform 550ms calc(var(--d) / 2),*/ opacity var(--d) calc(var(--delay) / 2.5);
    will-change: /*transform,*/ opacity;
    color: rgb(var(--sea-green));

    & > span {
      opacity: var(--t) !important;
      ${label}
      font-weight: 400;

      & > span {
        opacity: var(--t) !important;
        font-weight: 600;
      }
    }
  }
`

export function Wishlist() {
  const console = useConsole()
  const ws = useWebSocket()
  const navigation = useNavigation()
  const translations = useTranslations()
  const wishlist = useWishlist()
  const [[count, countFrom], setCount] = useState([wishlist?.list?.length ?? 0, wishlist?.list?.length ?? 0])
  const [warn, setWarn] = useState(false)

  useLayoutEffect(() => {
    let timer
    const onupdate = e => {
      startTransition(() => {
        setCount([e.list.length, wishlist.update < 1 ? e.list.length : count])
      })
    }
    const onwarn = e =>
      startTransition(() => {
        setWarn(true)
      })

    wishlist.addEventListener("wishlistupdate", onupdate)
    wishlist.addEventListener("warn", onwarn)
    return () => {
      clearTimeout(timer)
      wishlist.removeEventListener("wishlistupdate", onupdate)
      wishlist.removeEventListener("warn", onwarn)
    }
  })

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      startTransition(() => {
        if (wishlist.update >= 1) setCount([count, count])
        if (warn) setWarn(false)
      })
    }, 4500)
    return () => clearTimeout(timer)
  })

  const t = +(count > countFrom || warn)
  console.verbose("SelectionSTD:Wishlist(%o)", { readyState: ws.readyState, count, countFrom, t: +(count > countFrom) })
  return (
    ws.readyState === wsStates.TOKENIZED &&
    (navigation.slug !== "/wishlist" ? (
      <>
        <ToolWishlist
          aria-label={translations["wishlist"]}
          className={count > 0 ? "active wishlist" : "wishlist"}
          title={translations["wishlist"]}
          href={navigation.localize("/wishlist").url}
          isHome={navigation.slug !== "/"}
          onClick={e => {
            e.preventDefault()
            navigation.push(e.target.getAttribute("href"))
          }}
        >
          <span>
            <Counter style={{ "--o": +count ? "1" : "0", "--v": !count ? "hidden" : "visible" }}>{count}</Counter>
            <Clip
              isHome={navigation.slug !== "/"}
              style={{
                "--t": t,
              }}
            >
              <div
                style={{
                  "--t": t,
                  "--d": t + "s",
                  "--delay": t + "s",
                }}
              >
                <span dangerouslySetInnerHTML={{ __html: warn ? translations["wishlist-warn-full"] : translations["wishlist-add-watches"] }} />
              </div>
            </Clip>
          </span>
          <p>{translations["my-watches"]}</p>
        </ToolWishlist>
      </>
    ) : (
      <ToolWishlist isHome={navigation.slug !== "/"} as='span' className={count > 0 ? "active wishlist" : "wishlist"}>
        <span>
          <Counter style={{ "--o": +count ? "1" : "0", "--v": !count ? "hidden" : "visible" }}>{count}</Counter>
        </span>
        <p>{translations["my-watches"]}</p>
      </ToolWishlist>
    ))
  )
}

function Selection({ children, id }) {
  const console = useConsole()
  const ws = useWebSocket()
  const header = useHeader()
  const navigation = useNavigation()

  const et = useConstant(() => new EventTarget())
  const addEventListener = (...args) => et.addEventListener(...args)
  const removeEventListener = (...args) => et.removeEventListener(...args)
  const lists = useConstant(() => ({ wishlist: null, shown: null, wishlistFull: false, update: -1 }))

  const [ts, setTS] = useState(0)

  useLayoutEffect(() => {
    const onreadystatechange = e => setTS(Date.now())
    ws.addEventListener("readystatechange", onreadystatechange)
    return () => ws.removeEventListener("readystatechage", onreadystatechange)
  })

  const ctx = {
    addEventListener,
    removeEventListener,
    wishlist: {
      addEventListener,
      removeEventListener,
      get list() {
        return lists.wishlist && [...lists.wishlist]
      },
      get full() {
        return lists.wishlistFull
      },
      get update() {
        return lists.update
      },
      toggle: async reqRmc =>
        new Promise((resolve, reject) => {
          const event = `wishlist:toggle`
          const timer = setTimeout(() => {
            reject("timeout")
            ws.removeEventListener(event, ontoggle)
          }, 1000)
          const ontoggle = ({ rmc: resRmc, active, full, warn }) => {
            if (reqRmc !== resRmc) return

            lists.wishlistFull = full
            if (warn) {
              const event = new Event("warn")
              et.dispatchEvent(event)
            }

            clearTimeout(timer)
            ws.removeEventListener(event, ontoggle)
            resolve(active)
          }
          ws.addEventListener(event, ontoggle)
          ws.send(event, { rmc: reqRmc })
        }),
      empty: async () =>
        new Promise(resolve => {
          const onupdate = () => resolve()
          ws.addEventListener("wishlist:empty", onupdate, { once: true })
          ws.send(`wishlist:empty`)
        }),
    },
  }

  useLayoutEffect(() => {
    let timer
    const event = `wishlist:list`
    const onlist = ({ list, full }) => {
      lists.update += 1
      lists.wishlistFull = full
      if (!Array.isArray(list)) lists.wishlist = null
      else lists.wishlist = list
      const event = new Event("wishlistupdate")
      Object.assign(event, { list: [...lists.wishlist] })
      et.dispatchEvent(event)
    }

    ws.addEventListener(event, onlist)
    if (ws.readyState === wsStates.TOKENIZED) {
      if (!lists.wishlist) ws.send(event)
      // else
      //   setTimeout(() => {
      //     const event = new Event("wishlistupdate")
      //     Object.assign(event, { list: [...lists.wishlist] })
      //     et.dispatchEvent(event)
      //   }, 4)
    } else lists.wishlist = null
    return () => {
      clearTimeout(timer)
      ws.removeEventListener(event, onlist)
    }
  })

  console.verbose("SelectionSTD(%o)", { lists, ready: process.browser && ws.readyState === wsStates.TOKENIZED })
  return (
    <Provider value={ctx}>
      {/* {ws.connector === "standalone" && navigation.slug !== "/" && createPortal(<Wishlist />, header.selection)} */}
      {children}
    </Provider>
  )
}

export default function SelectionBootstrap({ children, id }) {
  return <Selection id={id}>{children}</Selection>
}
