import styled from "@emotion/styled"
import Selection from "components/SelectionSTD"
import { Back } from "components/components"
import { useTranslations } from "contexts/I18N"

const Root = styled.header()

const Top = styled.div`
  --safe: env(safe-area-inset-top, 0px);
  margin-block-start: var(--safe);
  left: 0;
  top: 0;
  right: 0;
  position: absolute;
  z-index: var(--z-ui);

  svg {
    height: 30px;
    width: auto;
    position: absolute;
    top: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
  }
`

export default function Header({ children }) {
  const translations = useTranslations()

  return (
    <>
      <Selection>
        <Root>
          <Top>
            <Back>{translations["back"]}</Back>
          </Top>
        </Root>
      </Selection>
      {children}
    </>
  )
}
