import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { getMediaQuery } from "./../../../css/breakpoints"
import { helvetica } from "css/text"
import { buttonRound, button } from "css/button"
import { gradient, big, label, smallLabel } from "css/heading"
import { wviPhoneP, wviPadP, wviPadL } from "./../../../css/breakpoints"

export const Main = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100%;
  height: auto;
  //  overflow: hidden;
  top: var(--wv-watch-top);

  .wv_puppet & {
    ${wviPhoneP(css`
      top: 55%;
      transform: translateY(-50%);
    `)}
  }

  opacity: 0;

  .wvstep_1 &,
  .wvstep_2 & {
    & figure {
      img {
        height: calc(var(--wv-watch-height) * var(--ratio));
        &:nth-of-type(2) {
          position: absolute;
          top: 0;
          height: calc(var(--wv-watch-height));
        }
      }
    }
  }

  img {
    --ratio: 1380/1180;
    position: relative;
    width: auto;
    object-fit: cover;
    * & {
      left: 50%;
      transform: translate(-50%, 0);
    }
    [dir="rtl"] & {
      right: 50%;
      transform: translate(50%, 0);
    }
    height: var(--wv-watch-height);
  }

  ul li {
    overflow: hidden;
  }
  ._wvdb & {
    background: rgba(255, 255, 0, 0.9);

    li {
      background: rgba(127, 255, 127, 0.9);
    }
  }
`

export const Figure = styled.figure`
  margin: 0;
  padding: 0;
  position: relative;

  @media (hover: hover) and (pointer: fine) {
    &[data-focused="false"]&:hover {
      cursor: pointer;
      figcaption {
        opacity: 1;
        transition: opacity 1s cubic-bezier(0.23, 1, 0.32, 1) 0.3s;
      }
    }
    &[data-focused="true"]&:hover {
      cursor: ${({ pointer }) => (pointer ? "pointer" : "default")};
    }
  }
`

export const Caption = styled.figcaption`
  position: absolute;
  transform: translateZ(0);
  left: 0;
  top: var(--wv-watch-height);
  width: 100%;
  text-align: center;
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  ${helvetica}
  ${label}
  color: rgb(var(--on-interface) / 1);

  @media ${getMediaQuery("s", "max")} {
    display: none;
  }

  .wvstep_0 & {
    margin-top: 0px;
  }
  .wvstep_1 &,
  .wvstep_2 & {
    margin-top: -10px;
  }
  .wvstep_3 & {
    margin-top: -60px;
  }
  .wvstep_4 & {
    margin-top: -60px;
  }
  .wvstep_5 & {
    margin-top: -30px;
  }
  .wvstep_6 & {
    margin-top: -60px;
  }

  span {
    display: block;
    position: relative;
    white-space: pre-wrap;
  }
`
