import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { getMediaQuery } from "./../../../css/breakpoints"
import { buttonRound, button, buttonShadow } from "css/button"
import { gradient, small, big, label } from "css/heading"
import { headline100, headline30, legend100, body50, headline70, light, body70 } from "css/text"
import {
  labelSmall,
  largeMobile,
  labelNew,
  largeNewLighter,
  medium,
  mediumLighter,
  mediumThin,
  bigBanner,
  intermediate,
  largeNewLighterTkaway,
} from "css/heading-new"
import { wviPhoneP, wviPadP, wviPadL } from "./../../../css/breakpoints"

export const Wrapper = styled.div`
  position: relative;
  flex: 1;
  //  background: rgba(255,0,0,.9);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
`

/*
& h1 {
      ${bigBanner}
      margin-inline-start: -0.1ex;
      color: rgb(var(--green) / 1);
      ${gradient("rgb(var(--green))", "rgb(var(--sea-green))")}
      @media (min-aspect-ratio: 1/1) {
        justify-self: flex-start;
      }
    }

    & > div:first-of-type {
      text-align: left;

      [dir="rtl"] & {
        text-align: right;
      }
      & > p {
        ${largeNewLighter}
        color: rgb(var(--on-interface) / 1);
        background: linear-gradient(to right, rgb(var(--raisin-black)), rgb(var(--dark-silver)));
        background-clip: text;
        color: transparent;
        pointer-events: none;

      }
    }
  }
  */

export const SubHeading = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  justify-self: flex-start;
  @media (min-aspect-ratio: 1/1) {
    flex-direction: column;
  }
`

export const Main = styled.section`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: var(--wv-nav-top);

  .wv_puppet & {
    ${wviPhoneP(css`
      top: var(--wv-header-top);
    `)}
  }

  .wvstep_0 & {
    top: calc(var(--wv-header-top) + var(--wv-safe));
    header + div {
      display: none;
    }
  }
  z-index: 2;
  //  min-height: var(--wv-header-height);
  padding-inline-start: 8vw;
  padding-inline-end: 8vw;
  width: 100%;
  box-sizing: border-box;

  & > button {
    position: relative;
  }

  &[data-subtitle="true"] {
    & > button {
      display: none;
    }
  }

  div {
    margin-block-start: 0;
    padding: 0;
    p {
      ${body50}
    }
  }

  & header {
    min-height: var(--wv-header-height);
    grid-column: 1/-1;
    grid-row: 2/3;
    align-self: center;
    justify-self: flex-start;
    //    color: rgb(var(--on-background) / 1);
    //    height: fit-content;

    //    width: calc(100% - 5vw);

    justify-items: center;
    justify-content: center;
    align-self: center;

    @media (min-aspect-ratio: 1/1) {
      //      width: calc(100% - 5vw);
      grid-row: heading;
      justify-content: flex-start;
      justify-self: flex-start;
      justify-content: start;
      align-items: start;
      align-self: self-start;
    }

    @media screen and (max-width: 480px) {
      & h1 {
        grid-row: 1;
      }
    }
    & h1 {
      ${body50}
      display: inline-block;
      margin-inline-start: 0ex;

      @media (min-aspect-ratio: 1/1) {
        justify-self: flex-start;
      }
    }
    .wv_puppet & {
      h1 {
        ${headline70}
        margin-block-end: 0.625rem;
      }
    }

    .wvstep_0 & {
      & h1 {
        ${headline100}
        display: block;
        text-align: center;
      }
    }

    & > div:first-of-type {
      //      margin-block-start: 0.5rem;
      text-align: left;

      [dir="rtl"] & {
        text-align: right;
      }
      & > p {
        ${body50}
        ${light}
        pointer-events: none;
        //        margin-block-end: 0.312rem;
      }
      & > span {
        display: flex;
        ${body50}
        ${light}
      }
      & h2 {
        margin-block-start: 0.5rem;
        color: inherit !important;
      }
    }
  }
  ._wvdb & {
    background: rgba(255, 0, 0, 0.4);
  }
`
/*
export const Desc = styled(Legend)`
  //  line-height: 1.1;
`
*/
export const PriceLabel = styled.p`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
  ${legend100}

  & span {
    [dir="rtl"] & {
      direction: ltr;
    }
  }

  & button:not(:only-child) {
    margin-bottom: 1px;
  }
`

/*
export const ViewModelLink = styled(Legend)`
  position: relative;
  visibility: hidden;
  padding: 16px 0;
  font-size: ${toREM("14px")};
  pointer-events: initial;
  a,
  em {
    position: relative;
    font-size: 1em;
    span {
      font-size: 1em;
    }
  }
`
*/
export const ViewModelButton = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  white-space: nowrap;
`
/*
export const InfoIcon = styled(Icon)`
  position: relative;
`

export const ButtonInfo = styled(Button)`
  padding: 2px;
  margin-left: 8px;
  position: relative;
  top: 2px;
  transform: scale(0.7);
  transform-origin: 0 0;
  pointer-events: auto;
  @media ${getMediaQuery("m", "max")} {
    padding: 2px;
  }

  .contrast-active & {
    background-color: ${colors.green};
    border-color: ${colors.green};
    svg {
      fill: ${colors.white};
    }
  }

  &::after {
    content: "";
    height: 200%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 200%;
  }
`

export const ButtonView = styled(Link)`
  position: relative;
  pointer-events: auto;
  &:not(:lang(ar)):not(:lang(fa)) span::first-letter {
    text-transform: uppercase;
  }
  outline: none;

  .tab-active &:focus {
    outline: 2px solid ${colors.green};
    outline-offset: 3px;
  }
`*/
