import { ctx } from "contexts/Header"
import styled from "@emotion/styled"
import { useConsole } from "contexts/Console"
import { useWebSocket, readyStates as wsStates } from "contexts/WebSocket"
import { usePairing, readyStates as pairingStates } from "contexts/Pairing"
import Selection from "components/v2/selection/Selection"
import { startTransition, useLayoutEffect, useState } from "react"
import { Picture } from "components/components"
import { rolex } from "css/text"
import Status from "components/v2/header/puppet/Status"

const Root = styled.div`
  width: 100vw;
  display: grid;

  & > * {
    grid-area: 1 / 1;
  }

  & > picture {
    z-index: 0;
    height: var(--vh100);

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & > ul {
    z-index: 1;
    display: grid;
    justify-self: center;
    grid-gap: 10vw;
    align-items: center;
    grid-auto-flow: column;

    & > li {
      ${rolex}
      color: rgb(var(--on-banner) / 1);
      font-size: 10vw;
    }
  }
`

export default function Puppet({ children, ...props }) {
  const console = useConsole()
  const ws = useWebSocket()
  const pairing = usePairing()
  const [{ code = null, expires = -1 } = {}, setCode] = useState(undefined)

  useLayoutEffect(() => {
    let timer
    const oncodegenerate = ({ code, expires }) => startTransition(() => setCode({ code, expires }))

    const oncodeexpires = ({ code: was }) => {
      if (was === code) startTransition(() => setCode(undefined))
    }

    if (!code || Date.now() > expires) {
      timer = setTimeout(() => ws.send("code:generate", { localTimeStamp: Date.now() }), 4)
    } else timer = setTimeout(() => startTransition(() => setCode(undefined)), expires - Date.now())

    ws.addEventListener("code:generate", oncodegenerate)
    ws.addEventListener(`code:expires`, oncodeexpires)
    return () => {
      clearTimeout(timer)
      ws.removeEventListener("code:generate", oncodegenerate)
      ws.removeEventListener(`code:expires`, oncodeexpires)
    }
  })

  console.verbose("Header:Puppet(%o)", { pairingState: pairing.readyState, code, idle: props.idle })
  return (
    <>
      <Selection />
      <Status />
      <Root>
        {pairing.readyState !== pairingStates.PAIRED && <Picture sources={props.idle.sources} />}
        {(pairing.readyState <= pairingStates.CHECKING || (pairing.readyState === pairingStates.UNPAIRED && !code)) && <div>unavailable</div>} {/** TODO */}
        {pairing.readyState === pairingStates.UNPAIRED && code && (
          <ul>
            {[...code.toString().padStart(4, "0")].map((char, i) => (
              <li key={`${i}-${char}`}>{char}</li>
            ))}
          </ul>
        )}
      </Root>
      {pairing.readyState === pairingStates.PAIRED && children}
    </>
  )
}
