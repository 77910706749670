import useConstant from "utils/useConstant"
import { useConsole } from "contexts/Console"
import { useLocale } from "contexts/Locale"
import QS from "qs"
import localeToSolr from "utils/localeToSolr"
import { useEnv } from "contexts/Env"

export default function useSOLRbyRMC(rmc) {
  const console = useConsole()
  const { locale } = useLocale()
  const env = useEnv()
  const base = env.solr.proxy ? (process.browser ? global.location.origin : `http://${process.env.SERVICE_NGINX}`) : env.solr.origin
  const pathname = env.solr.pathnames.modelpage

  const filters = useConstant(() => ({
    lang: localeToSolr(locale),
  }))

  return (handler = Function.prototype) => {
    const search = Object.assign({}, { q: rmc, fq: Object.keys(filters).reduce((acc, key) => [...acc, `${key}:${filters[key]}`], []) })
    const url = new URL(pathname + "?" + QS.stringify(search, { encode: false, indices: false }), base)

    const controller = new AbortController()
    fetch(new Request(url), { signal: controller.signal, credentials: "include" })
      .then(response => response.json())
      .then(data => handler(null, data))
      .catch(err => {
        if (err.name === "AbortError") return
        handler(err, null)
      })

    console.verbose("useSOLRbyRMC(%o) => fetch", { search, url })
    return () => {
      controller.abort()
    }
  }
}
