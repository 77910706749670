import React, { forwardRef, useEffect } from "react"

import { useCarol } from "../context"

import { heights } from ".."

import { ConfiguFrame } from "../styles"
import { Roller } from "./styles"

const CONTROL_ID = "CollectionRoller-items"

export const CollectionRoller = forwardRef(
  ({ type, startSlide, height, marginLeft, direction, children, indexTabbed, removeScrollBar, onFocusClick, arrows=true, ariaLabel="" }, ref) => {

    return (
      <>
        <ConfiguFrame initial='leave' whileHover='hover'>
          <Strip
            ref={ref}
            type={type}
            startSlide={startSlide}
            height={height}
            marginLeft={marginLeft}
            direction={direction}
            removeScrollBar={removeScrollBar}
            onFocusClick={onFocusClick}
            ariaLabel={ariaLabel}
          >
            {children}
          </Strip>
        </ConfiguFrame>
      </>
    )
  }
)

const Strip = ({ type, startSlide, children, marginLeft, height, direction, onFocusClick, ariaLabel }) => {
  const { snapPoints, scrollType, slide, visiblePercent, ref } = useCarol()

  const updateSnapPoints = () => {
    const isrtl = direction === "rtl"

    if (React.Children.count(children) > 0) {
      const snapSlidePts = new Set()
      let snapArray = []
      const max = ref.current.scrollWidth - ref.current.clientWidth
      const offset = ref.current.childNodes[0].offsetLeft

      ref.current.childNodes.forEach(item => {
        let val
        if (isrtl) {
          if (scrollType === "default") {
            val =
              item.offsetLeft > 0
                ? max
                : Math.max(item.offsetLeft + max - (ref.current.clientWidth - item.clientWidth) + offset, 0)
          } else if (scrollType === "negative") {
            val =
              item.offsetLeft > 0
                ? 0
                : Math.max(item.offsetLeft + item.clientWidth - ref.current.clientWidth + offset, -max)
          } else {
            val = item.offsetLeft - offset
          }
        } else {
          val = /*item.offsetLeft >= max ? max : */item.offsetLeft - offset
        }
        snapSlidePts.add(val)
        item.setAttribute("slidenum", [...snapSlidePts].indexOf(val))
      })

      snapArray = [...snapSlidePts]
      ref.current.scrollLeft = snapArray[slide.get()]

      snapPoints.set(snapArray.toString())
    }
  }

  const onVisiblePercentChange = () => updateSnapPoints()
  useEffect(() => visiblePercent.onChange(onVisiblePercentChange))

  return (
    <Roller
      id={CONTROL_ID}
      ref={ref}
      type={type}
      startSlide={startSlide}
      height={height}
      marginLeft={marginLeft}
      parentHeight={{ height: height, heights: heights }}
      direction={direction}
      childNum={React.Children.count(children)}
      onFocusClick={onFocusClick}
      ariaLabel={ariaLabel}
    >
      {children}
    </Roller>
  )
}

export default CollectionRoller
