import useConstant from "utils/useConstant"
import { Provider } from "contexts/Console"

export const levels = {
  error: 0,
  warn: 1,
  ssr: 6,
  http: 7, // "hide" from browser
  info: 4,
  debug: 5,
  verbose: 6,
  silly: 7,
}

export default function Console({ children, level, console = global.console }) {
  const proxy = useConstant(
    () =>
      new Proxy(console, {
        get(t, k) {
          if (!Number.isFinite(levels[k])) return console?.[k]
          if (levels[k] > levels[level]) return Function.prototype
          if (!process.browser) return (...args) => console.log(k === "log" ? "default" : k, ...args)
          if (console[k]) return (...args) => console[k](...args)
          return (...args) => console.log(...args)
        },
      })
  )

  proxy.verbose("Console(%o)", { level })
  return <Provider value={proxy}>{children}</Provider>
}
