import { useConsole } from "contexts/Console"
import { useWebSocket, readyStates as wsStates } from "contexts/WebSocket"
import { usePairing, readyStates as pairingStates } from "contexts/Pairing"
import { useNavigation } from "contexts/Navigation"
import { Provider, readyStates } from "contexts/Prospect"
import { startTransition, useLayoutEffect, useRef, useState } from "react"
import useConstant from "utils/useConstant"
import { useWA } from "contexts/WA"
import { useWishlist } from "contexts/Selection"

export default function Prospect({ children }) {
  const console = useConsole()
  const ws = useWebSocket()
  const pairing = usePairing()
  const navigation = useNavigation()
  const [readyState, setReadyState] = useState(readyStates.IDLE)
  const et = useConstant(() => new EventTarget())
  const wa = useWA()
  const wishlist = useWishlist()

  const ctx = {
    addEventListener(...args) {
      return et.addEventListener(...args)
    },
    removeEventListener(...args) {
      return et.removeEventListener(...args)
    },

    get readyState() {
      return readyState
    },
    create: (opts = {}) => {
      ws.send("prospect:create", Object.assign({}, opts ?? {}))
    },
    close: () => {
      ws.send("prospect:close")
    },
    navigate: slug => {
      ws.send("prospect:navigate", { slug })
      const isModelPage = slug.startsWith("/watches")
      const isWishlistPage = slug.startsWith("/wishlist")
      const isConfigPage = slug.endsWith("/configure")
      const isVideoPage = slug.startsWith("/media")
      const paramsConfig = navigation.history.location.hash.split("/").slice(1)
      const paramsModel = navigation.history.location.pathname.split("/").slice(4)
      const [family, rmc] = paramsConfig
      const [familyModel, rmcModel] = paramsModel

      if (pairing.readyState === pairingStates.PAIRED) {
        wa.dispatch({
          detail: {
            triggerName: "liveBroadcasting",
            broadcastPageType:
              (isConfigPage && "configurator page") || (isWishlistPage && "wishlist page") || (isModelPage && "model page") || (isVideoPage && "video page"), // "model page" OR "wishlist page" OR "configurator page"
            productFamily: (isConfigPage && family) || (isWishlistPage && "") || (isModelPage && familyModel) || "", // product family
            productRMC: (isConfigPage && rmc) || (isWishlistPage && wishlist?.list) || (isModelPage && rmcModel) || "", // product RMC
          },
        })
      }
    },
    sync: componentId => ({
      send: (type, payload = {}) => {
        if (readyState !== readyStates.ACTIVE) return
        console.verbose("Prospect::%s::sync(%s, %o)", componentId, type, payload)
        ws.send(`prospect:sync`, { componentId, payload: { type, ...payload } })
      },
      addEventListener: (type, ...args) => {
        ws.addEventListener(`prospect:sync:${componentId}:${type}`, ...args)
      },
      removeEventListener: (type, ...args) => {
        ws.removeEventListener(`prospect:sync:${componentId}:${type}`, ...args)
      },
    }),
  }

  useLayoutEffect(
    function pairingState() {
      startTransition(() =>
        setReadyState(readyState => {
          if (pairing.readyState !== pairingStates.PAIRED) return readyStates.IDLE
          if (pairing.readyState === pairingStates.PAIRED) return readyStates.CHECKING
          return readyState
        })
      )
    },
    [pairing.readyState]
  )

  useLayoutEffect(
    function readystatechange() {
      const onprospectcheck = ({ prospect, slug }) =>
        startTransition(() => {
          setReadyState(readyStates[prospect ? "ACTIVE" : "INACTIVE"])
          onprospectnavigate({ slug: slug ?? "/" })
        })
      const onprospectcreate = ({ slug }) =>
        startTransition(() => {
          setReadyState(readyStates["ACTIVE"])
          onprospectnavigate({ slug }, true)
        })
      const onprospectclose = () =>
        startTransition(() => {
          setReadyState(readyStates["INACTIVE"])
          if (ws.connector === "puppet") navigation.push(navigation.localize("/").url)
        })
      const onprospecterror = ({ error }) => {
        console.error(new Error(error))
      }
      const onprospectnavigate = ({ slug }, everyone) => {
        slug = slug === "/watches?m=0" && ws.connector === "puppet" ? "/" : slug
        if ((everyone || ws.connector === "puppet") && slug !== navigation.slug) navigation.push(navigation.localize(slug).url)
      }
      const onprospectactivitychange = ({ active }) => {
        const event = new Event("activitychange")
        Object.assign(event, { active })
        et.dispatchEvent(event)
      }

      if (readyState === readyStates.CHECKING) {
        ws.addEventListener("prospect:check", onprospectcheck)
        ws.send("prospect:check")
      }

      if (readyState === readyStates.INACTIVE) {
        ws.addEventListener("prospect:create", onprospectcreate)
      }

      if (readyState === readyStates.ACTIVE) {
        ws.addEventListener("prospect:close", onprospectclose)
        ws.addEventListener("prospect:navigate", onprospectnavigate)
        ws.addEventListener("prospect:activitychange", onprospectactivitychange)
      }

      ws.addEventListener("prospect:error", onprospecterror)
      return () => {
        ws.removeEventListener("prospect:check", onprospectcheck)
        ws.removeEventListener("prospect:open", onprospectcreate)
        ws.removeEventListener("prospect:close", onprospectclose)
        ws.removeEventListener("prospect:error", onprospecterror)
        ws.removeEventListener("prospect:navigate", onprospectnavigate)
        ws.removeEventListener("prospect:activitychange", onprospectactivitychange)
      }
    },
    [readyState]
  )

  console.verbose("Prospect(%o)", { readyState })
  return <Provider /*key={readyState}*/ value={ctx}>{children}</Provider>
}
