import styled from "@emotion/styled"
import { helvetica } from "css/text"
import { gradient, big, label, smallLabel } from "css/heading"
import { clamp } from "css/text"
import { button, buttonRound, mask } from "css/button"
import { linearToRight } from "css/gradients"
import { mediumLighter, configButtons } from "css/heading-new"
import { headline70, headline50, legend100, body50, bold } from "css/text"

const colors = []

export const Image = styled.img`
  display: block;
  position: absolute;
  width: var(--img-width);
  border-radius: inherit;
  height: auto;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%);
  left: 0.25rem;

  @keyframes wvvnrfadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes wvvnrfadeout {
    0% {
      opacity: initial;
    }
    100% {
      opacity: 0;
    }
  }
`

export const Main = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: fit-content;
  flex-basis: min-content;
  overflow: visible;
  scroll-snap-align: center;
  padding-bottom: 10px;
`

export const Button = styled.button`
--img-width: calc(var(--height) * 1.8);
  flex-direction: row;
  text-align: center;
  ${button}

  position: relative;
border: .1rem solid white;
padding: calc(var(--height) / 5) 1rem calc(var(--height) / 5) calc(var(--img-width) + 1.25rem);

@media screen and (max-width: 480px) {
  padding: calc(var(--height) / 5) .75rem calc(var(--height) / 5) calc(var(--img-width) + 1rem);
}

[aria-selected="true"] & {
  cursor: default;
  border-color: rgb(var(--green) / 1);
}

  @media (hover: hover) and (pointer: fine) {
    [data-disabled="false"][aria-selected="false"] &:hover {
        cursor: pointer;
      }
    }
  }
`

export const ButtonStatic = styled.button`
// --img-width: calc(var(--height) * 1.8);
  flex-direction: row;
  text-align: center;
  padding: 0 12px;
  background-color: transparent;

  position: relative;
border: 0;
color: rgb(var(--black) / 1);

[aria-selected="true"] & {
  cursor: default;
  color: rgb(var(--green) / 1);
}

  @media (hover: hover) and (pointer: fine) {
    [data-disabled="false"][aria-selected="false"] &:hover {
        cursor: pointer;
      }
    }
  }
`

export const Label = styled.span`
  white-space: nowrap;
  ${headline70}
`
