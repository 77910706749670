import React, { useState, useLayoutEffect, useEffect, useCallback, useRef, memo } from "react"
import { lazy } from "react"
import { Main, List, ListItem } from "./styles"
import Carol from "./../../carol"
import { STEPS, INOUT_DURATION, INOUT_EASE, IN_EASE, OUT_EASE, OUT_DURATION, UPDATE_LAG, ROUTE_IGNORE } from "./../constants"
import { useMediaLoader } from "./../hooks"
import { CarolProvider, useCarol } from "./../../carol/context"
import { mvSubscribe, useWatchVariations } from "contexts/Configurator"
import { logmine, cssApply } from "./../utils"
import { useNavigation } from "contexts/Navigation"
import { motion, useScroll } from "framer-motion"
import { useWebSocket } from "contexts/WebSocket"
import { useSync, useProspect, readyStates } from "contexts/Prospect"

const ConfiguratorCard = lazy(() => import("components/card/ConfiguratorCard"))

const VARIANTS = {
  main: {
    hidden: [
      [
        "transition",
        `opacity ${INOUT_DURATION[1]}ms cubic-bezier(${OUT_EASE}),
        visibility 0ms ${INOUT_DURATION[1]}ms`,
      ],
      ["opacity", 0],
      ["visibility", "hidden"],
    ],
    visible: [
      [
        "transition",
        `opacity ${INOUT_DURATION[0]}ms cubic-bezier(${IN_EASE}),
        visibility 0ms 0ms`,
      ],
      ["visibility", "inherit"],
      ["opacity", 1],
    ],
  },
}

function useDisplay(newstepindex, ref) {
  //logmine("CustomHook", "Collection", "useDisplay")
  const newstepindexUpdate = useCallback(
    v => {
      //logmine("Callback", "Collection", "useDisplay", "newstepindexUpdate", v)
      cssApply(ref.current, VARIANTS.main.hidden)
    },
    [ref]
  )

  useEffect(() => mvSubscribe("Collection useDisplay", newstepindex, newstepindexUpdate, false), [newstepindex, newstepindexUpdate])
}

function useReady(items, stepindex, newstepindex, ref) {
  //logmine("CustomHook", "Collection", "useReady", items)
  const ready = useMediaLoader("WVC", ref, items)

  const display = useCallback(
    state => {
      if (!state) return
      const _stepindex = stepindex.get()
      if (_stepindex !== 0) return
      cssApply(ref.current, VARIANTS.main.visible)
    },
    [ref, stepindex]
  )

  const update = useCallback(() => {
    display(ready.get())
  }, [ready, display])

  useEffect(() => mvSubscribe("Collection", stepindex, update, false), [stepindex, update])
  useEffect(() => mvSubscribe("Collection", ready, update, false), [ready, update])

  return ready
}

function useInit(newstepindex, initQ) {
  //logmine("CustomHook", "Collection", "useInit")
  const update = useCallback(
    v => {
      if (v === 0) initQ(3)
    },
    [initQ]
  )

  useEffect(() => mvSubscribe("Collection", newstepindex, update), [newstepindex, update])
}

function useFocus(items, focused, entry) {
  const navigation = useNavigation()
  const update = useCallback(
    v => {
      if (v < 0) return
      const item = items[v]
      if (!item) return
      let href = item.link.href
      let [, hash] = href.split("#")
      if (!!hash) hash = `#${hash}`
      else href = `${navigation.localize("/").url}${href}`
      entry.set(hash || href)
    },
    [items, entry]
  )

  useEffect(() => mvSubscribe("Collection", focused, update), [focused, update])
}

function prevent(e) {
  e.preventDefault()
  return false
}

const CLASSIC = ["1908", "datejust", "day-date", "lady-datejust", "oyster-perpetual", "sky-dweller"]
const PRO = ["air-king", "cosmograph-daytona", "deepsea", "explorer", "gmt-master-ii", "sea-dweller", "submariner", "yacht-master"]

const Roller = ({ uid, title, items = [] }) => {
  const { puppet } = useWatchVariations()
  const ref = useRef()
  const sync = useSync("config")
  const prospect = useProspect()
  const isProspect = prospect.readyState === readyStates.ACTIVE

  const { scrollX } = useScroll({
    container: ref,
  })

  function onScrollX(v) {
    if (puppet) return
    const { current: el } = ref
    if (!el) return
    const max = el.scrollWidth - el.offsetWidth
    //    console.log(v)
    sync.send(`scrollXCol_${uid}`, { value: v / max })
  }

  useLayoutEffect(() => {
    if (!puppet) return
    const onscroll = ({ value }) => {
      const { current: el } = ref
      if (!el) return
      const max = el.scrollWidth - el.offsetWidth
      el.scrollLeft = value * max
    }

    sync.addEventListener(`scrollXCol_${uid}`, onscroll)
    return () => {
      sync.removeEventListener(`scrollXCol_${uid}`, onscroll)
    }
  }, [])

  useLayoutEffect(() => mvSubscribe("Collection", scrollX, onScrollX), [])

  return (
    <List>
      <h2>{title}</h2>
      <ul ref={ref}>
        {items.map(item => (
          <ConfiguratorCard {...item} />
        ))}
      </ul>
    </List>
  )
}

export const Collection = ({ children }) => {
  const items = children
    ?.map(v => v.props)
    .map(v => {
      let [family] = v.href.split("/").reverse().slice(1)
      return Object.assign({}, v, { family: family, key: `wv_col_${family}` })
    })
  const { focus, fromui, layout, newstepindex, stepindex, active, collection, route, category, tint, initQ, loadQ, entry } = useWatchVariations()

  const ref = useRef()
  const navigation = useNavigation()
  const ws = useWebSocket()

  const onClick = e => {
    let target = e.target
    let attr = "data-href"
    let href = null
    while (!(href = target.getAttribute(attr))) {
      target = target.parentNode
    }
    let [mp, hash] = href.split("#")
    if (!hash) {
      //      hash = navigation.localize(mp).url
      navigation.push(mp)
    } else {
      fromui.set(true)
      route.set(hash)
    }
  }

  /*
  href={navigation.localize("/watches/configure").url}
          onClick={e => {
            e.preventDefault()
            navigation.push(e.target.getAttribute("href"))
          }}
          */
  useLayoutEffect(() => {
    const { current: root } = ref
    if (!root) return
    root.addEventListener("click", onClick, true)

    return () => root.removeEventListener("click", onClick, true)
  }, [])

  const ready = useReady(items, stepindex, newstepindex, ref)

  const loadedUpdate = useCallback(() => {
    //    console.log('>>> loadedUpdate', ready.get(), stepindex.get(), newstepindex.get())
    if (!ready.get() || stepindex.get() !== 0 || newstepindex.get() !== 0) return
    loadQ(3)
  }, [ready, stepindex, newstepindex, loadQ])

  useInit(newstepindex, initQ)
  useDisplay(newstepindex, ref)

  useEffect(() => mvSubscribe("Collection", stepindex, loadedUpdate, false), [stepindex, loadedUpdate])
  useEffect(() => mvSubscribe("Collection", ready, loadedUpdate, false), [ready, loadedUpdate])

  return ws.connector !== "standalone" ? (
    <Main ref={ref}>
      <Roller uid='classic' title={"Classic watches"} items={CLASSIC.map(v => items.find(k => k.family === v))} />
      <Roller uid='pro' title={"Professional watches"} items={PRO.map(v => items.find(k => k.family === v))} />
    </Main>
  ) : null
}
