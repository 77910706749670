import React, { useRef, useEffect, useState } from "react"
import { useMotionValue, useAnimation, transform } from "framer-motion"

import { useCarol, isTouchDevice } from "./context"

import { CAROL_TYPE } from "./index"

import { Strip, Item } from "./styles"

export const Wrapper = ({ type, children, className, monoSize, onFocusClick, parentHeight, ips, ariaLabel, startSlide = 0 }) => {
  const { direction, slide, scrolling, grabbing, snapPoints, progress, focused, targetSlide, winWidth, winSize, ref, wsl, remote } = useCarol()

  const animCtrl = useAnimation()

  const dragData = useMotionValue()
  const snapping = useMotionValue(false)
  const bypass = useMotionValue(false)
  const swiping = useMotionValue(0)

  const [swipable, setSwipable] = useState(!!isTouchDevice && (type === "carousel" || type === "videocards") && Number(ips[winSize.get()]) === 1)

  const snapArr = useRef([])
  const durInputRef = useRef([])
  const isDragging = useRef(false)
  const rafId = useRef()
  const velo = useRef(0)
  const timer = useRef(null)
  const start = useRef(null)

  const friction = 0.85

  const propsAdd = parentHeight
    ? {
        tag: "div",
        parentHeight: parentHeight,
        trackerEventName: type,
      }
    : {
        tag: "div",
        trackerEventName: type,
        slide: type === "videocards" ? slide : null,
      }

  const onDragStart = (e, info) => {
    if (bypass.get()) return

    e.preventDefault()
    dragData.set(info)
    isDragging.current = true
  }

  const onDrag = (e, info) => {
    if (bypass.get()) return

    e.preventDefault()
    dragData.set(info)

    isDragging.current = true
    ref.current.scrollLeft -= info.delta.x

    velo.current = info.delta.x
  }

  const step = () => {
    if (Math.abs(velo.current) <= 0.1) {
      cancelAnimationFrame(rafId.current)
    } else {
      ref.current.scrollLeft -= velo.current
      velo.current *= friction
      rafId.current = requestAnimationFrame(step)
    }
  }

  const onMouseDownCapture = e => {
    e.preventDefault()
  }

  const onClickCapture = e => {
    if (bypass.get()) return

    if (isDragging.current) {
      e.preventDefault()
      e.stopPropagation()

      isDragging.current = false
      dragData.set(null)

      if (type === CAROL_TYPE.roller || type === CAROL_TYPE.menupanel) {
        cancelAnimationFrame(rafId.current)
        rafId.current = requestAnimationFrame(step)
      }
    }
  }

  const testIfScrolling = () => {
    let f = 0
    let bool = false
    let raf
    let curScrollLeft = ref.current.scrollLeft
    bypass.set(false)
    const cb = () => {
      if (bool) {
        cancelAnimationFrame(raf)
        if (!snapping.get()) snap()
      } else {
        if (f < 3 && ref.current.scrollLeft === curScrollLeft) {
          f++
          raf = requestAnimationFrame(cb)
        } else if (f === 3 && ref.current.scrollLeft === curScrollLeft) {
          bool = true
          raf = requestAnimationFrame(cb)
        } else {
          cancelAnimationFrame(raf)
        }
      }
    }
    raf = requestAnimationFrame(cb)
  }

  const onTouchStartCapture = e => {
    bypass.set(true)
    stopAnim()
  }

  const onTouchEndCapture = () => {
    if (!!swiping.get()) {
      const dir = direction === "rtl" ? -1 : 1
      const next = Math.min(
        Math.max(Math.abs(swiping.get()) < ref.current.clientWidth / 2 ? (swiping.get() > 0 ? slide.get() - 1 * dir : slide.get() + 1 * dir) : slide.get(), 0),
        snapArr.current.length - 1
      )

      goto(next)
      swiping.set(0)
    } else {
      bypass.set(false)
    }
  }
  const onTouchEnd = e => {
    testIfScrolling()
  }

  const onUpdate = scrollLeft => (ref.current.scrollLeft = scrollLeft)

  const onComplete = () => {
    requestAnimationFrame(() => {
      cancelAnimationFrame(timer.current)
      snapping.set(false)
      if (targetSlide.get() !== slide.get()) {
        targetSlide.set(slide.get())
      }
      scrolling.set(false)
    })
  }

  const stopAnim = () => {
    animCtrl.stop()
    snapping.set(false)
    cancelAnimationFrame(timer.current)
  }

  const goto = index => {
    const dist = Math.abs(ref.current.scrollLeft - snapArr.current[index])
    const dur = transform(dist, durInputRef.current, Wrapper.durOutput)
    stopAnim()
    const transit = swipable
      ? {
          type: "spring",
          bounce: 0,
          duration: dur,
          onUpdate: onUpdate,
          onComplete: onComplete,
        }
      : {
          type: "tween",
          ease: "easeIn",
          duration: dur,
          onUpdate: onUpdate,
          onComplete: onComplete,
        }
    animCtrl.start({
      scrollLeft: snapArr.current[index],
      transition: transit,
    })
  }

  const snap = () => {
    if (type === CAROL_TYPE.roller || type === CAROL_TYPE.menupanel || remote) {
      return
    }

    const pos = ref.current.scrollLeft
    const index = Wrapper.getIndex(snapArr.current, pos)

    if (snapArr.current[index] === pos) {
      onComplete()
      return
    }
    snapping.set(true)
    goto(index)
  }

  const onSnapPointsChange = sp => (snapArr.current = sp.split(",").map(Number))
  useEffect(() => snapPoints.onChange(onSnapPointsChange))

  const jumpto = index => {
    stopAnim()
    ref.current.scrollLeft = snapArr.current[index]
    slide.set(index)
    targetSlide.set(index)
  }

  useEffect(() => {
    jumpto(startSlide)
    focused.set(startSlide)
  }, [startSlide, children])

  const onTargetSlideChange = s => {
    if (s === slide.get()) {
      return
    }
    goto(s, 0.6)
  }
  useEffect(() => targetSlide.onChange(onTargetSlideChange))

  const loop = hrt => {
    const delta = hrt - start.current
    if (delta >= Wrapper.DELAY) {
      if (!snapping.get()) {
        cancelAnimationFrame(timer.current)
        snap()
      }
    } else {
      if (!snapping.get() && scrolling.get()) {
        cancelAnimationFrame(timer.current)
        timer.current = requestAnimationFrame(loop)
      }
    }
  }

  const onGrabbingChange = bool => {
    if (!bool) {
      testIfScrolling()
    }
  }
  useEffect(() => grabbing.onChange(onGrabbingChange))

  const onProgressChange = p => {
    const curIndex = Wrapper.getIndex(snapArr.current, ref.current.scrollLeft)
    slide.set(curIndex)
    focused.set(curIndex)
    if (grabbing.get()) {
      ref.current.scrollLeft = (ref.current.scrollWidth - ref.current.offsetWidth) * p
    } else {
      if (!snapping.get() && !bypass.get()) {
        start.current = performance.now()
        timer.current = requestAnimationFrame(loop)
      }
    }
  }

  useEffect(() => {
    const unSub = progress.onChange(onProgressChange)
    animCtrl.set({ scrollLeft: snapArr.current[slide.get()] || 0 })
    return () => {
      cancelAnimationFrame(timer.current)
      stopAnim()
      unSub()
    }
  })

  const onScroll = e => {
    const sl = ref.current.scrollLeft
    const max = ref.current.scrollWidth - ref.current.offsetWidth
    wsl.set(sl / max)

    scrolling.set(true)

    if (!snapping.get() && !bypass.get()) {
      animCtrl.set({ scrollLeft: sl })
    } else if (bypass.get()) {
      animCtrl.set({ scrollLeft: sl })
    }
    progress.set(sl / max)
  }

  const onItemClick = e => {
    if (winWidth.get() > 767) return
    const i = Number(e.currentTarget.getAttribute("slidenum"))
    if (i !== focused.get()) {
      e.preventDefault()
      goto(i)
    }
  }
  const onConfigItemClick = e => {
    let target = e.target
    while (target.tagName !== "LI") target = target.parentNode
    const num = target.getAttribute("data-num") ?? null
    if (!num) return
    if (Number(num) === focused.get()) {
      onFocusClick()
    } else {
      goto(Number(num))
    }
  }

  useEffect(() => {
    const r = ref.current
    let observer

    if (type !== CAROL_TYPE.configurator) {
      const onIntersection = entries => {
        if (!entries[0].isIntersecting) {
          jumpto(startSlide)
        }
      }
      observer = new IntersectionObserver(onIntersection)
      observer.observe(r)
    }
    return () => {
      if (type !== CAROL_TYPE.configurator && observer) {
        observer.unobserve(r)
      }
    }
  }, [ref])

  const onWinSizeChange = s => {
    setSwipable(!!isTouchDevice && (type === "carousel" || type === "videocards") && Number(ips[s]) === 1)
  }
  useEffect(() => winSize.onChange(onWinSizeChange))

  const onWinWidthChange = w => (durInputRef.current = w < 768 ? [w / 10, w / 6, w / 4] : [w / 6, w / 4, w / 2])
  useEffect(() => {
    const unSub = winWidth.onChange(onWinWidthChange)
    onWinWidthChange(winWidth.get())
    return () => unSub()
  })

  const onFocus = e => {
    if (!document.documentElement.classList.contains("tab-active")) return
    const liParent = e.target.closest("li")
    const num = liParent?.getAttribute("slidenum")
    if (!isNaN(num) && num !== slide.get()) {
      targetSlide.set(num)
    }
  }

  const onWheel = e => {
    const sl = ref.current.scrollLeft
    const max = ref.current.scrollWidth - ref.current.offsetWidth

    if (direction === "rtl") {
      if (sl > 0 && e.deltaX > 0) {
        e.preventDefault()
        e.stopPropagation()
      } else if (sl < -max && e.deltaX < 0) {
        e.preventDefault()
        e.stopPropagation()
      }
    } else {
      if (sl > max && e.deltaX > 0) {
        e.preventDefault()
        e.stopPropagation()
      } else if (sl < 0 && e.deltaX < 0) {
        e.preventDefault()
        e.stopPropagation()
      }
    }
  }

  const startPt = useRef([0, 0])
  const oldDeltaPt = useRef([0, 0])
  const deltaPt = useRef([0, 0])
  const distPt = useRef([0, 0])
  const lockDirection = useRef(null)

  const onTchStrt = e => {
    lockDirection.current = null
    startPt.current[0] = e.touches[0].clientX
    startPt.current[1] = e.touches[0].clientY
    oldDeltaPt.current[0] = startPt.current[0]
    oldDeltaPt.current[1] = startPt.current[1]
  }
  const onTchMv = e => {
    if (lockDirection.current === "y") return
    deltaPt.current[0] = e.touches[0].clientX - oldDeltaPt.current[0]
    deltaPt.current[1] = e.touches[0].clientY - oldDeltaPt.current[1]

    distPt.current[0] = e.touches[0].clientX - startPt.current[0]
    distPt.current[1] = e.touches[0].clientX - startPt.current[1]

    if (Math.abs(deltaPt.current[0]) > Math.abs(deltaPt.current[1])) {
      if (lockDirection.current === null) lockDirection.current = "x"
      e.preventDefault()
      swiping.set(distPt.current[0])
    } else {
      if (lockDirection.current === null) lockDirection.current = "y"
      swiping.set(0)
    }

    oldDeltaPt.current[0] = e.touches[0].clientX
    oldDeltaPt.current[1] = e.touches[0].clientY

    if (lockDirection.current === "x") ref.current.scrollLeft -= deltaPt.current[0]
  }

  useEffect(() => {
    const r = ref.current
    if (swipable) {
      r.addEventListener("touchstart", onTchStrt, false)
      r.addEventListener("touchmove", onTchMv, false)
    } else {
      r.removeEventListener("touchstart", onTchStrt, false)
      r.removeEventListener("touchmove", onTchMv, false)
    }

    return () => {
      r.removeEventListener("touchstart", onTchStrt, false)
      r.removeEventListener("touchmove", onTchMv, false)
    }
  }, [swipable])

  return (
    <Strip
      ref={ref}
      type={type}
      className={className}
      animate={animCtrl}
      //      monoSize={monoSize}
      onTouchStartCapture={onTouchStartCapture}
      onTouchEnd={onTouchEnd}
      onTouchEndCapture={onTouchEndCapture}
      onPanStart={onDragStart}
      onPan={onDrag}
      onClickCapture={onClickCapture}
      onScroll={onScroll}
      onFocus={onFocus}
      onWheelCapture={onWheel}
      aria-label={ariaLabel || null}
    >
      {React.Children.map(children, (child, i) => {
        return (
          <Item
            key={`carolitem-${i}`}
            itemnum={i}
            data-num={i}
            type={type}
            //            monoSize={monoSize}
            role='group'
            aria-roledescription='slide'
            onClickCapture={type === "configurator" || type === "collection" ? onConfigItemClick : type === "watchcarousel" ? onItemClick : undefined}
            onMouseDownCapture={onMouseDownCapture}
          >
            {child && React.cloneElement(child, propsAdd)}
          </Item>
        )
      })}
    </Strip>
  )
}

export default Wrapper

Wrapper.getIndex = (arr, t) => arr.reduce((acc, x, i, a) => (Math.abs(x - t) < Math.abs(a[acc] - t) ? i : acc), 0)

Wrapper.durOutput = [0.15, 0.25, 0.55]
Wrapper.DELAY = 100
