import { useConsole } from "contexts/Console"
import { useWebSocket } from "contexts/WebSocket"
import { ctx } from "contexts/Header"
import Catalog from "components/v2/header/catalog/Header"
import Puppet from "components/v2/header/puppet/Header"
import Standalone from "components/v2/header/standalone/Header"
import Embed from "components/v2/header/embed/Header"
import { useNavigation } from "contexts/Navigation"

export default function Header(props) {
  const console = useConsole()
  const ws = useWebSocket()
  const navigation = useNavigation()

  Object.assign(ctx, {})
  console.verbose("Header(%o)", { props })
  return (
    <>
      {ws.connector === "catalog" && <Catalog {...props} />}
      {ws.connector === "puppet" && <Puppet {...props} />}
      {ws.connector === "standalone" && <Standalone {...props} />}
      {navigation.app === "embed" && <Embed {...props} />}
      {/* {ws.connector === "takeaway" && <TakeAway />} */}
    </>
  )
}
