import { useRef } from "react"
import styled from "@emotion/styled"
import ZoomIn from "components/v2/majesty/ZoomIn"
import { useWebSocket } from "contexts/WebSocket"
import { useConsole } from "contexts/Console"
import { useEnv } from "contexts/Env"

import hash from "@emotion/hash"

const first = `css-${hash("first")}`
const second = `css-${hash("second")}`
const third = `css-${hash("third")}`
const last = `css-${hash("last")}`

// const Root = styled.div`
//   display: grid;
//   width: calc(100% - 1.5vw);
//   height: 100%;
//   grid-template-columns: [start] 50% [end] 50%;
//   grid-template-rows: [top] calc(50% - 1.5vw) [bottom] calc(50% - 1.5vw);
//   position: relative;
//   grid-gap: 1.5vw;

//   @media (max-aspect-ratio: 1/1) {
//     width: 100%;
//     min-width: 100vw;
//     grid-template-columns: [start] 1fr;
//     grid-template-rows: [top] calc(50% - 1.5vw) [bottom] 50%;
//   }

//   & > .first {
//     grid-column: start;
//     grid-row: top / span 3;

//     @media (max-aspect-ratio: 1/1) {
//       grid-column: start;
//       grid-row: top;
//     }

//     & > img {
//       width: 100%;
//       height: 100%;
//       object-fit: cover;
//     }
//   }

//   & > .aside {
//     display: grid;
//     width: calc(100% - 1.5vw);
//     grid-template-columns: [endStart] 50% [endEnd] 50%;
//     grid-template-rows: [endTop] 50% [endBottom] 50%;
//     grid-column: end;
//     grid-row: ${({ length }) => (length <= 4 ? "top/span 3" : "top/span 2")};
//     grid-column-gap: 1.5vw;
//     grid-row-gap: 1.5vw;

//     & img {
//       width: 100%;
//       height: 100%;
//       object-fit: cover;
//     }

//     @media (max-aspect-ratio: 1/1) {
//       width: 100%;
//       grid-template-columns: [left] calc(50% - 1.5vw) [right] 50%;
//       grid-template-rows: [startTop] calc(50% - 1.5vw) [startBottom] 50%;
//       grid-column: start;
//       grid-row: bottom;
//     }

//     & > .second {
//       grid-column: endStart;
//       grid-row: endTop;
//       @media (max-aspect-ratio: 1/1) {
//         grid-column: left;
//         grid-row: startTop;
//       }
//     }
//     & > .third {
//       grid-column: endEnd;
//       grid-row: endTop;

//       @media (max-aspect-ratio: 1/1) {
//         grid-column: right;
//         grid-row: startTop;
//       }
//     }
//     & > .last {
//       grid-column: endStart / span 2;
//       grid-row: endBottom;

//       @media (max-aspect-ratio: 1/1) {
//         grid-column: left/ span 2;
//         grid-row: startBottom;
//       }
//     }
//   }
// `

const Root = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: [start] 50% [middle] 25% [end] 25%;
  grid-template-rows: ${({ gridlayout }) => (gridlayout <= 3 ? "[start] 60% [end] 40%" : "[start] 50% [middle] 25% [end] 25%")};
  grid-gap: 1vw;

  @media screen and (min-width: 481px) {
    grid-template-rows: ${({ gridlayout }) => (gridlayout <= 3 ? "[start] 50% [end] 50%" : "[start] 50% [middle] 25% [end] 25%")};
  }

  @media (max-aspect-ratio: 1/1) {
    grid-template-columns: [start] 50% [end] 50%;
    grid-template-rows: ${({ gridlayout }) => (gridlayout <= 3 ? "[start] 60% [end] 40%" : "[start] 50% [middle] 25% [end] 25%")};

    /* ${({ gridlayout }) =>
      gridlayout === 2 &&
      `
    grid-template-columns: 1fr;

      `} */
  }

  > div {
    width: 100%;
    height: 100%;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .${first} {
    grid-column: start;
    grid-row: start / span 3;
  }
  .${last} {
    grid-column: end;
    grid-row: middle/ span 2;
  }

  @media screen and (max-width: 480px) {
    .${second} {
      ${({ gridlayout }) =>
        gridlayout === 2 &&
        `
      grid-column:start/span 2;
      `}
    }
    .${first} {
      ${({ gridlayout }) =>
        gridlayout === 1 &&
        `
    grid-row: start/ span 2;

      `}
    }
  }

  @media (min-aspect-ratio: 1/1) {
    .${second} {
      grid-column: middle / span 2;
      grid-row: start;

      ${({ gridlayout }) => gridlayout <= 2 && `grid-row: start / span 3;`}
    }
    .${third} {
      grid-column: middle;
      grid-row: middle/ span 2;

      ${({ gridlayout }) =>
        gridlayout <= 3 &&
        `
    
      grid-column: middle / span 2;
    grid-row: end;
      `}
    }
  }

  @media screen and (max-aspect-ratio: 1/1) {
    .${first} {
      grid-column: start / span 2;
      grid-row: start;
    }
    .${last} {
      grid-column: start / span 2;
      grid-row: end;
    }
  }
`

export default function Mosaic({ beautyShots, active }) {
  const rzoom = useRef()
  const console = useConsole()
  const ws = useWebSocket()
  const gridlayout = beautyShots.length
  const env = useEnv()

  const images = beautyShots.map(shot => new URL(`/v7/dam/model-page/gallery${beautyShots.length !== 3 ? "/_not3" : ""}${shot.landscape}`, env.content.origin))

  console.verbose("Mosaic(%o)", { beautyShots })
  return (
    <>
      <Root className='mosContainer' gridlayout={gridlayout}>
        {gridlayout <= 2 ? (
          <>
            <div className={first}>
              <img
                onClick={() => {
                  rzoom?.current.scrollTo(0, true)
                  if (ws.connector === "puppet") rzoom?.current.in()
                }}
                src={images[0]}
                alt=''
              />
            </div>
            <div className={second}>
              <img
                onClick={() => {
                  rzoom?.current.scrollTo(0, true)
                  if (ws.connector === "puppet") rzoom?.current.in()
                }}
                src={images[1]}
                alt=''
              />
            </div>
          </>
        ) : gridlayout <= 3 ? (
          <>
            <div className={first}>
              <img
                onClick={() => {
                  rzoom?.current.scrollTo(0, true)
                  if (ws.connector === "puppet") rzoom?.current.in()
                }}
                src={images[0]}
                alt=''
              />
            </div>
            <div className={second}>
              <img
                onClick={() => {
                  rzoom?.current.scrollTo(0, true)
                  if (ws.connector === "puppet") rzoom?.current.in()
                }}
                src={images[1]}
                alt=''
              />
            </div>
            <div className={third} gridlayout={gridlayout}>
              <img
                onClick={() => {
                  rzoom?.current.scrollTo(0, true)
                  if (ws.connector === "puppet") rzoom?.current.in()
                }}
                src={images[2]}
                alt=''
              />
            </div>
          </>
        ) : (
          <>
            <div className={first}>
              <img
                onClick={() => {
                  rzoom?.current.scrollTo(0, true)
                  if (ws.connector === "puppet") rzoom?.current.in()
                }}
                src={images[0]}
                alt=''
              />
            </div>
            <div className={second}>
              <img
                onClick={() => {
                  rzoom?.current.scrollTo(0, true)
                  if (ws.connector === "puppet") rzoom?.current.in()
                }}
                src={images[1]}
                alt=''
              />
            </div>
            <div className={third}>
              <img
                onClick={() => {
                  rzoom?.current.scrollTo(0, true)
                  if (ws.connector === "puppet") rzoom?.current.in()
                }}
                src={images[2]}
                alt=''
              />
            </div>
            <div className={last}>
              <img
                onClick={() => {
                  rzoom?.current.scrollTo(0, true)
                  if (ws.connector === "puppet") rzoom?.current.in()
                }}
                src={images[3]}
                alt=''
              />
            </div>
          </>
        )}
      </Root>
      {/* <ZoomIn active={active} ref={rzoom} beautyShots={beautyShots} /> */}
    </>
  )
}
