import { Provider } from "contexts/Env"
import { useConsole } from "contexts/Console"

export default function Env({ children, env }) {
  const console = useConsole()

  env.solr.origin = process.browser ? global.location.origin : process.env.SERVICE_NGINX

  const ctx = Object.assign(env)
  console.verbose("Env(%o)", ctx)
  return <Provider value={ctx}>{children}</Provider>
}
