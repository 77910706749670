import React, { useEffect, useState, useRef, useCallback } from "react"

import "ssr-intersection-observer"

import { ThemeProvider } from "@emotion/react"
import ConfiguRoller from "./ConfiguRoller"
import CollectionRoller from "./CollectionRoller"
import { ResizeObserver as ResizeObserverPoly } from "./ResizeObserver"

import { View } from "./styles"

import { CarolProvider, CarolStateProvider, useCarolStateValue, useCarol, isTouchDevice } from "./context"

import { CfgLightTheme, CfgDarkTheme } from "./themes"

export const CAROL_TYPE = {
  roller: "roller",
  rollercenter: "rollercenter",
  rollermono: "rollermono",
  rollertimeline: "rollertimeline",
  rollerstories: "rollerstories",
  carousel: "carousel",
  caroller: "caroller",
  configurator: "configurator",
  collection: "collection",
  watchcarousel: "watchcarousel",
  watchcards: "watchcards",
  business: "business",
  menupanel: "menupanel",
  videocards: "videocards",
}

export const heights = {
  small: { xl: "40vh", l: "40vh", m: "40vh", s: "70vw", xs: "70vw" },
  medium: { xl: "60vh", l: "60vh", m: "60vh", s: "90vw", xs: "90vw" },
  min: { small: "300px", medium: "400px" },
}

const Carol = ({
  type,
  overtitle,
  title,
  subtitle,
  textHeading,
  paragraph,
  subtitleLink,
  backgroundColor,
  marginLeft,
  monoSize,
  height,
  className,
  startSlide,
  focusedFade,
  onFocusClick,
  ctaList,
  isInPanel,
  indexTabbed,
  ips,
  columnsSize,
  pagination,
  scrollbar,
  arrows,
  ariaLabel,
  children,
}) => {
  const { winWidth, direction } = useCarol()

  const [mode, setMode] = useState(
    isInPanel ? CAROL_TYPE.menupanel : type === CAROL_TYPE.caroller ? (winWidth.get() <= 767 ? CAROL_TYPE.roller : CAROL_TYPE.carousel) : type
  )

  const updateTheme = useCallback(bgc => (bgc === "dark" ? CfgLightTheme : CfgDarkTheme), [])

  const [theme, setTheme] = useState(updateTheme(backgroundColor))

  useEffect(() => {
    setTheme(updateTheme(backgroundColor))
  }, [backgroundColor])

  const onWinWidthChange = w => {
    if (type === CAROL_TYPE.caroller && !isInPanel) {
      setMode(w <= 767 ? CAROL_TYPE.roller : CAROL_TYPE.carousel)
    }
  }
  useEffect(() => winWidth.onChange(onWinWidthChange))

  return (
    <ThemeProvider theme={theme}>
      <ViewContainer mode={mode} className={className} direction={direction}>
        {mode === CAROL_TYPE.configurator ? (
          <ConfiguRoller
            type={mode}
            startSlide={startSlide}
            focusedFade={focusedFade}
            onFocusClick={onFocusClick}
            direction={direction}
            indexTabbed={indexTabbed}
            columnsSize={columnsSize}
          >
            {children}
          </ConfiguRoller>
        ) : mode === CAROL_TYPE.collection ? (
          <CollectionRoller
            type={mode}
            startSlide={startSlide}
            focusedFade={focusedFade}
            onFocusClick={onFocusClick}
            direction={direction}
            indexTabbed={indexTabbed}
            columnsSize={columnsSize}
          >
            {children}
          </CollectionRoller>
        ) : null}
      </ViewContainer>
    </ThemeProvider>
  )
}

const ViewContainer = ({ mode, className, children }) => {
  const { winWidth, scrollWidth, ofsWidth /*, focused*/, visiblePercent, ref } = useCarol()

  const viewRef = useRef(null)
  /*
  const [{ focusSlide }, dispatchFocus] = useCarolStateValue()

  const onFocusedChange = f => {
    if (focusSlide !== f && mode === CAROL_TYPE.configurator) dispatchFocus({ focusSlide: f })
  }
  useEffect(() => focused.onChange(onFocusedChange))
*/
  const updateStripValues = () => {
    if (!ref.current) return
    scrollWidth.set(ref.current.scrollWidth)
    ofsWidth.set(ref.current.clientWidth)
    visiblePercent.set(ref.current.clientWidth / ref.current.scrollWidth)
  }

  const onWinWidthChange = () => visiblePercent.set(ref.current.clientWidth / ref.current.scrollWidth)
  useEffect(() => {
    const unSub = winWidth.onChange(onWinWidthChange)
    onWinWidthChange()
    return () => unSub()
  })

  const onUpdate = () => updateStripValues()

  useEffect(() => {
    const stripRef = ref.current
    const observers = []

    ref.current.childNodes.forEach(item => {
      const resizeObserver = process.browser ? (window.ResizeObserver ? new ResizeObserver(onUpdate) : new ResizeObserverPoly(onUpdate)) : null

      observers.push(resizeObserver)

      resizeObserver && resizeObserver.observe(item instanceof window.Element ? item : document.createElement("div"))
    })

    visiblePercent.set(ref.current.clientWidth / ref.current.scrollWidth)

    stripRef.addEventListener("load", onUpdate, { capture: true })
    return () => {
      observers.forEach(obs => obs.disconnect())
      stripRef.removeEventListener("load", onUpdate, { capture: true })
    }
  }, [ref])

  return (
    <View ref={viewRef} type={mode} className={className}>
      {children}
    </View>
  )
}

export default props => (
  <CarolStateProvider>
    <Carol {...props} />
  </CarolStateProvider>
)
