export default function localeToSolr(code) {
  switch (code) {
    case "pt-BR":
      return "pt_br"
    case "zh-Hans":
      return "zh_cn"
    case "zh-Hant":
      return "zh_tw"
    case "en-us":
      return "en_us"
    default:
      return code
  }
}
