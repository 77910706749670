import React from "react"
import { match } from "path-to-regexp"
import * as reactDOM from "react-dom/client"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"
import { LastLocationProvider } from "react-router-last-location"
import { HelmetProvider } from "react-helmet-async"
import { OverlayProvider } from "@react-aria/overlays"
import "focus-visible"
import Console, { levels } from "components/Console"
import Akamai from "components/Akamai"
import Locale from "components/Locale"
import I18N from "components/I18N"
import Helmet from "components/Helmet"
import Theme from "components/Theme"
import Navigation from "components/Navigation"
import WebSocket from "components/WebSocket"
import Layout from "components/Layout"
import Pairing from "components/v2/pairing/Pairing"
import Prospect from "components/v2/prospect/Prospect"
import * as serviceWorker from "./serviceWorker"
import { ConfiguratorProvider, ConfiguratorActive } from "components/configurator/Configurator"
import Suspender from "components/Suspender"
import WA from "components/v2/WA/WA"
import Env from "components/v2/env/Env"

async function main() {
  const hydrate = document.querySelector("meta[name=hydrate]")

  const { app } = match("/:app/:locale?", { end: false })(global.location.pathname)?.params ?? {}

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  // if (process.env.NODE_ENV === "development")
  serviceWorker.unregister({ app })
  // else serviceWorker.register({ app })

  const { forceLocaleInURL, locales, dictionaries, metas, rootFontSize, themes, layouts, pages, websocket, env } =
    JSON.parse(document.querySelector("#__INITIAL_STATE")?.textContent ?? null) ??
    (await Promise.all([
      fetch(`/${app}.model`).then(response => response.json()),
      fetch(`${global?.location?.pathname}.model`).then(response => response.json()),
    ]).then(([app, { id: pageId, layout }]) => {
      const page = app.pages.find(({ id }) => id === pageId)
      if (page) Object.assign(page, { layout, fetch: true })
      return app
    }))

  const akamai = await fetch("https://www.rolex.com/ak-getreqcontext.json")
    .then(response => response.json())
    .catch(err => {
      console.error(err)
      return { reqcontext: {} }
    })
    .then(({ reqcontext }) => reqcontext)

  if (process.env.NODE_ENV === "development")
    console.debug("initialState, %o", { forceLocaleInURL, locales, dictionaries, metas, rootFontSize, themes, layouts, pages, websocket })

  const App = (
    <HelmetProvider>
      <Console level={process.env.LOG_LEVEL ?? "verbose"}>
        <Env env={env}>
          <Suspender>
            <Akamai reqcontext={akamai} location={null}>
              <WebSocket id={app} connector={websocket}>
                <ConfiguratorProvider>
                  <BrowserRouter>
                    <LastLocationProvider>
                      <ConfiguratorActive>
                        <Locale schema={`/${app}/:locale`} locales={locales}>
                          <WA>
                            <Navigation app={app} home={`/${app}`} forceLocaleInURL={forceLocaleInURL} pages={pages}>
                              <I18N dictionaries={dictionaries}>
                                <Helmet app={app} metas={metas}>
                                  <Theme rootFontSize={rootFontSize} themes={themes}>
                                    <Pairing>
                                      <Prospect>
                                        <React.StrictMode>
                                          <OverlayProvider>
                                            <Layout layouts={layouts} />
                                          </OverlayProvider>
                                        </React.StrictMode>
                                      </Prospect>
                                    </Pairing>
                                  </Theme>
                                </Helmet>
                              </I18N>
                            </Navigation>
                          </WA>
                        </Locale>
                      </ConfiguratorActive>
                    </LastLocationProvider>
                  </BrowserRouter>
                </ConfiguratorProvider>
              </WebSocket>
            </Akamai>
          </Suspender>
        </Env>
      </Console>
    </HelmetProvider>
  )

  let target = document.querySelector(hydrate ? hydrate.getAttribute("content") : "#root")
  if (process.env.NODE_ENV === "production" && hydrate) reactDOM.hydrateRoot(target, App)
  else {
    const root = reactDOM.createRoot(target)
    root.render(App)
  }

  target = null
}

main()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
