import React, { useState, useEffect, useCallback, useRef, memo, useLayoutEffect } from "react"
import { Main, Title, List } from "./styles"
import { useWatchVariations, mvSubscribe } from "contexts/Configurator"
import { STEPS, IN_EASE, OUT_EASE, OUT_DURATION, INOUT_DURATION, LAUNCH_ROUTE } from "./../constants"
import { useMotionValue } from "framer-motion"
import { logmine, cssApply } from "./../utils"
import { VarNavRoller, VarNavStatic } from "./../VarNavRoller"
import { useTranslations } from "contexts/I18N"

const VARIANTS = {
  main: {
    hidden: [
      [
        "transition",
        `opacity ${INOUT_DURATION[1]}ms cubic-bezier(${OUT_EASE}),
        visibility 0ms ${INOUT_DURATION[1]}ms, height 0ms ${INOUT_DURATION[1]}ms`,
      ],
      ["opacity", 0],
      //      ["height", 0],
      ["flex", "none"],
      ["visibility", "hidden"],
    ],
    visible: [
      [
        "transition",
        `opacity ${INOUT_DURATION[0]}ms cubic-bezier(${IN_EASE}),
        visibility 0ms 0ms, height 0ms 0ms`,
      ],
      ["visibility", "inherit"],
      //      ["height", "100%"],
      ["flex", 1],
      ["opacity", 1],
    ],
  },
}

function useItems(collection, variations, varexpanded, stepindex, mode, familylabel, filtered, labels) {
  //logmine("CustomHook", "VarNav", "useItems")
  const [items, setItems] = useState(null)
  const [truevariations, setTruevariations] = useState(filtered ? null : variations)
  const tmRef = useRef(null)
  const translations = useTranslations()

  const update = useCallback(
    v => {
      //logmine("Callback", "VarNav", "useItems", "update")
      if (!truevariations) return
      clearTimeout(tmRef.current)
      let _items = truevariations[+v].split(",").map(item => {
        //        console.log(item)
        //        let _item = item === "size" ? "model" : item
        return {
          uid: item,
          key: `WVV_${item}`,
          label: translations[`facet_${item}`],
          pos: STEPS.indexOf(item),
        }
      })
      if (mode !== "cover" || !truevariations[1]) return setItems(_items)
      const buttonkey = v ? "replay" : "plus"
      _items = _items.concat([
        {
          key: `WVV_${buttonkey}`,
          uid: buttonkey,
          label: v ? "Back to Model variations" : `Open ${familylabel} configurator`,
          pos: -1,
        },
      ])
      tmRef.current = setTimeout(() => {
        setItems(_items)
      }, OUT_DURATION * 1.2)
    },
    [truevariations, mode, familylabel, labels]
  )

  const stepindexUpdate = useCallback(
    v => {
      if (v < 0) setItems(null)
      else if (!items) update(varexpanded.get())
    },
    [items, update, varexpanded]
  )

  const filterUnused = useCallback(
    v => {
      if (!v || !variations || !filtered) return
      setTruevariations(variations.map(filter))
      update(varexpanded.get())

      function filter(variation) {
        return variation
          .split(",")
          .reduce((a, variation) => {
            let found = v.filter(item => !!item.vars[variation])
            if (!!found.length) a.push(variation)
            return a
          }, [])
          .join(",")
      }
    },
    [variations, varexpanded, update, filtered]
  )

  useEffect(() => mvSubscribe("VarNav useItems", collection, filterUnused, false), [collection, filterUnused])
  useEffect(() => mvSubscribe("VarNav useItems", varexpanded, update), [varexpanded, update])
  useEffect(() => mvSubscribe("VarNav useItems", stepindex, stepindexUpdate), [stepindex, stepindexUpdate])

  return [items, truevariations]
}

function useDisplay(route, busy, varexpanded, ref) {
  //logmine("CustomHook", "VarNav", "useDisplay")
  const busyUpdate = useCallback(
    v => {
      //logmine("Callback", "VarNav", "busyUpdate", v)
      const _route = route.get()
      if (!v && !!_route && _route !== LAUNCH_ROUTE) cssApply(ref.current, VARIANTS.main.visible)
    },
    [route, ref]
  )

  const routeUpdate = useCallback(
    v => {
      //logmine("Callback", "VarNav", "routeUpdate", v)
      if (!v || v === LAUNCH_ROUTE) cssApply(ref.current, VARIANTS.main.hidden, true)
    },
    [ref]
  )

  const varexpandedUpdate = useCallback(
    v => {
      //logmine("Callback", "VarNav", "useDisplay varexpandedUpdate", v)
      cssApply(ref.current, VARIANTS.main.hidden)
    },
    [ref]
  )

  useEffect(() => mvSubscribe("VarNav useDisplay", busy, busyUpdate), [busy, busyUpdate])
  useEffect(() => mvSubscribe("VarNav useDisplay", route, routeUpdate), [route, routeUpdate])
  useEffect(() => mvSubscribe("WatchRoller useDisplay", varexpanded, varexpandedUpdate), [varexpanded, varexpandedUpdate])
}

function useDisplayOld(route, busy, varexpanded, ref) {
  //logmine("CustomHook", "VarNav", "useDisplay")
  const busyUpdate = useCallback(
    v => {
      //logmine("Callback", "VarNav", "busyUpdate", v)
      const _route = route.get()
      if (!v && !!_route && _route !== LAUNCH_ROUTE) cssApply(ref.current, VARIANTS.main.visible)
    },
    [route, ref]
  )

  const routeUpdate = useCallback(
    v => {
      //logmine("Callback", "VarNav", "routeUpdate", v)
      if (!v || v === LAUNCH_ROUTE) cssApply(ref.current, VARIANTS.main.hidden, true)
    },
    [ref]
  )

  const varexpandedUpdate = useCallback(
    v => {
      //logmine("Callback", "VarNav", "useDisplay varexpandedUpdate", v)
      cssApply(ref.current, VARIANTS.main.hidden)
    },
    [ref]
  )

  useEffect(() => mvSubscribe("VarNav useDisplay", busy, busyUpdate), [busy, busyUpdate])
  useEffect(() => mvSubscribe("VarNav useDisplay", route, routeUpdate), [route, routeUpdate])
  useEffect(() => mvSubscribe("WatchRoller useDisplay", varexpanded, varexpandedUpdate), [varexpanded, varexpandedUpdate])
}

export const VarNavOld = memo(({ familylabel, filtered = true }) => {
  //logmine("Main", "VarNav")
  const { mode, route, reset, collection, variations, varexpanded, stepindex, newstepindex, loadQ, initQ, busy, fromui, wactive, labels, reversed } =
    useWatchVariations()
  const readyRef = useRef(null)
  const [items, truevariations] = useItems(collection, variations, varexpanded, stepindex, mode, familylabel, filtered, labels)
  const load = useMotionValue(false)
  const ref = useRef()
  const expandRef = useRef(null)
  const rflist = useRef()

  const focus = () => {
    const col = [...ref.current.querySelectorAll("li")]
    let selected = -1
    const scrollit = col.map((item, i) => {
      if (item.getAttribute("aria-selected") === "true") selected = i
      return item.offsetLeft
    })
    if (selected < 0) return 0
    return scrollit[selected] - scrollit[0]
  }

  const display = useCallback(() => {
    //logmine("Callback", "VarNav", "display")
    //    ref.current.scrollLeft = 0
    let x = focus()
    const { current: list } = rflist
    if (!!list)
      list.scrollTo({
        top: 0,
        left: x,
        behavior: "smooth",
      })
    cssApply(ref.current, VARIANTS.main.visible)
    //    if (reversed.get()) ref.current.scrollTo(ref.current.offsetWidth, 0)
    //    reversed.set(false)
  }, [ref, reversed])

  const handleReady = useCallback(
    step => {
      //logmine("useEffect", "VarNav", "handleReady", step, JSON.parse(JSON.stringify(readyRef.current)))
      //    if (!readyRef.current.length) return setReady(true)
      if (!readyRef.current) return
      const index = readyRef.current.indexOf(step)
      if (~index) readyRef.current.splice(index, 1)
      if (!readyRef.current.length) {
        loadQ(4)
        display()
        load.set(false)
      }
    },
    [loadQ, load, display, readyRef]
  )

  const handleSwap = useCallback(
    e => {
      //logmine("Callback", "VarNav", "handleSwap")
      const state = varexpanded.get()
      const _reset = reset.get()
      fromui.set(true)
      route.set(state ? _reset : expandRef.current)
    },
    [route, reset, varexpanded, fromui]
  )

  const update = useCallback(
    v => {
      //logmine("Callback", "VarNav", "update")
      cssApply(ref.current, VARIANTS.main.hidden)
      if (!truevariations) return
      readyRef.current = truevariations[+varexpanded.get()].split(",")
      initQ(4)
    },
    [initQ, readyRef, truevariations, varexpanded]
  )

  const newstepindexUpdate = useCallback(
    v => {
      //logmine("Callback", "VarNav", "newstepindexUpdate")
      if (v <= 0 || !truevariations) return
      readyRef.current = truevariations[+varexpanded.get()].split(",")
      initQ(4)
    },
    [initQ, readyRef, truevariations, varexpanded]
  )

  const wactiveUpdate = useCallback(
    v => {
      //logmine("Callback", "VarNav", "wactiveUpdate")
      if (!v) return
      const _stepindex = stepindex.get()
      if (_stepindex < 0) return
      expandRef.current = `/${v.uid}/${STEPS[_stepindex]}&full`
    },
    [stepindex, expandRef]
  )

  const stepindexUpdate = useCallback(
    v => {
      //logmine("Callback", "VarNav", "wactiveUpdate")
      const _wactive = wactive.get()
      if (!_wactive) return
      if (v < 0) loadQ(4)
      expandRef.current = `/${_wactive.uid}/${STEPS[v]}&full`
    },
    [wactive, expandRef, loadQ]
  )

  useEffect(() => {
    newstepindexUpdate(newstepindex.get())
  }, [truevariations, newstepindex, newstepindexUpdate])

  useEffect(() => {
    //logmine("useEffect", "VarNav", "items")
    const _route = route.get()
    if (!_route) return
    if (!items) return loadQ(4)
    ref.current.scrollLeft = 0
    load.set(true)
  }, [items, load, loadQ, route])

  useEffect(() => mvSubscribe("VarNav", varexpanded, update, false), [varexpanded, update])
  useEffect(() => mvSubscribe("VarNav", newstepindex, newstepindexUpdate, false), [newstepindex, newstepindexUpdate])
  useEffect(() => mvSubscribe("VarNav", stepindex, stepindexUpdate, false), [stepindex, stepindexUpdate])
  useEffect(() => mvSubscribe("VarNav", wactive, wactiveUpdate, false), [wactive, wactiveUpdate])

  useDisplay(route, busy, varexpanded, ref, readyRef)

  return (
    <Main tabIndex='-1' aria-live='polite' ref={ref}>
      <List ref={rflist} role='status'>
        {!!items && /*[<VarNavStatic />].concat(*/ items.map(item => <VarNavRoller {...item} load={load} handleReady={handleReady} />) /*)*/}
      </List>
    </Main>
  )
})

export const VarNav = memo(({ familylabel, filtered = true }) => {
  const { mode, route, reset, collection, variations, varexpanded, stepindex, newstepindex, loadQ, initQ, busy, fromui, wactive, labels, reversed } =
    useWatchVariations()
  const [items, truevariations] = useItems(collection, variations, varexpanded, stepindex, mode, familylabel, filtered, labels)
  const ref = useRef()
  const rflist = useRef()
  const translations = useTranslations()

  const focus = () => {
    const col = [...ref.current.querySelectorAll("li")]
    let selected = -1
    const scrollit = col.map((item, i) => {
      if (item.getAttribute("aria-selected") === "true") selected = i
      return item.offsetLeft
    })
    if (selected < 0) return 0
    return scrollit[selected] - scrollit[0]
  }

  const display = useCallback(() => {
    //logmine("Callback", "VarNav", "display")
    //    ref.current.scrollLeft = 0
    let x = focus()
    const { current: list } = rflist
    if (!!list)
      list.scrollTo({
        top: 0,
        left: x,
        behavior: "smooth",
      })
    //    cssApply(ref.current, VARIANTS.main.visible)
    //    if (reversed.get()) ref.current.scrollTo(ref.current.offsetWidth, 0)
    //    reversed.set(false)
  }, [])

  useLayoutEffect(() => {
    if (!items) return
    const { current: list } = rflist
    if (!list) return
    display()
  }, [items])

  /*
  useEffect(() => {
    //logmine("useEffect", "VarNav", "items")
    const _route = route.get()
    if (!_route) return
    //    if (!items) return loadQ(4)
    //    ref.current.scrollLeft = 10000
    //    load.set(true)
  }, [route])
*/
  useDisplay(route, busy, varexpanded, ref)

  return (
    <Main tabIndex='-1' aria-live='polite' ref={ref}>
      <Title>{translations["select-feature"]}</Title>
      <List ref={rflist} role='status'>
        {!!items && items.map(item => <VarNavStatic {...item} />)}
      </List>
    </Main>
  )
})

VarNav.propTypes = {}
