import { useConsole } from "contexts/Console"
import { useNavigation } from "contexts/Navigation"

export default function useFeatures(family, rmc) {
  const console = useConsole()
  const navigation = useNavigation()

  const host = process.browser ? `${global.location.protocol}//${global.location.hostname}` : `http://${process.env.SERVICE_NGINX}`
  const pathname = `${navigation.localize("/").url}/features/${family}/${rmc}`

  return (handler = Function.prototype) => {
    const url = new URL(pathname, host)

    const controller = new AbortController()
    const signal = controller.signal
    fetch(new Request(url), { signal })
      .then(response => response.json())
      .then(data => handler(null, data))
      .catch(err => {
        if (err.name === "AbortError") return
        handler(err, null)
      })

    console.verbose("useFeatures(%o) => fetch", { url })
    return () => {
      controller.abort()
    }
  }
}
