import { useConsole } from "contexts/Console"
import useConstant from "utils/useConstant"
import { motion } from "framer-motion"

function Source({ id, media: baseMedia, min, max, landscape, portrait, srcset = [] }) {
  const media = useConstant(() => {
    const mediaParts = [baseMedia].filter(Boolean)
    if (typeof landscape === "boolean" && landscape) mediaParts.push("(min-aspect-ratio: 1/1)")
    if (typeof portrait === "boolean" && portrait) mediaParts.push("(max-aspect-ratio: 1/1)")
    if (min) mediaParts.push(`(min-width: ${min}px)`)
    if (max) mediaParts.push(`(max-width: ${max}px)`)
    return mediaParts.join(" and ")
  })
  const sources = useConstant(() => srcset.map(({ url, width }, i) => `${url} ${width}w`).join(", "))
  return <source key={id} media={media} srcSet={sources} />
}

export default function Picture({ className, variants, initial, animate, sources = [], sizes = "100vw", lazy = false }) {
  const console = useConsole()

  console.verbose("Picture(%o)", { sources, lazy })
  return (
    <motion.picture className={className} variants={variants} initial={initial} animate={animate}>
      {sources.map((source, i) => (
        <Source key={i} {...source} />
      ))}
      <img alt={sources?.[0]?.srcset?.[0]?.alt ?? ""} sizes={sizes} loading={lazy ? "lazy" : null} />
    </motion.picture>
  )
}
