import { forwardRef, useImperativeHandle, useLayoutEffect, useRef } from "react"
import { useConsole } from "contexts/Console"
import useConstant from "utils/useConstant"
import EventTarget from "@ungap/event-target"
import { useWA } from "contexts/WA"

function Video({ source, mime = "video/mp4", poster, autoplay = null, loop = false, controls = false, playsInline = true, fullscreen = false }, handle) {
  const console = useConsole()
  const rvideo = useRef()
  const wa = useWA()

  const wastats = useRef({
    milestones: [false, false, false],
  })

  const et = useConstant(() => new EventTarget())
  const addEventListener = (...args) => et.addEventListener(...args)
  const removeEventListener = (...args) => et.removeEventListener(...args)

  const canplay = {
    addCanPlayThroughListener: (...args) => rvideo.current.addEventListener("canplay", ...args),
    removeCanPlayThroughListener: (...args) => rvideo.current.removeEventListener("canplay", ...args),
  }

  const canplaythrough = {
    addCanPlayThroughListener: (...args) => rvideo.current.addEventListener("canplaythrough", ...args),
    removeCanPlayThroughListener: (...args) => rvideo.current.removeEventListener("canplaythrough", ...args),
  }

  const ended = {
    addEndedListener: (...args) => rvideo.current.addEventListener("ended", ...args),
    removeEndedListener: (...args) => rvideo.current.removeEventListener("ended", ...args),
  }

  const visibility = {
    // TODO
    observe: () =>
      new IntersectionObserver(([{ isIntersecting }]) => {
        if (isIntersecting) {
          console.verbose("Intersecting:Video(%o)", { source })
        }
      }).observe(rvideo.current),
    unobserve: () =>
      new IntersectionObserver(([{ isIntersecting }]) => {
        if (!isIntersecting) {
          console.verbose("Not intersecting:Video(%o)", { source })
        }
      }).unobserve(rvideo.current),
  }

  const ctx = {
    addEventListener,
    removeEventListener,
    canplay,
    canplaythrough,
    ended,
    visibility,
    get node() {
      return rvideo.current
    },
    play: ({ rewind } = {}) => {
      if (rewind) ctx.rewind()
      rvideo.current.play()
    },
    pause: () => {
      rvideo.current.pause()
    },
    togglePlayPause: () => {
      if (rvideo.current.paused) ctx.play()
      else ctx.pause()
    },
    stop: () => {
      ctx.pause()
      ctx.rewind()
    },
    rewind: () => {
      rvideo.current.currentTime = 0
    },
    requestFullscreen: () => {
      if (rvideo.current.requestFullscreen) rvideo.current.requestFullscreen()
      else if (rvideo.current.webkitEnterFullScreen) rvideo.current.webkitEnterFullScreen()
    },
    exitFullscreen: () => {
      if (document.exitFullscreen) document.exitFullscreen()
      else if (document.webkitExitFullscreen) document.webkitExitFullscreen()
    },
    toggleFullscreen: () => {
      if (!document.fullscreenElement && !document.webkitFullscreenElement) {
        rvideo.current.requestFullscreen()
      } else {
        rvideo.current.exitFullscreen()
      }
    },
    get currentTime() {
      return rvideo.current.currentTime
    },
    get canPlay() {
      return rvideo.current.readyState >= 3
    },
    get canPlayThrough() {
      return rvideo.current.readyState === 4
    },
    get isPlaying() {
      return !!(rvideo.current.currentTime > 0 && !rvideo.current.paused && !rvideo.current.ended && rvideo.current.readyState > 2)
    },
    get isFullscreen() {
      return !!(rvideo.current === document.fullscreenElement || rvideo.current === document.webkitFullscreenElement)
    },
  }
  useImperativeHandle(handle, () => ctx)

  useLayoutEffect(() => {
    const video = rvideo.current
    const stats = wastats.current

    const ontimeupdate = e => {
      const progress = video.currentTime / video.duration

      if (progress < 0.5 && !stats.milestones[0]) {
        stats.milestones[0] = true
        const event = new Event("milestone")
        Object.assign(event, { milestone: "0" })
        et.dispatchEvent(event)
      }

      if (progress >= 0.5 && progress < 1 && !stats.milestones[1]) {
        stats.milestones[1] = true
        const event = new Event("milestone")
        Object.assign(event, { milestone: "50" })
        et.dispatchEvent(event)
      }

      if (progress === 1 && !stats.milestones[2]) {
        stats.milestones[2] = true
        const event = new Event("milestone")
        Object.assign(event, { milestone: "100" })
        et.dispatchEvent(event)
      }

      const event = new Event("timeupdate")
      global.dispatchEvent(event)
    }
    video.addEventListener("timeupdate", ontimeupdate)
    return () => video.removeEventListener("timeupdate", ontimeupdate)
  })

  console.verbose("Video(%o)", { mime, source, poster, autoplay, loop, controls, playsInline, fullscreen })
  return (
    <video
      autoPlay={autoplay}
      controls={controls}
      controlsList='nofullscreen nodownload'
      disablePictureInPicture
      fullscreen={fullscreen.toString()}
      hwz='on' // Brightsign
      loop={loop}
      muted={true}
      playsInline={playsInline}
      poster={poster}
      ref={rvideo}
    >
      <source src={source} type={mime} />
    </video>
  )
}

export default forwardRef(Video)
