import { useState, useCallback, useEffect, useLayoutEffect, useRef } from "react"
import { STEPS, ALWAYSCOMPLETE } from "./constants"
import { upperFirstLetter } from "./utils"
import { useMotionValue } from "framer-motion"
//import { useGlobalStateValue } from "./../../Global/GlobalContext"
import { mvSubscribe } from "contexts/Configurator"
//import useEnv from "./../../Hooks/useEnv"

export const setSource = (variation, pathurl, v) => {
  let url = ""
  let param = ""
  switch (variation) {
    case "dial":
      url = "configurator/raw-dial-with-shadow/"
      break
    case "bezel":
      url = "configurator/bezel-with-shadow/"
      break
    case "bracelet":
      url = "bracelet-highlight-on-full-watch/"
      break
    case "shadow":
      param = "&ch1=1380"
      url = "upright-c-shadow/"
      break
    case "material":
      param = "&g1=Center"
      url = "configurator/material/"
      break
    case "size":
    case "model":
    case "roller":
    case "watch":
      url = "upright-c/"
      break
    default:
      throw new Error("unknown variation")
  }

  return `${pathurl}/${url}${v}.png?impolicy=v7-main-configurator${param}`
}

export const setSourceOld = (variation, pathurl, v) => {
  //  console.log('setSource', variation, v)
  let url = ""
  switch (variation) {
    case "shadow":
      //      url = `/harmonised/upright-watch-shadow/${v}.png?impolicy=main-configurator`
      return v
    case "roller":
    case "family":
    case "model":
      url = `/upright-cc/${v}.png?impolicy=main-configurator`
      break
    /*    case "family":
    case "model":
      url = `/harmonised/upright-watch-shadow/${shadow}.png?impolicy=v6-configurator&c1path=/dam/2022/upright-cc/${v}.png`
      break*/
    case "material":
      url = `/material/${v}.png?impolicy=main-configurator`
      break
    case "bezel":
      url = `/harmonised/bezel-with-colored-shadow/${v}.png?impolicy=main-configurator`
      break
    case "bracelet":
      url = `/harmonised/bracelet-highlight-on-full-watch/${v}.png?impolicy=main-configurator`
      break
    case "dial":
      url = `/harmonised/dial-raw-with-colored-shadow/${v}.png?impolicy=main-configurator`
      break
    case "chromalight":
      url = `/chromalight/${v}.png?impolicy=main-configurator`
      break
    default:
      throw new Error("unknown variation")
  }
  return `${pathurl}${url}`
  //  return `${pathurl.replace('rlx-uat', 'rolex')}${url}`
}

const mapSolrVariations = item => {
  return ["model", "size", "material", "bezel", "bracelet", "dial"].reduce((a, v) => {
    let vitem = item[`variations_${v}`]
    const o = Object.create(null)
    if (!!vitem && vitem.length > 1) o[v] = vitem.join(",")
    return Object.assign(a, o)
  }, Object.create(null))
}
/*
const mapSolrVariationsOld = (item, datapatch) => {
  return ["model", "material", "bezel", "bracelet", "dial"].reduce((a, v) => {
    const patch = !!datapatch&&datapatch[item.rmc]
    let vitem = !!patch && patch[`variations_${v}`]
    vitem = !!vitem && vitem.split(',')
    vitem = vitem || item[`variations_${v}`]
    const o = Object.create(null)
    if (!!vitem && vitem.length > 1) o[v] = vitem.join(",")
    return Object.assign(a, o)
  }, Object.create(null))
}
*/
const buildJourneyByStep = (stepindex, vars) => {
  const updateJourneyState = (a, v) => {
    let does_exist = !!vars[v]
    let is_alone = does_exist && !~vars[v].indexOf(",")
    return !!a && !!a.length ? a : does_exist && !is_alone ? v : ""
  }

  let nextlist = STEPS.slice(stepindex + 1)
  let prevlist = STEPS.slice(0, stepindex)
  prevlist.reverse()

  return {
    prev: prevlist.reduce(updateJourneyState, "") || "family",
    next: nextlist.reduce(updateJourneyState, "") || "family",
  }
}

const buildJourney = item => {
  const res = {
    prev: [],
    next: [],
    sindex: [],
  }
  let journey = {}
  let sindex = 0
  let last = 0
  let next = 0

  STEPS.map((v, i) => {
    journey = buildJourneyByStep(i, item.vars)
    res.prev.push(STEPS.indexOf(journey.prev))
    res.next.push(STEPS.indexOf(journey.next))
  })

  res.smap = STEPS.reduce((a, v) => {
    //    const _v = v === "model" ? "size" : v
    let does_exist = item.vars[v]
    //   if (!does_exist) does_exist = item.vars[_v]
    let is_alone = !!does_exist && !~does_exist.indexOf(",")
    return [a, +(!!does_exist && !is_alone)].join("")
  }, "")

  res.sindex = res.next.map(v => {
    next = v > last ? sindex++ : sindex
    last = v
    return next
  })

  return { ...item, ...res }
}

const mapSolrResults = pathname => item => {
  const specs = item.watch.docs[0]

  return buildJourney({
    uid: specs.rmc,
    family: specs.family,
    familyCode: specs.familyCode,
    name: specs.name,
    nameCode: specs.nameCode,
    category: (specs.facet_global_category || []).join(""),
    vars: Object.assign(mapSolrVariations(item), { discover: specs.rmc }),
    specs: [
      specs.spec_case,
      specs.family,
      specs.name,
      specs.spec_diameter,
      specs.spec_material,
      specs.spec_bezel,
      specs.spec_bracelet,
      specs.spec_dial,
      pathname.replace(/\/watches\/.*$/, `/watches/${specs.familyCode}/${specs.rmc}`),
      //      pathname.replace(/\/[^/.]*\.html$/, ["/watches/", specs.familyCode, "/", specs.rmc, ".html"].join("")),
      specs.newmodelselection,
    ],
    assets: {
      model: specs.rmc,
      size: specs.rmc,
      bezel: specs.part_case_id,
      dial: specs.part_dial_file_name,
      material: specs.part_material_id,
      shadow: specs.rmc,
      bracelet: specs.rmc,
    },
  })
}

export const setPicture = (src, uid, caption) => {
  //  console.log('setPicture', src)
  const islocal = src.indexOf("http") < 0
  return {
    uid: uid,
    caption: caption && upperFirstLetter(caption),
    alt: "",
    src_1x: islocal ? src : `${src}&imwidth=800`,
    src_2x: islocal ? src : `${src}&imwidth=800`,
  }
}

export const useImageLoader = (uid, ref, list, domdelay = 40, displaydelay = 40) => {
  const readyRef = useRef([])
  const ready = useMotionValue(false)
  const tmRef = useRef(null)

  const end = useCallback(() => {
    clearTimeout(tmRef.current)
    tmRef.current = setTimeout(() => {
      ready.set(true)
    }, displaydelay)
  }, [])

  const onload = useCallback(e => {
    //    if (!readyRef.current.length) return
    const i = e.target.getAttribute("data-loadindex")
    const index = readyRef.current.indexOf(i)
    if (!!~index) readyRef.current.splice(index, 1)
    e.target.removeAttribute("data-loadindex")
    if (!readyRef.current.length) end()
  }, [])

  useLayoutEffect(() => {
    if (!process.browser || !ref) return
    clearTimeout(tmRef.current)
    const { current: root } = ref
    if (!root) return
    ready.set(false)
    if (!list || !list.length) return

    let tm = setTimeout(() => {
      readyRef.current = [...root.querySelectorAll("img")]
        .filter(el => (ALWAYSCOMPLETE && !el.width) || !el.complete)
        .map((el, i) => {
          el.setAttribute("data-loadindex", `${uid}_${i}`)
          return el
        })
        .map(el => el.getAttribute("data-loadindex"))

      if (!readyRef.current.length) {
        end()
      } else {
        root.addEventListener("load", onload, true)
        root.addEventListener("error", onload, true)
      }
    }, domdelay)

    return () => {
      clearTimeout(tm)
      root.removeEventListener("load", onload)
      root.removeEventListener("error", onload)
    }
  }, [ref, list, domdelay])

  return ready
}
/*
export const useData = (config, dict) => {
  const [ready, setReady] = useState(false)
  const [{ lang, codeLang, AEMdata, labels }] = useGlobalStateValue()

  useEffect(
    () => {
      if (!lang||!codeLang||!AEMdata||!labels||!Object.keys(labels).length) return
      config.set({
        lang: lang.toLowerCase(),
        codeLang: codeLang.toLowerCase(),
        imagesPath: AEMdata.watchesImagesUrl,
        requestPath: AEMdata.filtersServiceUrl,//"https://search.rolex.com/solr/rolexcom",
        credentials: !!AEMdata.withCredentials,
        imagesUrl: AEMdata.imagesUrl
      })
      dict.set(labels)
      setReady(true)
    }
  , [lang, codeLang, AEMdata, labels, config, dict])

  return ready
}
*/
export const useMediaLoader = (uid, ref, list, video = false, domdelay = 40, displaydelay = 200) => {
  const readyRef = useRef([])
  const ready = useMotionValue(false)
  const tmRef = useRef(null)

  const end = useCallback(() => {
    clearTimeout(tmRef.current)
    tmRef.current = setTimeout(() => {
      ready.set(true)
    }, displaydelay)
  }, [ready, displaydelay])

  const onload = useCallback(
    e => {
      //    if (!readyRef.current.length) return
      const i = e.target.getAttribute("data-loadindex")
      const index = readyRef.current.indexOf(i)
      if (!!~index) readyRef.current.splice(index, 1)
      e.target.removeAttribute("data-loadindex")
      if (!readyRef.current.length) end()
    },
    [uid, end]
  )

  useLayoutEffect(() => {
    if (!process.browser || !ref) return
    clearTimeout(tmRef.current)
    const { current: root } = ref
    if (!root) return
    ready.set(false)
    if (!list || !list.length) return

    let tm = setTimeout(() => {
      readyRef.current = [...root.querySelectorAll("img")]
        .filter(el => (ALWAYSCOMPLETE && !el.width) || !el.complete)
        .concat(video ? [...root.querySelectorAll("video")] : [])
        .map((el, i) => {
          el.setAttribute("data-loadindex", `${uid}_${i}`)
          return el
        })
        .map(el => el.getAttribute("data-loadindex"))

      if (!readyRef.current.length) {
        end()
      } else {
        root.addEventListener("load", onload, true)
        root.addEventListener("error", onload, true)
        if (video) root.addEventListener("canplaythrough", onload, true)
      }
    }, domdelay)

    return () => {
      clearTimeout(tm)
      root.removeEventListener("load", onload, true)
      root.removeEventListener("error", onload, true)
      if (video) root.removeEventListener("canplaythrough", onload, true)
    }
  }, [uid, ref, list, domdelay, onload, end, ready, video])

  return ready
}

export const useImageLoaderII = (uid, ref, list, domdelay = 40, displaydelay = 100) => {
  const readyRef = useRef([])
  const [ready, setReady] = useState(false)
  const tmRef = useRef(null)

  const end = useCallback(() => {
    clearTimeout(tmRef.current)
    tmRef.current = setTimeout(() => {
      setReady(true)
    }, displaydelay)
  }, [displaydelay])

  const onload = useCallback(
    e => {
      //    if (!readyRef.current.length) return
      const i = e.target.getAttribute("data-loadindex")
      const index = readyRef.current.indexOf(i)
      if (!!~index) readyRef.current.splice(index, 1)
      e.target.removeAttribute("data-loadindex")
      if (!readyRef.current.length) end()
    },
    [uid, end]
  )

  useLayoutEffect(() => {
    if (!process.browser || !ref) return
    clearTimeout(tmRef.current)
    const { current: root } = ref
    if (!root) return
    setReady(false)
    if (!list || !list.length) return

    let tm = setTimeout(() => {
      readyRef.current = [...root.querySelectorAll("img")]
        .filter(el => el.parentNode.parentNode.className.indexOf("image-fallback") < 0)
        .filter(el => (ALWAYSCOMPLETE && !el.width) || !el.complete)
        .map((el, i) => {
          el.setAttribute("data-loadindex", `${uid}_${i}`)
          return el
        })
        .map(el => el.getAttribute("data-loadindex"))

      if (!readyRef.current.length) {
        end()
      } else {
        root.addEventListener("load", onload, true)
        root.addEventListener("error", onload, true)
      }
    }, domdelay)

    return () => {
      clearTimeout(tm)
      root.removeEventListener("load", onload)
      root.removeEventListener("error", onload)
    }
  }, [uid, ref, list, domdelay, onload, end])

  return ready
}

export const useActive = wactive => {
  const [viewlink, setViewlink] = useState("#")

  const update = useCallback(v => {
    if (!v) return
    setViewlink(v.specs[8])
  }, [])

  useEffect(() => mvSubscribe("Hooks useActive", wactive, update), [wactive, update])

  return viewlink
}

export const useRequest = (url, pathname) => {
  //  console.trace("useRequest", query)
  const [results, setResults] = useState(null)
  const controller = new AbortController()

  useEffect(() => {
    if (!url) return setResults(null)

    fetch(new Request(url, { credentials: "include" }), { signal: controller.signal })
      .then(response => response.json())
      .then(data => !!data && setResults(data.response.docs.map(mapSolrResults(pathname))))
      .catch(err => {
        if (err.name === "AbortError") return
        setResults(null)
      })

    return () => {
      controller.abort()
    }
  }, [url])

  return results
}
