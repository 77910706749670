import { createContext, forwardRef, useContext, useImperativeHandle, useState } from "react"
import styled from "@emotion/styled"
import { useHeader } from "contexts/Header"
import useT from "utils/useT"
import { buttonRound, mask } from "css/button"
import { useWebSocket } from "contexts/WebSocket"
import { motion } from "framer-motion"
import { createPortal } from "react-dom"
import useSSR from "utils/useSSR"
import { linearToRight } from "css/gradients"
import { useTranslations } from "contexts/I18N"

const Context = createContext()
export function usePopOver() {
  return useContext(Context)
}

const Root = styled(motion.div)`
  /* &:is(.off) {
    display: none;
  } */
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(--z-over);

  & > div {
    position: fixed;
    right: 8vw;
    top: 1.5rem;
    z-index: 10;
    padding-block-start: env(safe-area-inset-top, 0px);

    & > button {
      ${buttonRound}
      background-color: rgb(var(--background)/0.2);
      pointer-events: auto;

      &::before {
        content: "";
        background: rgb(var(--highlight));
        ${mask('<path d="m17 15 13 13-2 2-13-13-13 13-2-2 13-13-13-13 2-2 13 13 13-13 2 2z" />')}
      }
    }
  }

  &.popover-home-standalone {
    video {
      width: 100%;
      height: 100%;
      /* object-fit: cover; */
      aspect-ratio: 1/1;
    }
  }
`

const container = {
  inactive: {
    opacity: 0,
    x: "100%",
    transition: {
      type: "spring",
      ease: "easeInOut",
      bounce: 0,
      // delay: 0.8,
      duration: 0.5,
    },
  },
  active: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      ease: "easeInOut",
      bounce: 0,
      duration: 0.5,
    },
  },
}
const close = {
  hidden: {
    opacity: 0,
    scale: 0,
    transition: {
      type: "spring",
      ease: "easeInOut",
      // delay: 0,
      duration: 0.8,
    },
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      type: "spring",
      ease: "easeInOut",
      delay: 1,
      duration: 0.8,
    },
  },
}

const portal = process.browser && document.body.appendChild(document.createElement("div"), document.body.children[0])
portal?.classList.add("popover")

function PopOver({ children, className, backgroundColor, id }, handle) {
  const [active, setActive] = useState(false)
  const header = useHeader()
  const expansion = useT({ t: 0 })
  const ws = useWebSocket()
  const ssr = useSSR()
  const translations = useTranslations()

  const ctx = {
    show: () => {
      expansion.t(1)
      // header.expansion.lock(0, id)
      setActive(true)
    },
    hide: () => {
      expansion.t(0)
      // header.expansion.unlock(id)
      setActive(false)
    },
    active,
  }
  useImperativeHandle(handle, () => ctx)

  return (
    !ssr &&
    createPortal(
      <Root
        className={(!!className && className) || "popover"}
        variants={container}
        initial='inactive'
        animate={active ? "active" : "inactive"}
        style={{ background: backgroundColor || `rgb(var(--background))` }}
      >
        <motion.div variants={close} initial='hidden' animate={active ? "visible" : "hidden"}>
          <button onClick={() => ctx.hide()} aria-label={translations["close"]} title={translations["close"]}></button>
        </motion.div>
        <Context.Provider value={ctx}>{children}</Context.Provider>
      </Root>,
      portal
    )
  )
}

export default forwardRef(PopOver)
