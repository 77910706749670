/**
 * don't copy this implementation
 * we use a single source of state without provider
 * to make this context "global"
 */

import { createContext, useContext } from "react"

const Context = createContext()
export const Provider = Context.Provider

export const readyStates = {
  IDLE: 0,
  CHECKING: 1,
  INACTIVE: 2,
  ACTIVE: 3,
}

export function useProspect() {
  return useContext(Context)
}

export function useSync(id) {
  const { sync = () => {} } = useContext(Context) || {}
  return sync(id) || { addEventListener: () => {}, removeEventListener: () => {}, send: () => {} }
}
