import React, { useState, useCallback, useRef, memo, useEffect, useLayoutEffect } from "react"
import { Main, NavButton, NavLink, DiscoverItem, SelectItem, BackItem, ResetItem, LoaderWrapper, BottomNavWrapper, BottomNavSwitch, Sticker } from "./styles"
import { useWatchVariations, mvSubscribe } from "contexts/Configurator"
import { useActive } from "./../hooks"
import { STEPS, INOUT_DURATION, INOUT_EASE, OUT_DURATION, OUT_EASE, IN_EASE, KILL_ROUTE } from "./../constants"
import { BusyLoader } from "./../BusyLoader"
import { logmine, cssApply, cleanRoute } from "./../utils"
import { useTransform } from "framer-motion"
import { useNavigation } from "contexts/Navigation"
import { useTranslations } from "contexts/I18N"
import { WishlistButton } from "./../WishlistButton"
import { useWA } from "contexts/WA"

const VARIANTS = {
  main: {
    hidden: [
      [
        "transition",
        `opacity ${INOUT_DURATION[1]}ms cubic-bezier(${OUT_EASE}),
        visibility 0ms ${INOUT_DURATION[1]}ms`,
      ],
      ["opacity", 0],
      ["visibility", "hidden"],
    ],
    visible: [
      [
        "transition",
        `opacity ${INOUT_DURATION[0]}ms cubic-bezier(${IN_EASE}),
        visibility 0ms 0ms`,
      ],
      ["visibility", "inherit"],
      ["opacity", 1],
    ],
  },
  button: {
    hidden: [
      [
        "transition",
        `opacity ${INOUT_DURATION[1]}ms cubic-bezier(${OUT_EASE}),
        visibility 0ms ${INOUT_DURATION[1]}ms`,
      ],
      ["opacity", 0],
      ["visibility", "hidden"],
    ],
    mask: [
      [
        "transition",
        `opacity ${INOUT_DURATION[1]}ms cubic-bezier(${OUT_EASE}),
        visibility 0ms ${INOUT_DURATION[1]}ms`,
      ],
      ["opacity", 0],
      ["visibility", "hidden"],
    ],
    visible: [
      [
        "transition",
        `opacity ${INOUT_DURATION[0]}ms cubic-bezier(${IN_EASE}) 0ms,
        visibility 0ms 0ms`,
      ],
      ["visibility", "inherit"],
      ["opacity", 1],
    ],
  },
}

const ButtonReset = ({ label, handleClick }) => {
  const { reset, route, fromui, hashing, tint } = useWatchVariations()
  const [theme] = useState(tint.get())
  const [viewlink, setViewlink] = useState("")
  const ref = useRef()
  const translations = useTranslations()

  const update = useCallback(v => {
    setViewlink(v)
  }, [])

  const redirect = useCallback(
    e => {
      e.preventDefault()
      hashing.set(true)
      fromui.set(true)
      route.set(viewlink)
    },
    [fromui, route, viewlink]
  )

  const display = useCallback(([_route, _reset]) => {
    let state = !!_route && !!_reset
    _route = cleanRoute(_route)
    state = state && _route === _reset
    cssApply(ref.current, VARIANTS.button[state ? "hidden" : "visible"])
  }, [])

  useEffect(() => mvSubscribe("BottomNav", reset, update), [reset, update])
  useTransform([route, reset], display)

  return (
    <ResetItem ref={ref}>
      <a href={`#${viewlink}`} onClick={redirect} arai-label={translations["configure-reset"]}>
        <span>{translations["configure-reset"]}</span>
      </a>
    </ResetItem>
  )
}

const ButtonDiscover = () => {
  //logmine("Main", "DiscoverNav", "ButtonDiscover")
  const { wactive, tint, updateDataLayer, puppet } = useWatchVariations()
  const viewlink = useActive(wactive)
  //  const [theme] = useState(tint.get())
  const navigation = useNavigation()
  const translations = useTranslations()
  const isEmbed = navigation.app === "embed"
  const embedViewLink = viewlink.replace("/watches", "")
  const wa = useWA()

  const redirect = useCallback(
    e => {
      e.preventDefault()
      wa.dispatch({
        detail: updateDataLayer({
          triggerName: "configuratorCompleted",
        }),
      })
      navigation.push(isEmbed ? embedViewLink : viewlink)
    },
    [viewlink]
  )

  return puppet ? null : (
    <DiscoverItem>
      <button onClick={redirect} className='wvFocus'>
        <span>{translations["config-discover"]}</span>
      </button>
    </DiscoverItem>
  )
}

function useDisplay(newstepindex, stepindex, ref) {
  //logmine("CustomHook", "BottomNav", "useDisplay")

  const display = useCallback(() => {
    const _newstepindex = newstepindex.get()
    const _stepindex = stepindex.get()
    const state = _newstepindex > 0 && _stepindex > 0
    cssApply(ref.current, VARIANTS.main[state ? "visible" : "hidden"])
  }, [ref])

  useEffect(() => mvSubscribe("BottomNav", stepindex, display), [stepindex, display])
  useEffect(() => mvSubscribe("BottomNav", newstepindex, display), [newstepindex, display])
}

export const BottomNav = () => {
  const { newstepindex, stepindex } = useWatchVariations()
  const ref = useRef()
  const navigation = useNavigation()
  const isEmbed = navigation.app === "embed"

  useDisplay(newstepindex, stepindex, ref)

  return (
    <Main ref={ref} className='wvNav' embed={isEmbed} role='status'>
      <LoaderWrapper>
        <BusyLoader />
      </LoaderWrapper>
      <WishlistButton withLabel={true} />
      <ButtonDiscover />
    </Main>
  )
}
