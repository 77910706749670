import { useConsole } from "contexts/Console"
import { Provider, readyStates } from "contexts/Lobby"
import { startTransition, useLayoutEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/react"
import { useNavigation } from "contexts/Navigation"
import { useWishlist } from "contexts/SelectionSTD"
import Splash from "components/v2/standalone/lobby/Splash"
import { button, mask } from "css/button"
import { headline70, body50, bold } from "css/text"

const blur = keyframes`
  from {
    background: rgb(var(--pure-white) / 0);
    backdrop-filter: blur(0);
    -webkit-backdrop-filter: blur(0);
  }

  to {
    background: rgb(var(--pure-white) / 0.3);
    backdrop-filter: blur(3.5rem);
    -webkit-backdrop-filter: blur(1rem);
  }
`

const Alert = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: calc(var(--z-modal) + 1);
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(var(--pure-white) / 0.7);
  @supports (backdrop-filter: blur(1px)) OR (-webkit-backdrop-filter: blur(1px)) {
    animation: ${blur} 300ms ease 1 forwards;
  }

  color: rgb(var(--pure-white));

  button {
    ${button}
    ${body50}
    ${bold}

    &:first-of-type {
      background: rgb(var(--salem));
      color: rgb(var(--highlight));
      margin-block: 2.5rem 1rem;

      ::before {
        content: "";
        ${mask(
          '<path d="m14.8571429 7.5-5.57142861 5.3571429-1-1 3.78571431-3.64285719h-12.0714286v-1.42857142h12.0714286l-3.78571431-3.64285715 1-1z" />',
          15
        )}

        [dir="rtl"] & {
          transform: scaleX(-1);
        }
      }
    }
    &:last-of-type {
      background: none;
      color: rgb(var(--raisin-black));
    }
  }

  > section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgb(var(--highlight));
    width: 55vw;
    height: 30vh;
    padding-block: 3rem;
    border-radius: 25px;

    > header {
      ${headline70}
      color:rgb(var(--raisin-black));
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`

const timers = {
  activity: 60 * 10 * 1000,
  alert: 60 * 1000,
}

export default function Lobby({ children, ...props }) {
  const console = useConsole()
  const [from, expire] = global.sessionStorage?.getItem?.("activity")?.split?.(",") ?? [readyStates.INACTIVE, 0]
  const [readyState, setReadyState] = useState(() => (Math.max(0, +expire - Date.now()) ? +from : readyStates.INACTIVE))
  const ralert = useRef()
  const navigation = useNavigation()
  const wishlist = useWishlist()

  const downgrade = () =>
    startTransition(() => {
      const next = Math.max(readyStates.INACTIVE, readyState - 1)
      let timeout = Date.now() + timers.alert * next
      console.verbose("Lobby down(%o)", [next, timeout])
      global.sessionStorage.setItem("activity", [next, timeout])

      if (next === readyStates.INACTIVE) reset()

      setReadyState(next)
    })
  const upgrade = () => {
    global.sessionStorage.setItem("activity", [readyStates.ACTIVE, Date.now() + timers.activity])

    if (readyState === readyStates.ACTIVE) setTimer()

    startTransition(() => {
      setReadyState(readyStates.ACTIVE)
    })
  }
  const reset = async () => {
    navigation.push(navigation.localize("/").url)
    wishlist.empty()
  }

  let timer
  const setTimer = () => {
    clearTimeout(timer)
    const [, expire] = global.sessionStorage?.getItem?.("activity")?.split?.(",") ?? [, "0"]
    const timeout = Math.max(0, +expire - Date.now())
    if (timeout) timer = setTimeout(downgrade, timeout)
    console.silly("Lobby:setTimer(%o)", { timeout })
  }
  useLayoutEffect(() => {
    setTimer()
    return () => clearTimeout(timer)
  })

  const ctx = {
    get readyState() {
      return readyState
    },
  }

  useLayoutEffect(() => {
    const onactivity = e => {
      e.stopPropagation()
      if (ralert.current?.contains?.(e.target) || [document.documentElement, document.body].includes(e.target)) return
      upgrade()
    }

    global.addEventListener("touchend", onactivity)
    global.addEventListener("mousemove", onactivity)
    global.addEventListener("keypress", onactivity)
    global.addEventListener("click", onactivity)
    global.addEventListener("timeupdate", onactivity)
    return () => {
      global.removeEventListener("touchend", onactivity)
      global.removeEventListener("mousemove", onactivity)
      global.removeEventListener("keypress", onactivity)
      global.removeEventListener("click", onactivity)
      global.removeEventListener("timeupdate", onactivity)
    }
  })

  console.verbose("Lobby(%o)", { readyState, wishlist, expire, props })
  return (
    <Provider value={ctx}>
      {readyState === readyStates.INACTIVE && <Splash {...props} />}
      {readyState === readyStates.ALERT && (
        <Alert ref={ralert}>
          <section>
            <header>
              <h2>{props.lobbyTitle}</h2>
            </header>
            <div>
              <button onClick={upgrade}>{props.lobbyLabelContinue}</button>
              <button
                onClick={() => {
                  reset()
                  upgrade()
                }}
              >
                {props.lobbyResetSession}
              </button>
            </div>
          </section>
        </Alert>
      )}
      {children}
    </Provider>
  )
}
