export default function Geneve() {
  return (
    <svg width='300' viewBox='0 0 150.09 22.48'>
      <g id='Calque_2' data-name='Calque 2'>
        <g id='Calque_1-2' data-name='Calque 1'>
          <path d='M6.93,4.27h0a2.21,2.21,0,0,1,2,2.14c0,1.77-1.5,2.51-3.46,2.51H0V.22H4.59c2,0,3.46.68,3.46,2.31A1.78,1.78,0,0,1,6.93,4.27ZM4.68,1.48H1.41V4H4.68C6,4,6.57,3.5,6.57,2.73S6,1.48,4.68,1.48ZM1.41,5.13v2.6h4c1.35,0,1.93-.45,1.93-1.28S6.8,5.16,5.45,5.16h-4Z'></path>
          <path d='M11.42,4.59C11.42,1.8,13.66,0,16.52,0s5.1,1.8,5.1,4.59-2.25,4.58-5.1,4.58S11.42,7.41,11.42,4.59Zm8.75,0a3.34,3.34,0,0,0-3.62-3.37,3.37,3.37,0,1,0,0,6.73A3.31,3.31,0,0,0,20.17,4.59Z'></path>
          <path d='M24.57,5.16V.26H26V5.1C26,7,26.94,8,29,8s3-1,3-2.89V.26h1.41v4.9c0,2.73-1.47,4-4.39,4S24.57,7.89,24.57,5.16Z'></path>
          <path d='M39.83,1.48h-4V.26h9.43V1.48h-4V9H39.83Z'></path>
          <path d='M49.23,9H47.82V.26h1.41Z'></path>
          <path d='M56.16,9.08a4.47,4.47,0,0,1-3.85-4.46c0-2.79,2.24-4.59,5.1-4.59s5.1,1.8,5.1,4.59a4.53,4.53,0,0,1-4.24,4.52,9.39,9.39,0,0,0,4.11.67V11A8.87,8.87,0,0,1,56.16,9.08ZM61,4.59a3.63,3.63,0,0,0-7.25,0A3.35,3.35,0,0,0,57.41,8,3.3,3.3,0,0,0,61,4.59Z'></path>
          <path d='M65.55,5.16V.26H67V5.1C67,7,67.92,8,70,8s3-1,3-2.89V.26h1.41v4.9c0,2.73-1.48,4-4.39,4S65.55,7.89,65.55,5.16Z'></path>
          <path d='M77.77.26h8V1.48H79.18v2.4h4.2V5.1h-4.2V7.7h6.58V8.92h-8Z'></path>
          <path d='M96.05,5.55V8.92H94.64V.22h4.59c2,0,3.43.9,3.43,2.51a2.39,2.39,0,0,1-2.25,2.37v0a3.77,3.77,0,0,1,1.45,1.16L104,8.92h-1.76l-1.6-2a3.32,3.32,0,0,0-3.08-1.44H96.05Zm3.11-1.22c1.38,0,2-.64,2-1.44s-.61-1.45-2-1.45H96.05V4.33Z'></path>
          <path d='M105.58,4.59c0-2.79,2.24-4.59,5.09-4.59s5.1,1.8,5.1,4.59-2.24,4.58-5.1,4.58S105.58,7.41,105.58,4.59Zm8.72,0a3.63,3.63,0,0,0-7.25,0A3.35,3.35,0,0,0,110.67,8,3.31,3.31,0,0,0,114.3,4.59Z'></path>
          <path d='M118.82.26h1.41V7.73h6V9h-7.38Z'></path>
          <path d='M128.92.26h8V1.48h-6.55v2.4h4.2V5.1h-4.2V7.7h6.58V8.92h-8V.26Z'></path>
          <path d='M144.57,5.29,140.92,9h-1.77l4.56-4.56L139.6.26h1.86l3.18,3.2,3.2-3.2h1.77l-4.07,4.07,4.55,4.59h-1.86Z'></path>
          <path d='M60.9,21.65c0,.09,0,.16,0,.22s0,.1-.09.1a3.8,3.8,0,0,1-1.93.45A2.56,2.56,0,0,1,57,21.65a2.52,2.52,0,0,1-.61-1.83,2.29,2.29,0,0,1,.77-1.7,2.78,2.78,0,0,1,1.92-.67,2.57,2.57,0,0,1,1.38.35c.1.06.13,0,.16,0l.1-.1a.15.15,0,0,1,.1-.06.08.08,0,0,1,.09.09L61,19.08c0,.07,0,.1,0,.1l-.16,0s-.06,0-.06-.06a2.55,2.55,0,0,0-.55-.93A1.46,1.46,0,0,0,59,17.73a1.4,1.4,0,0,0-1.15.52,2.33,2.33,0,0,0-.55,1.51A2.68,2.68,0,0,0,58,21.62a1.4,1.4,0,0,0,1.09.44A2.25,2.25,0,0,0,60,21.9c.16-.09.16-.25.16-.61v-.48c0-.25-.06-.32-.25-.32h-.36a.06.06,0,0,1-.06-.06v-.16s0-.07,0-.07.42,0,1,0,.8,0,.87,0,.06,0,.06,0v.16s0,.06-.06.06h-.19c-.13,0-.2.06-.2.26v.93Z'></path>
          <path d='M64.88,20.36c0,.36,0,1,0,1.16,0,.35.13.48.26.51a1.59,1.59,0,0,0,.51.07,3,3,0,0,0,.58,0,1,1,0,0,0,.54-.22,4.22,4.22,0,0,0,.61-.71s0,0,.07,0l.09.07s0,0,0,.06l-.32,1a.14.14,0,0,1-.16.1H63.37s-.06,0-.06-.06V22.1s0-.07.06-.07h.23c.32,0,.32,0,.32-.29V19.5a13.3,13.3,0,0,0,0-1.48c0-.19-.09-.22-.28-.22h-.26s0,0,0,0v-.16s0,0,0,0,.38,0,.93,0h2.6s0,0,.06,0,.29,1,.32,1.06,0,.06,0,.06l-.13.07s0,0-.06,0a5.7,5.7,0,0,0-.74-.73.86.86,0,0,0-.51-.16l-1,0v2a5.87,5.87,0,0,0,.77,0c.16,0,.22-.1.32-.26a1.51,1.51,0,0,0,.1-.38.07.07,0,0,1,.06-.07h.16s0,0,0,.07v.73c0,.26,0,.58,0,.74s0,0-.07,0l-.16,0s0,0-.06,0-.07-.25-.1-.32a.47.47,0,0,0-.32-.25,5.44,5.44,0,0,0-.77,0Z'></path>
          <path d='M70.23,19.27c0,1.16.07,2.09.07,2.28s.09.51.32.51h.16c.06,0,.06,0,.06.07v.16s0,.06-.06.06-.48,0-.74,0l-.7,0s0,0,0-.06v-.16s0,0,0,0h.22c.16,0,.26-.16.29-.55,0-.13,0-1.06,0-2.15v-.86c0-.42-.22-.71-.51-.71h-.23a0,0,0,0,1,0,0v-.19s0,0,0,0h1.29a.22.22,0,0,1,.16.07c.06.06,1.38,1.73,1.5,1.92S73.25,21,73.34,21.1h0v-2a8.61,8.61,0,0,0-.07-1,.35.35,0,0,0-.35-.26h-.22s0,0,0-.06v-.16s0-.07.07-.07.29,0,.74,0a5.45,5.45,0,0,0,.64,0c.06,0,.06,0,.06,0v.16s0,.06,0,.06H74c-.13,0-.2.1-.23.35,0,.45-.09,1.58-.09,2.28v1.76c0,.1,0,.2-.1.2s-.16-.07-.23-.1-.41-.48-.83-1-1-1.22-1.09-1.32-1.15-1.44-1.25-1.54h0v.8Z'></path>
          <path d='M77.77,20.36c0,.36,0,1,0,1.16,0,.35.13.48.26.51a1.69,1.69,0,0,0,.51.07,3,3,0,0,0,.58,0,1,1,0,0,0,.54-.22,7.62,7.62,0,0,0,.65-.71s0,0,.06,0l.1.07s0,0,0,.06l-.32,1a.15.15,0,0,1-.16.1H76.3s-.07,0-.07-.06V22.1s0-.07.07-.07h.22c.32,0,.32,0,.32-.29V19.5c0-.55,0-1.38,0-1.48s-.1-.22-.29-.22h-.26s0,0,0,0v-.16s0,0,0,0,.39,0,.93,0h2.6s0,0,.07,0,.28,1,.32,1.06,0,.06,0,.06l-.12.07s0,0-.07,0a5.13,5.13,0,0,0-.74-.73.84.84,0,0,0-.51-.16l-1,0v2a5.72,5.72,0,0,0,.77,0,.42.42,0,0,0,.32-.26,1.18,1.18,0,0,0,.1-.38.07.07,0,0,1,.07-.07h.16s0,0,0,.07v.73c0,.26,0,.58,0,.74s0,0-.06,0l-.16,0s0,0-.07,0a2.42,2.42,0,0,0-.1-.32.47.47,0,0,0-.32-.25,5.3,5.3,0,0,0-.77,0Zm1-3.2s0,0-.06,0l-1.15-.52a.38.38,0,0,1-.23-.32.35.35,0,0,1,.32-.32.49.49,0,0,1,.29.13l.9.87s0,0,0,.06Z'></path>
          <path d='M85.21,17.61s0-.07.06-.07.36,0,.81,0,.73,0,.8,0,.06,0,.06,0v.16s0,.07-.06.07h-.16a.54.54,0,0,0-.48.32,17.92,17.92,0,0,0-.8,1.86c-.13.29-.93,2.24-1,2.41a.17.17,0,0,1-.13.09c-.09,0-.13-.06-.16-.16-.22-.58-.8-2.12-1-2.73s-.48-1.34-.55-1.53-.13-.23-.29-.23h-.16s0,0,0-.06v-.16s0-.07,0-.07.29,0,1,0,.93,0,1,0,.06,0,.06.07v.16s0,.06-.06.06H83.8c-.16,0-.29.07-.29.16a.69.69,0,0,0,0,.23A10.11,10.11,0,0,0,84,19.63c.1.25.45,1.31.58,1.66h0c.16-.38.58-1.5.67-1.7a12.32,12.32,0,0,0,.52-1.6c0-.09-.07-.19-.26-.19h-.19s0,0,0-.07v-.12Z'></path>
          <path d='M90,20.36c0,.36,0,1,0,1.16,0,.35.13.48.26.51a1.59,1.59,0,0,0,.51.07,3,3,0,0,0,.58,0,1,1,0,0,0,.54-.22,4.22,4.22,0,0,0,.61-.71s0,0,.07,0l.09.07s0,0,0,.06l-.32,1a.14.14,0,0,1-.16.1H88.48s-.06,0-.06-.06V22.1s0-.07.06-.07h.23c.32,0,.32,0,.32-.29V19.5c0-.55,0-1.38,0-1.48s-.1-.22-.29-.22h-.26s0,0,0,0v-.16s0,0,0,0,.38,0,.93,0H92s0,0,.06,0,.29,1,.32,1.06,0,.06,0,.06l-.13.07s0,0-.06,0a5.13,5.13,0,0,0-.74-.73.86.86,0,0,0-.51-.16l-1,0v2a5.87,5.87,0,0,0,.77,0,.42.42,0,0,0,.32-.26,1.51,1.51,0,0,0,.1-.38.07.07,0,0,1,.06-.07h.16s0,0,0,.07v.73c0,.26,0,.58,0,.74s0,0-.07,0l-.16,0s0,0-.06,0-.07-.25-.1-.32a.47.47,0,0,0-.32-.25,5.44,5.44,0,0,0-.77,0Z'></path>
        </g>
      </g>
    </svg>
  )
}
