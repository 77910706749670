import React, { useEffect, useState, useMemo, useRef } from "react"

import { transform } from "framer-motion"

import { ConfiguFrame } from "../styles"
//import ScrollBar from "../ScrollBar"
//import Arrows from "../Arrows"

import { useCarol, isTouchDevice } from "../context"

import { Roller } from "./styles"

const CONTROL_ID = "ConfiguRoller-items"

export const ConfiguRoller = ({ type, startSlide, children, marginLeft, direction, focusedFade, onFocusClick, indexTabbed, columnsSize }) => {
  //  const { winSizeConf: winSize } = useCarol()

  //  const DESKTOP = ["l", "xl"]
  //  const [showArrows, setShowArrows] = useState(!!~DESKTOP.indexOf(winSize.get()))

  //  const onWinSizeChange = s => setShowArrows(!!~DESKTOP.indexOf(s))
  //  useEffect(() => winSize.onChange(onWinSizeChange))

  return (
    <>
      <ConfiguFrame role='region' aria-roledescription='carousel' initial='leave' whileHover='hover'>
        <Strip
          type={type}
          itemPerSlide={1}
          startSlide={startSlide}
          marginLeft={marginLeft}
          direction={direction}
          focusedFade={focusedFade}
          onFocusClick={onFocusClick}
        >
          {children}
        </Strip>
        {/*!isTouchDevice && (
          <Arrows type={type} indexTabbed={indexTabbed - 1} controlId={CONTROL_ID} columnsSize={columnsSize} />
        )*/}
      </ConfiguFrame>
      {/*<ScrollBar marginLeft={marginLeft} type={type} direction={direction} />*/}
    </>
  )
}

const Strip = ({ type, itemPerSlide, startSlide, children, marginLeft, direction, focusedFade, onFocusClick }) => {
  const { scrollType, slide, progress, snapPoints, focused, winWidth, visiblePercent, ref } = useCarol()

  const isrtl = useMemo(() => direction === "rtl", [direction])

  const IPADL = 1024

  const getDivide = w => (w < IPADL ? 2 : w >= IPADL && w < 1441 ? 4 : 6)

  const padRef = useRef(
    winWidth.get() < IPADL
      ? Math.round(winWidth.get() * 0.25)
      : winWidth.get() >= IPADL && winWidth.get() < 1441
      ? Math.round(winWidth.get() / (4 / 1.5))
      : Math.round(winWidth.get() / 2.4)
  )

  const ofsWidthRef = useRef(!!ref.current ? ref.current.offsetWidth : 0)
  const ofsWidthHalfRef = useRef(ofsWidthRef.current / 2)
  const unitRef = useRef(Math.round(winWidth.get() / getDivide(winWidth.get())))
  const unitHalfRef = useRef(unitRef.current / 2)
  const outputRef = useRef(focusedFade === true ? [0, 1, 1, 0, 0, 1, 1, 0] : [0, 1, 1, 0])

  useEffect(() => {
    outputRef.current = focusedFade === true ? [0, 1, 1, 0, 0, 1, 1, 0] : [0, 1, 1, 0]
  }, [focusedFade])

  const updateMetrics = w => {
    padRef.current = w < IPADL ? Math.round(w * 0.25) : w >= IPADL && w < 1441 ? Math.round(w / (4 / 1.5)) : Math.round(w / 2.4)
    ofsWidthRef.current = ref.current.offsetWidth
    ofsWidthHalfRef.current = ofsWidthRef.current / 2
    unitRef.current = w / getDivide(w)
    unitHalfRef.current = unitRef.current / 2
  }

  const updateOpacity = () => {
    const input =
      focusedFade === true
        ? [
            0,
            unitHalfRef.current,
            ofsWidthHalfRef.current - unitRef.current * 1.3,
            ofsWidthHalfRef.current - unitRef.current * 0.7,
            ofsWidthHalfRef.current - unitRef.current * 0.3,
            ofsWidthHalfRef.current + unitRef.current * 0.3,
            ofsWidthRef.current - unitRef.current * 1.5,
            ofsWidthRef.current - unitRef.current,
          ]
        : [0, unitHalfRef.current, ofsWidthRef.current - unitRef.current * 1.5, ofsWidthRef.current - unitRef.current]

    const inputNorm = winWidth.get() < 1441 && !!focusedFade ? input.slice(2, input.length - 2) : input.slice()
    const outputNorm = winWidth.get() < 1441 && !!focusedFade ? outputRef.current.slice(2, outputRef.current.length - 2) : outputRef.current.slice()

    ref.current.childNodes.forEach((item, i, a) => {
      const pos = isrtl && i === a.length - 1 ? item.offsetLeft - ref.current.scrollLeft + padRef.current : item.offsetLeft - ref.current.scrollLeft

      const o = transform(pos, inputNorm, outputNorm)

      if (winWidth.get() >= 1441 || !!focusedFade) {
        item.style.opacity = o
        item.style.pointerEvents = o === 0 && i !== focused.get() ? "none" : "auto"
      } else {
        item.style.opacity = "initial"
        item.style.pointerEvents = "auto"
      }
    })
  }

  const updateSnapPoints = () => {
    if (React.Children.count(children) > 0) {
      const snapSlidePts = []
      const max = ref.current.scrollWidth - ref.current.clientWidth

      ref.current.childNodes.forEach(item => {
        let val
        if (isrtl) {
          if (scrollType === "default") {
            val = item.offsetLeft + max - (ref.current.clientWidth - item.clientWidth) + padRef.current
          } else if (scrollType === "negative") {
            val = item.offsetLeft + item.clientWidth - ref.current.clientWidth + padRef.current
          } else {
            val = item.offsetLeft - padRef.current
          }
        } else {
          val = item.offsetLeft - padRef.current
        }
        snapSlidePts.push(val)
      })

      ref.current.scrollLeft = snapSlidePts[slide.get()]

      snapPoints.set(snapSlidePts.toString())
    }
  }

  const onWinWidthChange = () => {
    updateMetrics(winWidth.get())
    updateSnapPoints()
    updateOpacity()
  }

  useEffect(() => {
    const unSub = visiblePercent.onChange(onWinWidthChange)
    onWinWidthChange(winWidth.get())
    return () => unSub()
  })

  useEffect(() => winWidth.onChange(onWinWidthChange))

  const onProgressChange = () => updateOpacity()
  useEffect(() => progress.onChange(onProgressChange))

  return (
    <Roller
      id={CONTROL_ID}
      type={type}
      startSlide={startSlide}
      itemPerSlide={itemPerSlide}
      marginLeft={marginLeft}
      direction={direction}
      onFocusClick={onFocusClick}
      aria-live='polite'
    >
      {children}
    </Roller>
  )
}

export default ConfiguRoller
