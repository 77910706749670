import { Suspense, useState, useLayoutEffect, useRef } from "react"
import styled from "@emotion/styled"
import { useConsole } from "contexts/Console"
import useSOLRbyRMC from "solr/useSOLRbyRMC"
import useSOLRModelPage from "solr/useSOLRModelPage"
import useSOLRPicture from "solr/useSOLRPicture"
import { useParams } from "react-router-dom"
import { Ballerina, AddToFavorites, Picture, PinchZoomPicture, Price, AddToWishlist } from "components/components"
import { useEnv } from "contexts/Env"
import { buttonReset, buttonRound, mask } from "css/button"
import { labelSmall } from "css/heading-new"
import { headline70, legend150, legend100, body50, bold, light } from "css/text"
import { useNavigation } from "contexts/Navigation"
import Specifications from "components/v2/Specifications"
import { PaneV2 } from "components/components"
import KeyFeatures from "components/v2/majesty/KeyFeatures"
import { useTranslations } from "contexts/I18N"
import watchName from "utils/watchName"
import QRCode from "qrcode"
import useLockTouchAction from "utils/useLockTouchAction"

import { useSync, useProspect, readyStates } from "contexts/Prospect"

import { useWebSocket } from "contexts/WebSocket"
import { useModal } from "contexts/Modal"
import useFeatures from "utils/useFeatures"
import useBeautyShot from "utils/useBeautyShot"
import useSocialRenditions from "utils/useSocialRenditions"
import { useLocale } from "contexts/Locale"
import { motion } from "framer-motion"

// import { Provider as Tracker } from "components/ballerina/Tracker"
import { useWA } from "contexts/WA"

const Root = styled.section`
  width: 100vw;
  height: var(--vh100);
  overflow: hidden;

  display: grid;
  grid-template-columns: [col1] 33.3vw [col2] 33.3vw [col3] 33.3vw;
  grid-template-rows: [marginT] 10vh [row1] 1fr [row2] 1fr [row3] 1fr [row4] 1fr [marginB] 10vh;

  @media (min-aspect-ratio: 1/1) {
    grid-template-columns: [col1] 33.3vw [col2] 33.3vw [col3] 33.3vw;
    grid-template-rows: [marginT] 15vh [row1] 1fr [row2] minmax(0px, max-content) [row3] 10vh [marginB] 15vh;
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: [col1] 1fr;
    grid-template-rows: [marginT] 10vh [row1] 1fr [row2] 1fr [row3] 1fr [row4] 1fr [row5] 0.5fr [row6] 0.5fr [marginB] 10vh;
  }

  /* & > * {
    z-index: 2;
  } */

  & .ballerina picture {
    visibility: hidden;
  }

  & .ballerina,
  & .pinch-zoom,
  & .shadow,
  & > picture {
    &:is(.ballerina) {
      z-index: 3;

      --vmin: min(120vmin, 60vh);

      @media screen and (max-width: 480px) {
        --vmin: min(120vmin, 50vh);
      }

      @media (min-aspect-ratio: 1/1) {
        --vmin: min(120vmin, 75vh);
        margin-top: 2vh;
      }
    }

    &:is(.pinch-zoom) {
      z-index: 3;

      --vmin: min(120vmin, 55vh);

      @media screen and (max-width: 480px) {
        --vmin: min(120vmin, 50vh);
      }

      @media (min-aspect-ratio: 1/1) {
        --vmin: min(120vmin, 75vh);
        /* margin-top: 2vh; */
      }
    }

    z-index: 0;
    width: 35vw;
    height: 100%;
    display: flex;
    grid-column: col2;
    grid-row: row2 / span 2;
    justify-content: center;
    align-items: center;
    position: relative;
    pointer-events: none;

    @media (min-aspect-ratio: 1/1) {
      width: 100%;
      grid-column: col2;
      grid-row: row1 / span 2;
    }

    @media screen and (max-width: 480px) {
      width: 100vw;
      grid-column: col1;
      grid-row: row2 / span 3;
    }
    & > img {
      width: 90vw;
      pointer-events: none;

      @media (min-aspect-ratio: 1/1) {
        width: 40vw;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 28rem;
      }
      @media (max-aspect-ratio: 1/1) {
        width: auto;
        object-fit: contain;
        height: 50vh;
      }
      @media screen and (max-width: 480px) {
        width: 100%;
        height: 55vh;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 22rem;
      }
    }
  }
  @media screen and (min-width: 481px) {
    & .shadow {
      grid-row: row1 / span 3;
    }
  }
`

export const Heading = styled.header`
  display: flex;
  flex-direction: column;
  grid-column: col1 / span 2;
  grid-row: row1 / row2;
  padding-top: env(safe-area-inset-top);
  align-items: start;
  width: calc(100% - 5vw);
  justify-content: end;
  margin-inline-start: 8vw;

  @media (max-aspect-ratio: 1/1) {
    align-self: start;
  }
  @media (min-aspect-ratio: 1/1) {
    max-width: 45%;
  }
  h1 {
    ${headline70}
    margin-block-end: 0.625rem;
  }

  > p {
    ${body50}
    max-width: 75%;
    margin-block-end: 0.312rem;
  }
  span {
    ${legend100}
    display: flex;
  }

  .priceClient {
    margin-block-start: 2rem;
    ${body50}
    ${bold}
  }

  @media (min-aspect-ratio: 1/1) {
    grid-column: col1/span 2;
    grid-row: row1/span 2;
    justify-content: start;
    margin-inline-start: 5vw;
    margin-block-start: 14vw;
  }

  @media screen and (max-width: 480px) {
    margin-inline-start: 0;
    grid-column: col1;
    grid-row: row1;
    width: calc(100% - 16vw);
    margin: 0 auto;
    justify-content: center;

    & h1 {
      grid-row: 1;
    }
  }
`

const WishlistWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  height: fit-content;
  margin-block-start: 3rem;
  grid-column: col1;
  grid-row: row2;
  & > button {
    margin-inline-start: -1.5rem;

    @media (max-aspect-ratio: 1/1) {
      margin-inline-start: calc(8vw - 0.5rem);
    }
    @media screen and (max-width: 480px) {
      margin-inline-start: calc(6rem - 8vw);
    }
  }

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-inline-start: -1.5rem;

    span {
      /* ${legend100} */
      ${bold}
    }
  }

  @media (min-aspect-ratio: 1/1) {
    display: none;
  }
`

const WishlistWrapperBis = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
  margin-block-start: 3rem;
  flex-direction: column;
  & > button {
    margin-inline-start: -1.5rem;
  }

  > div {
    margin-inline-start: -0.5rem;
    > span {
      ${bold}/* ${legend100} */
    }
  }

  > span {
    /* ${legend100} */
    ${bold}
  }

  @media (max-aspect-ratio: 1/1) {
    display: none;
  }
`

export const Ctabar = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-self: center;
  grid-column: col1 / span 4;
  grid-row: marginB;
  align-items: ${({ mode }) => (mode === "catalog" ? `start` : "center")};
  justify-content: center;
  padding-bottom: env(safe-area-inset-top, 0px);
  .catalog & {
    margin-block-end: -1rem;
  }

  width: ${({ mode }) => (mode === "puppet" ? `fit-content` : "calc(100% - 10vw)")};
  @media (min-aspect-ratio: 1/1) {
    margin: 0 auto;
    margin-inline-start: ${({ mode }) => mode === "puppet" && `24vw`};
  }

  @media (min-aspect-ratio: 1/1) {
    align-items: start;
    margin-inline-start: 0;
    justify-self: center;
    grid-column: col2;
    margin: 0 auto;
    grid-row: marginB;
    align-self: center;

    .catalog & {
      align-self: start;
    }
  }

  @media screen and (max-width: 480px) {
    grid-column: col1;
    grid-row: row6;
    align-items: end;
  }

  & > button {
    ${buttonReset}
    ${body50}
    ${bold}
    display: flex;
    padding: 0.5rem;
    margin-inline: 0.5rem;

    &::before {
      display: none;
    }
  }
`

const SpecButton = styled.button`
  ${buttonReset}
  --height: 1rem;
  margin-inline: 0;
  margin-block-end: 0.5rem;
  line-height: 1.4;
  width: 70%;
  max-width: 70%;
  @media screen and (max-width: 480px) {
    width: 80%;
    max-width: 80%;
  }

  > p {
    ${body50}
    margin-block-end: 0.312rem;
  }
  > span {
    ${legend100}
  }

  > svg {
    height: var(--height);
    width: var(--height);
    margin-inline-start: 0.5rem;

    @media screen and (min-width: 481px) {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    > svg {
      margin-block-end: -0.1rem;
    }
    & > span {
      display: none;
    }
  }
`

const ConfigButton = styled.a`
  ${bold}
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  grid-column: col3;
  grid-row: row3;
  align-self: end;
  justify-self: center;
  align-items: center;

  @media (min-aspect-ratio: 1/1) {
    grid-column: col3;
    grid-row: row1;
    margin-block-end: 6vw;
  }

  @media screen and (max-width: 480px) {
    grid-column: col1;
    grid-row: row4;
    justify-self: end;
    align-self: start;
    margin-inline-end: 8vw;
    align-items: center;
  }

  & > span {
    /* ${legend150} */
  }

  picture {
    width: 20vw;
    height: 100%;

    @media screen and (max-width: 480px) {
      width: 25vw;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      &.placeholder {
        display: flex;
        height: 100%;

        &::before {
          content: "";
          display: block;
          padding-top: 100%;
        }

        &::before,
        img {
          grid-area: 1/1;
          object-fit: cover;
        }
      }
    }
  }
`

const Variations = styled.div`
  ${bold}
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  grid-column: col3;
  grid-row: row3;
  align-self: end;
  justify-self: center;
  align-items: center;

  @media (min-aspect-ratio: 1/1) {
    grid-column: col3;
    grid-row: row1;
    margin-block-end: 6vw;
  }

  @media screen and (max-width: 480px) {
    grid-column: col1;
    grid-row: row4;
    justify-self: end;
    align-self: start;
    margin-inline-end: 8vw;
    align-items: center;
  }
  > a {
    ${buttonRound}
    background: linear-gradient(to top, rgb(var(--green)), rgb(var(--sea-green)));
    pointer-events: auto;
    color: rgb(var(--raisin-black));
    cursor: pointer;

    &::before {
      display: none;
    }
    path {
      fill: rgb(var(--highlight));
    }

    /* &::before {
      content: "";
      transform: rotate(45deg);
      background: currentColor;
      ${mask('<path d="m17 15 13 13-2 2-13-13-13 13-2-2 13-13-13-13 2-2 13 13 13-13 2 2z" />')}
    } */
  }
  > span {
    margin-block-start: 0.5rem;
  }
`

export const Rotate = styled.div`
  margin-inline: 0;
  display: flex;
  flex-direction: column;
  grid-column: col1;
  grid-row: row3;
  align-self: end;
  justify-self: center;

  @media (min-aspect-ratio: 1/1) {
    width: fit-content;
    flex-direction: row;
    margin-block-end: 6vw;
    grid-column: col3;
    grid-row: row1;
    justify-self: start;
  }

  @media screen and (max-width: 480px) {
    grid-column: col1;
    grid-row: row4;
    align-items: center;
    justify-self: start;
    align-self: center;
    margin-block-end: 0;
    margin-inline-start: 15vw;

    .embed & {
      justify-self: center;
      align-self: end;
      margin-inline-start: 0;
      margin-block-end: -5vw;
    }
    /* margin-block-start: 3.5rem; */
  }

  & > svg {
    fill: rgb(var(--on-interface));
    width: 2rem;
    height: 2rem;
  }
`

export const SideAsset = styled.div`
  width: 25vw;
  height: var(--vh100);
  grid-column: col3 / span 2;
  justify-self: end;
  z-index: 1;
  @media (max-aspect-ratio: 1/1) {
    display: none;
  }
  picture:is(.sideasset) {
    width: 100%;
    height: 100%;
    display: flex;
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      user-select: none;
    }
  }
`

export const ShowHide = styled.div`
  ${buttonReset}
  position: relative;
  width: fit-content;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  justify-self: flex-start;
  background: transparent;
  padding: 0.5rem;
  margin-block-start: 1rem;

  @media screen and (min-width: 481px) {
    margin-inline-start: -0.5rem;
  }
  @media screen and (max-width: 480px) {
    padding-inline-start: 0;
    margin-block-start: 0.5rem;
  }

  > div > p {
    ${light}
    margin-inline-end: 0.5rem;
    background: rgb(var(--on-interface));
    color: transparent;
    background-clip: text;
  }
  & > button {
    ${buttonReset}
    font-size: inherit;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0.5rem;

    justify-content: start;
    align-items: center;

    @media screen and (max-width: 480px) {
      padding-inline: 0;
    }

    > p {
      /* ${legend150} */
      ${bold}
      white-space: nowrap;
      background: rgb(var(--on-interface));
      color: transparent;
      background-clip: text;
    }
  }
`

export const Toggle = styled.button`
  ${buttonReset}
  position: relative;
  width: fit-content;
  z-index: 1;
  align-items: center;
  justify-content: flex-start;
  justify-self: flex-start;
  background: transparent;
  padding: 0.5rem;
  margin-block-start: 1rem;

  @media screen and (min-width: 481px) {
    margin-inline-start: -0.5rem;
  }
  @media screen and (max-width: 480px) {
    padding-inline-start: 0;
    > div > p {
      /* ${labelSmall} */
    }
  }

  > div > p {
    /* ${legend150} */
    font-weight: 400;
    background: rgb(var(--on-interface));
    color: transparent;
    background-clip: text;
  }
  & > span {
    ${buttonReset}
    font-size: inherit;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0.5rem;

    justify-content: start;
    align-items: center;

    @media screen and (max-width: 480px) {
      padding-inline: 0;
    }

    > p {
      /* ${legend150} */
      ${bold}
      white-space: nowrap;
      background: rgb(var(--on-interface));
      color: transparent;
      background-clip: text;
    }
  }
`
export const toggle = {
  inactive: {
    x: 0,
    transition: {
      type: "spring",
      ease: "easeInOut",
      bounce: 0,
      duration: 0.5,
    },
  },
  active: {
    x: "100%",
    transition: {
      type: "spring",
      ease: "easeInOut",
      bounce: 0,
      duration: 0.5,
    },
  },
}
export const togglePrice = {
  hidden: {
    opacity: 0,
    transition: {
      type: "spring",
      ease: "easeInOut",
      duration: 0.3,
    },
  },
  shown: {
    opacity: 1,
    transition: {
      type: "spring",
      ease: "easeInOut",
      delay: 0.1,
      duration: 0.3,
    },
  },
}

const suspenders = {}
export default function CatalogMajestyV2({ id, rmc, family }) {
  const console = useConsole()
  const [active, setActive] = useState(null)
  const translations = useTranslations()
  const params = useParams()
  rmc ??= params.rmc
  family ??= params.family
  const solrRMC = useSOLRbyRMC(rmc)
  const solrMP = useSOLRModelPage(rmc)
  const features = useFeatures(family, rmc)
  const beautyShots = useBeautyShot({ rmc })
  const socialRenditions = useSocialRenditions(rmc)
  const [watch, setWatch] = useState(null)
  const env = useEnv()
  const picture = useSOLRPicture({ rmc, policy: env.cloudinary.policies.configure.main, folder: env.cloudinary.folders.upright })
  const navigation = useNavigation()
  const rpane = useRef()
  const sync = useSync(rmc)
  const ws = useWebSocket()
  const { locale } = useLocale()
  const ctx = useModal()
  const prospect = useProspect()
  const wa = useWA()

  useLockTouchAction()

  const isProspect = prospect.readyState === readyStates.ACTIVE

  useLayoutEffect(() => {
    let late = false
    if (!watch)
      Promise.all([
        Promise.resolve(new URL("/services/search", process.browser ? global.location.origin : `http://${process.env.SERVICE_NGINX}`))
          .then(url => {
            url.searchParams.append("q", rmc)
            url.searchParams.append("lang", locale.toLowerCase())
            return url
          })
          .then(url => new Request(url))
          .then(request => fetch(request))
          .then(response => response.json())
          .then(
            ({
              result: {
                rmcs: [rmc],
              },
            }) => rmc
          ),
        new Promise((resolve, reject) => {
          resolve(null)
          // solrRMC((err, data) => {
          //   if (err) reject(err)
          //   else resolve(data?.response?.docs?.[0])
          // })
        }),
        new Promise((resolve, reject) => {
          solrMP((err, data) => {
            if (err) reject(err)
            else resolve(data?.response?.docs?.[0])
          })
        }),
        new Promise((resolve, reject) => {
          resolve(null)
          // beautyShots((err, data) => {
          //   if (err) {
          //     /*reject(err)*/ console.warn(err)
          //     resolve({ beautyShots: [] })
          //   } else resolve({ beautyShots: data })
          // })
        }),
        new Promise((resolve, reject) => {
          resolve(null)
          // socialRenditions((err, data) => {
          //   if (err) reject(err)
          //   else resolve(data)
          // })
        }),
        new Promise((resolve, reject) => {
          features((err, data) => {
            if (err) reject(err)
            else resolve({ ballerina: data.ballerina ?? false, configure: data.configure, video: data.videos?.[0] })
          })
        }),
      ])
        .catch(err => (console.error(err), [null, null, null, null, { ballerina: false, configure: false, video: null }]))
        .then(([w, mp, b, sr, x, { ballerina, configure, video: f }]) => {
          if (late) return
          console.verbose("CatalogMajestyV2:solrRMC+MP(%s) => %o", rmc, { w, mp, b, f, ballerina, configure })
          setWatch(Object.assign(w, mp, b, sr, f, { ballerina, configure }))
        })
    return () => (late = true)
  })

  const show = () => {
    rpane?.current?.show?.()

    wa.dispatch({
      detail: {
        triggerName: "specificationsClicked",
        productFamily: family,
        productRMC: rmc,
      },
    })
  }

  const configure = e => {
    e.preventDefault()
    navigation.push(navigation.localize(`/watches/configure#${watch.slug}`).url)
    wa.dispatch({
      detail: {
        triggerName: "variationsClicked",
        productFamily: family || "",
        productRMC: rmc || "",
      },
    })
  }

  const handleClick = e => {
    e.preventDefault()
    setActive(v => !v)
    sync.send("active", { active })
    if (isProspect) {
      wa.dispatch({
        detail: {
          triggerName: !active ? "priceShowed" : "priceHidden",
        },
      })
    }
  }

  /** PUPPET */
  // useLayoutEffect(() => {
  //   if (ws.connector === "catalog") return

  //   const onactive = ({ active }) => {
  //     if (active) ctx.show()
  //     else ctx.hide()
  //   }

  //   sync.addEventListener("active", onactive)
  //   return () => {
  //     sync.removeEventListener("active", onactive)
  //   }
  // })

  // const localhost = process.env
  const [qrCode, setQRCode] = useState(null)

  useLayoutEffect(() => {
    const options = { scale: 8 }

    QRCode.toDataURL(rmc, options)
      .then(dataurl => setQRCode(dataurl))
      .catch(err => {
        console.error(err)
      })
  }, [])

  useLayoutEffect(function autoBroadcast() {
    prospect.navigate(navigation.slug)
  }, [])

  suspenders[family] ??= {}
  suspenders[family][locale] ??= {
    done: false,
    result: null,
    promise: fetch(
      new Request(new URL(`${navigation.localize("/").url}/family/${family}`, process.browser ? global.location.origin : `http://${process.env.SERVICE_NGINX}`))
    )
      .then(response => response.json())
      .catch(err => {
        console.error(err)
        return {}
      })
      .then(data => {
        suspenders[family][locale].done = true
        suspenders[family][locale].result = data
      }),
  }
  if (!suspenders[family][locale].done) throw suspenders[family][locale].promise
  const data = suspenders[family][locale].result

  const refCode = watch?.reference_code
  const material = watch?.description
  const title = watch && watchName(watch?.title)
  const sideasset = data?.picture?.sources
  const dialVariation = data?.picture?.thumbnail
  const isConfigurable = watch?.configure

  const rballerina = useRef()
  const rshadow = useRef()
  const rshadowT = useRef(0)

  useLayoutEffect(() => {
    const shadow = rshadow.current
    let ballerina
    let timer

    const onscroll = ({ scroll }) => {
      rshadowT.current = +!!(scroll.x || scroll.y || scroll.z)
      shadow.style.setProperty("--t", rshadowT.current)
    }

    const test = () => {
      if (rballerina.current) {
        ballerina = rballerina.current
        ballerina.addEventListener("scroll", onscroll)
        onscroll(ballerina)
      } else timer = setTimeout(test, 100)
    }

    test()
    return () => {
      clearTimeout(timer)
      ballerina?.removeEventListener?.("scroll", onscroll)
    }
  })

  console.verbose("CatalogMajesty(%o) => ", { rmc, family, watch, ballerina: watch?.ballerina })
  return (
    <Root>
      {watch && (
        <>
          <Heading>
            <h1>{title}</h1>
            <SpecButton onClick={show}>
              {material}
              <span>{translations["specifications"]}</span>
              <svg viewBox='0 0 30 30'>
                <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                  <path
                    d='M15,1 C22.7319865,1 29,7.2680135 29,15 C29,22.7319865 22.7319865,29 15,29 C7.2680135,29 1,22.7319865 1,15 C1,7.2680135 7.2680135,1 15,1 Z M15,6 C13.8952,6 13,6.8952 13,8 C13,9.1048 13.8952,10 15,10 C16.1048,10 17,9.1048 17,8 C17,6.8952 16.1048,6 15,6 Z M13.6,12 L16.4,12 C16.6209139,12 16.8,12.1790861 16.8,12.4 L16.8,23.6 C16.8,23.8209139 16.6209139,24 16.4,24 L13.6,24 C13.3790861,24 13.2,23.8209139 13.2,23.6 L13.2,12.4 C13.2,12.1790861 13.3790861,12 13.6,12 Z'
                    fill='#127749'
                  />
                </g>
              </svg>
            </SpecButton>
            <span>{rmc}</span>
            <ShowHide className='toggle' active={active} onClick={handleClick}>
              <motion.div>
                <Price rmc={rmc} />
              </motion.div>
              {isProspect && (
                <motion.button>
                  <p>{active ? translations["hide-price"] : translations["show-price"]}</p>
                </motion.button>
              )}
            </ShowHide>
            {/* <Toggle className='toggle' active={active} onClick={handleClick}>
              <motion.span variants={toggle} initial='inactive' animate={active ? "active" : "inactive"}>
                <p>{active ? translations["hide-price"] : translations["show-price"]}</p>
              </motion.span>
              <motion.div variants={togglePrice} initial='hidden' animate={active ? "shown" : "hidden"}>
                <Price rmc={rmc} />
              </motion.div>
            </Toggle> */}
            <WishlistWrapperBis>
              {isProspect && (
                <Suspense fallback={null}>
                  <AddToWishlist rmc={rmc} withLabel />
                </Suspense>
              )}
              {/* {isProspect ? (
              ) : (
                <Suspense fallback={null}>
                  <AddToFavorites rmc={rmc} withLabel />
                </Suspense>
              )} */}
            </WishlistWrapperBis>
          </Heading>
          <WishlistWrapper>
            {isProspect && (
              <Suspense fallback={null}>
                <AddToWishlist rmc={rmc} />
              </Suspense>
            )}
            {/* {isProspect ? (
            ) : (
              <Suspense fallback={null}>
                <AddToFavorites rmc={rmc} />
              </Suspense>
            )} */}
          </WishlistWrapper>
          <Suspense fallback={null}>
            {/* {watch?.ballerina ? ( */}
            <Ballerina watch={watch}>
              <Picture sources={picture} />
            </Ballerina>
            {/* ) : (
               <PinchZoomPicture rmc={rmc} sources={picture} />
             )} */}
            {/* <Tracked rmc={rmc} watch={watch} picture={picture} watchShadow={watchShadow} /> */}
            {/* <Picture sources={picture} sizes='50vmin' /> */}
          </Suspense>
          {watch?.has360 ? (
            <Rotate>
              <svg width='40px' height='23px' viewBox='0 0 40 23'>
                <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                  <g transform='translate(-60.000000, -540.000000)'>
                    <g transform='translate(60.000000, 541.000000)'>
                      <path
                        d='M11.5266998,11.1238503 C10.5478607,11.109468 10.064942,10.336294 10.0115423,9.66280433 L8,9.66280433 C8.02646766,10.1498197 8.13373134,10.937872 8.57625207,11.582101 C9.1933665,12.5417493 10.400199,13 11.4863018,13 C13.4309784,13 15,11.5677187 15,9.67718665 C15,9.20455365 14.9062023,7.67209197 13.0957214,7.01298467 C13.6051078,6.71244364 14.4232836,6.09648332 14.4232836,4.79314698 C14.4232836,3.2606853 13.2568491,2 11.5132338,2 C10.2260697,2 8.44252073,2.77366997 8.38865672,5.07980162 L10.359801,5.07980162 C10.386733,4.60667268 10.5877944,3.87664563 11.4329022,3.87664563 C12.0764842,3.87664563 12.4252073,4.44945897 12.4252073,5.09368801 C12.4252073,5.99630298 11.6204975,6.41140667 10.9365174,6.41140667 L10.4939967,6.41140667 L10.4939967,8.00139766 L11.2987065,8.00139766 C12.0764842,8.00139766 13.0019237,8.47403066 13.0019237,9.51947701 C13.0019237,10.4077096 12.492073,11.1238503 11.5266998,11.1238503'
                        fill='#000000'
                      />
                      <path
                        d='M22.2303045,2 L20.2299309,4.84446179 C20.0864421,5.06369398 19.9280065,5.26777186 19.7695709,5.50114805 C19.8846609,5.44255143 20.287725,5.34051249 20.6185464,5.34051249 C22.2447531,5.34051249 24,6.60993755 24,8.98764695 C24,11.321914 22.215856,13 20.0286479,13 C18.7193124,13 17.5684125,12.4893001 16.8918229,11.5992377 C16.2446285,10.8551616 16,9.80496877 16,8.98764695 C16,7.85006429 16.46036,6.93070353 16.9640655,6.14318516 L19.7551224,2 L22.2303045,2 Z M19.9713521,7.25197465 C19.0664804,7.25197465 18.2013764,7.91358634 18.1210374,8.99724713 L18.1149654,9.17758082 C18.1149654,10.3298126 18.9783895,11.0885378 19.9569035,11.0885378 C21.0216105,11.0885378 21.8561375,10.257072 21.8561375,9.17758082 C21.8561375,8.09808964 21.0505076,7.25197465 19.9713521,7.25197465 Z'
                        fill='#000000'
                      />
                      <path
                        d='M29.0151002,2 C30.5787555,2 33,3.00279531 33,5.86735798 L33,9.13264202 C33,12.0259693 30.5938558,13 29.0453007,13 C27.4811247,13 25,12.0259693 25,9.13264202 L25,5.89562669 C25,2.83070334 27.6316064,2 29.0151002,2 Z M28.9848998,3.87614968 C27.7367873,3.87614968 27.2405624,4.89332732 27.2405624,5.824211 L27.2405624,9.19017133 C27.2405624,10.1498197 27.782088,11.1238503 29,11.1238503 C30.2481125,11.1238503 30.7594376,10.0064923 30.7594376,9.19017133 L30.7594376,5.80982867 C30.7594376,5.20825068 30.5188753,3.87614968 28.9848998,3.87614968 Z'
                        fill='#000000'
                      />
                      <path
                        d='M35.9924457,4 C37.1558074,4 38,3.09386195 38,2 C38,0.906138046 37.1506138,0 35.9976393,0 C34.9372049,0 34,0.796751851 34,2.03104848 L34,2.03630284 C34,3.06759016 34.8238905,4 35.9924457,4 L35.9924457,4 Z'
                        stroke='#000000'
                      />
                      <path
                        d='M38.0243452,9 C38.3823263,9.4808884 38.5725819,9.98408606 38.5725819,10.5021566 C38.5725819,11.6567845 37.6288137,12.7380398 35.985105,13.6641218 C33.6109147,15.1152149 29.2440467,16.1999405 24,16.5722572 L24,19 C33.1302688,18.3416291 40,15.4880274 40,12.0677706 C40,10.9686679 39.2905467,9.92806504 38.0243452,9 M17.7902411,16.6838028 C15.0906432,16.5895351 12.5745468,16.3037554 10.380938,15.872109 C7.65611492,15.3357759 5.42837788,14.5727044 3.9663018,13.6677353 C2.34248726,12.7409358 1.41014172,11.6588428 1.41014172,10.5033204 C1.41014172,9.98484848 1.59809466,9.48126097 1.95174297,9 C0.700866648,9.92878406 0,10.9701931 0,12.0701473 C0,15.7396382 7.80004731,18.7571941 17.7902411,19.1149149 L17.7902411,22 L23,17.9400427 L17.7902411,13.8795893 L17.7902411,16.6838028 Z'
                        fill='#000000'
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </Rotate>
          ) : null}
          {/* {!!isConfigurable && (
            <ConfigButton className='configBtn' onClick={configure}>
              <Suspense fallback={null}>
                <Picture className='dial-variation' sources={dialVariation} />
              </Suspense>
              <span>{translations["configure-long"]}</span>
            </ConfigButton>
          )} */}
          {!!isConfigurable && (
            <Variations>
              <a className='configBtn' onClick={configure}>
                <svg width='15px' height='15px' viewBox='0 0 15 15'>
                  <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <path d='M11.3419355,14.7 C13.3301606,14.7 14.9419355,13.0882251 14.9419355,11.1 C14.9419355,9.1117749 13.3301606,7.5 11.3419355,7.5 C9.35371038,7.5 7.74193548,9.1117749 7.74193548,11.1 C7.74193548,13.0882251 9.35371038,14.7 11.3419355,14.7 Z M3.6,14.7 C5.5882251,14.7 7.2,13.0882251 7.2,11.1 C7.2,9.1117749 5.5882251,7.5 3.6,7.5 C1.6117749,7.5 0,9.1117749 0,11.1 C0,13.0882251 1.6117749,14.7 3.6,14.7 Z M7.22903226,7.2 C9.21725736,7.2 10.8290323,5.5882251 10.8290323,3.6 C10.8290323,1.6117749 9.21725736,-9.14823772e-14 7.22903226,-9.14823772e-14 C5.24080716,-9.14823772e-14 3.62903226,1.6117749 3.62903226,3.6 C3.62903226,5.5882251 5.24080716,7.2 7.22903226,7.2 Z' />
                  </g>
                </svg>
              </a>
              <span>{translations["configure-long"]}</span>
            </Variations>
          )}
          <Ctabar mode={ws.connector}>
            {/* <KeyFeatures
              rmc={rmc}
              family={family}
              id={id + "-kf"}
              mapping={watch?.editorial_mapping}
              videos={watch?.video}
              socialRenditions={watch?.social_renditions}
              beautyShots={watch?.beautyShots}
            /> */}
            <Suspense fallback={null}>
              <PaneV2 className='popover' id={"pane" + id} ref={rpane} size='xl'>
                <Specifications watch={watch} />
              </PaneV2>
            </Suspense>
          </Ctabar>
          {/* {!isConfigurable && (
            <SideAsset>
              <Suspense fallback={null}>
                <Picture className='sideasset' sources={sideasset} />
              </Suspense>
            </SideAsset>
          )} */}
        </>
      )}
    </Root>
  )
}

// function Tracked({ rmc, watch, picture, watchShadow }) {
//   const console = useConsole()
//   // const [shadow, setShadow] = useState(true)
//   // const onscroll = ({ x, y, z }) => {
//   //   if (!!(x || y || z)) setShadow(false)
//   //   else if (!x && !y && !z) setShadow(true)
//   // }

//   console.verbose("Majesty:Tracker()", {})
//   return (
//     <Tracker
//       value={
//         {
//           /*onscroll: onscroll*/
//         }
//       }
//     >
//       {watch?.ballerina ? (
//         <Ballerina rmc={rmc}>
//           <Picture sources={picture} />
//         </Ballerina>
//       ) : (
//         <PinchZoomPicture rmc={rmc} sources={picture} />
//       )}
//       {/* <Shadow className='shadow' src={watchShadow.url} style={{ "--t": 1 - +shadow }} /> */}
//     </Tracker>
//   )
// }
