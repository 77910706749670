import { useConsole } from "contexts/Console"
import { ctx } from "contexts/Header"
import styled from "@emotion/styled"
import { useNavigation } from "contexts/Navigation"
import Selection from "components/v2/selection/Selection"
import Crown from "components/Crown"
import { Back } from "components/components"
import { visuallyHidden } from "css/text"
import { mask } from "css/button"
import { useLayoutEffect, useRef } from "react"
import hash from "@emotion/hash"
import Session from "components/v2/header/catalog/Session"
import { usePairing, readyStates as pairingStates } from "contexts/Pairing"
import { useProspect, readyStates as prospectStates } from "contexts/Prospect"
import useT from "utils/useT"
import { body50, bold } from "css/text"
import { keyframes } from "@emotion/react"
import useConstant from "utils/useConstant"

const home = `css-${hash("home")}`
const settings = `css-${hash("settings")}`
const wishlist = `css-${hash("wishlist")}`

const slidein = keyframes`
  from {
    transform: translateY(var(--b, 100%));
  }

  to {
    transform: translateY(0);
  }
`

const Root = styled.header()

const Strip = styled.nav`
  --safe: env(safe-area-inset-bottom, 0px);
  position: fixed;
  z-index: var(--z-ui);
  inset: auto 0 var(--safe) 0;
  width: 100vw;
  height: 60px;

  transform: translateY(var(--b, 100%));
  animation: ${slidein} 300ms 300ms ease 1 forwards;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: var(--safe);
    background: red;
    transform: translateY(100%);
    background: #17724a;
  }

  &:nth-of-type(1) {
    z-index: calc(var(--z-ui) + 1);
    background: #17724a;
    /* clip-path: path("M 0 0 L 40 0 c 60 0 47 60 110 60 l 90 0 c 60 0 47 -60 110 -60 l 40 0 l 0 60 L 0 60 Z"); */

    display: flex;
    justify-content: center;
    align-items: center;

    & > a + div {
      flex-basis: calc(var(--w) * 1px);
    }
  }

  &:nth-of-type(2) {
    display: flex;
    justify-content: center;
    align-items: stretch;
    /* transform: translateY(calc(0.8rem * (1 - var(--pa, 0))));
    transition: transform 300ms; */
  }
`

export const Action = styled.a`
  all: unset;
  cursor: pointer;
  color: rgb(var(--highlight));
  text-transform: capitalize;
  ${body50}
  ${bold}
  padding: 1rem;
  position: relative;

  * {
    pointer-events: none;
  }

  &.${home}, &.${settings}, &.${wishlist} {
    ::before {
      background: currentColor;
      content: "";
      height: 15px;
      width: 15px;
    }

    > span {
      ${visuallyHidden}
    }
  }

  &.${home} {
    ::before {
      ${mask(
        '<polygon points="7.66666667 0 1 5.45454545 1 15 6.21788342 15 6.21788342 10.2954545 9.11544992 10.2954545 9.11544992 15 14.3333333 15 14.3333333 5.45454545"></polygon>',
        15
      )}
    }
  }

  &.${settings} {
    pointer-events: none;

    ::before {
      background: rgb(166, 166, 166);

      ${mask(
        '<path d="M13.75,6.25 L12.335625,6.25 C12.2175,5.793125 12.0375,5.3625 11.8025,4.965625 L12.80375,3.964375 C13.291875,3.47625 13.291875,2.684375 12.80375,2.196875 C12.315625,1.70875 11.524375,1.70875 11.035625,2.196875 L10.035,3.198125 C9.638125,2.96375 9.2075,2.783125 8.750625,2.665 L8.750625,1.25 C8.750625,0.56 8.190625,0 7.500625,0 C6.810625,0 6.250625,0.56 6.250625,1.25 L6.250625,2.664375 C5.79375,2.7825 5.3625,2.9625 4.96625,3.1975 L3.965625,2.19625 C3.4775,1.708125 2.68625,1.708125 2.1975,2.19625 C1.709375,2.68375 1.709375,3.475625 2.1975,3.96375 L3.19875,4.965 C2.964375,5.361875 2.78375,5.7925 2.665625,6.249375 L1.25,6.249375 C0.56,6.249375 0,6.809375 0,7.499375 C0,8.189375 0.56,8.749375 1.25,8.749375 L2.664375,8.749375 C2.7825,9.20625 2.9625,9.636875 3.1975,10.03375 L2.19625,11.035 C1.708125,11.523125 1.708125,12.315 2.19625,12.8025 C2.440625,13.046875 2.76,13.16875 3.08,13.16875 C3.4,13.16875 3.72,13.046875 3.964375,12.8025 L4.965,11.80125 C5.361875,12.035625 5.7925,12.21625 6.249375,12.334375 L6.249375,13.75 C6.249375,14.44 6.809375,15 7.499375,15 C8.189375,15 8.749375,14.44 8.749375,13.75 L8.749375,12.335625 C9.20625,12.2175 9.6375,12.0375 10.03375,11.8025 L11.034375,12.80375 C11.27875,13.048125 11.59875,13.17 11.91875,13.17 C12.23875,13.17 12.558125,13.048125 12.8025,12.80375 C13.290625,12.31625 13.290625,11.524375 12.8025,11.03625 L11.80125,10.035 C12.035625,9.638125 12.21625,9.2075 12.334375,8.750625 L13.75,8.750625 C14.44,8.750625 15,8.190625 15,7.500625 C15,6.810625 14.44,6.25 13.75,6.25 Z M7.5,10 C6.12125,10 5,8.87875 5,7.5 C5,6.12125 6.12125,5 7.5,5 C8.87875,5 10,6.12125 10,7.5 C10,8.87875 8.87875,10 7.5,10 Z"></path>',
        // '<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect stroke="currentColor" stroke-width="2" x="1" y="3" width="9" height="6" rx="2"></rect><rect stroke="currentColor" stroke-width="2" x="5.14285714" y="6" width="9" height="6" rx="2"></rect></g>',
        15
      )}
    }
  }

  &.${wishlist} {
    position: relative;
    ::after {
      position: absolute;
      left: 0;
      top: 50%;
      bottom: 0;
      content: "";
      height: 50%;
      width: 2px;
      display: block;
      transform: translateY(-50%);
      background-color: rgb(var(--salem));
    }

    ::before {
      content: "";
      ${mask(
        '<path d="M29.9441238,11.3392971 C29.4728324,16.5668103 23.5888307,23.4889569 14.991333,28.7164701 C6.39383539,23.4889569 0.509833671,16.5668103 0.0385422716,11.3392971 C-0.432749128,6.11178382 3.48039765,1.43 8.36469033,1.43 C13.248983,1.43 14.991333,4.99160242 14.991333,4.99160242 C14.991333,4.99160242 16.7479646,1.43 21.6179758,1.43 C26.4879869,1.43 30.4154152,6.11178382 29.9441238,11.3392971 Z" />'
      )}
    }
  }
`

const Top = styled.div`
  --safe: env(safe-area-inset-top, 0px);
  left: 0;
  margin-block-start: var(--safe);
  position: absolute;
  right: 0;
  top: 0;
  z-index: var(--z-ui);
`

const Brand = styled.a`
  position: absolute;
  top: 1.25rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem;

  svg {
    height: 30px;
    pointer-events: none;
    width: auto;
  }
`

export default function Catalog({ children, ...props }) {
  const console = useConsole()
  const navigation = useNavigation()
  const pairing = usePairing()
  const prospect = useProspect()
  const rroot = useRef()
  const rseparator = useRef()
  const rsession = useRef()
  const rstrip = useRef()
  const rstrip2 = useRef()
  const pa = useT({ t: +(pairing.readyState === pairingStates.PAIRED) })
  const pr = useT({ t: +(prospect.readyState === prospectStates.ACTIVE) })
  const cssVars = useConstant(() => ({}))

  Object.assign(ctx, {
    nav: {
      update() {
        if (!rroot.current || !rsession.current) return

        const vw = rroot.current.clientWidth
        const w = rsession.current.clientWidth - 155
        const a = (vw - w) / 2
        /**
         * path("M 0 0 L 40 0 c 60 0 47 60 110 60 l 90 0 c 60 0 47 -60 110 -60 l 40 0 l 0 60 L 0 60 Z")
         */
        cssVars.w = w + 190
        cssVars.path = `path("M 0 0 L ${a - 110} 0 c 60 0 47 60 110 60 l ${w} 0 c 60 0 47 -60 110 -60 l ${a - 110} 0 l 0 60 L 0 60 Z")`
        rseparator.current.style.setProperty("--w", cssVars.w)
        rstrip.current.style.setProperty("clip-path", cssVars.path)
      },
    },
  })

  const navigate = e => {
    e.preventDefault()
    navigation.push(e.target.getAttribute("href"))
  }

  useLayoutEffect(() => {
    global.addEventListener("resize", ctx.nav.update)
    return () => {
      global.removeEventListener("resize", ctx.nav.update)
    }
  })

  useLayoutEffect(() => {
    ctx.nav.update()
  }, [])

  useLayoutEffect(() => {
    pa.t(+(pairing.readyState === pairingStates.PAIRED))
    pr.t(+(prospect.readyState === prospectStates.ACTIVE))
  })

  useLayoutEffect(() =>
    pa.onChange(t => {
      rroot.current.style.setProperty("--pa", pa.t())
      ctx.nav.update()
    })
  )
  useLayoutEffect(() =>
    pr.onChange(t => {
      rroot.current.style.setProperty("--pr", pr.t())
      ctx.nav.update()
    })
  )

  useLayoutEffect(() => {
    const root = rroot.current
    const onanimationend = () => {
      Catalog.b = true
      root.style.setProperty("--b", 0)
    }
    if (!Catalog.b) root.addEventListener("animationend", onanimationend)
    return () => {
      root.removeEventListener("animationend", onanimationend)
    }
  }, [])

  console.verbose("Header:Catalog(%o)", {})
  return (
    <>
      <Selection />
      {/* <Settings /> */}
      <Root key='header' ref={rroot} style={{ "--pa": pa.t(), "--pr": pr.t(), "--b": Catalog.b && 0 }}>
        <Top>
          <Back />
          <Brand href={navigation.localize(props.home.href).url} onClick={navigate}>
            <Crown />
          </Brand>
        </Top>
        <Strip ref={rstrip} style={{ clipPath: cssVars.path }}>
          <Action className={home} href={navigation.localize(props.home.href).url} onClick={navigate} title={props.home.label}>
            <span>{props.home.label}</span>
          </Action>
          <div ref={rseparator} style={{ "--w": cssVars.w }} />
          <Action className={settings} href={navigation.localize(props.settings.href).url} onClick={navigate} title={props.settings.label}>
            <span>{props.settings.label}</span>
          </Action>
        </Strip>
        <Strip ref={rstrip2}>
          <Session ref={rsession} {...props} pr={pr} />
        </Strip>
      </Root>
      {children}
    </>
  )
}
