import styled from "@emotion/styled"
import { createContext, forwardRef, useState, Suspense, startTransition, useContext, useImperativeHandle, useLayoutEffect, useRef, Children } from "react"
import { buttonRound, mask } from "css/button"
import { small, large, label } from "css/heading"
import { usePopOver } from "components/v2/majesty/PopOver"
import { useSync } from "contexts/Prospect"
import { useWebSocket } from "contexts/WebSocket"
import { useConsole } from "contexts/Console"
import { noScrollbar } from "css/helper"
import useT from "utils/useT"
import * as pop from "popmotion"
import { motion } from "framer-motion"
import { Video } from "components/components"
import Mosaic from "components/v2/majesty/Mosaic"
import useConstant from "utils/useConstant"
import { useTranslations } from "contexts/I18N"
import { button } from "css/button"
import { body70, headline50 } from "css/text"
import { useWA } from "contexts/WA"
import { useEnv } from "contexts/Env"
import { useNavigation } from "contexts/Navigation"

const Context = createContext()
export function useKF() {
  return useContext(Context)
}

const ContextItem = createContext()
export function useKFItem() {
  return useContext(ContextItem)
}

const items = {
  hidden: {
    opacity: 0,
    transition: {
      type: "spring",
      ease: "easeInOut",
      bounce: 0,
      duration: 0.8,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      type: "spring",
      ease: "easeInOut",
      bounce: 0,
      delay: 0.6,
      duration: 0.8,
    },
  },
}
const expandFT = {
  hidden: {
    opacity: 0,
    y: "100vh",
    transition: {
      type: "spring",
      bounce: 0,
      ease: "easeInOut",
      delay: 0,
      duration: 0.4,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      bounce: 0,
      ease: "easeInOut",
      delay: 0,
      duration: 0.4,
    },
  },
}
const sectionTxt = {
  hidden: {
    opacity: 0,
    y: `88vh`,
    transition: {
      type: "spring",
      bounce: 0,
      ease: "easeInOut",
      delay: 0.2,
      duration: 0.5,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      bounce: 0,
      ease: "easeInOut",
      delay: 0.2,
      duration: 0.5,
    },
  },
}

const Root = styled.section`
  width: 100%;
  height: 100%;
  overflow: auto;

  & > ul {
    ${noScrollbar}

    display: flex;
    height: 100%;

    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: inline mandatory;
    scroll-behavior: smooth;

    justify-items: center;

    .puppet & {
      scroll-snap-type: inline none;
    }

    &::after {
      content: "";
      display: block;
    }

    & > li {
      display: flex;
      position: relative;
      flex-direction: column;
      background: rgb(var(--interface));
      height: var(--vh100);
      flex-basis: min-content;
      scroll-snap-align: center;
      width: 100%;
      min-width: 100%;

      .mosContainer {
        background: rgb(var(--interface));
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        background: rgb(var(--on-interface) / 0.2);
        pointer-events: none;
      }

      /* @media screen and (max-width: 480px) { */
      background: rgb(var(--on-interface));
      /* } */

      @media (min-aspect-ratio: 1/1) {
        flex-direction: row;
      }

      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        .puppet & {
          object-fit: cover;
        }
      }

      & > div:not(.vidContainer):not(.mosContainer):not(.zoomin):not(.textbutton) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        max-width: 100vw;
        margin: 0 auto;

        h3 {
          ${small}
          color: rgb(var(--sea-green));
          margin-block: 2rem 1rem;
        }

        h2 {
          ${large}
          color:rgb(var(--on-interface));
          margin-block-end: 1rem;
        }

        & > p {
          ${label}
          line-height: 1.6;
        }
      }
    }
  }
`

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(var(--on-interface));

  > img,
  > video {
    width: 100%;
    height: 100%;
    object-fit: contain;

    .puppet & {
      object-fit: cover;
    }
  }
`

const SectionTextFT = styled(motion.section)`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  align-items: flex-start;
  justify-content: center;
  z-index: var(--z-over);

  background: rgb(var(--pure-black) / calc(1 * 0.5));
  @supports (backdrop-filter: blur(1px)) OR (-webkit-backdrop-filter: blur(1px)) {
    background: rgb(var(--pure-black) / calc(1 * 0.5));
    backdrop-filter: blur(calc(0.7 * 5px));
    -webkit-backdrop-filter: blur(calc(0.7 * 5px));
  }

  & h2,
  h3,
  p {
    color: rgb(var(--highlight));
  }

  & > div {
    width: 60%;
    max-height: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 480px) {
      flex-direction: column;
      width: 70%;
      max-height: 75%;
    }
    & > div {
      display: flex;
      flex-direction: column;
      ${headline50}
      width:50%;
      margin-right: 20px;
      @media screen and (max-width: 480px) {
        width: 100%;
        margin-bottom: 20px;
      }
    }
    & > p {
      overflow-x: auto;
      width: 50%;
      ${body70}
      @media screen and (max-width: 480px) {
        width: 100%;
        padding-block: 0.2rem;
      }
    }
  }
  & > button {
    ${buttonRound}
    margin-block-start: env(safe-area-inset-top, 0px);
    background-color: rgb(var(--background) / 0.2);
    pointer-events: auto;
    position: fixed;
    right: 8vw;
    top: 5vw;
    z-index: 10;
    @media screen and (max-width: 480px) {
      top: 6vw;
    }
    &::before {
      content: "";
      background: rgb(var(--highlight));
      ${mask('<path d="m17 15 13 13-2 2-13-13-13 13-2-2 13-13-13-13 2-2 13 13 13-13 2 2z" />')}
    }
  }
`

const TextButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: calc(100vw - 16vw);
  margin: 0 auto;
  top: 66vh;
  left: 0;
  right: 0;
  z-index: 1;

  p {
    ${headline50}

    @media screen and (min-width:481px) {
      max-width: 60%;
    }

    color: rgb(var(--highlight));
    margin-block-end: 1.25rem;
  }
  button {
    ${button}
    color:rgb(var(--highlight));
    background-color: rgb(var(--background) / 0.2);
  }
`

const MarkerContainer = styled.div`
  height: fit-content;
  pointer-events: none;
  position: absolute;
  bottom: 2.5rem;
  z-index: 1;
  width: 100vw;

  & > div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  & > div > div {
    width: fit-content;
    display: grid;
    grid-template-columns: auto;
    grid-auto-flow: column;
    flex-direction: row;
  }
`

const Mark = styled(motion.button)`
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 99999px;
  background: rgb(var(--highlight));
  border: none;
  padding: 0;
  pointer-events: all;
  margin-inline: 0.5rem;

  /* outline: 1px solid rgba(var(--highlight)); */
`

function Marker({ index }) {
  const console = useConsole()
  const kf = useKF()
  const [active, setActive] = useState(false)

  const variants = {
    active: { width: "5.25rem", background: "rgb(var(--highlight))" },
    inactive: { width: "0.625rem", background: "rgb(var(--highlight))" },
  }
  const onClick = () => {
    if (active) return
    const event = new Event("select")
    Object.assign(event, { index })
    kf.dispatchEvent(event)
  }

  useLayoutEffect(() => {
    const onnavigate = ({ index: currIndex }) => {
      startTransition(() => setActive(currIndex === index))
    }

    kf.addEventListener("navigate", onnavigate)
    return () => {
      kf.removeEventListener("navigate", onnavigate)
    }
  })

  console.verbose("Marker(%o)", { index, active })
  return <Mark onClick={onClick} variants={variants} animate={active ? "active" : "inactive"} transition={{ type: "tween", ease: "easeOut", duration: 0.3 }} />
}

function Markers({ children }) {
  return (
    <MarkerContainer>
      <div>
        <div>
          {new Array(children?.length ?? 0).fill(null).map((_, i) => (
            <Marker key={`marker-${i}`} index={i} />
          ))}
        </div>
      </div>
    </MarkerContainer>
  )
}

function TextFeatures({ content, active, expand, setexpand, rmc, family }) {
  const { popin_subtitle_1, popin_title_1, popin_text_1, title } = content

  return (
    popin_title_1 && (
      <SectionTextFT variants={expandFT} initial='hidden' animate={expand ? "visible" : "hidden"}>
        <button
          onClick={() => {
            setexpand(false)
          }}
        />

        <motion.div variants={sectionTxt} initial='hidden' animate={expand ? "visible" : "hidden"}>
          <div>
            <h2 dangerouslySetInnerHTML={{ __html: popin_title_1 }} />
            <h3 dangerouslySetInnerHTML={{ __html: popin_subtitle_1 }} />
          </div>
          <p dangerouslySetInnerHTML={{ __html: popin_text_1 }} />
        </motion.div>
        {/* <Arrow style={{ top: !!expand ? "5vw" : "2vw", "--rotate": !!expand ? "270deg" : "90deg" }}>
          <svg viewBox='0 0 30 30'>
            <polygon points='21.2857143 29.7142857 5.71428571 14.8571429 21.2857143 1.29420284e-13 23.2857143 2 9.85714286 14.8571429 23.2857143 27.7142857'></polygon>
          </svg>
        </Arrow> */}
      </SectionTextFT>
    )
  )
}

function ItemsFeatures({ content = {}, id, active, rmc, family }) {
  const console = useConsole()
  const ws = useWebSocket()
  const rroot = useRef()
  const rvideo = useRef()
  const sync = useSync(id)
  const kf = useKF()
  const { index } = useKFItem()
  const translations = useTranslations()
  const [expand, setExpand] = useState(false)
  const wa = useWA()
  const env = useEnv()
  const navigation = useNavigation()

  const [fam, prodRmc] = process.browser && navigation.slug.split("/").slice(1).filter(Boolean)

  const {
    popin_image_1_landscape,
    popin_image_1_portrait,
    popin_subtitle_1,
    popin_title_1,
    popin_text_1,
    title,
    video,
    beautyShots,
    socialRenditions,
    poster,
  } = content
  const base = env.content.origin //process.env.SOLR_ASSET

  useLayoutEffect(() => {
    const root = rroot?.current
    if (!root) return
    const observer = new IntersectionObserver(
      ([{ isIntersecting }]) => {
        if (isIntersecting) {
          const event = new Event("navigate")
          Object.assign(event, { index })
          kf.dispatchEvent(event)
        }
      },
      { threshold: 0.9 }
    )
    observer.observe(root)
    return () => {
      observer.unobserve(root)
    }
  })

  const slide = () => rroot.current?.scrollIntoView?.({ behavior: "smooth", block: "end", inline: "start" })

  useLayoutEffect(() => {
    const onselect = ({ index: currIndex }) => {
      if (index !== currIndex) return
      slide()
    }
    kf.addEventListener("select", onselect)
    return () => kf.removeEventListener("select", onselect)
  }, [])

  useLayoutEffect(() => {
    if (ws.connector === "puppet") return

    const root = rroot?.current
    let timer
    if (!root) return
    const observer = new IntersectionObserver(
      ([{ isIntersecting }]) => {
        if (isIntersecting) {
          timer = setTimeout(() => sync.send("intersection", { isIntersecting: isIntersecting }), 100)
        } else {
          clearTimeout(timer)
        }
      },
      { threshold: 0.9 }
    )

    observer.observe(root)
    return () => {
      observer.unobserve(root)
    }
  })

  useLayoutEffect(() => {
    if (ws.connector === "catalog") return

    const onintersection = ({ isIntersecting }) => {
      if (isIntersecting) kf.scrollTo(index)
    }
    sync.addEventListener("intersection", onintersection)
    return () => {
      sync.removeEventListener("intersection", onintersection)
    }
  })

  useLayoutEffect(() => {
    const root = rroot?.current
    // const video = rvideo?.current
    let timer
    if (!root) return
    const observer = new IntersectionObserver(
      ([{ isIntersecting }]) => {
        if (isIntersecting) {
          timer = setTimeout(() => sync.send("play", { isIntersecting: isIntersecting }), 300)
          rvideo.current?.play({ rewind: true })
        } else {
          timer = setTimeout(() => sync.send("pause", { isIntersecting: isIntersecting }), 300)
          rvideo.current?.stop()
          clearTimeout(timer)
        }
      },
      { threshold: 0.3 }
    )

    observer.observe(root)
    return () => {
      observer.unobserve(root)
    }
  })

  // useLayoutEffect(() => {
  //   const video = rvideo?.current

  //   const onpause = ({ isIntersecting }) => {
  //     if (!isIntersecting) video?.stop()
  //   }
  //   const onplay = ({ isIntersecting }) => {
  //     if (isIntersecting) video?.play()
  //   }
  //   sync.addEventListener("play", onplay)
  //   sync.addEventListener("pause", onpause)
  //   return () => {
  //     sync.removeEventListener("play", onplay)
  //     sync.removeEventListener("pause", onpause)
  //   }
  // })

  console.verbose("RollerKeyFeatures:ItemsFeatures(%o)", { content })

  if (socialRenditions) {
    return (
      <motion.li ref={rroot} key={title + index} variants={items} initial='hidden' animate={active ? "visible" : "hidden"}>
        {/* <SocialGrid active={active} assets={content?.socialRenditions} /> */}
        <Suspense fallback={<div className='placeholder' />}>
          <img alt='' src={content?.socialRenditions?.[0]} loading='lazy' />
        </Suspense>
      </motion.li>
    )
  }

  if (content?.beautyShots?.length <= 1) return

  return (
    <>
      {content?.beautyShots?.length ? (
        <motion.li ref={rroot} key={title + index} variants={items} initial='hidden' animate={active ? "visible" : "hidden"}>
          <Mosaic active={active} beautyShots={content?.beautyShots} />
        </motion.li>
      ) : (
        <motion.li ref={rroot} key={title + index} variants={items} initial='hidden' animate={active ? "visible" : "hidden"}>
          {video ? (
            <Suspense fallback={null}>
              <VideoContainer className='vidContainer'>
                <Video ref={rvideo} source={video.url} poster={poster.url} loop={true} muted={true} controls={false} />
              </VideoContainer>
            </Suspense>
          ) : (
            <>
              <Suspense fallback={<div className='placeholder' />}>
                <img alt={title} src={base + popin_image_1_landscape} loading='lazy' />
              </Suspense>
              <TextButtonContainer className='textbutton'>
                <p>{content.popin_title_1}</p>
                <button
                  onClick={() => {
                    if (process.browser && document.querySelector(".embed")) {
                      wa.dispatch({
                        detail: {
                          triggerName: "readMoreClicked",
                          productFamily: family || fam,
                          productRMC: rmc || prodRmc,
                          readMoreSlide: "slide " + index + ": " + content.topic_key || "",
                          pageScreenMode: "embed screen",
                        },
                      })
                    } else {
                      wa.dispatch({
                        detail: {
                          triggerName: "readMoreClicked",
                          productFamily: family || fam,
                          productRMC: rmc || prodRmc,
                          readMoreSlide: "slide " + index + ": " + content.topic_key || "",
                        },
                      })
                    }
                    setExpand(true)
                  }}
                >
                  {translations["read-more"]}
                </button>
              </TextButtonContainer>
            </>
          )}
          <TextFeatures content={content} active={active} expand={expand} setexpand={setExpand} />
          {/* {ws.connector === "catalog" && !content?.video && !content.beautyShots && <TextFeatures />} */}

          {/* <motion.div variants={text} initial='hidden' animate={active ? "visible" : "hidden"}>
            <h3 dangerouslySetInnerHTML={{ __html: popin_subtitle_1 }} />
            <h2 dangerouslySetInnerHTML={{ __html: popin_title_1 }} />
            <p dangerouslySetInnerHTML={{ __html: popin_text_1 }} />
          </motion.div> */}
        </motion.li>
      )}
    </>
  )
}

function RollerKeyFeatures({ id, features, rmc, family }, handle) {
  const console = useConsole()
  const popover = usePopOver()
  const ws = useWebSocket()
  const sync = useSync(id + "rollkf")
  const scroll = useT({ t: 0, duration: 0, ease: pop.easeInOut })
  const et = useConstant(() => new EventTarget())

  const rtrack = useRef()
  /** CATALOG */
  useLayoutEffect(() => {
    if (ws.connector === "puppet") return
    sync.send("active", { active: popover.active })
  })

  /** PUPPET */
  useLayoutEffect(() => {
    if (ws.connector === "catalog") return

    const onactive = ({ active }) => {
      if (active) popover.show()
      else popover.hide()
    }

    // const onat = ({ at }) => {
    //   const { clientWidth, scrollWidth } = track
    //   const length = scrollWidth - clientWidth
    //   const scrollLeft = length * at
    //   track.scrollLeft = scrollLeft
    // }

    sync.addEventListener("active", onactive)
    return () => {
      sync.removeEventListener("active", onactive)
    }
  })

  const ctx = {
    scrollTo(idx, immediate = false) {
      const track = rtrack.current
      const { clientWidth, scrollWidth } = track
      const length = scrollWidth - clientWidth
      const at = track.querySelector(`li:nth-child(${idx + 1})`).offsetLeft / length
      if (immediate) scroll.set(at)
      else scroll.t(at)
    },
    addEventListener: (...args) => et.addEventListener(...args),
    removeEventListener: (...args) => et.removeEventListener(...args),
    dispatchEvent: (...args) => et.dispatchEvent(...args),
  }

  useLayoutEffect(() =>
    scroll.onChange(t => {
      const track = rtrack.current
      const { clientWidth, scrollWidth } = track
      const length = scrollWidth - clientWidth
      const scrollLeft = length * t
      track.scrollLeft = scrollLeft
    })
  )

  useImperativeHandle(handle, () => ctx)

  console.verbose("RollerKeyFeatures(%o)", { active: popover.active, features })
  return (
    <Root>
      <ul ref={rtrack}>
        <Context.Provider value={ctx}>
          {features &&
            features.map((content, i) => {
              return (
                <ContextItem.Provider value={{ index: i }} key={id + "-" + i}>
                  <ItemsFeatures rmc={rmc} family={family} rtrack={rtrack} active={popover.active} id={id + "-" + i} content={content} index={i} />
                </ContextItem.Provider>
              )
            })}
          <Markers children={features} />
        </Context.Provider>
      </ul>
    </Root>
  )
}

export default forwardRef(RollerKeyFeatures)
