import React, { useEffect, useState, useRef, useCallback, memo } from "react"
import { Main } from "./styles"
import { INOUT_DURATION, OUT_EASE, IN_EASE } from "./../constants"
import { useWatchVariations } from "contexts/Configurator"
import { logmine, cssApply } from "./../utils"
import { useTransform } from "framer-motion"

const DISPLAY_DELAY = 600

const VARIANTS = {
  main: {
    hidden: [
      [
        "transition",
        `opacity 300ms cubic-bezier(${OUT_EASE}), 
        visibility 0ms 200ms`,
      ],
      ["opacity", 0],
      ["visibility", "hidden"],
    ],
    visible: [
      [
        "transition",
        `opacity ${INOUT_DURATION[0]}ms cubic-bezier(${IN_EASE}) ${DISPLAY_DELAY}ms, 
        visibility 0ms 0ms`,
      ],
      ["visibility", "inherit"],
      ["opacity", 1],
    ],
  },
}

const Loader = ({ size, color = "#808284" }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'>
    {[
      {
        rotate: "rotate(0 50 50)",
        begin: "-0.9166666666666666s",
      },
      {
        rotate: "rotate(30 50 50)",
        begin: "-0.8333333333333334s",
      },
      {
        rotate: "rotate(60 50 50)",
        begin: "-0.75s",
      },
      {
        rotate: "rotate(90 50 50)",
        begin: "-0.6666666666666666s",
      },
      {
        rotate: "rotate(120 50 50)",
        begin: "-0.5833333333333334s",
      },
      {
        rotate: "rotate(150 50 50)",
        begin: "-0.5s",
      },
      {
        rotate: "rotate(180 50 50)",
        begin: "-0.4166666666666667s",
      },
      {
        rotate: "rotate(210 50 50)",
        begin: "-0.3333333333333333s",
      },
      {
        rotate: "rotate(240 50 50)",
        begin: "-0.25s",
      },
      {
        rotate: "rotate(270 50 50)",
        begin: "-0.16666666666666666s",
      },
      {
        rotate: "rotate(300 50 50)",
        begin: "-0.08333333333333333s",
      },
      {
        rotate: "rotate(330 50 50)",
        begin: "0s",
      },
    ].map(({ rotate, begin }, index) => (
      <g transform={rotate} key={index}>
        <rect x='46' y='2.5' rx='9.200000000000001' ry='0.5' width='8' height='15' fill={color}>
          <animate attributeName='opacity' values='1;0' keyTimes='0;1' dur='1s' begin={begin} repeatCount='indefinite' />
        </rect>
      </g>
    ))}
  </svg>
)

function useDisplay(busy, ref) {
  //logmine("CustomHook", "BusyLoader", "useDisplay")
  const display = useCallback(
    ([_busy]) => {
      const state = _busy
      cssApply(ref.current, VARIANTS.main[state ? "visible" : "hidden"])
    },
    [ref]
  )

  useTransform([busy], display)
}

export const BusyLoader = memo(({ size = 30 }) => {
  //logmine("Main", "BusyLoader")
  const { busy, tint } = useWatchVariations()
  const [theme] = useState(tint.get())
  const ref = useRef()

  useDisplay(busy, ref)

  return (
    <Main ref={ref}>
      <Loader size={size} color={"black"} />
    </Main>
  )
})
