import React, { useEffect, useRef, useCallback, memo } from "react"
import { Main, WLoader } from "./styles"
import { useWatchVariations, mvSubscribe } from "contexts/Configurator"
import { logmine, cssApply } from "./../utils"
import {
  IN_DURATION,
  OUT_DURATION,
  FADE_DURATION,
  FADE_EASE,
  INOUT_DURATION,
  IN_EASE,
  OUT_EASE,
  INOUT_EASE,
  UPDATE_LAG,
  LAUNCH_ROUTE,
  LAUNCH_DURATION,
  LAUNCH_EASE,
  FOCUS_DURATION,
  FOCUS_EASE,
} from "./../constants"
//import Loader from "./../../../Component/Loader"
import { useTransform } from "framer-motion"

const VARIANTS = {
  main: {
    hidden: [
      ["transition", "visibility 0ms 100ms"],
      ["visibility", "hidden"],
    ],
    visible: [
      ["transition", "visibility 0ms 0ms"],
      ["visibility", "inherit"],
    ],
  },
  loader: {
    hidden: [
      [
        "transition",
        `opacity ${INOUT_DURATION[1]}ms cubic-bezier(${OUT_EASE}),
        visibility 0ms ${INOUT_DURATION[1]}ms`,
      ],
      ["opacity", 0],
      ["visibility", "hidden"],
    ],
    visible: [
      [
        "transition",
        `opacity ${INOUT_DURATION[0]}ms cubic-bezier(${IN_EASE}) 100ms,
        visibility 0ms 0ms`,
      ],
      ["visibility", "inherit"],
      ["opacity", 1],
    ],
  },
}

function useDisplay(visible, ref) {
  //logmine("CustomHook", "GlassDoor", "useDisplay", visible)
  const display = useCallback(
    state => {
      //logmine("Callback", "GlassDoor", "useDisplay", "display")
      if (!ref.current) return
      cssApply(ref.current, VARIANTS.main[state ? "visible" : "hidden"])
    },
    [ref]
  )

  useEffect(() => mvSubscribe("GlassDoor useDisplay", visible, display, false), [visible, display])
}
/*
const Load = memo(({hashing}) => {
  //logmine("Main", "GlassDoor", "Load")
  const ref = useRef(null)

  const display = useCallback(state => {
    //logmine("Callback", "GlassDoor", "Load", "display")
    if (!ref.current) return
    cssApply(ref.current, VARIANTS.loader[state?"visible":"hidden"])
  }, [])

  useEffect(() => mvSubscribe("GlassDoor useDisplay", hashing, display, false), [hashing, display])

  return (
    <WLoader 
      ref={ref}
    >
      <Loader 
        size={60} 
      />
    </WLoader>
  )
})
*/
export const GlassDoor = memo(({ noLoader = false }) => {
  //logmine("Main", "GlassDoor")
  const { busy, hashing } = useWatchVariations()
  const ref = useRef()

  const update = useCallback(([a, b]) => {
    return !!(a || b)
  }, [])

  const visible = useTransform([busy, hashing], update)

  useDisplay(visible, ref)

  return (
    <Main ref={ref}>
      {/*!noLoader && <Load
        hashing={hashing}
      />*/}
    </Main>
  )
})
