import { css } from "@emotion/react"
import { visuallyHidden, bold, body50 } from "css/text"
import { linearToRight } from "css/gradients"

export function mask(path, size = 30) {
  return css`
    display: block;
    mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${size} ${size}">${path}</svg>');
    pointer-events: none;
  `
}

export const buttonReset = css`
  all: unset;
  box-sizing: border-box;
`

// TODO: remove
export const textButton = css`
  all: unset;
`

export const buttonCommon = css`
  ${buttonReset}
  --height: 2.5rem;
  align-items: center;
  display: inline-flex;
  justify-content: center;

  // icons
  ::before,
  ::after {
    background: currentColor;
    height: 15px;
    width: 15px;
  }

  :is(a, button):not(:disabled, .schrodinger-disabled) {
    cursor: pointer;
  }

  // "disabled" state
  :is(span),
  :disabled,
  .schrodinger-disabled {
    ::before,
    ::after {
      opacity: 0.5;
    }
    span {
      opacity: 0.5;
    }
  }
`

export const button = css`
  ${buttonCommon}
  ${body50}
  ${bold}
  background: rgb(var(--cultured));
  border-radius: 9999px;
  color: rgb(var(--raisin-black));
  height: var(--height);
  padding: calc(var(--height) / 5) calc(var(--height) / 1.5);
  white-space: nowrap;
  width: fit-content;

  ::before {
    margin-inline-end: 1ex;
    margin-inline-start: -1ex;
  }

  ::after {
    margin-inline-start: 1ex;
    margin-inline-end: -1ex;
  }

  * {
    pointer-events: none;
  }
`

export const buttonRound = css`
  ${buttonCommon}
  border-radius: 50%;
  height: var(--height);
  padding: 0.5rem;
  width: var(--height);

  ::before {
    content: "";
  }

  > span {
    ${visuallyHidden}
  }
`

export const biggerClickZone = css`
  --height: 3.5rem;

  @media screen and (max-width: 480px) {
    --height: 3rem;
  }
`

// ========================
// Variants
// ------------------------

export const buttonDark = css`
  background: rgb(var(--raisin-black));
  color: white;
`

export const buttonEmbed = css`
  background: rgb(var(--pure-white));
  color: rgb(var(--sea-green));
`

export const buttonLight = css`
  background: white;
  color: rgb(var(--salem));
`

export const buttonGreen = css`
  background: ${linearToRight("rgb(var(--green))", "rgb(var(--sea-green))")};
  color: white;
`
