import { useConsole } from "contexts/Console"
import { usePairing, readyStates } from "contexts/Pairing"
import styled from "@emotion/styled"
import { startTransition, useLayoutEffect, useState } from "react"
import { useTranslations } from "contexts/I18N"
import { buttonRound, mask } from "css/button"
import { linearToRight } from "css/gradients"

const Root = styled.div`
  position: fixed;
  z-index: var(--z-modal);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(var(--raisin-black), 0.3);

  & > button {
    ${buttonRound}
    background: ${linearToRight("rgb(var(--cultured))", "rgb(var(--pure-white))")};
    pointer-events: auto;
    position: absolute;
    right: 1rem;
    bottom: 1rem;

    &::before {
      content: "";
      background: rgb(var(--raisin-black));
      ${mask('<path d="m17 15 13 13-2 2-13-13-13 13-2-2 13-13-13-13 2-2 13 13 13-13 2 2z" />')}
    }
  }
`

export default function Status() {
  const console = useConsole()
  const pairing = usePairing()
  const [, setUpdate] = useState(Date.now())
  const translations = useTranslations()
  const active = pairing.activity.active

  useLayoutEffect(() => {
    const onupdate = () => startTransition(() => setUpdate(Date.now()))
    pairing.addEventListener("activitychange", onupdate)
    return () => pairing.removeEventListener("activitychange", onupdate)
  })

  const close = () => {
    pairing.close()
  }

  console.verbose("Header:Puppet:Status(%o)", { active })
  return null //TODO skin & explain what it does
  // return (
  //   pairing.readyState === readyStates.PAIRED &&
  //   !active && (
  //     <Root>
  //       <button onClick={close} aria-label={translations["close"]} title={translations["close"]}></button>
  //     </Root>
  //   )
  // )
}
