import { css } from "@emotion/react"

export const helvetica = css`
  font-family: "Helvetica Now Text", Helvetica, Arial, sans-serif;
`

export const rolex = css`
  font-family: "RolexFont-S", sans-serif;
`

export const bold = css`
  font-weight: 700;
`

export const normal = css`
  font-weight: 400;
`

export const light = css`
  font-weight: 300;
`

// ========================
// Styles
// ------------------------

/*
  Fluid type scale:
  https://www.fluid-type-scale.com/calculate?minFontSize=16&minWidth=640&minRatio=1.33&maxFontSize=20&maxWidth=1280&maxRatio=1.33&steps=sm%2Cbase%2Cmd%2Clg%2Cxl%2C2xl&baseStep=base&prefix=font-size&decimals=2&useRems=on&previewFont=Inter
  
  https://codesandbox.io/s/clamp-linear-intepolation-based-on-viewport-width-builder-xgkft
  min viewport: 640px
  max viewport: 1920px
  1rem === 16px
*/

// 44px > 100px
export const headline100 = css`
  ${bold}
  font-size: clamp(2.75rem, 1rem + 4.37vw, 6.25rem);
  line-height: 0.9;
`

// 36px > 70px
export const headline70 = css`
  ${bold}
  font-size: clamp(2.25rem, 1.1875rem + 2.6563vw, 4.375rem);
  line-height: 1.143;
`

// 30px > 50px
export const headline50 = css`
  ${bold}
  font-size: clamp(1.875rem, 1.25rem + 1.5625vw, 3.125rem);
  line-height: 1.2;
`

// 22px > 26px
export const headline30 = css`
  ${bold}
  font-size: clamp(1.375rem, 1.25rem + 0.3125vw, 1.625rem);
  line-height: 1.31;
`

// 20px > 30px
export const body100 = css`
  font-size: clamp(1.25rem, 0.9375rem + 0.7813vw, 1.875rem);
  line-height: 1.334;
`

// 18px > 24px
export const body70 = css`
  font-size: clamp(1.125rem, 0.9375rem + 0.4688vw, 1.5rem);
  line-height: 1.585;
`

// 16px > 20px
export const body50 = css`
  font-size: clamp(1rem, 0.875rem + 0.3125vw, 1.25rem);
  line-height: 1.6;
`

// 14px > 34px
export const legend150 = css`
  ${bold}
  font-size: clamp(0.875rem, 0.25rem + 1.5625vw, 2.125rem);
  line-height: 1;
`

// 14px > 28px
export const legend120 = css`
  ${light}
  font-size: clamp(0.875rem, 0.4375rem + 1.0938vw, 1.75rem);
  line-height: 1.075;
`

// 12px > 16px
export const legend100 = css`
  font-size: clamp(0.75rem, 0.625rem + 0.3125vw, 1rem);
  line-height: 1.375;
`

// 10px > 14px
export const legend80 = css`
  ${bold}
  font-size: clamp(0.625rem, 0.5rem + 0.3125vw, 0.875rem);
  line-height: 1.43;
`

// 13px > 20px
export const surtitle100 = css`
  ${rolex}
  ${normal}
  font-size: clamp(0.8125rem, 0.5938rem + 0.5469vw, 1.25rem);
  line-height: 1.4;
`

// 11px > 14px
export const surtitle70 = css`
  ${rolex}
  ${normal}
  font-size: clamp(0.6875rem, 0.5938rem + 0.2344vw, 0.875rem);
  line-height: 1.43;
`

// 9px > 12px
export const surtitle50 = css`
  ${rolex}
  ${normal}
  font-size: clamp(0.5625rem, 0.4688rem + 0.2344vw, 0.75rem);
  line-height: 1.42;
`

// ========================
// Utilities
// ------------------------

export const visuallyHidden = css`
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export function clamp(lines = 2) {
  return css`
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${lines};
  `
}
