import useConstant from "utils/useConstant"
import { useConsole } from "contexts/Console"
import { useLocale } from "contexts/Locale"
import pseudolocalize from "pseudolocalize"
import { Provider } from "contexts/I18N"

export default function I18N({ children, pseudolocalization = false, dictionaries = [] }) {
  const console = useConsole()
  const { locale: currLocale } = useLocale()

  const state = useConstant(() => {
    const initial = dictionaries.find(({ locale }) => locale === currLocale)?.translations ?? []
    return initial.reduce((translations, { key, value }) => ((translations[currLocale][key] = value), translations), { [currLocale]: {} }) //eslint-disable-line no-sequences
  })

  const transform = value => {
    if (pseudolocalization) return pseudolocalize(value)
    else return value
  }

  const translations = new Proxy(state?.[currLocale] ?? {}, {
    get: (t, k) => transform(t[k]) ?? pseudolocalize(k),
  })

  console.verbose("I18N(%o)", { state, dictionaries, currLocale, pseudolocalization })
  return <Provider value={{ translations, transform }}>{children}</Provider>
}
