import { useLayoutEffect } from "react"
import { useConsole } from "contexts/Console"
import { ctx } from "contexts/Selection"
import { useWebSocket, readyStates as wsStates } from "contexts/WebSocket"
import { useProspect, readyStates as prospectStates } from "contexts/Prospect"
import useConstant from "utils/useConstant"

export default function Selection({}) {
  const console = useConsole()
  const ws = useWebSocket()
  const prospect = useProspect()

  const et = useConstant(() => new EventTarget())
  const addEventListener = (...args) => et.addEventListener(...args)
  const removeEventListener = (...args) => et.removeEventListener(...args)
  const lists = useConstant(() => ({ favorites: null, wishlist: null, shown: null, plateau: null, wishlistFull: false, wishlistUpdate: -1 }))

  Object.assign(ctx, {
    addEventListener,
    removeEventListener,
    favorites: {
      addEventListener,
      removeEventListener,
      get list() {
        return lists.favorites && [...lists.favorites]
      },
      toggle: async reqRmc =>
        new Promise((resolve, reject) => {
          const event = `favorite:toggle`
          const timer = setTimeout(() => {
            reject("timeout")
            ws.removeEventListener(event, ontoggle)
          }, 1000)
          const ontoggle = ({ rmc: resRmc, active }) => {
            if (reqRmc !== resRmc) return
            clearTimeout(timer)
            ws.removeEventListener(event, ontoggle)
            resolve(active)
          }
          ws.addEventListener(event, ontoggle)
          ws.send(event, { rmc: reqRmc })
        }),
    },
    wishlist: {
      addEventListener,
      removeEventListener,
      get list() {
        return lists.wishlist && [...lists.wishlist]
      },
      get full() {
        return lists.wishlistFull
      },
      get update() {
        return lists.wishlistUpdate
      },
      toggle: async reqRmc =>
        new Promise((resolve, reject) => {
          const event = prospect.readyState === prospectStates.ACTIVE ? `prospect:wishlist:toggle` : "wishlist:toggle"
          const timer = setTimeout(() => {
            reject("timeout")
            ws.removeEventListener(event, ontoggle)
          }, 1000)
          const ontoggle = ({ rmc: resRmc, active, full, warn }) => {
            if (reqRmc !== resRmc) return

            lists.wishlistFull = full
            if (warn) {
              const event = new Event("warn")
              et.dispatchEvent(event)
            }

            clearTimeout(timer)
            ws.removeEventListener(event, ontoggle)
            resolve(active)
          }
          ws.addEventListener(event, ontoggle)
          ws.send(event, { rmc: reqRmc })
        }),
      // empty: async () =>
      //   new Promise((resolve, reject) => {
      //     const event = `wishlist:empty`
      //     const timer = setTimeout(() => {
      //       reject("timeout")
      //       ws.removeEventListener(event, onempty)
      //     }, 1000)
      //     const onempty = ({ full }) => {
      //       lists.wishlistFull = full

      //       clearTimeout(timer)
      //       ws.removeEventListener(event, onempty)
      //       resolve()
      //     }
      //     ws.addEventListener(event, onempty)
      //     ws.send(event)
      //   }),
      // import: async (list = []) =>
      //   new Promise((resolve, reject) => {
      //     const event = `wishlist:import`
      //     const timer = setTimeout(() => {
      //       reject("timeout")
      //       ws.removeEventListener(event, onimport)
      //     }, 1000)
      //     const onimport = ({ full }) => {
      //       lists.wishlistFull = full
      //       clearTimeout(timer)
      //       ws.removeEventListener(event, onimport)
      //       resolve()
      //     }
      //     ws.addEventListener(event, onimport)
      //     ws.send(event, { list })
      //   }),
    },
    shown: {
      addEventListener,
      removeEventListener,
      get list() {
        return lists.shown && [...lists.shown]
      },
      add: async reqRmc => {
        const event = `prospect:shown:add`
        ws.send(event, { rmc: reqRmc })
      },
    },
  })

  useLayoutEffect(() => {
    const event = `favorite:list`
    const onlist = ({ list }) => {
      if (!Array.isArray(list)) lists.favorites = null
      else lists.favorites = list
      const event = new Event("favoriteupdate")
      Object.assign(event, { list: [...lists.favorites] })
      et.dispatchEvent(event)
    }
    ws.addEventListener(event, onlist)
    if (ws.readyState === wsStates.TOKENIZED && !lists.favorites) ws.send(event)
    else if (ws.readyState !== wsStates.TOKENIZED) lists.favorites = null
    return () => ws.removeEventListener(event, onlist)
  })

  useLayoutEffect(() => {
    const event = prospect.readyState === prospectStates.ACTIVE ? `prospect:wishlist:list` : "wishlist:list"
    const onlist = ({ list, full }) => {
      lists.wishlistUpdate += 1
      lists.wishlistFull = full
      if (!Array.isArray(list)) lists.wishlist = null
      else lists.wishlist = list
      const event = new Event("wishlistupdate")
      Object.assign(event, { list: [...lists.wishlist] })
      et.dispatchEvent(event)
    }
    ws.addEventListener(event, onlist)
    if (prospect.readyState === prospectStates.ACTIVE /*&& !lists.wishlist*/ || ws.readyState === wsStates.TOKENIZED /*&& !lists.wishlist)*/) ws.send(event)
    else if (prospect.readyState !== prospectStates.ACTIVE || ws.readyState !== wsStates.TOKENIZED) lists.wishlist = null

    return () => ws.removeEventListener(event, onlist)
  })

  useLayoutEffect(() => {
    const event = `prospect:shown:list`
    const onlist = ({ list }) => {
      if (!Array.isArray(list)) lists.shown = null
      else lists.shown = list
      const event = new Event("shownupdate")
      Object.assign(event, { list: [...lists.shown] })
      et.dispatchEvent(event)
    }
    ws.addEventListener(event, onlist)
    if (prospect.readyState === prospectStates.ACTIVE && !lists.shown) ws.send(event)
    else if (prospect.readyState !== prospectStates.ACTIVE) lists.shown = null

    return () => ws.removeEventListener(event, onlist)
  })

  console.verbose("Selection()")
  return null
}
