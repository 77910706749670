import { compile, match } from "path-to-regexp"
import { useHistory, useLocation } from "react-router-dom"
import { useConsole } from "contexts/Console"
import { useLocale } from "contexts/Locale"
import { Provider } from "contexts/Navigation"
import { useLastLocation } from "react-router-last-location"
import useConstant from "utils/useConstant"
import EventTarget from "@ungap/event-target"
import { useEffect, useLayoutEffect } from "react"

export async function scrollToTop() {
  global.scrollTo(0, 0)

  return Promise.resolve()
  // return Promise.race([
  //   new Promise(resolve => setTimeout(resolve, 300)),
  //   new Promise(resolve => {
  //     const check = () =>
  //       requestAnimationFrame(() => {
  //         if (global.scrollY <= 0) resolve()
  //         else check()
  //       })
  //   }),
  // ])
}

let pwaHistory = []
export default function Navigation({ children, app, home, forceLocaleInURL, pages }) {
  const console = useConsole()
  const { defaultLocale, locales, schema, locale: currentLocale } = useLocale()
  const history = useHistory()
  const location = useLocation()
  const lastLocation = useLastLocation()
  const slug = "/" + ((match(`${schema}/:slug(.*)?`)(location.pathname) ?? {}).params?.slug || "")
  pages = useConstant(() => pages)
  const et = useConstant(() => new EventTarget())
  const lockers = useConstant(() => new Map())
  // const pwaHistory = useConstant(() => [])
  const pwa = global?.matchMedia?.("(display-mode: standalone)")?.matches || (process.browser && app === "embed")

  if (pwa) {
    //process.browser implicit
    const current = global.location.pathname + global.location.search + global.location.hash
    if (pwaHistory[pwaHistory.length - 1] !== current) pwaHistory.push(current)
  }

  useLayoutEffect(() => {
    if (!pwa) return

    const onurlchange = ({ url }) => (pwaHistory[pwaHistory.length - 1] = url)
    global.addEventListener("urlchange", onurlchange)
    return () => global.removeEventListener("urlchange", onurlchange)
  })

  const localize = (reqUrl, reqLocale = null) => {
    // let locale = reqLocale && locales.includes(reqLocale) ? reqLocale : forceLocaleInURL ? defaultLocale : null
    let locale = null
    if (locales.includes(reqLocale)) locale = reqLocale
    else locale = currentLocale || defaultLocale
    if (!forceLocaleInURL && locale === defaultLocale) locale = null
    const base = compile(schema)({ locale })
    const url = `${base}${reqUrl === "/" ? "" : reqUrl}`

    console.verbose("Navigation::localize(%o) => %o", { reqUrl, reqLocale }, { url, locale })
    return { url, locale }
  }

  const route = (reqUrl = "", reqLocale) => {
    const locale = reqLocale && locales.includes(reqLocale) ? reqLocale : forceLocaleInURL ? defaultLocale : null
    const base = locale ? schema.replace(/\/:locale\(.*\)\?/, `/${reqLocale}`) : schema
    const url = `${base}${reqUrl}`
    console.verbose("Navigation::route(%o) => %o", { reqUrl, reqLocale }, { url, locale })
    return { url, locale }
  }

  const push = async (reqUrl, state = null, replace = false) => {
    const url = new URL(reqUrl, `${global.location.protocol}//${global.location.hostname}`)
    return Promise.all([
      fetch(`${url.pathname}.model`),
      Promise.all(
        [...lockers].map(([id, handler]) => {
          unlock(id)
          return handler(url)
        })
      ).catch(err => console.error(err)),
    ])
      .then(([response]) => response.json())
      .then(({ id: pageId, ...pageProps }) => {
        const page = pages.find(({ id }) => id === pageId)
        if (page) Object.assign(page, { id: pageId, ...(pageProps ?? {}), fetch: true })
      })
      .then(scrollToTop)
      .then(async () => {
        const rule = `${schema}/:slug?`
        const slug = "/" + ((match(rule)(reqUrl) ?? {})?.params?.slug || "")
        const event = new Event("navigate")
        Object.assign(event, { method: "push", slug })
        et.dispatchEvent(event)

        if (pwa) history.replace(reqUrl, state)
        else history[replace ? "replace" : "push"](reqUrl, state)
      })
  }

  const model = async reqUrl => {
    console.info("model(%s)", reqUrl)
    const url = new URL(`${reqUrl}.model`, `${global.location.protocol}//${global.location.hostname}`)
    const request = new Request(url)
    return fetch(request)
      .then(response => response.json())
      .then(({ id: pageId, ...pageProps }) => {
        const page = pages.find(({ id }) => id === pageId)
        if (page) Object.assign(page, { id: pageId, ...(pageProps ?? {}), fetch: true })
        return pages.find(({ id }) => id === pageId)
      })
      .catch(err => console.error(err))
  }

  const replace = async (reqUrl, state = null) => push(reqUrl, state, true)

  const goBack = () => {
    if (pwa) {
      pwaHistory.splice(-1)
      push(pwaHistory[pwaHistory.length - 1])
    } else history.goBack()
  }

  const canGoBack = () => {
    if (pwa) return pwaHistory.length > 1
    else return !!lastLocation
  }

  const lock = (id, unlock) => {
    lockers.set(id, unlock)
  }

  const unlock = id => {
    const handler = lockers.get(id)
    lockers.delete(id)
    return handler
  }

  const addEventListener = (...args) => et.addEventListener(...args)
  const removeEventListener = (...args) => et.removeEventListener(...args)

  global.navigate = { push, replace, goBack }

  console.verbose("Navigation(%o)", { forceLocaleInURL, app, slug })
  return (
    <Provider
      value={{
        localize,
        route,
        history,
        push,
        replace,
        model,
        goBack,
        canGoBack,
        home,
        forceLocaleInURL,
        pages,
        slug,
        app,
        lock,
        unlock,
        addEventListener,
        removeEventListener,
      }}
    >
      {children}
    </Provider>
  )
}
