/**
 * don't copy this implementation
 * we use a single source of state without provider
 * to make this context "global"
 */

import { createContext, useContext } from "react"

export const ctx = {}
const Context = createContext(ctx)
export const Provider = ({ children }) => children

export function useFavorites() {
  return useContext(Context).favorites
}
export function useRecentlyShown() {
  return useContext(Context).shown
}
export function useWishlist() {
  return useContext(Context).wishlist
}
export function usePlateau() {
  return useContext(Context).plateau
}
