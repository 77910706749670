import { useConsole } from "contexts/Console"

export default function Image({ sources = [], lazy = false, sizes = "100vw", alt }) {
  const console = useConsole()
  const srcSet = sources.reduce(
    (acc, { srcset }) => (acc += (acc ? ", " : "") + srcset.map(({ width, url }) => `${url?.href ?? url} ${width}w`).join(", ")),
    ""
  )

  console.verbose("Image(%o)", { sources, lazy, srcSet, sizes })
  return (
    <img
      alt={alt ?? sources?.[0]?.srcset?.[0]?.alt ?? ""}
      srcSet={srcSet}
      loading={lazy ? "lazy" : null}
      sizes={sizes}
      width={sources?.[0]?.srcset?.[0]?.width}
      height={sources?.[0]?.srcset?.[0]?.height}
    />
  )
}
