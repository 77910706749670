import { useConsole } from "contexts/Console"
import styled from "@emotion/styled"
import { Action } from "components/v2/header/catalog/Header"
import { forwardRef, useRef, useState, useLayoutEffect, startTransition } from "react"
import { useNavigation } from "contexts/Navigation"
import { useProspect, readyStates as prospectStates } from "contexts/Prospect"
import hash from "@emotion/hash"
import { useWishlist } from "contexts/Selection"
import { createPortal } from "react-dom"
import { button } from "css/button"
import { legend100, bold } from "css/text"
import { useTranslations } from "contexts/I18N"
import { useWA } from "contexts/WA"

const portal = process.browser && document.body.appendChild(document.createElement("div"))

const wishlistClass = `css-${hash("wishlist")}`
const broadcastClass = `css-${hash("broadcast")}`

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, rgba(11, 62, 39, 1) 0%, rgba(25, 113, 73, 1) 100%);
  border-radius: 4ex;
  min-width: 120px;
  padding-inline: 55px;

  /* & a:not(.${wishlistClass}) {
    opacity: calc(0.6 + 0.4 * var(--pa));
    color: var(--t);
  } */

  & a:is(.${wishlistClass}) {
    pointer-events: auto;
    opacity: calc(var(--pr));
    transform: scale(var(--pr));
    padding: calc(var(--pr) * 1rem);
    max-width: calc(var(--pr) * 30px);
  }

  .${broadcastClass} {
    color: var(--t);
  }
`

const Counter = styled.div`
  display: flex;
  z-index: 1;
  width: 0.2rem;
  height: 0.2rem;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
  visibility: var(--v);
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  padding: 0.5rem;
  font-size: 0.6em;
  opacity: var(--o);
  transition: opacity 400ms ease;
  will-change: opacity, visibility;
  color: rgb(var(--interface));
  background: rgb(var(--sea-green));
  font-weight: 600;
  border-radius: 9999px;
  pointer-events: none;

  @media screen and (max-width: 480px) {
    font-size: 0.5rem;
  }
`

const Clip = styled.div`
  --safe: env(safe-area-inset-bottom, 0px);
  background-color: rgb(var(--cultured));
  pointer-events: none;
  position: absolute;
  z-index: calc(var(--z-ui) - 1);
  bottom: calc((60px + var(--safe)) + 2rem);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: var(--t);
  width: 60%;
  max-width: 60%;
  height: auto;
  transition: opacity 500ms;
  will-change: opacity;

  /* background: ${({ isHome }) => (!isHome ? `rgb(var(--background)/0.1););` : `rgb(var(--concrete));`)}; */

  > div {
    display: flex;
    padding: 0.5rem;
    opacity: var(--t);
    transition: opacity var(--d) calc(var(--delay) / 2.5);
    will-change: opacity;

    & > span {
      ${legend100}
      ${bold}
      display: flex;
      flex-direction: column;
      align-items: center;
      opacity: var(--t);
      font-weight: 400;
      color: rgb(var(--raisin-black));

      & > span {
        opacity: var(--t);
        font-weight: 600;
        color: rgb(var(--sea-green));
      }
    }
  }
`

function Session(props, ref) {
  const console = useConsole()
  const translations = useTranslations()
  const navigation = useNavigation()
  const prospect = useProspect()
  const rwishlist = useRef()
  const mirroring = prospect.readyState
  const wishlist = useWishlist()
  const [[count, countFrom], setCount] = useState([wishlist?.list?.length ?? 0, wishlist?.list?.length ?? 0])
  const [warn, setWarn] = useState(false)
  const wa = useWA()

  const t = +(count > countFrom || warn)

  useLayoutEffect(() => {
    let timer
    const onupdate = e => {
      startTransition(() => {
        setCount([e.list.length, wishlist.update < 1 ? e.list.length : count])
      })
    }
    const onwarn = e =>
      startTransition(() => {
        setWarn(true)
      })

    wishlist.addEventListener("wishlistupdate", onupdate)
    wishlist.addEventListener("warn", onwarn)
    return () => {
      clearTimeout(timer)
      wishlist.removeEventListener("wishlistupdate", onupdate)
      wishlist.removeEventListener("warn", onwarn)
    }
  })

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      startTransition(() => {
        if (wishlist.update >= 1) setCount([count, count])
        if (warn) setWarn(false)
      })
    }, 3500)
    return () => clearTimeout(timer)
  })

  useLayoutEffect(() => {
    if (mirroring !== 0) {
      wa.dispatch({
        detail: {
          triggerName: "pairingDevices",
          pairingStatus: "paired",
        },
      })
    }
  }, [mirroring])

  const navigate = e => {
    e.preventDefault()
    navigation.push(e.target.getAttribute("href"))
  }
  console.verbose("Header:catalog:Session()", { prospect: props.pr.t() > 0, count, countFrom })
  return (
    <Root ref={ref}>
      <Action
        style={{ "--t": mirroring == 2 || mirroring == 0 ? "rgb(var(--highlight))" : "#33C985" }}
        href={navigation.localize(props.prospect.href).url}
        onClick={navigate}
        title={props.prospect.label}
        className={broadcastClass}
      >
        <span>{mirroring == 0 ? props.prospect.label : translations["broadcasting"]}</span>
      </Action>
      <Action className={wishlistClass} ref={rwishlist} href={navigation.localize(props.wishlist.href).url} onClick={navigate} title={props.wishlist.label}>
        <span>{props.wishlist.label}</span>
        <Counter style={{ "--o": +count ? "1" : "0", "--v": !count ? "hidden" : "visible" }}>{count}</Counter>
      </Action>
      <Clip
        className='tooltip'
        style={{
          "--t": t,
        }}
      >
        <div
          style={{
            "--t": t,
            "--d": t + "s",
            "--delay": t + "s",
          }}
        >
          <span dangerouslySetInnerHTML={{ __html: warn ? translations["wishlist-warn-full"] : translations["wishlist-add-watches"] }} />
        </div>
      </Clip>
    </Root>
  )
}

export default forwardRef(Session)
