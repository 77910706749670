import { Suspense } from "react"
import * as components from "components/components"
import * as placeholders from "placeholders/placeholders"
import EmptyFallback from "components/Fallback"
import Missing from "components/Missing"

export function generateComponent({ symbol, id, component, fallback = null, props: initialProps, children, empty } = { empty: true }, console) {
  if (empty) {
    console?.warn?.("generateComponent() trying to generate a component from empty parameters")
    return
  }

  if (symbol) return generateComponent(symbol, console)

  const Component = components[component] ?? Missing
  const Fallback = placeholders?.[fallback]?.$$typeof !== Symbol.for("react.lazy") ? placeholders[fallback] ?? EmptyFallback : EmptyFallback
  const props = Array.isArray(initialProps)
    ? initialProps.reduce((acc, set) => {
        const { key, ...props } = set
        const values = Object.keys(props)

        if (key) {
          if (acc[key] && !Array.isArray(acc[key])) acc[key] = [acc[key]]
          const value = Object.keys(values).length === 1 ? props[values[0]] : props
          if (Array.isArray(acc[key])) acc[key].push(value)
          else acc[key] = value
        }

        // if (key && Object.keys(values).length === 1) return { ...acc, [key]: props[values[0]] }
        // else if (key) return { ...acc, [key]: props }
        return { ...acc, ...set }
      }, {})
    : initialProps

  console?.verbose?.("generateComponent(%o)", {
    id,
    component,
    Component,
    fallback,
    Fallback,
    lazy: Component?.$$typeof === Symbol.for("react.lazy"),
    props,
    children,
  })
  if (Component?.$$typeof === Symbol.for("react.lazy"))
    return (
      <Suspense key={id} fallback={<Fallback id={id} component={component} {...props} />}>
        <Component id={id} component={component} {...props}>
          {children?.map?.(child => generateComponent(child, console)) ?? null}
        </Component>
      </Suspense>
    )
  return (
    <Component key={id} id={id} component={component} {...props}>
      {children?.map?.(child => generateComponent(child, console)) ?? null}
    </Component>
  )
}
