import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { buttonRound, button, buttonLight } from "css/button"
import { headline70, legend100, body50, bold } from "css/text"
import { wviPhoneP, wviPhonePS, wviPhoneP6s, wviPadP, wviPadL } from "./../../../css/breakpoints"

export const Main = styled.div`
  position: absolute;
  top: calc(var(--wv-watch-height) / 2);
  left: 50%;
  transform: translate(-120px, -120px);
  //  padding-inline-start: 5vw;
  z-index: 4;
  visibility: hidden;
  opacity: 0;
  display: flex;
  flex-direction: column;
  .wv_puppet & {
    ${wviPhoneP(css`
      top: 56%;
    `)}
  }
  ${wviPadL(css`
    transform: translate(-160px, -130px);
  `)}
  ${wviPadP(css`
    transform: translate(-160px, -160px);
  `)}
  ${wviPhoneP(css`
    transform: translate(-120px, -120px);
    .wv_retailer & {
      transform: translate(-110px, calc(var(--wv-safe) - 130px));
    }
  `)}
  ${wviPhoneP6s(css`
    transform: translate(-120px, -120px);
    .wv_retailer &,
    .wv_standalone & {
      transform: translate(-110px, calc(var(--wv-safe) - 110px));
    }
  `)}
  ${wviPhonePS(css`
    transform: translate(-110px, -80px);
    .wv_retailer & {
      transform: translate(-110px, calc(var(--wv-safe) - 90px));
    }
  `)}
  & span {
    ${bold}
    ${legend100}
  }
  & > button {
    //    padding-left: 0;
    .wv_puppet & {
      pointer-events: auto !important;
    }
  }

  @keyframes wvwbfadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ._wvdb & {
    background: rgba(0, 0, 127, 0.9);
  }

  .wvNav & {
    position: relative;
    top: 0;
    left: 0;
    transform: none;

    & > button {
      padding: 0;
      & span {
        ${button}
        ${buttonLight}
        font-size: 1rem;
        color: rgb(var(--on-background) / 1);
      }
      &::before {
        display: none;
      }
    }
  }

  ${wviPhoneP(css`
    .wvNav & {
      transform: none;
      & > button {
        & span {
          padding: calc(var(--height) / 5) calc(var(--height) / 4);
        }
      }
    }
  `)}
`
