import { Suspense, lazy, useEffect, useLayoutEffect, useCallback, useRef, useState, memo } from "react"
import { useConsole } from "contexts/Console"
import { useWebSocket } from "contexts/WebSocket"
import { useLocale } from "contexts/Locale"
import { useWatchVariations, mvSubscribe, fromCollection } from "contexts/Configurator"
import { useTransform } from "framer-motion"
import { useNavigation } from "contexts/Navigation"
import { STEPS, TRANSIT_DELAY, LAUNCH_ROUTE, KILL_DURATION, ROUTE_REPLACE, ROUTE_IGNORE, TRACKING_DELAY } from "./../constants"
import { useRequest } from "./../hooks"
import { logmine, cleanRoute } from "./../utils"
import { useSync, useProspect, readyStates } from "contexts/Prospect"
import localeToSolr from "utils/localeToSolr"
import { useEnv } from "contexts/Env"
import { useWA } from "contexts/WA"

const FLAGSHIPS = new Map([
  ["1908", "m52508-0006"],
  ["datejust", "m126234-0051"],
  ["gmt-master-ii", "m126713grnr-0001"],
  ["day-date", "m228236-0012"],
  ["submariner", "m124060-0001"],
  ["oyster-perpetual", "m124300-0001"],
  ["cosmograph-daytona", "m126506-0001"],
  ["yacht-master", "m226627-0001"],
  ["lady-datejust", "m279135rbr-0001"],
  ["sea-dweller", "m126603-0001"],
  ["explorer", "m124273-0001"],
  ["sky-dweller", "m336239-0002"],
  ["air-king", "m116900-0001"],
])

function useResults(family, collection, updateDataLayer) {
  //logmine("Main", "Route", "useResults")
  const navigation = useNavigation()
  const env = useEnv()
  const url = navigation.localize("/watches/").url
  const base = env.solr.proxy ? (process.browser ? global.location.origin : `http://${process.env.SERVICE_NGINX}`) : env.solr.origin
  const pathname = env.solr.pathnames.variationsdetail
  const { locale } = useLocale()
  const [path, setPath] = useState(null)
  const results = useRequest(path, url)

  //    alert('ok')

  const clear = useCallback(() => {
    setPath(null)
  }, [])

  const update = useCallback(
    v => {
      //logmine("Callback", "Route", "useResults", "update")
      if (!v) return
      //      const _route = route.get()
      //      if (!_route) return clear()
      setPath(new URL(`${pathname}?fq=title:global&fq=familyCode:${v}&watch.fq=lang:${localeToSolr(locale)}`, base))
    },
    [pathname, locale]
  )

  //  useEffect(() => mvSubscribe("Route useResults", route, update), [route, update])
  useEffect(() => mvSubscribe("Route useResults", family, update), [family, update])
  //    useEffect(() => mvSubscribe("Route useResults", category, update), [category, update])

  useEffect(() => {
    //logmine("useEffect", "Route", "useResults", "results", results)
    collection.set(results)
  }, [results, collection])
}

export const Route = memo(() => {
  //logmine("Main", "Route")
  const console = useConsole()
  const ws = useWebSocket()
  const prospect = useProspect()

  const isProspect = prospect.readyState === readyStates.ACTIVE

  const {
    fromui,
    family,
    entry,
    steps,
    stepindex,
    newstepindex,
    active,
    newactive,
    collection,
    route,
    reset,
    variations,
    reversed,
    puppet,
    std,
    updateDataLayer,
  } = useWatchVariations()
  const readypuppet = useRef(ws.connector === "standalone" || puppet || !isProspect)
  const wa = useWA()

  useResults(family, collection, updateDataLayer)
  const tm = useRef(null)

  const findNextStep = useCallback(
    (wmodel, reverse) => {
      let step = null
      let sorted = variations[0].split(",")
      sorted = reverse ? sorted.reverse() : sorted
      sorted.map(v => {
        if (!step && !!wmodel.vars[v]) step = v
      })
      return step
    },
    [variations]
  )

  const exit = useCallback(
    replace => {
      fromui.set(true)
      route.set(`${replace ? ROUTE_REPLACE : ""}${LAUNCH_ROUTE}`)
    },
    [fromui, route]
  )

  const updateCollection = useCallback(
    (stepind, uid, vars) => {
      //logmine("Callback", "Route", "updateCollection", stepind, uid, vars)
      //        init()
      stepindex.set(stepind)
      newstepindex.set(stepind)
      active.set(uid)
      newactive.set(uid)
      let items = vars.split(",")
      const index = items.indexOf(uid)
      if (index < 0) return exit()
      //      throw new Error(`"${wmodel.uid}" model unfindable in "${STEP}" variations`)
      const _steps = [stepind, index, vars].join(",")
      steps.set(_steps)
      wa.dispatch({
        detail: updateDataLayer(),
      })
    },
    [stepindex, newstepindex, active, newactive, steps, exit]
  )

  const updateSelection = useCallback(() => {
    //logmine("Callback", "Route", "updateSelection")
    //        init()
    family.set(null)
    active.set(null)
    stepindex.set(0)
    steps.set(null)
    collection.set(null)
    //    active.set(_newactive)
  }, [steps, stepindex, family])

  const sync = useSync("config")

  const update = useCallback(() => {
    if (!readypuppet.current) return
    //logmine("Callback", "Route", "update", route.get())
    clearTimeout(tm.current)
    let _route = route.get()
    if (!puppet && !std) sync.send("route", { value: _route })
    if (!_route) return
    _route = cleanRoute(_route)
    let [FAMILY, RMC, STEP] = _route.split("/").slice(1)
    if (_route === LAUNCH_ROUTE) {
      if (!reset.get()) reset.set(_route)
      newstepindex.set(0)
      tm.current = setTimeout(updateSelection, TRANSIT_DELAY)
      return
    }
    if (!FAMILY) {
      family.set(null)
      newstepindex.set(0)
      steps.set(null)
      stepindex.set(0)
      collection.set(null)
      return
    }
    family.set(FAMILY)
    const _family = family.get()
    if (_family !== FAMILY) return
    const _collection = collection.get()
    if (!_collection) return
    RMC = RMC || FLAGSHIPS.get(FAMILY)
    if (!RMC) return exit()
    const wmodel = fromCollection(_collection)(RMC)
    if (!wmodel) return
    if (_family !== wmodel.familyCode) return exit()
    //    let _STEP = STEP
    //    if (STEP === "size") STEP = "model"
    const stepind = STEPS.indexOf(STEP)
    const vars = wmodel.vars[STEP]
    if (!STEP || !vars || stepind < 0) {
      //      if (puppet) return
      fromui.set(true)
      const _reversed = false //stepindex.get() !== 0
      reversed.set(_reversed)
      if (puppet) return
      route.set(`${ROUTE_REPLACE}/${FAMILY}/${RMC}/${findNextStep(wmodel, _reversed)}`)
      return
    }
    if (!reset.get()) reset.set(_route)
    newactive.set(wmodel.uid)
    newstepindex.set(stepind)
    tm.current = setTimeout(() => {
      updateCollection(stepind, wmodel.uid, vars)
    }, TRANSIT_DELAY)
  }, [newstepindex, newactive, collection, route, reset, exit, family, fromui, findNextStep, updateCollection])

  useEffect(() => mvSubscribe("Route", route, update, false), [route, update])
  useEffect(() => mvSubscribe("Route", collection, update, false), [collection, update])

  useEffect(() => {
    if (!puppet) return
    const onroute = ({ value }) => {
      route.set(value)
    }

    sync.addEventListener("route", onroute)
    sync.send("livingpuppet")
    return () => {
      sync.removeEventListener("route", onroute)
    }
  })

  useEffect(() => {
    if (puppet || std) return
    const onpuppet = () => {
      readypuppet.current = true
      update()
    }

    sync.addEventListener("livingpuppet", onpuppet)
    return () => {
      sync.removeEventListener("livingpuppet", onpuppet)
    }
  })

  return null
})
