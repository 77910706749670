import { useConsole } from "contexts/Console"
import { useLocale } from "contexts/Locale"
import { useAkamai } from "contexts/Akamai"

let queue = Promise.resolve()
export default function usePrice({ rmc, countryCode: overrideCountryCode = null, lang: overrideLocale = null }) {
  const console = useConsole()
  const { locale: defLocale } = useLocale()
  const { countryCode: defCountryCode } = useAkamai()

  const locale = overrideLocale ?? defLocale
  const countryCode = overrideCountryCode ?? defCountryCode

  const base = process.browser ? global.location.origin : `http://${process.env.SERVICE_NGINX}`
  const pathname = `/services/watch/${rmc}?lang=${locale}&countryCode=${countryCode}`
  // const pathname = navigation.localize(`/price/${rmc}/${countryCode}`, locale).url

  return (handler = Function.prototype) => {
    const url = new URL(pathname, base)
    const controller = new AbortController()
    const request = new Request(url, { mode: "no-cors" })

    queue = queue
      .then(() => {
        if (!controller.aborted)
          return fetch(request, { signal: controller.signal })
            .then(response => response.json())
            .then(data => handler(null, data))
            .catch(err => {
              if (err.name === "AbortError") return
              handler(err, null)
            })
      })
      .then(() => new Promise(resolve => setTimeout(resolve, 100)))

    console.verbose("usePrice(%o) => fetch", { url })
    return () => {
      controller.abort()
    }
  }
}
