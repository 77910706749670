import { Suspense, useState, useRef, useLayoutEffect } from "react"
import styled from "@emotion/styled"
import { label } from "css/heading"
import { labelNew, largeLSnoneMobile } from "css/heading-new"
import { useTranslations } from "contexts/I18N"
import { useWebSocket } from "contexts/WebSocket"
import hash from "@emotion/hash"
import { motion, useAnimationControls } from "framer-motion"
import { headline70, headline30, legend100, body50, body70, bold, light } from "css/text"
import watchName from "utils/watchName"
import { useEnv } from "contexts/Env"

const cssCollapsed = `css-${hash("collapsed")}`
const cssExpanded = `css-${hash("expanded")}`

const Root = styled.section`
  display: flex;
  flex-direction: column;
  height: calc(100% - 6vw);
  justify-items: center;

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 16vw);
    margin: 0 auto;
  }

  & > div:last-of-type {
    display: flex;
    flex-direction: column;
    margin-block-start: 10vw;
    padding-block-start: env(safe-area-inset-top, 0px);

    @media screen and (max-width: 480px) {
      margin-block-start: 13vw;

      .embed & {
        margin-block-start: 21vw;
      }
    }
  }
`
const Title = styled.h3`
  ${headline70}
  align-self: start;

  & > em {
    all: unset;
    ${headline70}
    color: rgb(var(--salem));
  }
`

const Ref = styled.p`
  ${legend100}
  ${bold}
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  margin-block-start: 0.5rem;
  width: 100%;

  & > span {
    ${light}
  }

  & > span:last-of-type {
    margin-inline-start: 0.375rem;
  }
`

const Header = styled.h4`
  ${headline30}
  ${bold}
  margin-block: 1rem;

  button {
    cursor: pointer;

    all: unset;
    display: flex;
    justify-content: space-between;
    transition: color 0.3s;
    width: 100%;
    align-items: center;
  }
`

const Icon = styled.span`
  &::before,
  &::after {
    background: currentColor;
    content: "";
    display: block;
    height: 3px;
    left: 0;
    position: absolute;
    right: 0;
    top: 7px;
    transition: transform 0.6s;
    will-change: transform;
  }

  .${cssCollapsed} & {
    &::after {
      transform: rotate(90deg);
    }
  }

  position: relative;
  height: 15px;
  width: 15px;

  @media screen and (max-width: 480px) {
    height: 2px;
    height: 12px;
    width: 15px;
  }
`

const List = styled.ul`
  width: calc(100vw - 16vw);
  margin: 0 auto;
  margin-block-start: 3.125rem;

  > li {
    border-top: 1px solid rgb(var(--platinum));
    &:first-of-type {
      border-top: none;
    }
    &:last-of-type {
      border-bottom: 1px solid rgb(var(--platinum));
    }
  }
`

const Panel = styled(motion.div)`
  width: 100%;
  overflow: hidden;
  height: 0;

  > ul {
    & > li:last-of-type {
      margin-block-end: 5rem;
      @media screen and (max-width: 480px) {
        margin-block-end: 2rem;
      }
    }
    > li {
      break-inside: avoid;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1rem;

      @media (min-aspect-ratio: 1/1) {
        padding-inline-end: 15rem;
      }

      @media screen and (max-width: 480px) {
        grid-template-columns: 1fr;
        column-gap: 0;
      }
    }
  }

  h5 {
    ${body70}
    margin-block:1.5rem 0.5rem;
    width: fit-content;
  }

  p {
    ${body70}
    ${light}
    line-height: 1.6;
    @media screen and (min-width: 481px) {
      margin-block: 1.5rem 0.5rem;
    }
  }
`

function Section({ texts, sources, i }) {
  const [isOpen, setIsOpen] = useState(false)
  const ws = useWebSocket()
  const controls = useAnimationControls()

  const expand = async () => {
    await controls.start({ height: "auto" })
    return await controls.start({ opacity: 1, translateY: 0 })
  }

  const collapse = async () => {
    await controls.start({ opacity: 0, translateY: "-5%" })
    return await controls.start({ height: 0 })
  }

  useLayoutEffect(() => {
    if (isOpen) expand()
    else collapse()
  }, [isOpen])

  return (
    <li>
      <Header mode={ws.connector} className={isOpen ? cssExpanded : cssCollapsed}>
        <button type='button' onClick={() => setIsOpen(!isOpen)}>
          {texts[0].title}
          <Icon />
        </button>
      </Header>
      <Panel animate={controls} role='region' transition={{ duration: 0.3 }}>
        <ul>
          {/* <Suspense fallback={<div className='placeholder' />}>
              <Image sources={sources} sizes={"50vw"} />
            </Suspense> */}
          {texts &&
            texts.map(({ title, text }, i) => {
              return (
                <>
                  {text && (
                    <li key={title + i}>
                      {title && <h5 dangerouslySetInnerHTML={{ __html: title }} />}
                      {text && <p dangerouslySetInnerHTML={{ __html: text }} />}
                    </li>
                  )}
                </>
              )
            })}
        </ul>
      </Panel>
    </li>
  )
}

export default function Specifications({ watch }) {
  const translations = useTranslations()
  const env = useEnv()
  const base = env.content.origin //process.env.SOLR_ASSET

  const items = [
    {
      sources: [
        { id: "1", srcset: [{ alt: translations["model-case"], width: 750, url: new URL(watch?.editorial_mapping?.specs?.case_image_landscape, base) }] },
      ],
      texts: [
        { title: translations["model-case"], text: watch?.case?.labels?.title },
        { title: translations["oyster-architecture"], text: watch?.case?.labels?.oyster_architecture },
        { title: translations["diameter"], text: watch?.case?.labels?.diameter },
        { title: translations["material"], text: watch?.case?.labels?.material },
        { title: translations["bezel"], text: watch?.case?.labels?.bezel },
        { title: translations["winding-crown"], text: watch?.case?.labels?.winding_crown },
        { title: translations["crystal"], text: watch?.case?.labels?.crystal },
        { title: translations["water-resistance"], text: watch?.case?.labels?.water_resistance },
      ],
    },
    {
      sources: [
        { id: "2", srcset: [{ alt: translations["movement"], width: 750, url: new URL(watch?.editorial_mapping?.specs?.movement_image_landscape, base) }] },
      ],
      texts: [
        { title: translations["movement"], text: watch?.movement?.labels?.title },
        { title: translations["calibre"], text: watch?.movement?.labels?.calibre },
        { title: translations["precision"], text: watch?.movement?.labels?.precision_static },
        { title: translations["functions"], text: watch?.movement?.labels?.functions },
        { title: translations["oscillator"], text: watch?.movement?.labels?.oscillator },
        { title: translations["winding"], text: watch?.movement?.labels?.winding },
        { title: translations["power-reserve"], text: watch?.movement?.labels?.autonomy },
      ],
    },
    {
      sources: [
        { id: "3", srcset: [{ alt: translations["bracelet"], width: 750, url: new URL(watch?.editorial_mapping?.specs?.bracelet_image_landscape, base) }] },
      ],
      texts: [
        { title: translations["bracelet"], text: watch?.bracelet?.labels?.title },
        { title: translations["material"], text: watch?.bracelet?.labels?.material },
        { title: translations["clasp"], text: watch?.bracelet?.labels?.clasp_type },
      ],
    },
    {
      sources: [{ id: "4", srcset: [{ alt: translations["dial"], width: 750, url: new URL(watch?.editorial_mapping?.specs?.dial_image_landscape, base) }] }],
      texts: [
        { title: translations["dial"], text: watch?.dial?.labels?.title },
        { title: translations["gem-settings"], text: watch?.dial?.labels?.gem_settings },
        { title: translations["details"], text: watch?.dial?.labels?.details },
      ],
    },
    {
      sources: [
        {
          id: "5",
          srcset: [{ alt: translations["certification"], width: 750, url: new URL(watch?.editorial_mapping?.specs?.certification_image_landscape, base) }],
        },
      ],
      texts: [{ title: translations["certification"], text: watch?.movement?.labels?.certification }],
    },
  ]

  return (
    <Root>
      <div>
        <Title dangerouslySetInnerHTML={{ __html: translations["specifications-title"].replace("%watchname%", watchName(watch?.title)) }} />
        <Ref>
          {/* <span dangerouslySetInnerHTML={{ __html: translations["reference"] }} /> */}
          <span dangerouslySetInnerHTML={{ __html: watch?.rmc }} />
        </Ref>
      </div>
      <List>{items && items.map((props, index) => <Section key={index} index={index} {...props} />)}</List>
    </Root>
  )
}
