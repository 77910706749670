import styled from "@emotion/styled"
import { Picture } from "components/components"
import { headline100, body70 } from "css/text"
import Geneve from "components/Geneve"
const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: var(--z-over);
  color: rgb(var(--highlight));
  pointer-events: auto;
  background: rgb(var(--raisin-black));

  svg {
    display: flex;
    justify-content: center;
    height: fit-content;
    margin: 0 auto;
    fill: currentColor;
    position: absolute;
    bottom: 2rem;
    left: 0;
    right: 0;
    align-self: center;

    @media (min-aspect-ratio: 1/1) {
      bottom: 1rem;
      padding-bottom: env(safe-area-inset-bottom, 0px);
    }
    @media screen and (max-width: 480px) {
      bottom: 2rem;
    }
  }

  picture {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  div {
    position: absolute;
    max-width: 65vw;
    margin-inline-start: 8vw;
    margin-block-end: 20vw;
    bottom: 1rem;
    left: 0;
    right: 0;
    padding-bottom: env(safe-area-inset-bottom, 0px);

    @media (min-aspect-ratio: 1/1) {
      margin-block-end: 15vw;
    }

    @media screen and (max-width: 480px) {
      max-width: 80vw;
      bottom: 8rem;
    }

    h1 {
      color: rgb(var(--highlight));
      ${headline100}
      margin-block-end:1rem;
    }
    p {
      color: rgb(var(--highlight));
      ${body70}
    }
  }
`

export default function Splash({ splashTitle, splashDescription, picture, ...props }) {
  return (
    <Root>
      <Picture sources={picture.sources} />
      <div>
        <h1>{splashTitle}</h1>
        <p>{splashDescription}</p>
      </div>
      <Geneve />
    </Root>
  )
}
