import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { getMediaQuery } from "./../../../css/breakpoints"
import { gradient, small, big, label } from "css/heading"
import { noScrollbar } from "css/helper"
import { headline70, legend100, body50, body70, bold, light } from "css/text"
import { wviPhoneP, wviPhonePS, wviPadP, wviPadL } from "./../../../css/breakpoints"

export const Main = styled.nav`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 2;
  width: 100%;
  text-align: start;
  top: var(--wv-vars-top);
  ._wvdb & {
    background: rgba(255, 255, 0, 0.9);
  }
`

export const List = styled.ul`
  ${noScrollbar}
  position: relative;
  display: flex;
  grid-gap: 0.75rem;
  flex-direction: row;
  overflow-x: auto;
  scrollbar-width: none;
  scroll-behavior: auto;
  left: -0.75rem;

  ${wviPhoneP(css`
    grid-gap: 0;
    left: -0;
  `)}

  &::-webkit-scrollbar {
    display: none;
  }

  &:before,
  &:after {
    content: "";
    flex: 0 0 calc(8vw - 15px);
  }
`
export const Title = styled.p`
  ${body50}
  padding-inline-start: 8vw;
`
