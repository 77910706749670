import { css } from "@emotion/react"
import { linearToRight } from "css/gradients"

export function gradient(darker, lighter) {
  return css`
    background: ${linearToRight(darker, lighter)};
    background-clip: text;
    color: transparent;
  `
}

/**
 * https://codesandbox.io/s/clamp-linear-intepolation-based-on-viewport-width-builder-xgkft
 */

export const bigger = css`
  /* font-size: 3.125rem; */
  font-size: clamp(2.075rem, 1.9375rem + 3.1667vw, 4.125rem);
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 1.3;
`

export const biggerLhSmall = css`
  /* font-size: 3.125rem; */
  font-size: clamp(2.075rem, 1.9375rem + 3.1667vw, 4.125rem);
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 1;
`

export const big = css`
  /* font-size: 3.125rem; */
  font-size: clamp(1.875rem, 0.9375rem + 4.1667vw, 3.125rem);
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 1.3;
`

export const bigLhSmall = css`
  font-size: clamp(1.875rem, 0.9375rem + 4.1667vw, 3.125rem);
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 1;
`

export const bigBanner = css`
  font-size: clamp(1.875rem, 0.9375rem + 4.1667vw, 3.125rem);
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 1.2;
`

export const large = css`
  /* font-size: 1.875rem; */
  font-size: clamp(1.3rem, -0.0938rem + 3.75vw, 1.275rem);
  font-weight: 600;
  line-height: 1.1;
`

export const largeNew = css`
  /* font-size: 1.875rem; */
  font-size: clamp(1.5rem, -0.0938rem + 3.75vw, 1.275rem);
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 1.1;
`
export const anotherOne = css`
  ${largeNew}
  font-size: clamp(1.8rem, -0.0938rem + 3.75vw, 1.7rem);
`

export const configButtons = css`
  ${largeNew}
  font-size: clamp(2.8rem, -0.0938rem + 3.75vw, 1.7rem);
`

export const largeNewLighter = css`
  /* font-size: 1.875rem; */
  font-size: clamp(1.275rem, 0.0938rem + 3.75vw, 0.925rem);
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 1.2;
`

export const largeNewLighterTkaway = css`
  /* font-size: 1.875rem; */
  font-size: clamp(1.075rem, 0.0938rem + 3.75vw, 0.925rem);
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 1.1;
`

export const largeLPnone = css`
  /* font-size: 1.875rem; */
  font-size: clamp(1.3rem, -0.0938rem + 3.75vw, 1.275rem);
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 1.1;
`

export const largeLSnoneMobile = css`
  /* font-size: 1.875rem; */
  font-size: clamp(1.1rem, -0.0938rem + 3.75vw, 1.275rem);
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 1.1;
`

export const lightLargeLSnone = css`
  /* font-size: 1.875rem; */
  font-size: clamp(1.3rem, -0.0938rem + 3.75vw, 1.275rem);
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 1.1;
`

export const medium = css`
  /* font-size: 0.8rem; */
  font-size: clamp(0.625rem, 0.4937rem + 0.5833vw, 0.8rem);
  font-weight: 700;
  letter-spacing: 0.07em;
  line-height: 1.05;
`

export const mediumLighter = css`
  font-size: clamp(0.925rem, 0.4937rem + 0.5833vw, 0.8rem);
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.2;
`

export const mediumThin = css`
  font-size: clamp(0.925rem, 0.4937rem + 0.5833vw, 0.8rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.2;
`

export const smallMedium = css`
  font-size: clamp(0.75rem, -0.0938rem + 3.75vw, 0.975rem);
  font-weight: 300;
  line-height: 1.3;
`

export const intermediate = css`
  font-size: clamp(0.725rem, 0.5313rem + 0.4167vw, 0.75rem);
  font-weight: 400;
  line-height: 1.3;
`

export const intermediateWeighter = css`
  font-size: clamp(0.725rem, 0.5313rem + 0.4167vw, 0.75rem);
  font-weight: 600;
  line-height: 1.3;
`

export const labelNew = css`
  font-size: clamp(0.75rem, -0.0938rem + 3.75vw, 1.275rem);
  font-weight: 300;
  line-height: 1.3;
`

export const labelNewLH = css`
  font-size: clamp(0.75rem, -0.0938rem + 3.75vw, 1rem);
  font-weight: 300;
  line-height: 1.4;
`

export const labelSmall = css`
  font-size: clamp(0.625rem, 0.4375rem + 0.8333vw, 0.675rem);
  font-weight: 300;
  letter-spacing: 0.05em;
  line-height: 1.3;
`

export const largeMobile = css`
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 1.1;
`

export const smallMobile = css`
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 1.1;
`

export const takeawayTitle = css`
  font-size: clamp(1.875rem, 1.4063rem + 2.0833vw, 2.5rem);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 0.9;
`

export default css`
  font-family: RolexFont-S, sans-serif;
  letter-spacing: 0.1em;
`
