import React, { useState, useCallback, useEffect, useMemo, useRef, memo, useLayoutEffect } from "react"
import { Main, Figure, Caption } from "./styles"
import Carol from "./../../carol"
import { STEPS, INOUT_DURATION, INOUT_EASE, IN_EASE, OUT_EASE, OUT_DURATION, UPDATE_LAG } from "./../constants"
import { useMediaLoader, setPicture, setSource } from "./../hooks"
import { CarolProvider, useCarol } from "./../../carol/context"
import { mvSubscribe, useWatchVariations, fromCollection } from "contexts/Configurator"
import { logmine, cssApply } from "./../utils"
import { useTransform } from "framer-motion"
import { useSync } from "contexts/Prospect"
import { useEnv } from "contexts/Env"
import { Image } from "components/components"
import { getSOLRPicture } from "solr/useSOLRPicture"

const VARIANTS = {
  main: {
    hidden: [
      [
        "transition",
        `opacity ${INOUT_DURATION[1]}ms cubic-bezier(${OUT_EASE}),
        height 0ms ${INOUT_DURATION[1]}ms`,
      ],
      ["opacity", 0],
      ["height", "0"],
    ],
    visible: [
      [
        "transition",
        `opacity ${INOUT_DURATION[0]}ms cubic-bezier(${IN_EASE}),
        height 0ms 0ms`,
      ],
      ["height", "calc(var(--wv-watch-height)"],
      ["opacity", 1],
    ],
  },
}

function useItems(steps, collection, active) {
  //logmine("CustomHook", "StepList", "useItems")
  const [items, setItems] = useState(null)
  const env = useEnv()

  const clear = useCallback(() => {
    return setItems(null)
  }, [])

  const update = useCallback(() => {
    //logmine("Callback", "StepList", "useItems update", steps.get(), collection.get())
    const _steps = steps.get()
    if (!_steps) return clear()
    let [_stepindex, _start, ..._items] = _steps.split(",")
    _stepindex = +_stepindex
    _start = +_start
    if (_stepindex <= 0) return clear()
    const _collection = collection.get()
    if (!_collection) return clear()
    //    const fullwatch = _stepindex === 4 || _stepindex < 2
    //    const imagesPath = `${env.content.origin}${env.content.pathname}` //"https://content.rolex.com/dam/2022"
    const step = STEPS[_stepindex]
    _items = _items.map(fromCollection(_collection))
    const unready = !!_items.filter(v => !v).length
    if (unready) return
    _items = _items.map(v => {
      let ind = _stepindex === 1 ? 2 : _stepindex + 1
      let ret = {
        k: `WVSL_${_stepindex}_${v.uid}`,
        uid: v.uid,
        picture: getSOLRPicture({
          rmc: v.assets[step],
          policy: env.cloudinary.policies.configure.main,
          folder: env.cloudinary.folders.configure[step],
          env
        }),
        caption: v.specs[ind]
      }
      if (_stepindex === 1 || _stepindex === 2)
        ret.shadow = {
          picture: getSOLRPicture({
            rmc: v.uid,
            policy: env.cloudinary.policies.configure.shadow,
            folder: env.cloudinary.folders.configure.shadow,
            env
          })
        }
      return ret
    })
    setItems([_stepindex, _start].concat(_items))
    active.set(_items[_start].uid)
  }, [steps, active, collection, clear])

  useEffect(() => mvSubscribe("StepList useItems", steps, update), [steps, update])
  useEffect(() => mvSubscribe("StepList useItems", collection, update), [collection, update])

  return items
}

function useReady(items, ref, loadQ) {
  //logmine("CustomHook", "StepList", "useReady")
  const ready = useMediaLoader("WVSL", ref, items)

  const update = useCallback(
    v => {
      if (v) loadQ(1)
    },
    [loadQ]
  )

  useEffect(() => mvSubscribe("StepList useDisplay", ready, update), [ready, update])
}

function useDisplay(route, newstepindex, stepindex, busy, mode, ref) {
  //logmine("CustomHook", "StepList", "useDisplay")
  const update = useCallback(([_busy, _newstepindex, _stepindex]) => {
    //logmine("Callback", "StepList", "useDisplay", "update")
    let state = !_busy
    state = state && _stepindex > 0 && _stepindex < 7 && _newstepindex > 0 && _newstepindex < 7
    //    console.log(_busy, _newstepindex, _stepindex, state)
    return state
  }, [])

  const display = useCallback(
    state => {
      //logmine("Callback", "StepList", "useDisplay", "display", state)
      cssApply(ref.current, VARIANTS.main[state ? "visible" : "hidden"])
    },
    [ref]
  )

  const hide = useCallback(() => {
    //logmine("Callback", "StepList", "useDisplay", "hide")
    display(false)
  }, [display])

  const shouldDisplay = useTransform([busy, newstepindex, stepindex], update)
  useEffect(() => mvSubscribe("StepList useDisplay", shouldDisplay, display), [shouldDisplay, display])
  useEffect(() => mvSubscribe("StepList useDisplay", route, hide), [route, hide])
}

function useFocus(items, ref, active, focused) {
  //logmine("CustomHook", "StepList", "useFocus")
  useEffect(() => {
    const unsub = focused.onChange(onFocusChange)
    let tm = null

    function onFocusChange(v) {
      clearTimeout(tm)
      //      console.log("onFocusChange", v, items)
      if (!items) return
      updateFocus()
      tm = setTimeout(() => {
        let item = items[v + 2]
        if (!item) return
        active.set(item.uid)
      }, UPDATE_LAG)

      function updateFocus() {
        const { current: root } = ref
        if (!root) return
        const col = [...root.querySelectorAll("li > figure")]
        col.map((el, i) => el.setAttribute("data-focused", i === +v))
      }
    }

    return () => {
      clearTimeout(tm)
      unsub()
    }
  }, [ref, items, active, focused])
}

function useInit(newstepindex, category, mode, initQ, loadQ) {
  //logmine("CustomHook", "StepList", "useInit")
  const update = useCallback(
    v => {
      if (v > 0 && v < 7) initQ(1)
      else loadQ(1)
    },
    [initQ, loadQ]
  )

  const categoryUpdate = useCallback(
    v => {
      if (!v || mode !== "ymal") return
      initQ(1)
    },
    [initQ, mode]
  )

  useEffect(() => mvSubscribe("StepList", newstepindex, update), [newstepindex, update])
  useEffect(() => mvSubscribe("StepList", category, categoryUpdate), [category, categoryUpdate])
}

const Picture = memo(({ picture }) => {
  return (
    <Image sources={picture} lazy={false} sizes="50vw" />
  )
})

export const StepListContents = memo(() => {
  //logmine("Main", "StepList")
  const { focus, route, steps, stepindex, newstepindex, active, category, mode, collection, loadQ, initQ, tint, busy, moving, puppet } = useWatchVariations()

  const ref = useRef(null)
  const [theme] = useState(tint.get())
  const items = useItems(steps, collection, active)
  const { focused, scrolling, wsl } = useCarol()
  const sync = useSync("config")

  const scrollingUpdate = useCallback(() => {
    moving.set(scrolling.get())
  }, [moving, scrolling])

  function onWrapperScroll(v) {
    if (puppet) return
    sync.send("wscroll", { value: v })
  }

  useDisplay(route, newstepindex, stepindex, busy, mode, ref)
  useReady(items, ref, loadQ)
  useFocus(items, ref, active, focused)
  useInit(newstepindex, category, mode, initQ, loadQ)

  useEffect(() => mvSubscribe("StepList scrolling", scrolling, scrollingUpdate), [scrolling, scrollingUpdate])
  useEffect(() => mvSubscribe("StepList scrolling", wsl, onWrapperScroll), [wsl, onWrapperScroll])

  useLayoutEffect(() => {
    if (!puppet) return
    const onscroll = ({ value }) => {
      if (busy.get()) return
      const { current: main } = ref
      if (!main) return
      const el = main.querySelector("ul")
      const max = el.scrollWidth - el.offsetWidth
      el.scrollLeft = value * max
      //      console.log(scroll * max)
    }

    sync.addEventListener("wscroll", onscroll)
    return () => {
      sync.removeEventListener("wscroll", onscroll)
    }
  })

  return (
    <Main ref={ref}>
      <Carol
        type='configurator'
        marginLeft='small'
        height='small'
        startSlide={(items && items[1]) || 0}
        focusedFade={(items && items[0] > 2) || false}
        backgroundColor={theme}
        onFocusClick={focus}
        indexTabbed={true}
        remote={puppet}
      >
        {!!items &&
          items.slice(2).map((item, i) => (
            <Figure data-focused={i === items[1]} pointer={mode !== "global"} key={item.k}>
              {item.shadow && <Picture {...item.shadow} />}
              <Picture {...item} />
              <Caption theme={theme}>
                <span>{item.caption}</span>
              </Caption>
            </Figure>
          ))}
      </Carol>
    </Main>
  )
})

export const StepList = memo(props => {
  const { puppet } = useWatchVariations()

  return (
    <CarolProvider remote={puppet}>
      <StepListContents {...props} />
    </CarolProvider>
  )
})
