import styled from "@emotion/styled"
import Lobby from "components/v2/standalone/lobby/Lobby"
import Selection, { Wishlist } from "components/SelectionSTD"
import Crown from "components/Crown"
import { Back } from "components/components"
import { useNavigation } from "contexts/Navigation"
import { useTranslations } from "contexts/I18N"

const Root = styled.header()

const Top = styled.div`
  --safe: env(safe-area-inset-top, 0px);
  margin-block-start: var(--safe);
  left: 0;
  top: 0;
  right: 0;
  position: absolute;
  z-index: var(--z-ui);

  svg {
    height: 30px;
    width: auto;
    position: absolute;
    top: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .wishlist {
    --safe: env(safe-area-inset-bottom, 0px);
    position: absolute;
    display: flex;
    right: 8vw;
    top: 1.5rem;
  }
`

function Link({ children, href }) {
  const navigation = useNavigation()
  href = navigation.localize(href).url

  const navigate = e => {
    e.preventDefault()
    navigation.push(href)
  }

  return (
    <a href={href} onClick={navigate}>
      {children}
    </a>
  )
}

export default function Header({ children, link, ...props }) {
  const translations = useTranslations()
  const navigation = useNavigation()

  const navigate = e => {
    e.preventDefault()
    navigation.push(e.target.getAttribute("href"))
  }

  return (
    <>
      <Selection>
        <Lobby {...props}>{children}</Lobby>
        <Root>
          <Top>
            <Back>{translations["back"]}</Back>
            <Link {...props.home}>
              <Crown />
            </Link>
            <Wishlist />
          </Top>
        </Root>
      </Selection>
    </>
  )
}
