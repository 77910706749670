import React, { useState, useRef, useEffect, useLayoutEffect, memo } from "react"
import { Suspense, lazy } from "react"
import { Toggle, toggle, togglePrice } from "./../../v2/majesty/CatalogMajesty"
//import { WatchNameLabel, Legend } from "./../../VariousText"
import { INOUT_DURATION, IN_EASE, OUT_EASE, FADE_DURATION, FADE_EASE, EMPTY_STRING } from "./../constants"
//import PricePanel from "./../../../Component/PricePanel"
import { PPrice } from "./styles"
import { useWatchVariations, mvSubscribe } from "contexts/Configurator"
import { logmine, cssApply, upperFirstLetter } from "./../utils"
import { useTransform } from "framer-motion"
import { useTranslations } from "contexts/I18N"
import { motion } from "framer-motion"
import usePrice from "utils/usePrice"
import { useWebSocket } from "contexts/WebSocket"
import { ShowHide } from "./../../v2/majesty/CatalogMajesty"
import { useSync, useProspect, readyStates } from "contexts/Prospect"

const Price = ({ rmc, reveal = () => true }) => {
  const fetch = usePrice({ rmc })
  const [data, setData] = useState("")
  const rspan = useRef()

  useEffect(() => {
    if (!process.browser) return
    //    console.log("+++", rmc)
    fetch((err, data) => {
      if (err) {
        setData("")
        return console.error(err)
      }
      setData(data?.price?.formatted ?? "")
      setTimeout(reveal, 80)
    })
  }, [rmc])

  return <p ref={rspan}>{data}</p>
}

function TogglePrice({ rmc }) {
  const [active, setActive] = useState(null)
  const sync = useSync("config")

  // const [policy, setPolicy] = useState(null)

  useLayoutEffect(() => {
    const onprice = ({ value }) => {
      setActive(value)
    }

    sync.addEventListener("price", onprice)
    return () => {
      sync.removeEventListener("price", onprice)
    }
  })

  return (
    <PPrice className='priceClient' variants={togglePrice} initial='hidden' animate={active ? "shown" : "hidden"}>
      <Price rmc={rmc} />
    </PPrice>
  )
}

const HeaderPrice = () => {
  const { active, puppet, std } = useWatchVariations()
  const [showhide, setShowhide] = useState(false)
  const [pricedisplay, setPricedisplay] = useState(false)
  const [rmc, setRmc] = useState(null)
  const translations = useTranslations()
  const ws = useWebSocket()
  const prospect = useProspect()
  const isProspect = prospect.readyState === readyStates.ACTIVE

  const sync = useSync("config")

  const handleClick = e => {
    if (puppet) return
    e.preventDefault()
    setShowhide(v => !v)
  }

  const update = v => {
    setPricedisplay(false)
    setRmc(v)
  }

  useEffect(() => {
    setPricedisplay(showhide)
    if (!puppet && !std && isProspect) sync.send("price", { value: showhide })
  }, [showhide])

  useEffect(() => mvSubscribe("HeaderPrice", active, update, false), [active, update])

  return std ? (
    <Toggle onClick={handleClick}>
      <motion.span variants={toggle} initial='inactive' animate={showhide ? "active" : "inactive"}>
        <p>{showhide ? translations["hide-price"] : translations["show-price"]}</p>
      </motion.span>
      <motion.div variants={togglePrice} initial='hidden' animate={pricedisplay ? "shown" : "hidden"}>
        <Price
          rmc={rmc}
          reveal={() => {
            setPricedisplay(showhide)
          }}
        />
      </motion.div>
    </Toggle>
  ) : !puppet ? (
    <ShowHide className='toggle' active={showhide} onClick={handleClick}>
      <motion.div>
        <Price rmc={rmc} />
      </motion.div>
      {isProspect && (
        <motion.button>
          <p>{showhide ? translations["hide-price"] : translations["show-price"]}</p>
        </motion.button>
      )}
    </ShowHide>
  ) : (
    <TogglePrice rmc={rmc} />
  )
}

export default HeaderPrice
