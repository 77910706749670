import { createContext, useContext } from "react"

const Context = createContext()
export const Provider = Context.Provider
export function useLobby() {
  return useContext(Context)
}

export const readyStates = {
  INACTIVE: 0,
  ALERT: 1,
  ACTIVE: 2,
}
