import React, { Suspense, useState, useEffect, useCallback, useRef, memo } from "react"
import { Main, Wrapper } from "./styles"
import { UPDATE_DELAY, FADE_DURATION, IN_DURATION, OUT_DURATION, FADE_EASE, IN_EASE, OUT_EASE } from "./../constants"
import { useWatchVariations, mvSubscribe } from "contexts/Configurator"
import { logmine, cssApply } from "./../utils"
import { useTransform } from "framer-motion"
import { AddToFavorites, AddToWishlist } from "components/components"
import { AddToWishlistSTD } from "components/components"
import { useWebSocket } from "contexts/WebSocket"
import { useProspect, readyStates } from "contexts/Prospect"
import { useTranslations } from "contexts/I18N"
import useSSR from "utils/useSSR"

const VARIANTS = {
  main: {
    hidden: (duration = OUT_DURATION) => [
      [
        "transition",
        `opacity ${duration}ms cubic-bezier(${OUT_EASE}),
        visibility 0ms ${duration}ms`,
      ],
      ["opacity", 0],
      ["visibility", "hidden"],
    ],
    visible: focus => [
      [
        "transition",
        `opacity ${IN_DURATION}ms cubic-bezier(${IN_EASE}),
        visibility 0ms 0ms`,
      ],
      ["visibility", "inherit"],
      ["opacity", 1],
    ],
  },
}

function useDisplay(busy, route, stepindex, mode, moving, ref) {
  //logmine("CustomHook", "WishlistButton", "useDisplay")
  const tmRef = useRef(null)

  const show = useCallback(
    state => {
      //logmine("Callback", "WishlistButton useDisplay", "show")
      const _stepindex = stepindex.get()
      const focus = _stepindex >= 7 || (mode === "cover" && _stepindex < 0)
      cssApply(ref.current, VARIANTS.main.visible(focus))
    },
    [ref, stepindex, mode]
  )

  const hide = useCallback(
    duration => () => {
      //logmine("Callback", "WishlistButton useDisplay", "hide")
      clearTimeout(tmRef.current)
      cssApply(ref.current, VARIANTS.main.hidden(duration))
    },
    [ref]
  )

  const update = useCallback(
    ([_moving, _stepindex, _busy]) => {
      //logmine("Callback", "WishlistButton useDisplay", "update")
      let state = !_moving && !_busy
      state = state && _stepindex >= 1
      return state
    },
    [mode]
  )

  const display = useCallback(
    v => {
      //logmine("Callback", "WishlistButton useDisplay", "display")
      clearTimeout(tmRef.current)
      let state = v >= 1
      if (!state) return hide(0)()
      tmRef.current = setTimeout(show, OUT_DURATION)
    },
    [show, hide]
  )

  //  const visible = useTransform([moving, stepindex, busy], update)
  useEffect(() => mvSubscribe("WishlistButton", stepindex, display), [stepindex, display])
  //  useEffect(() => mvSubscribe("WishlistButton", route, hide(0)), [route, hide])
}

export const WishlistButton = memo(({ withLabel }) => {
  //logmine("Main", "WishlistButton")
  const { mode, busy, wactive, route, stepindex, tint, moving, std } = useWatchVariations()
  const [rmc, setRmc] = useState(null)
  const [family, setFamily] = useState(null)
  const [theme] = useState(tint.get())
  const ref = useRef()
  const ws = useWebSocket()
  const translations = useTranslations()
  const ssr = useSSR()
  const prospect = useProspect()
  const isProspect = prospect.readyState === readyStates.ACTIVE

  const update = useCallback(v => {
    //logmine("Callback", "WishlistButton", "update", v)
    if (!v) return
    setRmc(v.uid)
    setFamily(v.family)
  }, [])

  useDisplay(busy, route, stepindex, mode, moving, ref)

  //  useEffect(() => mvSubscribe("WishlistButton", tint, v => setTheme(v)), [tint])
  useEffect(() => mvSubscribe("WishlistButton", wactive, update), [wactive, update])

  return !std && !isProspect ? null : (
    <Main ref={ref}>
      <>
        {ws.connector !== "standalone" && (
          <Suspense fallback={null}>
            <AddToWishlist rmc={rmc} withLabel={withLabel} />
          </Suspense>
        )}
        {!ssr && ws.connector === "standalone" && (
          <Suspense>
            <AddToWishlistSTD rmc={rmc} withLabel={withLabel} />
          </Suspense>
        )}
      </>
    </Main>
  )
})
