import React, { useState, useCallback, useEffect, useLayoutEffect, useRef, useMemo, memo } from "react"
import { Main, Cache, List, ListItem, WLoader } from "./styles"
import {
  IN_DURATION,
  OUT_DURATION,
  FADE_DURATION,
  FADE_EASE,
  INOUT_DURATION,
  IN_EASE,
  OUT_EASE,
  INOUT_EASE,
  UPDATE_LAG,
  KILL_ROUTE,
  LAUNCH_ROUTE,
  LAUNCH_DURATION,
  LAUNCH_EASE,
  FOCUS_DURATION,
  FOCUS_EASE,
} from "./../constants"
import { useMediaLoader, setPicture, setSource } from "./../hooks"
import { useWatchVariations, mvSubscribe, fromCollection } from "contexts/Configurator"
import { logmine, cssApply } from "./../utils"
import { useTransform } from "framer-motion"
import { useEnv } from "contexts/Env"
import { Image } from "components/components"
import { getSOLRPicture } from "solr/useSOLRPicture"
//import Loader from "./../../../Component/Loader"

const VARIANTS = {
  main: {
    hidden: [
      [
        "transition",
        `opacity ${INOUT_DURATION[1]}ms cubic-bezier(${OUT_EASE}),
        visibility 0ms ${INOUT_DURATION[1]}ms,
        transform ${LAUNCH_DURATION}ms cubic-bezier(${LAUNCH_EASE})`,
      ],
      ["opacity", 0],
      ["pointerEvents", "none"],
      ["transform", "scale(1)"],
      ["visibility", "hidden"],
    ],
    kill: [
      [
        "transition",
        `opacity 0ms cubic-bezier(${OUT_EASE}),
        visibility 0ms 0ms,
        transform 0ms cubic-bezier(${LAUNCH_EASE})`,
      ],
      ["opacity", 0],
      ["pointerEvents", "none"],
      ["transform", "scale(1)"],
      ["visibility", "hidden"],
    ],
    visible: [
      [
        "transition",
        `opacity ${INOUT_DURATION[0]}ms cubic-bezier(${IN_EASE}),
        visibility 0ms 0ms`,
      ],
      ["opacity", 1],
      ["pointerEvents", "none"],
      ["transform", "scale(1)"],
      ["visibility", "inherit"],
    ],
    focus: [
      [
        "transition",
        `opacity ${INOUT_DURATION[0]}ms cubic-bezier(${IN_EASE}),
        transform 0ms,
        visibility 0ms 0ms`,
      ],
      ["transform", "translate(-50%,var(--wv-focus-offset)) scale(var(--wv-cover-scale))"],
      ["pointerEvents", "auto"],
      ["opacity", 1],
      ["visibility", "inherit"],
    ],
    blur: [
      ["transition", `transform ${LAUNCH_DURATION}ms cubic-bezier(${LAUNCH_EASE})`],
      ["pointerEvents", "none"],
      ["transform", "scale(1)"],
    ],
    cursor: [["cursor", "default"]],
    pointer: [["cursor", "pointer"]],
  },
  loader: {
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.2,
        ease: OUT_EASE.split(",").map(v => +v),
      },
      transitionEnd: {
        visibility: "hidden",
      },
    },
    visible: {
      opacity: 1,
      visibility: "visible",
      transition: {
        duration: INOUT_DURATION[0] / 1000,
        ease: IN_EASE.split(",").map(v => +v),
        delay: 1,
      },
    },
  },
}

function useItems(steps, collection, newstepindex, stepindex, loadQ) {
  //logmine("CustomHook", "WatchRoller", "useItems")
  //  console.log(">>> shadow", shadow.url)
  const [items, setItems] = useState(null)
  const env = useEnv()

  const clear = useCallback(() => {
    return setItems(null)
  }, [])

  const update = useCallback(() => {
    //logmine("Callback", "WatchRoller", "useItems update")
    const _steps = steps.get()
    if (!_steps) return clear()
    let [_stepindex, , ..._items] = _steps.split(",")
    if (+_stepindex >= 0 && +_stepindex < 2) {
      loadQ(2)
      return clear()
    }
    const _collection = collection.get()
    if (!_collection) return clear()
    const item = fromCollection(_collection)(_items[0])
    if (!item) return
    //    const shadow = item.assets["shadow"]

    const shadow_item = {
      k: "WVWR_shadow",
      uid: item.uid,
      picture: getSOLRPicture({
        rmc: item.uid,
        policy: env.cloudinary.policies.configure.shadow,
        folder: env.cloudinary.folders.configure.shadow,
        env
      }),
    }

    _items = _items.map(v => ({
      k: `WVWR_${v}`,
      uid: v,
      picture: getSOLRPicture({
        rmc: v,
        policy: env.cloudinary.policies.configure.main,
        folder: env.cloudinary.folders.configure.roller,
        env
      }),
    }))

    setItems([shadow_item].concat(_items))
    //setItems(_items)
  }, [steps, collection, loadQ, clear])

  const collectionUpdate = useCallback(
    v => {
      if (!v) return
      const _newstepindex = newstepindex.get()
      if (_newstepindex <= 1) return
      update()
    },
    [newstepindex, update]
  )

  useEffect(() => mvSubscribe("WatchRoller useItems", steps, update), [steps, update])
  useEffect(() => mvSubscribe("WatchRoller useItems", collection, collectionUpdate), [collection, collectionUpdate])

  return items
}

const Item = memo(({ picture }) => {
  return (
    <ListItem>
      <Image sources={picture} lazy={false} sizes="50vw" />
    </ListItem>
  )
})

function useInit(newstepindex, initQ, mode) {
  //logmine("CustomHook", "WatchRoller", "useInit")
  const update = useCallback(
    v => {
      //logmine("Callback", "WatchRoller", "useInit update")
      if (v !== 1 && (mode === "cover" || v > 1)) initQ(2)
    },
    [initQ, mode]
  )

  useTransform(newstepindex, update)
  //  useEffect(() => mvSubscribe("WatchRoller", newstepindex, update), [newstepindex, update])
}

function useDisplay(items, mode, route, newstepindex, stepindex, loadQ, coverReady, hashing, ref) {
  //logmine("CustomHook", "WatchRoller", "useDisplay")
  const ready = useMediaLoader("WVWR", ref, items)

  const alter = useCallback(
    v => {
      cssApply(ref.current, VARIANTS.main[v])
    },
    [ref]
  )

  const blur = useCallback(() => {
    alter("blur")
  }, [alter])

  const focus = useCallback(() => {
    alter("focus")
  }, [alter])

  const show = useCallback(() => {
    alter("visible")
  }, [alter])

  const hide = useCallback(() => {
    alter("hidden")
  }, [alter])

  const kill = useCallback(() => {
    alter("kill")
  }, [alter])

  const routeUpdate = useCallback(
    v => {
      //logmine("Callback", "WatchRoller", "useDisplay routeUpdate", v)
      if (v === LAUNCH_ROUTE) blur()
      else if (v === KILL_ROUTE) kill()
    },
    [blur, kill]
  )

  const newstepindexUpdate = useCallback(
    v => {
      //logmine("Callback", "WatchRoller", "useDisplay newstepindexUpdate", v)
      if (v >= 0 && v < 3) hide()
      /*    else if  (mode==="cover"&&v<0) {
      cssApply(ref.current, VARIANTS.main.hidden)
    }*/ else if (mode === "global") {
        const _stepindex = stepindex.get()
        if (_stepindex >= 7) blur()
        else if (v >= 7 && ready.get()) focus()
        else if (v < 0) hide()
      }
    },
    [blur, focus, hide, mode, stepindex, ready]
  )

  const display = useCallback(
    ([_ready, _stepindex]) => {
      //logmine("Callback", "WatchRoller", "useDisplay update", _ready, _stepindex)
      if (!_ready) return
      loadQ(2)
      const _route = route.get()
      if (_route === LAUNCH_ROUTE) return
      if (mode === "cover") {
        hashing.set(false)
        if (!_route) {
          if (_stepindex < 0) {
            focus()
            coverReady.set(true)
          }
        } else if (_stepindex < 3) hide()
        else show()
        //      cssApply(ref.current, !_route?VARIANTS.main.focus:_stepindex===1?VARIANTS.main.hidden:VARIANTS.main.visible)
      } else {
        if (_stepindex < 3) return
        else if (_stepindex >= 7) focus()
        else if (_stepindex >= 3) show()
        else hide()
      }
    },
    [route, coverReady, mode, hashing, loadQ, focus, show, hide]
  )

  const stepindexUpdate = useCallback(
    v => {
      //logmine("Callback", "WatchRoller", "useDisplay stepindexUpdate", v)
      const pointer = mode === "cover" && v < 0
      cssApply(ref.current, VARIANTS.main[pointer ? "pointer" : "cursor"])
    },
    [ref, mode]
  )
  /*
  const wupdateUpdate = useCallback(v => {
    //logmine("Callback", "WatchRoller", "useDisplay wupdateUpdate", v)
    if (v) {
      cssApply(ref.current, VARIANTS.main.hidden)
    }
  }, [ref])
*/
  useTransform([ready, stepindex], display)
  useEffect(() => mvSubscribe("WatchRoller useDisplay", route, routeUpdate), [route, routeUpdate])
  useEffect(() => mvSubscribe("WatchRoller useDisplay", newstepindex, newstepindexUpdate), [newstepindex, newstepindexUpdate])
  useEffect(() => mvSubscribe("WatchRoller useDisplay", stepindex, stepindexUpdate), [stepindex, stepindexUpdate])
  //  useEffect(() => mvSubscribe("WatchRoller useDisplay", wupdate, wupdateUpdate), [wupdate, wupdateUpdate])
}

function buildZIndexes(arr) {
  let k = 1
  return arr.reduce((a, item) => {
    a[item] = k++
    return a
  }, Object.create(null))
}

function firstPos(arr, item) {
  const index = arr.indexOf(item)
  if (index < 0) return
  arr.splice(index, 1)
  arr.unshift(item)
}

function fade(state, zIndex, straight) {
  return state
    ? [
      ["animation", `wvwrfadein ${straight ? 0 : FADE_DURATION}ms cubic-bezier(${FADE_EASE})`],
      ["animation-fill-mode", "both"],
      ["zIndex", zIndex],
    ]
    : [
      ["animation", `wvwrfadeout ${straight ? 0 : 300}ms linear ${straight ? 0 : FADE_DURATION}ms`],
      ["animation-fill-mode", "both"],
      ["zIndex", zIndex],
    ]
}

function animate(list, selected, zIndexes, straight) {
  list.forEach((el, i) => {
    cssApply(el, fade(+(i === selected), zIndexes[i], straight))
  })
}

function useHighlight(items, ref, active) {
  //logmine("CustomHook", "WatchRoller", "useHighlight")
  const listRef = useRef(null)
  const colRef = useRef(null)

  const update = useCallback(
    straight => {
      //logmine("Callback", "WatchRoller", "useHighlight update", straight)
      const _active = active.get()
      const current = listRef.current
      if (!_active || !current || !items) return
      const selected = items
        .slice(1)
        .map(v => v.uid)
        .indexOf(_active)
      firstPos(current, selected)
      const zIndexes = buildZIndexes(current.slice().reverse())
      animate(colRef.current, selected, zIndexes, straight)
    },
    [items, active]
  )

  useEffect(() => {
    //logmine("useEffect", "VarNavRoller", "useHighlight", "items", items)
    if (!items) return
    listRef.current = items.slice(1).map((v, i) => i)
    //listRef.current = items.map((v, i) => i)
    colRef.current = [...ref.current.querySelectorAll("div")].slice(1)
    update(true)
  }, [items, update, ref])

  //  useTransform(active, v => update(false))
  useEffect(() => mvSubscribe("WatchRoller useHighlight", active, v => update(false), false), [active, update])
}

export const WatchRoller = memo(({ ctx, onCoverClick }) => {
  //logmine("Main", "WatchRoller")
  const { focus, hashing, mode, steps, newstepindex, stepindex, active, collection, route, coverReady, loadQ, initQ, highlight } = useWatchVariations(ctx)
  const ref = useRef()
  const items = useItems(steps, collection, newstepindex, stepindex, loadQ)

  const highlightUpdate = useCallback(v => {
    ref.current.setAttribute("data-highlight", v)
  }, [])

  useInit(newstepindex, initQ, mode)
  useDisplay(items, mode, route, newstepindex, stepindex, loadQ, coverReady, hashing, ref)
  useHighlight(items, ref, active)

  useEffect(() => mvSubscribe("WatchRoller", highlight, highlightUpdate, false), [highlight, highlightUpdate])

  const onClick = useCallback(
    e => {
      if (mode === "cover" && stepindex.get() < 0 && onCoverClick) return onCoverClick()
      /*    else if (mode!=="global")
      return focus()*/
    },
    [mode, stepindex, onCoverClick]
  )

  return (
    <Main /* onClick={onClick}*/>
      {/*<Load/>*/}
      <List ref={ref}>{!!items && items.map(item => <Item {...item} key={item.k} />)}</List>
    </Main>
  )
})
