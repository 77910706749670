// import QS from "qs"
import { useEnv } from "contexts/Env"
export function getSOLRPicture({ rmc, policy, folder, env = {} }) {
  const base = "https://" + env.cloudinary.secureDistribution

  return [
    {
      id: `${rmc}-0`,
      alt: "",
      srcset: [240, 320, 640, 800, 1200, 1600, 1920, 2440].map(width => {
        const pathname = `/image/upload/q_auto/f_auto/${policy}/c_limit,w_${width}/v1/${env.cloudinary.path}${folder}/${rmc}`
        return { width, url: new URL(pathname, base) }
      })
    },
  ]
}

export default function useSOLRPicture(opts) {
  const env = useEnv()
  return getSOLRPicture(Object.assign(opts, { env }))
}

//avec comme option de taille : 420, 450, 550, 600, 680, 750, 780, 840, 900, 1000, 1200, 1350, 1920, 2400, 2880
