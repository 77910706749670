import { useEffect, useCallback, useLayoutEffect } from "react"
import { ROUTE_REPLACE, ROUTE_IGNORE, KILL_DURATION } from "./../constants"
import { logmine, hashIt } from "./../utils"
import { mvSubscribe } from "contexts/Configurator"
import { useProspect } from "contexts/Prospect"
import { useNavigation } from "contexts/Navigation"
import { useWebSocket } from "contexts/WebSocket"
import { useSync } from "contexts/Prospect"

export const useHash = (route, fromui, hashing, family, puppet, std) => {
  const prospect = useProspect()
  const navigation = useNavigation()
  const sync = useSync("config")
  //logmine("CustomHook", "useHash")
  const update = useCallback(
    v => {
      //logmine("Callback", "useHash", "updateRoute", v)
      if (!process.browser || puppet) return
      let hash = v
      let replace = hash.indexOf(ROUTE_REPLACE) === 0
      if (replace) hash = hash.split("").slice(1).join("")
      const _fromui = fromui.get()

      if (!_fromui) return
      if (!hash) return hashIt(null)
      //    if (hash.length<3)
      //      return
      if (hash.indexOf(ROUTE_IGNORE) === 0) return

      hashIt(hash, replace)
    },
    [fromui, family]
  )

  const onHashChange = useCallback(
    e => {
      let _hash = window.location.hash.split("").slice(1).join("")
      //logmine("Callback", "useHash", "onHashChange", _hash)
      //    if (_hash.length<3)
      //      _hash = null
      hashing.set(true)
      fromui.set(false)

      let tm = setTimeout(() => {
        route.set(_hash)
        //        if (!puppet) prospect.navigate([navigation.slug, _hash].join("#"))
      }, KILL_DURATION * 1.1)

      return () => clearTimeout(tm)
    },
    [route, fromui, hashing]
  )

  function onHashing(v) {
    if (puppet || std) return
    sync.send("hashing", { value: v })
  }

  useLayoutEffect(() => {
    if (!puppet) return
    const onupdate = ({ value }) => {
      //      console.log("route", value)
      hashing.set(value)
    }

    sync.addEventListener("hashing", onupdate)
    return () => {
      sync.removeEventListener("hashing", onupdate)
    }
  })

  useEffect(() => {
    //logmine("useEffect", "useHash")
    if (!process.browser || puppet) return
    window.addEventListener("hashchange", onHashChange, false)
    onHashChange(false)
    return () => window.removeEventListener("hashchange", onHashChange, false)
  }, [onHashChange])

  //  useTransform(route, update)
  useEffect(() => mvSubscribe("useHash", route, update, false), [route, update])
  useEffect(() => mvSubscribe("useHash", hashing, onHashing, false), [])
}
