/**
 * don't copy this implementation
 * we use a single source of state without provider
 * to make this context "global"
 */

import { createContext, useContext } from "react"

const Context = createContext()
export const Provider = Context.Provider

export const readyStates = {
  IDLE: 0,
  CHECKING: 1,
  UNPAIRED: 2,
  PAIRED: 3,
}

export function usePairing() {
  return useContext(Context)
}
