import { useConsole } from "contexts/Console"

export default function useSocialRenditions(rmc) {
  const console = useConsole()
  const BASE_URL = "https://content.rolex.com"

  const renditions = data => {
    const renditions = []
    if (data.length > 0) {
      data.forEach(item => {
        const url = `${BASE_URL}${item.variations[0].url}`
        renditions.push(url)
      })
    }

    return renditions
  }

  const host = process.browser ? `${global.location.protocol}//${global.location.hostname}` : `http://${process.env.SERVICE_NGINX}`
  const pathname = `/services/social/renditions/${rmc}`

  return (handler = Function.prototype) => {
    const url = new URL(pathname, host)
    const controller = new AbortController()
    const request = new Request(url)
    const signal = controller.signal

    fetch(request, { signal })
      .then(response => response.json())
      .then(data => renditions(data))
      .then(data => handler(undefined, { social_renditions: data }))
      .catch(err => {
        if (err.name === "AbortError") return
        handler(err, undefined)
      })

    console.verbose("useSocialRenditions(%o) => fetch", { url })
    return () => {
      controller.abort()
    }
  }
}
