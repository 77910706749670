import { Switch, Route } from "react-router-dom"
import { useConsole } from "contexts/Console"
import { useNavigation } from "contexts/Navigation"
import { generateComponent } from "utils/generateComponent"
import { Provider } from "contexts/Layout"
import { Suspense } from "react"
import { Helmet } from "react-helmet-async"
import { useSuspender } from "contexts/Suspender"
import { useWebSocket } from "contexts/WebSocket"
import { useParams } from "react-router-dom"

function bundle(...trees) {
  trees = JSON.parse(JSON.stringify(trees.filter(Boolean)))

  const root = trees.shift()
  let curr = root.symbol || root

  while (true) {
    if (curr?.children?.[0]) curr = curr.children[0]?.symbol || curr.children[0]
    else break
  }

  while (trees.length) {
    const tree = trees.shift()
    const component = tree.symbol || tree

    if (Array.isArray(component)) {
      curr.children = Array.isArray(curr.children) ? [...curr.children, ...component].filter(Boolean) : component
    } else {
      curr.children ??= []
      if (component) curr.children.push(component)
    }
    curr = component
  }

  return Array.isArray(root) ? root[0] : root
}

export default function Layout({ layouts = [] }) {
  const console = useConsole()
  const navigation = useNavigation()

  layouts = layouts.reduce((accumulator, layout) => {
    accumulator[layout.locale] = layout
    return accumulator
  }, {})

  console.verbose("Layout(%o)", { layouts, pages: navigation.pages })
  return (
    <Switch>
      {navigation.pages.map(({ id, route: { slug, exact, refresh }, locale, header, layout, breadcrumb, fetch, title, description, metas }) => {
        const path = navigation.route(slug, locale).url
        refresh = typeof refresh === "boolean" ? refresh : true
        console.verbose("Layout::define page(%o)", { locale, slug, path, exact, refresh, header, layout, breadcrumb })
        return (
          <Route
            key={refresh ? id + "-" + Date.now() : id}
            path={path}
            exact={exact}
            render={() => {
              /**
               * the layout context helps to set various value immediatly
               * for ssr or preventing clipping/flashes
               */

              return (
                <Suspense>
                  <Page
                    layouts={layouts}
                    slug={slug}
                    path={path}
                    locale={locale}
                    fetch={fetch}
                    title={title}
                    description={description}
                    metas={metas}
                    header={header}
                    breadcrumb={breadcrumb}
                    layout={layout}
                  />
                </Suspense>
              )
            }}
          />
        )
      })}
    </Switch>
  )
}

function Page({ layouts, slug, path, locale, fetch, title, description, metas, header, breadcrumb, layout }) {
  const console = useConsole()
  const navigation = useNavigation()
  const [page] = useSuspender(["page", path], () => navigation.model(path), fetch && { title, description, metas, layout })

  const ctx = {
    breadcrumb,
    header,
    title,
    description,
  }

  console.verbose("Page(%o)", { slug, path, fetch, title, description, metas })
  return (
    <>
      <Helmet>
        <title>{page.title ?? ""}</title>
        {page.description && <meta name='description' content={page.description} />}
        {page?.metas?.map?.(({ name, content }, i) => (
          <meta key={i} name={name} content={content} />
        ))}
      </Helmet>
      <WAPage page={page} />
      <Provider value={ctx}>{generateComponent(bundle(...[layouts[locale]?.header, layouts[locale]?.footer, page.layout].filter(Boolean)), console)}</Provider>
    </>
  )
}

function WAPage({ children, page }) {
  const navigation = useNavigation()
  const console = useConsole()
  const ws = useWebSocket()
  const params = useParams()

  if (process.browser && global.digitalDataLayer) {
    const query = new URLSearchParams(global.location.search.slice(1))
    const from = global.digitalDataLayer?.page?.pageInfo ?? {}

    let pageName, pageType, pageSiteSection, pageSiteSubSection, productFamily, productRMC, pageScreenMode

    if (navigation.slug === "/") {
      pageName = "home"
      pageType = "homepage"
      pageSiteSection = "homepage"
      pageSiteSubSection = ""
    }

    if (navigation.slug.endsWith("/watches") && query.get("q")) {
      pageName = "search results"
      pageType = "search results page"
      pageSiteSection = "search"
      pageSiteSubSection = ""
    }

    if (navigation.slug.endsWith("/watches") && !query.get("q")) {
      pageName = "catalogue"
      pageType = "catalogue page"
      pageSiteSection = "watches"
      pageSiteSubSection = "catalogue"
    }

    if (navigation.slug.startsWith("/watches") && params.family && params.rmc) {
      pageName = navigation.slug.replaceAll("/", ":").slice(1)
      pageType = "model page"
      pageSiteSection = "watches"
      pageSiteSubSection = params.family
      productFamily = params.family
      productRMC = params.rmc
    }

    if (navigation.slug.startsWith("/wishlist")) {
      pageName = "wishlist"
      pageType = "wishlist page"
      pageSiteSection = "wishlist"
      pageSiteSubSection = ""
    }

    if (navigation.slug.startsWith("/media")) {
      pageName = "media:library"
      pageType = "media library page"
      pageSiteSection = "media library"
      pageSiteSubSection = "library"
    }

    if (navigation.slug.endsWith("/configure")) {
      const [family, rmc, step] = global.location.hash.slice(1).split("/").filter(Boolean)
      pageName = `watches:configure:${step}:${family}`
      pageType = "configurator"
      pageSiteSection = "watches"
      pageSiteSubSection = "configure"
    }

    if (navigation.app === "embed" && navigation.slug.startsWith(`/${params.family}`)) {
      pageName = `watches:${params.family}:${params.rmc}`
      pageType = "model page"
      pageSiteSection = "watches"
      pageSiteSubSection = params.family
      productFamily = params.family
      productRMC = params.rmc
    }

    if (navigation.app === "embed" && navigation.slug.endsWith("/")) {
      pageName = `watches`
      pageType = "catalogue page"
      pageSiteSection = "watches"
      pageSiteSubSection = ""
    }

    if (!pageName) {
      //defaults
      pageName = navigation.slug.replaceAll("/", ":")
      pageType = ""
      pageSiteSection = ""
      pageSiteSubSection = ""
    }

    if (navigation.app !== "embed") {
      global.digitalDataLayer.page = {
        pageInfo: {
          pageName,
          pageSiteSection, // channel (site section)
          pageSiteSubSection, // channel (site section)
          pageType, // page template
          pageURL: global.location.href, // page URL
          //pagePreviousURL: from?.pageURL ?? "", previous URL
          pageScreenMode: ws.connector === "catalog" ? "retailer screen" : ws.connector === "puppet" ? "client screen" : "standalone screen",
        },
      }
    } else {
      global.digitalDataLayer.page = {
        pageInfo: {
          pageName,
          pageSiteSection, // channel (site section)
          pageSiteSubSection, // channel (site section)
          pageType, // page template
          pageURL: global.location.href, // page URL
          //pagePreviousURL: from?.pageURL ?? "", previous URL
          pageScreenMode: "embed screen",
        },
      }
    }

    if (
      (navigation.slug.startsWith("/watches") && params.family && params.rmc) ||
      (navigation.slug.startsWith(`/${params.family}`) && params.family && params.rmc)
    ) {
      global.digitalDataLayer.products = [
        {
          productDetails: {
            productFamily,
            productRMC,
          },
        },
      ]
    }
  }

  console.verbose("WAPage(%o)", { page })
  return children
}
