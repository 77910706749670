import { createContext, useContext } from "react"

const Context = createContext()
export const Provider = Context.Provider
export function usePalette() {
  return useContext(Context).palette
}
export function useOutline() {
  return useContext(Context).outlines
}
